import { UserData } from "../context/AuthContext";

const AUTH_MANAGER_KEYS = {
  TOKEN: 'token',
  OLDAPIToken: 'oldApiToken',
  USER: 'user'
};

export const authManager = {
  set: async (token: string, oldApiToken: string) => {
    try {
      localStorage.setItem(AUTH_MANAGER_KEYS.TOKEN, token);
      localStorage.setItem(AUTH_MANAGER_KEYS.OLDAPIToken, oldApiToken);
      return true
    } catch {
      return false;
    }
  },

  get: () => {
    return { token: localStorage.getItem(AUTH_MANAGER_KEYS.TOKEN), oldApiToken: localStorage.getItem(AUTH_MANAGER_KEYS.OLDAPIToken) };
  },

  clear: async () => {
    localStorage.removeItem(AUTH_MANAGER_KEYS.TOKEN);
    localStorage.removeItem(AUTH_MANAGER_KEYS.OLDAPIToken);
  },
};

export const userManager = {
  set: async (user: UserData) => {

    let _user = { ...user };
    _user.USER_ACESSO_SISTEMA = `${user.USER_ACESSO_SISTEMA}`?.trim();

    try {
      localStorage.setItem(AUTH_MANAGER_KEYS.USER, JSON.stringify(_user));
      return true;
    } catch {
      return false;
    }
  },

  get: () => {
    function isUserData(o: any): o is UserData {
      return "CD_APLICACAO" in o && Array.isArray(o["CD_APLICACAO"])
    }

    const _user = localStorage.getItem(AUTH_MANAGER_KEYS.USER);
    if (_user) {
      const userObj = JSON.parse(_user);
      if (!isUserData(userObj)) {
        localStorage.removeItem(AUTH_MANAGER_KEYS.USER);
        localStorage.removeItem(AUTH_MANAGER_KEYS.TOKEN);
        localStorage.removeItem(AUTH_MANAGER_KEYS.OLDAPIToken);
        window.location.pathname = '/login'
      }
      return userObj;
    }
  },

  clear: async () => {
    localStorage.removeItem(AUTH_MANAGER_KEYS.USER);
  },
};

