import moment from 'moment';
import {
  Calendar,
  CalendarProps, Messages, momentLocalizer
} from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css'; // main css file

const lang: Messages = {
  week: 'Semana',
  date: 'Data',
  time: 'Horário',
  event: 'Evento',
  work_week: 'Semana de trabalho',
  day: 'Dia',
  month: 'Mês',
  previous: 'Anterior',
  next: 'Próximo',
  today: 'Hoje',
  yesterday: 'Amanhã',
  agenda: 'Agenda',
  noEventsInRange: "Nenhum evento nesse período",
  showMore: (total) => `+${total} mais`,
}

const CustomBigCalendar = (props: Partial<CalendarProps>) => {
  const localizer = momentLocalizer(moment);
  return (
    <Calendar messages={lang} {...props} localizer={localizer}
      style={{ height: 500 }} />
  );
}

export default CustomBigCalendar;