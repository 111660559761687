// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Logo_img__1bNAb.Logo_sm__KeJY7 {\n  height: 45px;\n}\n.Logo_img__1bNAb.Logo_md__2JL7v {\n  height: 100px;\n}\n.Logo_img__1bNAb.Logo_lg__3bKIJ {\n  height: 200px;\n}\n\n.Logo_text__2tII4.Logo_sm__KeJY7 {\n  height: 10px;\n}\n.Logo_text__2tII4.Logo_md__2JL7v {\n  height: 40px;\n}\n.Logo_text__2tII4.Logo_lg__3bKIJ {\n  height: 60px;\n}", "",{"version":3,"sources":["webpack://src/components/Logo/Logo.module.scss"],"names":[],"mappings":"AACI;EACI,YAAA;AAAR;AAGI;EACI,aAAA;AADR;AAII;EACI,aAAA;AAFR;;AAQI;EACI,YAAA;AALR;AAQI;EACI,YAAA;AANR;AASI;EACI,YAAA;AAPR","sourcesContent":[".img{\r\n    &.sm{\r\n        height: 45px;\r\n    }\r\n    \r\n    &.md{\r\n        height: 100px;\r\n    }\r\n    \r\n    &.lg{\r\n        height: 200px;\r\n    }\r\n}\r\n\r\n\r\n.text{\r\n    &.sm{\r\n        height: 10px;\r\n    }\r\n    \r\n    &.md{\r\n        height: 40px;\r\n    }\r\n    \r\n    &.lg{\r\n        height: 60px;\r\n    }\r\n}\r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"img": "Logo_img__1bNAb",
	"sm": "Logo_sm__KeJY7",
	"md": "Logo_md__2JL7v",
	"lg": "Logo_lg__3bKIJ",
	"text": "Logo_text__2tII4"
};
export default ___CSS_LOADER_EXPORT___;
