
import { useRef, useState } from "react";
import { useMutation, useQuery } from "react-query";
import moment from "moment";
import "moment/locale/pt-br";

import {
  Box,
  Container,
  HStack,
  Image,
  Stack,
  Text,
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogOverlay,
  useDisclosure,
  AlertDialogFooter,
  useBreakpointValue,
  Flex,
} from "@chakra-ui/react";

import Breadcrumb from "../../../components/Breadcrumb/Breadcrumb";
import IconButton from "../../../components/IconButton/IconButton";
import PageLayout from "../../../components/PageLayout/PageLayout";
import ActionButton from "../../../components/ActionButton/ActionButton";
import Loading from "../../../components/Loading/Loading";
import { PageTitle } from "../../../components/PageTitle/PageTitle";

import { ROUTES } from "../../../constants/routes";

import styles from "./EmittedInvites.module.scss";
import trashIcon from "../../../assets/icons/trash-icon.svg";
import { useInvitesService } from "../../../services/invites.service";
import { OverlayBackdrop } from "../../../components/OverlayBackdrop/OverlayBackdrop";

import { capitalize } from "../../../helpers/capitalize";
import { uppercase } from "../../../helpers/uppercase";

import { Invite } from "../../../models/invite.model";
import useCustomToast from "../../../hooks/useCustomToast";
import { userManager } from "../../../services/authManager";
import { InviteTicket } from "../../../components/Ticket/Ticket";


type EmittedInvitesProps = {
  nautical?: boolean;
};

type DeleteInviteMutation = {
  id: number;
  user: string;
}

export default function EmittedInvites({ nautical }: EmittedInvitesProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [selectedInvite, setSelectedInvite] = useState<Invite>(null!);

  const cancelRef: any = useRef();

  const [invites, setInvites] = useState<any[]>([]);

  const { USER_ACESSO_SISTEMA } = userManager.get()

  const handleDelete = () => {
    const data = {
      id: selectedInvite.NR_CONVITE!,
      user: USER_ACESSO_SISTEMA,
    }
    deleteInviteMutation.mutate(data);
  };

  const { showToast } = useCustomToast();

  const { getInvites, deleteInvite } = useInvitesService();

  const deleteInviteMutation = useMutation(({ id, user }: DeleteInviteMutation) => deleteInvite(id, user), {
    onSuccess: () => {
      showToast("success", "Convite excluído.");
      refetch();
    },
    onError: () => showToast("error", "Não foi possível excluir o dependente."),
  });


  const { isLoading, isFetching, refetch } = useQuery<any[], Error>(
    "listaConvitesEmitidos",
    async () => {
      return await getInvites(USER_ACESSO_SISTEMA);
    },
    {
      refetchOnWindowFocus: false,
      onSuccess: (data: any[]) => {
        let _invites: any[] = [...data];
        // if (nautical) {
        //   _invites = [..._invites.filter((x) => x.tipoConvite.iden === 3)];
        // }

        setInvites(_invites);
      },
      onError: () => showToast("error", "Erro ao listar convites emitidos."),
      onSettled: () => setSelectedInvite(null!),
    }
  );

  const isMobile = useBreakpointValue({ base: true, md: false });

  const handleInviteDetails = (invite: Invite) => {
    setSelectedInvite(invite);
    setModalVisible(true);
  };

  return (
    <PageLayout>
      <Container maxW="container.md" pt={5} pb={20}>
        <Stack spacing={8}>
          <Breadcrumb
            items={
              nautical
                ? [
                  { route: "/", label: "Início" },
                  { route: ROUTES.NAUTICAL, label: "Náutica" },
                  {
                    route: ROUTES.NAUTICAL_INVITES,
                    label: "Convites para embarcação",
                  },
                ]
                : [
                  { route: "/", label: "Início" },
                  { route: ROUTES.INVITES_SELECT, label: "Convites" },
                  { route: ROUTES.INVITES_GENERAL, label: "Geral" },
                  {
                    route: ROUTES.INVITES_EMITTED,
                    label: "Convites Emitidos",
                  },
                ]
            }
          />
          {isLoading || isFetching ? (
            <Flex justifyContent="center" alignItems="center" h="200">
              <Loading />
            </Flex>
          ) : (
            <Stack
              justifyContent={"center"}
              w="full"
              display={"flex"}
              alignItems={"center"}
              spacing={6}
            >
              <Box marginY={[4, 4, 10]}>
                <PageTitle>Convites emitidos</PageTitle>
              </Box>

              {(!invites || invites.length === 0) && (
                <Text alignSelf={"center"}>Não existem convites emitidos.</Text>
              )}

              {invites.length > 0 &&
                invites.map((x, i) => (
                  <HStack spacing={6} key={i}>
                    <Box
                      onClick={() => handleInviteDetails(x)}
                      className={styles.ticket}
                      w={["280px", "300px", "500px"]}
                      maxW={"90%"}
                    >
                      <div className={styles.date}>
                        <Stack
                          spacing={0}
                          justifyContent={"center"}
                          alignItems={"center"}
                          textAlign="center"
                        >
                          <Text color={"primary"} fontSize={12}>Válido até o dia</Text>
                          <Text color={"primary"} fontSize={12}>
                            {capitalize(moment(x.DT_MAX_UTILIZACAO).format("DD/MM/YYYY"))}
                          </Text>
                        </Stack>
                      </div>
                      <Box
                        className={styles.info}
                        pl={["1rem", "1rem", "1.5rem"]}
                      >
                        <Stack spacing={0} marginRight={"auto"}>
                          <Text
                            color={"primary"}
                            fontWeight={"bold"}
                            fontSize={[15, 18, 20]}
                          >
                            Convidado - {uppercase(x.NO_CONVIDADO)}
                          </Text>

                          <Text color={"primary"} fontSize={[12, 12, 14]}>
                            Tipo: Geral {x.CD_CATEGORIA_CONVITE === 'GR'
                              ? capitalize('GRATUITO')
                              : capitalize('VENDIDO')
                            }
                          </Text>
                          <Text color={"primary"} fontSize={[12, 12, 14]}>
                            Código Convite: {x.NR_CONVITE}
                          </Text>
                        </Stack>
                      </Box>
                    </Box>
                    <IconButton
                      onClick={() => {
                        setSelectedInvite(x);
                        onOpen();
                      }}
                    >
                      <Image src={trashIcon} alt={"Excluir"} />
                    </IconButton>
                  </HStack>
                ))}
            </Stack>
          )}
        </Stack>
      </Container>

      <AlertDialog
        size={"xs"}
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        isCentered
      >
        <AlertDialogOverlay>
          <AlertDialogContent maxW={'90vw'}>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              <AlertDialogCloseButton />
            </AlertDialogHeader>
            <AlertDialogBody>
              <Box>
                <Text>Tem certeza que deseja excluir o convite?</Text>
              </Box>
            </AlertDialogBody>
            <AlertDialogFooter>
              <HStack justifyContent={"end"} alignItems={"center"}>
                <ActionButton variant={"outline"} onClick={onClose}>
                  Não
                </ActionButton>
                <ActionButton
                  variant={"primary"}
                  onClick={() => {
                    onClose();
                    handleDelete();
                  }}
                >
                  Sim
                </ActionButton>
              </HStack>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>

      <OverlayBackdrop
        setModalVisible={setModalVisible}
        modalVisible={modalVisible}
      >
        <Stack>
          <Box borderRadius={10}>
            <InviteTicket invite={selectedInvite} />
          </Box>
        </Stack>
      </OverlayBackdrop>
    </PageLayout >
  );
}
