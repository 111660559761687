// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".LoginForm_loginForm__3YWEo {\n  display: flex;\n  flex-direction: column;\n}\n.LoginForm_loginForm__3YWEo h2 {\n  color: #0d4f7b;\n  font-weight: bold;\n}", "",{"version":3,"sources":["webpack://src/pages/Login/LoginForm/LoginForm.module.scss","webpack://src/styles/variables.scss"],"names":[],"mappings":"AAEA;EACI,aAAA;EACA,sBAAA;AADJ;AAGI;EACI,cCNE;EDOF,iBAAA;AADR","sourcesContent":["@import '../../../styles/variables.scss';\r\n\r\n.loginForm{\r\n    display: flex;\r\n    flex-direction: column;\r\n\r\n    h2{\r\n        color: $primary;\r\n        font-weight: bold;\r\n    }\r\n}","// $primary: #003E66;\r\n$primary: #0d4f7b;\r\n$danger: #F03D3E;\r\n$success: #44AC80;\r\n$white: #FFFFFF;\r\n\r\n$border-radius: 8px;\r\n$navbar-height: 50px;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loginForm": "LoginForm_loginForm__3YWEo"
};
export default ___CSS_LOADER_EXPORT___;
