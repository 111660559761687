// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".DebtPayments_table__lsE99 tbody tr td {\n  cursor: pointer;\n}\n.DebtPayments_table__lsE99 tbody tr:hover td {\n  background-color: #f7f7f7;\n}\n\n.DebtPayments_chevron__3PLOF {\n  font-size: 26px;\n}", "",{"version":3,"sources":["webpack://src/pages/Financial/DebtPayments/DebtPayments.module.scss"],"names":[],"mappings":"AAGM;EACE,eAAA;AAFR;AAKQ;EACE,yBAAA;AAHV;;AAUE;EACE,eAAA;AAPJ","sourcesContent":["\r\n.table {\r\n    tbody tr {\r\n      td {\r\n        cursor: pointer;\r\n      }\r\n      &:hover {\r\n        td {\r\n          background-color: #f7f7f7;\r\n        }\r\n      }\r\n    }\r\n  }\r\n  \r\n  \r\n  .chevron{\r\n    font-size: 26px;\r\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"table": "DebtPayments_table__lsE99",
	"chevron": "DebtPayments_chevron__3PLOF"
};
export default ___CSS_LOADER_EXPORT___;
