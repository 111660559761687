import { ENDPOINTS } from "../constants/endpoints";
import useAxios from "../hooks/useAxios";
import useOldApi from "../hooks/useOldApi";

const oldApiUser = {
  carteira: '919191',
  senha: '123456'
}

export function useLoginService() {
  const axios = useAxios();
  const oldApi = useOldApi();

  const login = async (user: string, password: string) => {
    const newApiLogin = await axios.post(ENDPOINTS.login, { usuario: user, senha: password });

    let getOldApiToken;
    try {
      getOldApiToken = await oldApi.post('/login', { carteira: oldApiUser.carteira, senha: oldApiUser.senha });
    } catch (error) {
      console.error('Erro tentando logar na outra API: ', error);
    }


    const response = { ...newApiLogin.data, oldApiToken: getOldApiToken?.headers.authorization }; //

    return response;
  };

  const getDados = async (user: string, password: string) => {
    const response = await axios.post(ENDPOINTS.getUser, { usuario: user, senha: password });
    return response.data;
  };

  const resetPassword = async (email: string) => {
    return await axios.post(ENDPOINTS.resetPassword, { email });
  };

  const services = {
    login,
    resetPassword,
    getDados
  };

  return services;
}
