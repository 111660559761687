import {
  Container, NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Stack,
} from "@chakra-ui/react";
import moment from "moment";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import ActionButton from "../../../components/ActionButton/ActionButton";
import Actions from "../../../components/Actions/Actions";
import Breadcrumb from "../../../components/Breadcrumb/Breadcrumb";
import PageLayout from "../../../components/PageLayout/PageLayout";
import { PageTitle } from "../../../components/PageTitle/PageTitle";
import { ROUTES } from "../../../constants/routes";

export default function DeclarationOfDischarges() {
  const initialYear = moment().subtract(1,'year').format('YYYY');
  const [year, setYear] = useState(initialYear);
  const navigate = useNavigate();

  const generateDeclarationOfDischarge = () => {
    navigate(`${ROUTES.FINANCIAL_DECLARATION_OF_DISCHARGE}/${year}`);
  };

  return (
    <PageLayout noPaddingX>
      <Container maxW="container.md" pt={5} pb={20}>
        <Stack spacing={8}>
          <Breadcrumb
            items={[
              { route: "/", label: "Início" },
              { route: ROUTES.FINANCIAL, label: "Financeiro" },
              {
                route: ROUTES.FINANCIAL_DECLARATION_OF_DISCHARGE,
                label: "Declaração de Quitação",
              },
            ]}
          />
          <PageTitle>Declaração de Quitação</PageTitle>
          <Actions justify="left">
            <NumberInput defaultValue={initialYear} max={parseInt(initialYear)}
              onChange={(valueString) => setYear(valueString)}>
              <NumberInputField />
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            </NumberInput>
            <ActionButton onClick={generateDeclarationOfDischarge}>Gerar</ActionButton>
          </Actions>
        </Stack>
      </Container>
    </PageLayout>
  );
}
