import { Box, Stack } from "@chakra-ui/react";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import PageLayout from "../../components/PageLayout/PageLayout";
import { ROUTES } from "../../constants/routes";
import styles from "../Jornal/Jornal.module.scss";

export default function Jornal() {
  return (
    <PageLayout>
      <Stack className={styles.stack} spacing={10}>
        <Breadcrumb
          items={[
            { route: "/", label: "Início" },
            { route: ROUTES.JORNAL, label: "Jornal" },
          ]}
        />
        <iframe
          style={styles}
          // src="http://jornal.iateclubedebrasilia.com.br"
          // src="http://iatefacilhmg.1boaideia.com.br:3333/journal"
          src="https://iatefacil.iatebsb.com.br/journal"
          width="100%"
          height="100%"
        ></iframe>
      </Stack>
    </PageLayout>
  );
}
