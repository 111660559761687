import { ENDPOINTS } from "../constants/endpoints";
import useAxios from "../hooks/useAxios";
import useProfile from "../hooks/useProfile";
import { ChurrasqueiraReserva } from "../models/churrasqueira.model";
import { ConviteChurrasqueiraDadosConvidado } from "../pages/Reservations/Barbecue/Churrasqueira";

export function useChurrasqueiraService() {
  const axios = useAxios();
  const {user} = useProfile();

  const listarChurrasqueirasPorData = async (
    dataReserva: string
  ) => {
    const response = await axios.get<any[]>(
      ENDPOINTS.listarChurrasqueirasPorData,
      {
        params: {
            dataReserva
        },
      }
    );
    return response.data;
  };

  const listarConvitesDeReservaDeChurrasqueira = async (
    seqReserva: string
  ) => {
    const response = await axios.get<any[]>(
      ENDPOINTS.listarConvitesDeReservaDeChurrasqueira,
      {
        params: {
          seqReserva
        },
      }
    );
    return response.data;
  };

  const recuperarSaldoConvitesReservaChurrasqueira = async (
    seqReserva: string
  ) => {
    const response = await axios.get<any[]>(
      ENDPOINTS.recuperarSaldoConvitesReservaChurrasqueira,
      {
        params: {
          seqReserva
        },
      }
    );
    return response.data;
  };

  const listarReservasChurrasqueira = async () => {
    const response = await axios.get<any[]>(
      ENDPOINTS.listarReservasChurrasqueira,
      {
        params: {
            usuario: user.USER_ACESSO_SISTEMA
        },
      }
    );
    return response.data;
  };

  const reservarChurrasqueira = async (
    reserva: ChurrasqueiraReserva
  ) => {
    const response = await axios.post<any[]>(
      ENDPOINTS.reservarChurrasqueira,
      null,
      {
        params: {
          usuario: user.USER_ACESSO_SISTEMA,
            ...reserva
        },
      }
    );
    return response.data;
  };

  const cancelarReservaChurrasqueira = async (
    id: any
  ) => {
    const response = await axios.put<any[]>(
      ENDPOINTS.cancelarChurrasqueira,
      null,
      {
        params: {
            seqReserva: id,
            usuario: user.USER_ACESSO_SISTEMA,
            ip: ''
        },
      }
    );
    return response.data;
  };

  const cancelarConviteChurrasqueira = async (
    numeroConvite: number
  ) => {
    const response = await axios.put<any[]>(
      ENDPOINTS.cancelarConviteChurrasqueira,
      null,
      {
        params: {
          numeroConvite,
            usuario: user.USER_ACESSO_SISTEMA,
            ip: ''
        },
      }
    );
    return response.data;
  };

  const emitirConviteChurrasqueira = async (
    reserva: any,
    dadosConvidado: ConviteChurrasqueiraDadosConvidado,
  ) => {
    const response = await axios.post<any[]>(
      ENDPOINTS.emitirConviteChurrasqueira,
      null,
      {
        params: {
            reserva,
            nomeConvidado: dadosConvidado.nomeConvidado,
            cpfConvidado: dadosConvidado.cpfConvidado,
            dataNascimento: dadosConvidado.dataNascimento,
            docEstrangeiro: dadosConvidado.docEstrangeiro,
            usuario: user.USER_ACESSO_SISTEMA,
            ip: ''
        },
      }
    );
    return response.data;
  };

  const services = {
    listarChurrasqueirasPorData,
    reservarChurrasqueira,
    cancelarReservaChurrasqueira,
    emitirConviteChurrasqueira,
    listarReservasChurrasqueira,
    recuperarSaldoConvitesReservaChurrasqueira,
    listarConvitesDeReservaDeChurrasqueira,
    cancelarConviteChurrasqueira
  };

  return services;
}
