import axios from "axios";
import { authManager } from "../services/authManager";
import { saveAs } from "file-saver";

const useDownloadFile = () => {
  const token = authManager.get();

  const download = async (
    downloadUrl: string,
    errorCallbackFn: () => void = () => {},
    type = "application/pdf",
    filename?: string
  ) => {
    
    const response = await axios({
      url: downloadUrl,
      responseType: "blob",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": type,
        Accept: "*/*",
        "Access-Control-Expose-Headers": "Authorization",
        "Access-Control-Allow-Origin": "*",
      },
    });

    saveAs(response.data, filename);
    if (response.status !== 200) {
      errorCallbackFn();
    }
  };

  return {download}
};

export default useDownloadFile;
