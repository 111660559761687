import { Container, Flex, Icon, Stack, Table, Tbody, Td, Th, Thead, Tr, useBreakpointValue } from "@chakra-ui/react";
import Breadcrumb from "../../../components/Breadcrumb/Breadcrumb";
import PageLayout from "../../../components/PageLayout/PageLayout";
import { ROUTES } from "../../../constants/routes";
import styles from "./BillingDetails.module.scss";

import { useNavigate } from "react-router-dom";
import { useMutation, useQuery } from "react-query";
import { Billing } from "../../../models/financial.model";
import { useFinancialService } from "../../../services/financial.service";
import { useState } from "react";
import useCustomToast from "../../../hooks/useCustomToast";
import zeroLeft from "../../../helpers/zeroLeft";
import { currency } from "../../../helpers/currency";
import Loading from "../../../components/Loading/Loading";
import { FiDownload } from "react-icons/fi";
import saveAs from "file-saver";
import moment from "moment";

// const MOCK:Billing[] = [
//   { id:-1, vencimento:"20/09/2021", ano:2021, mes:9, mesAbreviado: "09/2021", valor: 1200.2, status: "PENDENTE", isLancamentosFuturos: false },
//   { id:-2, vencimento:"20/10/2021", ano:2021, mes:10, mesAbreviado: "10/2021", valor: 1500.2, status: "QUITADA", isLancamentosFuturos: false },
//   { id:-3, vencimento:"20/11/2021", ano:2021, mes:11, mesAbreviado: "11/2021", valor: 6200.2, status: "QUITADA", isLancamentosFuturos: false },
//   { id:-4, vencimento:"20/12/2021", ano:2021, mes:12, mesAbreviado: "12/2021", valor: 3200.2, status: "QUITADA", isLancamentosFuturos: false },
// ];

export interface IBillingDetailsProps {}

export default function BillingDetails(props: IBillingDetailsProps) {
  const [billings, setBillings] = useState<Billing[]>([]);
  const { getBillings, downloadBillingReport } = useFinancialService();
  const { showToast } = useCustomToast();
  const navigate = useNavigate();

  const query = useQuery<Billing[], Error>(
    "listaFaturas",
    async () => {
      return await getBillings();
    },
    {
      refetchOnWindowFocus: false,
      onSuccess: (billings: Billing[]) => {
        setBillings(billings);
      },
      onError: (error) => {
        // setBillings(MOCK);
        showToast("error", `Não foi possível recuperar as faturas do usuário. ${error.message}`);
      },
    }
  );

  const mutation = useMutation((idBoleto:number) => downloadBillingReport(idBoleto),
    {
      onSuccess: (response) => {
        const blob = new Blob([response.data])
        if (window.ReactNativeWebView) {
          const reader = new FileReader();
          reader.onload = function(event){
            const base64 = event.target?.result;
            window.ReactNativeWebView?.postMessage(base64 as string);
          };

          reader.readAsDataURL(blob);
        } else {
          const filename = `Boleto_${moment().format("YYYYMMDD_HHmmSS")}.pdf`;
          saveAs(blob,filename);
        }
      },
      onError: (error:any) => {
        // showToast("error", `Não foi possível baixar o boleto PDF. ${error.message}`);
        var message = "";
        if(error.response.status == 412){
          message = "Boleto não pode ser emitido. Em caso de dúvida entrar em contato com a Tesouraria.";
        }else{
          message = `Não foi possível baixar o boleto PDF. ${error.message}`
        }
        showToast("error", message);
      },
    }
  );

  const handleBillingDetails = (billing: Billing) => {
    navigate(`${ROUTES.FINANCIAL_BILLINGS_DETAILS}/${billing.id}`);
  };
  const handleDownloadBill = (billing: Billing) => {
    mutation.mutate(billing.id);
  };
  const handleFutureBillingDetails = () => {
    navigate(`${ROUTES.FINANCIAL_FUTURE_BILLINGS}`);
  };

  const tableSize = useBreakpointValue({ base: "sm", md: "md" });

  return (
    <PageLayout noPaddingX>
      <Container maxW="container.md" pt={5} pb={20}>
        <Stack spacing={8}>
          <Breadcrumb
            items={[
              { route: "/", label: "Início" },
              { route: ROUTES.FINANCIAL, label: "Financeiro" },
              {
                route: ROUTES.FINANCIAL_BILLINGS_DETAILS,
                label: "Faturas",
              },
            ]}
          />
        </Stack>
        <div className={styles.warningText}>
          <div>Atenção!</div>
          <div className={styles.upperCase}>Ao emitir o boleto, favor aguardar o registro.</div>
          <div>Há necessidade de registro, junto a instituição bancária.</div>
          <div>O registro é realizado a cada hora, de segunda a sexta-feira, entre 9H e 17H.</div>
        </div>
        <Stack marginTop={5} overflowX="auto">
          {query.isLoading || query.isFetching ? (
            <Flex w="full" justifyContent="center">
              <Loading />
            </Flex>
          ) : (
            
            <Table variant="simple" className={styles.table} size={tableSize}>
              <Thead>
                <Tr>
                  <Th>Mês de referência</Th>
                  <Th>Vencimento</Th>
                  <Th isNumeric>Valor (R$)</Th>
                  <Th>Situação</Th>
                  <Th>2ª Via</Th>
                </Tr>
              </Thead>
              <Tbody>
                {billings &&
                  billings.map((x: Billing, i) =>
                    x.isLancamentosFuturos ? (
                      <Tr key={i} onClick={() => handleFutureBillingDetails()}>
                        <Td colSpan={2}>Lançamentos Futuros</Td>
                        <Td isNumeric color={x.status === "PENDENTE" ? "danger" : "success"}>
                          {currency(x.valor)}
                        </Td>
                        <Td colSpan={2}></Td>
                      </Tr>
                    ) : (
                      <Tr key={i} onClick={() => handleBillingDetails(x)}>
                        <Td color={"gray.500"}>
                          {zeroLeft(x.mes)}/{x.ano}
                        </Td>
                        <Td>{x.vencimento}</Td>
                        <Td isNumeric color={x.status === "PENDENTE" ? "danger" : "success"}>
                          {currency(x.valor)}
                        </Td>
                        <Td color={x.status === "PENDENTE" ? "danger" : "success"}>{x.status}</Td>
                        <Td>{x.status === "PENDENTE" && <Icon as={FiDownload} color="primary" onClick={(event) => {
                          event.stopPropagation();
                          handleDownloadBill(x)
                          }}/>}</Td>
                      </Tr>
                    )
                  )}
              </Tbody>
            </Table>
          )}
        </Stack>
      </Container>
    </PageLayout>
  );
}
