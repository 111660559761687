import { Icon } from "@chakra-ui/react";
import ReactPaginate, { ReactPaginateProps } from "react-paginate"
import styles from "./Paginator.module.scss";
import {FaChevronRight, FaChevronLeft} from "react-icons/fa";
export const Paginator = (props: ReactPaginateProps) => {
    return (
        <ReactPaginate
        nextLabel={<Icon as={FaChevronRight} fontSize={12} color={"primary"}/>}
        previousLabel={<Icon as={FaChevronLeft} fontSize={12} color={"primary"}/>}
        containerClassName={styles.paginator}
        activeClassName={styles.active}
        pageClassName={styles.page}
        disabledClassName={styles.disabled}
        renderOnZeroPageCount={null!}
        {...props}
      />
    )
}

export default Paginator;