import { SearchIcon } from "@chakra-ui/icons";
import {
  Box,
  Container,
  FormControl,
  FormLabel,
  GridItem,
  Heading,
  HStack,
  Input,
  InputGroup,
  InputRightElement,
  Select,
  SimpleGrid,
  Stack,
  Text,
} from "@chakra-ui/react";

import React, { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useQuery } from "react-query";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import Loading from "../../components/Loading/Loading";
import PageLayout from "../../components/PageLayout/PageLayout";
import { PageTitle } from "../../components/PageTitle/PageTitle";
import Paginator from "../../components/Paginator/Paginator";
import { ROUTES } from "../../constants/routes";
import { capitalize } from "../../helpers/capitalize";
import { intersect } from "../../helpers/intersect";
import { uppercase } from "../../helpers/uppercase";
import useCustomToast from "../../hooks/useCustomToast";
import { useDebounce } from "../../hooks/useDebounce";
import {
  LostAndFoundCategory,
  LostAndFoundItem,
  LostAndFoundLocation,
} from "../../models/lostAndFound.model";
import { useLostAndFoundService } from "../../services/lostAndFound.service";
import styles from "./LostAndFound.module.scss";

export const LostAndFound = () => {
  const [categories, setCategories] = useState<LostAndFoundCategory[]>([]);
  const [locations, setLocations] = useState<LostAndFoundLocation[]>([]);
  const [terms, setTerms] = useState<string>("");
  const [category, setCategory] = useState<number>();
  const [location, setLocation] = useState<number>();
  const [filterLoading, setFilterLoading] = useState<boolean>(false);
  const debounced = useDebounce(terms, 500);

  const pageSize = useMemo(() => 10, []);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [results, setResults] = useState<LostAndFoundItem[]>([]);
  const [resultsPage, setResultsPage] = useState<LostAndFoundItem[]>([]);

  const { showToast } = useCustomToast();

  const { register } = useForm();

  const {
    getCategories,
    getLocations,
    searchAll,
    searchByTerms,
    searchByCategory,
    searchByLocation,
  } = useLostAndFoundService();

  useEffect(() => {
    filter();
  }, [debounced, category, location]);

  const {
    isLoading: getAllLoading,
    isFetching: getAllFetching,
    refetch,
  } = useQuery<LostAndFoundItem[], Error>(
    "listaTodosAchadosEPerdidos",
    async () => {
      return await searchAll();
    },
    {
      refetchOnWindowFocus: false,
      onSuccess: (results: LostAndFoundItem[]) => {
        setResults(results);
      },
      onError: () => showToast("error", "Não foi possível listar os objetos."),
    }
  );

  const { isLoading: categoriesLoading, isFetching: categoriesFetching } =
    useQuery<LostAndFoundCategory[], Error>(
      "listaCategoriasAchadosEPerdidos",
      async () => {
        return await getCategories();
      },
      {
        refetchOnWindowFocus: false,
        onSuccess: (categories: LostAndFoundCategory[]) => {
          setCategories(categories);
        },
        onError: () =>
          showToast("error", "Não foi possível listar categorias de objetos."),
      }
    );

  const { isLoading: locationsLoading, isFetching: locationsFetching } =
    useQuery<LostAndFoundLocation[], Error>(
      "listaLocaisAchadosEPerdidos",
      async () => {
        return await getLocations();
      },
      {
        refetchOnWindowFocus: false,
        onSuccess: (locations: LostAndFoundLocation[]) => {
          setLocations(locations);
        },
        onError: () =>
          showToast("error", "Não foi possível listar os locais."),
      }
    );

  const handlePageClick = (event: any) => {
    const page = event.selected;
    const array = [...results];
    setCurrentPage(page);
    setResultsPage(array.slice(page * pageSize, pageSize * (page + 1)));
  };

  const filter = async () => {
    setFilterLoading(true);
    const queries: any = [];
    if (location) queries.push(searchByLocation(location));
    if (category) queries.push(searchByCategory(category));
    queries.push(searchByTerms(terms));

    await Promise.all(queries)
      .then((values: LostAndFoundItem[][]) => {
        //a interseção serão os resultados que atendem aos 3 filtros da tela ao mesmo tempo
        //ignorando os filtros que estão como 'Todos' que não retornam nada
        let intersection: LostAndFoundItem[] = intersect(values, 'NU_SEQ_OBJETO');
        // setResults(sort(intersection, "dtaCadastro", "desc"));
        setResults(intersection);
        setResultsPage(intersection.slice(0, pageSize));

      })
      .catch(() => {
        setResults([]);
        showToast("error", "Erro ao buscar filtros.");
      })
      .finally(() => setFilterLoading(false));
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const terms = e.target.value;
    setTerms(terms);
  };

  const handleCategoryFilter = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const value = e.target.value;
    setCategory(Number(value));
  };

  const handleLocationFilter = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const value = e.target.value;
    setLocation(Number(value));
  };

  return (
    <PageLayout>
      <Container maxW="container.md" pt={5} pb={20}>
        <Stack spacing={8}>
          <Breadcrumb
            items={[
              { route: "/", label: "Início" },
              { route: ROUTES.LOST_AND_FOUND, label: "Achados e Perdidos" },
            ]}
          />
          {getAllLoading ||
            categoriesLoading ||
            locationsFetching ||
            categoriesFetching ||
            getAllFetching ||
            locationsLoading ? (
            <Loading centered />
          ) : (
            <>
              <Stack spacing={5}>
                <PageTitle>Consulte os objetos abaixo</PageTitle>
                <form>
                  <SimpleGrid columns={12} columnGap={4} rowGap={4}>
                    <GridItem colSpan={12}>
                      <FormControl>
                        <FormLabel fontWeight="400" color="gray.500">
                          Descrição
                        </FormLabel>
                        <InputGroup>
                          <InputRightElement
                            pointerEvents="none"
                            children={<SearchIcon color="gray.300" />}
                          />
                          <Input
                            {...register("terms")}
                            onChange={(e) => handleSearch(e)}
                            type="text"
                            placeholder=""
                          />
                        </InputGroup>
                      </FormControl>
                    </GridItem>
                    <GridItem colSpan={6}>
                      <FormControl>
                        <FormLabel fontWeight="400" color="gray.500">
                          Categoria do objeto
                        </FormLabel>
                        <Select
                          {...register("category")}
                          onChange={handleCategoryFilter}
                        >
                          <option>Todos</option>
                          {categories.map(({ CD_DEFINICAO, DE_DEFINICAO }) => (
                            <option key={CD_DEFINICAO} value={CD_DEFINICAO}>
                              {capitalize(DE_DEFINICAO)}
                            </option>
                          ))}
                        </Select>
                      </FormControl>
                    </GridItem>
                    <GridItem colSpan={6}>
                      <FormControl>
                        <FormLabel fontWeight="400" color="gray.500">
                          Local encontrado
                        </FormLabel>
                        <Select
                          {...register("location")}
                          onChange={handleLocationFilter}
                        >
                          <option value={""}>Todos</option>
                          {locations.map(({ CD_SETOR, DE_SETOR }) => (
                            <option key={CD_SETOR} value={CD_SETOR}>
                              {capitalize(DE_SETOR)}
                            </option>
                          ))}
                        </Select>
                      </FormControl>
                    </GridItem>
                  </SimpleGrid>
                </form>
              </Stack>
              {filterLoading ? (
                <Loading centered />
              ) : (
                <>
                  {resultsPage.map((x) => (
                    <div className={styles.item} key={x.NU_SEQ_OBJETO}>
                      {/* <div className={styles.picture} style={{ backgroundImage: `url(${placeholder})` }}></div> */}
                      <HStack
                        w="full"
                        display="flex"
                        justifyContent={"space-between"}
                        alignItems={"flex-start"}
                      >
                        <Stack spacing={2}>
                          <Heading size={"sm"} color="primary">
                            <Text fontSize={14} color="gray.500" mb="2">Descrição</Text>
                            {uppercase(x?.DE_OBJETO)}
                          </Heading>

                          <Text fontSize={14} color="gray.500" >
                            Local Encontrado: {<strong color="primary">{x.SETOR_ENCONTRADO ? capitalize(x.SETOR_ENCONTRADO) : 'Não informado'}</strong>}
                          </Text>

                          <Text fontSize={14} color="gray.500" >
                            Modelo: <strong>{x.MODELO ? capitalize(x.MODELO) : 'Não informado'}</strong>
                          </Text>

                          <Text fontSize={14} color="gray.500" mb="2">
                            Sexo: <strong>{x.SEXO ? capitalize(x.SEXO) : 'Não informado'}</strong>
                          </Text>

                          <Text fontSize={14} color="gray.500" mb="2">
                            Marca: <strong>{x.DE_MARCA ? capitalize(x.DE_MARCA) : 'Não informado'}</strong>
                          </Text>

                          <Text fontSize={14} color="gray.500" mb="2">
                            Cor: <strong>{x.DE_COR ? capitalize(x.DE_COR) : 'Não informado'}</strong>
                          </Text>

                          <Text fontSize={14} color="gray.500" mb="2">
                            Tamanho: <strong>{x.TAMANHO ? capitalize(x?.TAMANHO) : 'Não informado'}</strong>
                          </Text>

                        </Stack>
                        {/* <Text fontSize={11}>{getDaysAgo(x.dtaCadastro)}</Text> */}
                      </HStack>
                    </div>
                  ))}
                  <Box py={"1rem"}>
                    <Paginator
                      pageCount={Math.ceil(results.length / pageSize)}
                      forcePage={currentPage}
                      onPageChange={handlePageClick}
                    />
                  </Box>
                  {results.length === 0 ? (
                    <Text py={20} alignSelf={"center"}>
                      Sem resultados.
                    </Text>
                  ) : (
                    <></>
                  )}
                </>
              )}
            </>
          )}
        </Stack>
      </Container >
    </PageLayout >
  );
};
