import { Box,  Heading, Stack, Text, useBreakpointValue } from "@chakra-ui/react";
import ActionButton from "../ActionButton/ActionButton";
import Actions from "../Actions/Actions";
import PageLayout from "../PageLayout/PageLayout";
import styles from "./PoliciyAgreement.module.scss";

interface PolicyAgreementProps {
  title: string;
  text?: string | React.ReactNode;
  children?: React.ReactNode
  agreed: (option: boolean) => void;
}

const PolicyAgreement = ({ title, text, agreed, children }: PolicyAgreementProps) => {
  
  const paddingX = useBreakpointValue({base: 0, lg: '1rem'})

  return (
    <PageLayout>
      <Box pb={20}>
        <Stack spacing={7}>
          <Heading pb={8} size="lg" fontWeight="500" color="primary">
            <Text textAlign="center">{title}</Text>
          </Heading>
          <Box className={styles.textContainer} px={paddingX} color={"#939393"}>
            {!!children ? children :
            <div>{text}</div>
             }
          </Box>
          <Actions>
            <ActionButton
              onClick={() => agreed(false)}
              variant="outline"
            >
              Não concordo
            </ActionButton>
            <ActionButton onClick={() => agreed(true)}>
              Concordo
            </ActionButton>
          </Actions>
        </Stack>
      </Box>
    </PageLayout>
  );
};

export default PolicyAgreement;
