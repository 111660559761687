import { Container, HStack, Image, Link, Stack, Text } from "@chakra-ui/react";
import { useState } from "react";
import { useQuery } from "react-query";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import PageLayout from "../../components/PageLayout/PageLayout";
import { PageTitle } from "../../components/PageTitle/PageTitle";
import { ROUTES } from "../../constants/routes";
import useCustomToast from "../../hooks/useCustomToast";
import { ContactInfo } from "../../models/contact.model";
import { useContactService } from "../../services/contact.service";
import styles from "./Contact.module.scss";
import youtube from "../../assets/icons/youtube.svg";
import twitter from "../../assets/icons/twitter (1) 1.svg";
import facebook from "../../assets/icons/facebook 2.svg";
import instagram from "../../assets/icons/instagram 2.svg";
import Loading from "../../components/Loading/Loading";
import mapa from "../../assets/img/Mapa.png";

export const Contact = () => {
  const [contact, setContact] = useState<ContactInfo>(null!);
  const [latLng, setLatLng] = useState<string[]>([])

  const { showToast } = useCustomToast();

  const { getContacts } = useContactService();

  const { isLoading, isFetching } = useQuery<ContactInfo[], Error>(
    "listaContatos",
    async () => {
      return await getContacts();
    },
    {
      refetchOnWindowFocus: false,
      onSuccess: (contacts: ContactInfo[]) => {
        setContact(contacts[0]);
        let coordenadas = contacts[0].coordenadas.split(',');
        if(coordenadas){
            coordenadas = coordenadas.map(x => x.trim());
        }
        setLatLng(coordenadas);
      },
      onError: () =>
        showToast(
          "error",
          "Não foi possível listar informações de contato."
        ),
    }
  );

  return (
    <PageLayout>
      <Container maxW="container.md" pt={5} pb={20}>
        <Stack spacing={10}>
          <Breadcrumb
            items={[
              { route: "/", label: "Início" },
              { route: ROUTES.CONTACT, label: "Contato" },
            ]}
          />

          {isFetching || isLoading ? (
            <Loading centered />
          ) : (
            <>
              <PageTitle>Fale conosco</PageTitle>

              <p>
                <strong>Endereço:</strong> &nbsp;Setor de Clubes Esportivos
                Norte Trecho 2 conjunto 4
              </p>

              <p>
                <strong>Telefone:</strong> &nbsp;{contact?.telefone}
                <br />
                <strong>Whatsapp:</strong> &nbsp; {contact?.contatoWhatsapp}
                <br />
                <strong>E-mail:</strong> &nbsp;{" "}
                <Link href={`mailto:${contact?.email}`}>{contact?.email}</Link>{" "}
                <br />
                <strong>Site:</strong> &nbsp;{" "}
                <Link target="_blank" color={"primary"} href={contact?.site}>
                  {contact?.site}
                </Link>{" "}
                <br />
              </p>

              <p>
                <strong>Horário de atendimento:</strong> {contact?.atendimento}
              </p>

              <a role={"button"} target="_blank" href="https://www.google.com/maps/place/Iate+Clube+de+Bras%C3%ADlia+-+Portaria+Sul+(Principal)/@-15.7785812,-47.8635569,17z/data=!3m1!4b1!4m5!3m4!1s0x0:0x103c11f684bf60ab!8m2!3d-15.7785812!4d-47.8613682?hl=pt-BR">
              <Image borderRadius={'8px'} src={mapa} alt="Mapa"/>
              </a>

              <PageTitle>Redes sociais</PageTitle>

              <HStack spacing={5}>
                <Link
                  href={contact?.urlInstagram}
                  target="_blank"
                  className={styles.socialMedia}
                >
                  <img src={instagram} />
                </Link>
                <Link
                  href={contact?.urlTwitter}
                  className={styles.socialMedia}
                  target="_blank"
                >
                  <img src={twitter} />
                </Link>
                <Link
                  href={contact?.urlFacebook}
                  className={styles.socialMedia}
                  target="_blank"
                >
                  <img src={facebook} />
                </Link>
                <Link
                  href={contact?.urlYoutube}
                  className={styles.socialMedia}
                  target="_blank"
                >
                  <img src={youtube} />
                </Link>
              </HStack>
            </>
          )}
        </Stack>
      </Container>
    </PageLayout>
  );
};
