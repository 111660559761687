import { ENDPOINTS } from "../constants/endpoints";
import useOldApi from "../hooks/useOldApi";
import { TransparencyReport } from "../models/transparency.model";

export function useTransparencyService() {
  const oldApi = useOldApi();

  const getTransparencyReports = async () => {
    const response = await oldApi.get<TransparencyReport[]>(
      `${ENDPOINTS.getTransparencyReports}`
    );
    return response.data;
  };

  const downloadTransparencyReport = async (id: any) => {
    const response = await oldApi.get<any>(
      `${ENDPOINTS.downloadTransparencyReport(id)}`
    );
    return response.data;
  };

  const services = {
    getTransparencyReports,
    downloadTransparencyReport,
  };

  return services;
}
