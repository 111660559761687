import { ENDPOINTS } from "../constants/endpoints";
import useAxios from "../hooks/useAxios";
import React from "react";

export function useRecaptchaService() {
  const axios = useAxios();

  const validateRecaptcha = async (token: string) => {
    const response = await axios.post<any>(ENDPOINTS.validateRecaptcha, {
      token,
      name: '123',
      feedback: '123',
      email: '123@email.com'
    });
    return response.data;
  };

  const services = {
    validateRecaptcha,
  };

  return services;
}
