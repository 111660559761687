export function sort(array: any[], prop: string, dir: 'asc' | 'desc') : any[] {
    if(array.length === 0) return array;
    const sorted = array.sort((a: any, b: any) => {
        if(dir === 'asc'){
            if(a[prop] > b[prop]) return 1;
            if(a[prop] < b[prop]) return -1;
            return 0;
        }
        else{
            if(a[prop] > b[prop]) return -1;
            if(a[prop] < b[prop]) return 1;
            return 0;
        }
    });

    return sorted;
}