// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".LostAndFound_item__267Bp {\n  display: flex;\n  align-items: center;\n  background: rgba(49, 49, 49, 0.05);\n  border-radius: 8px;\n  padding: 0.75rem;\n}\n.LostAndFound_item__267Bp .LostAndFound_picture__1508I {\n  width: 150px;\n  height: 120px;\n  background-position: center;\n  background-size: cover;\n  border-radius: 6px;\n  margin-right: 1rem;\n}", "",{"version":3,"sources":["webpack://src/pages/LostAndFound/LostAndFound.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,kCAAA;EACA,kBAAA;EACA,gBAAA;AACF;AACE;EACE,YAAA;EACA,aAAA;EACA,2BAAA;EACA,sBAAA;EACA,kBAAA;EACA,kBAAA;AACJ","sourcesContent":[".item {\r\n  display: flex;\r\n  align-items: center;\r\n  background: rgba(49, 49, 49, 0.05);\r\n  border-radius: 8px;\r\n  padding: 0.75rem;\r\n\r\n  .picture {\r\n    width: 150px;\r\n    height: 120px;\r\n    background-position: center;\r\n    background-size: cover;\r\n    border-radius: 6px;\r\n    margin-right: 1rem;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"item": "LostAndFound_item__267Bp",
	"picture": "LostAndFound_picture__1508I"
};
export default ___CSS_LOADER_EXPORT___;
