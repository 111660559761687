// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Paginator_paginator__2IHH9 {\n  display: flex;\n  justify-content: space-around;\n  align-items: center;\n}\n\n.Paginator_active__2ztfK {\n  background: #0d4f7b;\n  height: 30px;\n  width: 30px;\n  border-radius: 50%;\n  padding: 0.5rem;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  color: white;\n}\n\n.Paginator_page__86c8h {\n  font-size: 14px;\n}\n\n.Paginator_disabled__2mZTo {\n  color: #eee;\n}", "",{"version":3,"sources":["webpack://src/components/Paginator/Paginator.module.scss","webpack://src/styles/variables.scss"],"names":[],"mappings":"AAEA;EACI,aAAA;EACA,6BAAA;EACA,mBAAA;AADJ;;AAIA;EACI,mBCRM;EDSN,YAAA;EACA,WAAA;EACA,kBAAA;EACA,eAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,YAAA;AADJ;;AAIA;EACI,eAAA;AADJ;;AAIA;EACI,WAAA;AADJ","sourcesContent":["@import \"../../styles/variables.scss\";\r\n\r\n.paginator {\r\n    display: flex;\r\n    justify-content: space-around;\r\n    align-items: center;\r\n}\r\n\r\n.active {\r\n    background: $primary;\r\n    height: 30px;\r\n    width: 30px;\r\n    border-radius: 50%;\r\n    padding: .50rem;\r\n    display: flex;\r\n    align-items: center;\r\n    justify-content: center;\r\n    color: white;\r\n}\r\n\r\n.page {\r\n    font-size: 14px;\r\n}\r\n\r\n.disabled {\r\n    color: #eee;\r\n}","// $primary: #003E66;\r\n$primary: #0d4f7b;\r\n$danger: #F03D3E;\r\n$success: #44AC80;\r\n$white: #FFFFFF;\r\n\r\n$border-radius: 8px;\r\n$navbar-height: 50px;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"paginator": "Paginator_paginator__2IHH9",
	"active": "Paginator_active__2ztfK",
	"page": "Paginator_page__86c8h",
	"disabled": "Paginator_disabled__2mZTo"
};
export default ___CSS_LOADER_EXPORT___;
