import { useBreakpointValue, useToast } from "@chakra-ui/react";
import Toast from "../components/Toast/Toast";

const useCustomToast = () => {
  const positionResponsive: any = useBreakpointValue({base: 'bottom', lg: 'bottom-right'}) || 'bottom-right'
  const toast = useToast({
    position: positionResponsive,
  });

  const showToast = (type: "error" | "success" | "alert" = "success", data: any) => {
    
    const params = {
        render: () => <Toast type={type} message={data} />,
        duration: 4000,
        isClosable: true,
      }
    
    toast(params);
  };

  return {showToast};
};


export default useCustomToast;