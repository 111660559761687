import {
  Box,
  Container,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  Stack,
  useToast,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import Breadcrumb from "../../../components/Breadcrumb/Breadcrumb";
import PageLayout from "../../../components/PageLayout/PageLayout";
import styles from "./ChangePassword.module.scss";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Actions from "../../../components/Actions/Actions";
import ActionButton from "../../../components/ActionButton/ActionButton";
import { useProfileService } from "../../../services/profile.service";
import { useMutation, useQuery } from "react-query";
import Toast from "../../../components/Toast/Toast";
import Loading from "../../../components/Loading/Loading";
import useProfile from "../../../hooks/useProfile";
import { ChangePasswordModel } from "../../../models/profile.model";

const ChangePassword = () => {
  const schema = yup
    .object({
      senhaAtual: yup.string().required("Campo obrigatório"),
      senhaNova: yup.string()
      .min(4, "A senha deve ter no mínimo 4 caracteres").max(32, "A senha deve ter no máximo 32 caracteres")
      .required("Campo obrigatório"),
      confirmarSenha: yup
        .string()
        .oneOf([yup.ref("senhaNova"), null], "As senhas não conferem."),
    })
    .required();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset
  } = useForm({ resolver: yupResolver(schema) });

  const onSubmit = (data: ChangePasswordModel) => mutation.mutate(data);

  const toast = useToast();

  const showToast = (data: any, type: "error" | "success") => {
    toast({
      render: () => <Toast type={type} message={type === 'error' ? 'Não foi possível alterar a senha.' : data} />,
      duration: 8000,
      isClosable: false,
    });
  };

  const { changePassword } = useProfileService();

  const {profile, isLoading} = useProfile();
  
  const mutation = useMutation(
    (formData: ChangePasswordModel) => changePassword({
      senhaAtual: formData.senhaAtual,
      senhaNova: formData.senhaNova,
      usuario: `${profile.USER_ACESSO_SISTEMA}`.trim()
    }),
    {
      onSuccess: () => {
        showToast("Senha alterada com sucesso.", "success");
        reset()
      },
      onError: (err: any) => {
        showToast(err.response, "error");
      },
    }
  );

  return (
    <PageLayout>
      <Container maxW="container.md" pt={5} pb={20}>
        <Stack spacing={10} className={styles.profileForm}  w={["100%", "100%", "80%"]}>
          <Breadcrumb
            items={[
              { route: "/", label: "Início" },
              { route: "/perfil", label: "Perfil" },
              { route: "/perfil/alterar-senha", label: "Alterar Senha" },
            ]}
          />
          {isLoading  ? (
            <Box pt={'200px'} alignSelf={"center"}><Loading blockUi/></Box>
          ) : (
            <>
              <Box alignSelf={"center"} w="full">
                <form
                  id="change-password-form"
                  className={styles.form}
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <Stack spacing={4}>
                    <Heading size="md" fontWeight="500" color="primary">
                      Alterar senha
                    </Heading>
                    <FormControl>
                      <FormLabel fontWeight="400" color="gray.500">
                        Senha atual*
                      </FormLabel>
                      <Input
                        type="password"
                        placeholder=""
                        {...register("senhaAtual")}
                      />
                    </FormControl>
                    <FormControl  isInvalid={!!errors?.senhaNova}>
                      <FormLabel fontWeight="400" color="gray.500">
                        Nova senha*
                      </FormLabel>
                      <Input
                        type="password"
                        placeholder=""
                        {...register("senhaNova")}
                      />
                     <FormErrorMessage>{errors?.senhaNova?.message}</FormErrorMessage>
                    </FormControl>
                    <FormControl isInvalid={errors?.confirmarSenha}>
                      <FormLabel fontWeight="400" color="gray.500">
                        Confirmar nova senha*
                      </FormLabel>
                      <Input
                        type="password"
                        placeholder=""
                        errorBorderColor="red"
                        {...register("confirmarSenha")}
                      />
                      <FormErrorMessage>
                        {errors?.confirmarSenha?.message}
                      </FormErrorMessage>
                    </FormControl>
                  </Stack>
                </form>
              </Box>

              <Actions >
                <Link to="/perfil">
                  <ActionButton variant="outline" type="button">
                    Cancelar
                  </ActionButton>
                </Link>
                <ActionButton type="submit" form="change-password-form"  isLoading={mutation.isLoading}>
                  Salvar alterações
                </ActionButton>
              </Actions>
            </>
          )}
        </Stack>
      </Container>
    </PageLayout>
  );
};

export default ChangePassword;
