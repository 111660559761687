// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SidebarItem_sidebarItem__3RkCU {\n  color: white;\n  padding: 0.125rem 1rem;\n  display: flex;\n  width: 100%;\n  align-items: center;\n  border-radius: 10px;\n  font-weight: 500;\n  background-color: #0d4f7b;\n  cursor: pointer;\n  word-break: normal;\n}\n.SidebarItem_sidebarItem__3RkCU:hover {\n  background-color: #0e5584;\n}", "",{"version":3,"sources":["webpack://src/components/SidebarItem/SidebarItem.module.scss","webpack://src/styles/variables.scss"],"names":[],"mappings":"AAEA;EACI,YAAA;EACA,sBAAA;EACA,aAAA;EACA,WAAA;EACA,mBAAA;EACA,mBAAA;EACA,gBAAA;EACA,yBCTM;EDUN,eAAA;EACA,kBAAA;AADJ;AAEI;EACI,yBAAA;AAAR","sourcesContent":["@import \"../../styles/variables.scss\";\r\n\r\n.sidebarItem{\r\n    color: white;\r\n    padding: .125rem 1rem;\r\n    display: flex;\r\n    width: 100%;\r\n    align-items: center;\r\n    border-radius: 10px;\r\n    font-weight: 500;\r\n    background-color: $primary;\r\n    cursor: pointer;\r\n    word-break: normal;\r\n    &:hover{\r\n        background-color: lighten($color: $primary, $amount: 2);\r\n    }\r\n}","// $primary: #003E66;\r\n$primary: #0d4f7b;\r\n$danger: #F03D3E;\r\n$success: #44AC80;\r\n$white: #FFFFFF;\r\n\r\n$border-radius: 8px;\r\n$navbar-height: 50px;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sidebarItem": "SidebarItem_sidebarItem__3RkCU"
};
export default ___CSS_LOADER_EXPORT___;
