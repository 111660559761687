import styles from "./IconButton.module.scss";
import { Tooltip } from "@chakra-ui/react";

type IconButtonProps = {
  children: JSX.Element;
  onClick: () => void;
  disabled?: boolean;
  tooltip?: string
};

export default function IconButton({
  children,
  onClick,
  disabled,
  tooltip
}: IconButtonProps) {
  return (
    <Tooltip label={tooltip || ''} aria-label={tooltip || ''}>
      <button type="button" onClick={onClick} style={styles} disabled={disabled}>
        {children}
      </button>
    </Tooltip>
  );
}
