
export const ENDPOINTS = {

  //BASE_URL
  // apiBaseUrl: 'http://194.163.129.186:8080/api',
  //apiBaseUrl: 'https://localhost:44372/api',
  // apiBaseUrl: 'http://38.242.219.127:8081/api',
  apiBaseUrl: 'https://iateapiapp.iatebsb.com.br/api',

  //LOGIN, AUTH
  login: "/Authentication/ValidaLogin",
  ping: () => `/AlteracaoCadastral/GetDadosTitular`,
  resetPassword: "/auth/forgot",
  changePassword: "/Acesso/StoredProcAlteraSenha",

  //RESERVAS
  listFacilities: (type: string, locationDate: string) => `/dependencias/?tipo=${type}&dataLocacao=${locationDate}`,
  reserveFacility: `/dependencias/reservas`,
  getGymModalities: '/Academia/GetServicoAcademia',
  getGymModalitiesClass: '/Academia/StoredProcAgendaAcademiaDia',
  getGymModalitiesClassMonth: '/Academia/StoredProcAgendaAcademiaMes',
  getTeacherModalities: '/Academia/StoredProcFuncionarioAcademia',
  confirmReservation: '/Academia/StoredProcAgendaServicoAcademia',
  reservationsHistory: '/Academia/StoredProcAgendaAcademiaTitulo',
  getReservationDetails: '/Academia/StoredProcRecDadosCompAgenServAcademia',
  cancelGymReservation: '/Academia/StoredProcCancelaAgendaServAcademia',
  listGymUsers: '/Academia/StoredProcPessoaAcademia',
  //PROFILE
  updateProfile: '/AlteracaoCadastral/StoredProcAlteraDadosTitulo',
  getUser: '/Acesso/StoredProcValidaLogin',
  getProfile: `/AlteracaoCadastral/GetDadosTitular`,

  //DEPENDENTES
  getDependents: '/AlteracaoCadastral/GetDadosDependentes',
  updateDependent: '/socios/dependentes',
  saveDependent: '/AlteracaoCadastral/StoredProcAlteraDadosDependente',
  deleteDependent: (id: string) => `/socios/dependentes/${id}`,

  //CONVITES
  addInvite: '/Convite/StoredProcIncluiConvite',
  getInvites: `/Convite/StoredProcListaConvite`,
  getInviteBalance: `Convite/StoredProcSaldoConvite`,
  getSellTax: '/Convite/StoredProcValorConvVendido',
  getInvite: (id: string) => `/convites/${id}`,
  deleteInvite: 'Convite/StoredProcCancelaConvite',

  //FINANCEIRO
  getSlips: '/boletos',
  requestSlipCopy: '/boletos',
  getSlipDetails: 'Financeiro/StoredProcDadosBoleto',
  getBillings: '/Financeiro/StoredProcListaCarne',
  getFutureBillingsDetails: '/Financeiro/StoredProcLancFuturo',
  getBillingDetails: 'Financeiro/StoredProcDetCarne',
  getBillingDetailsInstallments: '/Financeiro/StoredProcParcelaCarne',
  downloadBillingReport: '/PDFCreator/CreateBoletoPDF',
  getDebtPaymentTerms: '/termoquitacao',
  getDeclarationOfDischarge: '/Financeiro/StoredProcDeclaracaoQuitacao',

  //CONTATO E REDES SOCIAIS
  contacts: '/contatos/',

  //ACHADOS E PERDIDOS
  getCategories: '/AchadosPerdidos/GetDefinicaoAP',
  getLocations: '/AchadosPerdidos/GetSetorAP',

  searchAll: '/AchadosPerdidos/StoredProcObjetosAP/',
  searchByLocation: (id: any) => `AchadosPerdidos/StoredProcObjetosAP?setor=${id}`,
  searchByCategory: (id: any) => `AchadosPerdidos/StoredProcObjetosAP?definicao=${id}`,
  searchByTerms: (terms: string) => `AchadosPerdidos/StoredProcObjetosAP?objeto=${terms}`,

  //PORTAL DA TRANSPARÊNCIA
  getTransparencyReports: '/transparencia',
  downloadTransparencyReport: (id: any) => `/transparencia/download/${id}`,

  //OUVIDORIA
  getSubjectsAndSubSubjects: '/ouvidoria/assuntos',
  getOnbudsmanClassification: '/ouvidoria/classificacao',
  registerManifest: '/ouvidoria/registrarmanifestacao',
  getManifests: '/ouvidoria',

  //NÁUTICA
  getOwner: '/solicitacoesembarcacoes/titular',
  listShips: '/solicitacoesembarcacoes/embarcacoes',
  listMoveConditions: '/solicitacoesembarcacoes/condicoes',
  requestShip: '/solicitacoesembarcacoes',
  queryRequest: (id: any) => `/solicitacoesembarcacoes/${id}`,
  cancelRequest: (id: any) => `/solicitacoesembarcacoes/cancelamento/${id}`,
  getNauticalModalities: '/nautica/',
  getNauticalModalityAvailable: (idModalidade: any, horaInicio: string) => `/nautica/${idModalidade}/${horaInicio}`,
  latestRequest: (idEmbarcacao: any, data: any) => `/solicitacoesembarcacoes/${idEmbarcacao}/${data}`,
  getNauticalBoxes: '/nautica/box',
  reserveNauticalBoxes: (id: any) => `/nautica/box/${id}`,

  //notificações
  getNotifications: '/notificacao',
  readNotification: (id: any) => `notificacao/${id}`,


  //Churrasqueira
  listarChurrasqueirasPorData: `/ChurraReserva/StoredProcConsultaChurrasqueira`,
  reservarChurrasqueira: `/ChurraReserva/StoredProcReservaChurrasqueira`,
  cancelarChurrasqueira: `/ChurraReserva/StoredProcCancelaResChurrasqueira`,
  listarReservasChurrasqueira: `/ChurraReserva/StoredProcRecReservaChurrasqueira`,
  recuperarSaldoConvitesReservaChurrasqueira: `/ChurraConvite/StoredProcSaldoConviteChurrasqueira`,
  emitirConviteChurrasqueira: `/ChurraConvite/StoredProcIncluiConviteChurrasqueira`,
  listarConvitesDeReservaDeChurrasqueira: 'ChurraConvite/StoredProcRecConviteChurrasqueira',
  cancelarConviteChurrasqueira: '/ChurraConvite/StoredProcCancelaConviteChurrasqueira',

  //NOTICIAS
  obterNoticias: '/noticias',
  obterNoticiasDireto: 'https://www.iateclubedebrasilia.com.br/wp-json/wp/v2/posts',

  //verificar suspensao
  isSuspended: '/Convite/StoredProcDireitoSuspenso',

  //RECAPTCHA
  validateRecaptcha: '/ReCaptchaFeedback',
};
