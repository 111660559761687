// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PoliciyAgreement_textContainer__wi_rJ {\n  max-height: 600px;\n  overflow-y: auto;\n}\n.PoliciyAgreement_textContainer__wi_rJ div {\n  line-height: 1.8;\n  text-align: justify;\n}\n\n.PoliciyAgreement_actions__3-r2i {\n  align-self: flex-end;\n}", "",{"version":3,"sources":["webpack://src/components/PolicyAgreement/PoliciyAgreement.module.scss"],"names":[],"mappings":"AAAA;EACI,iBAAA;EACA,gBAAA;AACJ;AACI;EACI,gBAAA;EACA,mBAAA;AACR;;AAGA;EACI,oBAAA;AAAJ","sourcesContent":[".textContainer{\r\n    max-height: 600px;\r\n    overflow-y: auto;\r\n \r\n    div{\r\n        line-height: 1.8;\r\n        text-align: justify;\r\n    }\r\n}\r\n\r\n.actions{\r\n    align-self: flex-end;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"textContainer": "PoliciyAgreement_textContainer__wi_rJ",
	"actions": "PoliciyAgreement_actions__3-r2i"
};
export default ___CSS_LOADER_EXPORT___;
