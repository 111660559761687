import {
  Box,
  Container,
  FormControl,
  FormErrorMessage,
  FormLabel,
  GridItem,
  Heading,
  HStack,
  Input,
  Select,
  SimpleGrid,
  Stack,
  Text,
  Textarea,
  Tooltip,
} from "@chakra-ui/react";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import LinkBox from "../../components/LinkBox/LinkBox";
import Loading from "../../components/Loading/Loading";
import PageLayout from "../../components/PageLayout/PageLayout";
import { PageTitle } from "../../components/PageTitle/PageTitle";
import { ROUTES } from "../../constants/routes";
import cadastrarManifestacaoIcon from "../../assets/icons/cadastrar-manifestracao.svg";
import consultarManifestacaoIcon from "../../assets/icons/consultar-manifestracao.svg";
import ActionButton from "../../components/ActionButton/ActionButton";
import Actions from "../../components/Actions/Actions";
import helpIcon from "../../assets/icons/Help.svg";
import { FileUploader } from "../../components/FileUploader/FileUploader";
import { useOnbudsmanService } from "../../services/onbudsman.service";
import { useMutation, useQuery } from "react-query";
import {
  Manifest,
  OnbudsmanClassification,
  OnbudsmanSubject,
} from "../../models/onbudsman.model";
import useCustomToast from "../../hooks/useCustomToast";
import { useCallback, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { capitalize } from "../../helpers/capitalize";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { CAMPO_OBRIGATORIO } from "../../common/feedbacks";
import { useLocation, useNavigate } from "react-router-dom";
import styles from "./Onbudsman.module.scss";
import ReactToPrint from "react-to-print";
import logo from "../../assets/img/logo/logo_iate_versao_12_azul.jpg";

export const Onbudsman = () => {
  return (
    <PageLayout>
      <Container maxW="container.md" pt={5} pb={20}>
        <Stack spacing={8}>
          <Breadcrumb
            items={[
              { route: "/", label: "Início" },
              { route: ROUTES.ONBUDSMAN, label: "Ouvidoria" },
            ]}
          />
          {false ? (
            <Loading centered />
          ) : (
            <Stack spacing={5}>
              <PageTitle>Sua comunicação com o Iate</PageTitle>
              <Text color={"gray.500"}>
                A ouvidoria é uma das principais ferramentas de gestão do Iate
                Clube de Brasília. <br />É por meio da sua opnião que definimos
                políticas de gestão e demandas sociais.
              </Text>

              <a role="button" target="_blank" href="https://iatebsb.omd.com.br/iatebsb/externo/cadastro.do">
                <LinkBox
                  label="Cadastrar manifestação"
                  noRoute
                  leftIcon={cadastrarManifestacaoIcon}
                />
              </a>

              <a role="button" target="_blank" href="https://iatebsb.omd.com.br/iatebsb/externo/consulta.do">
                <LinkBox
                  label="Consultar manifestação"
                  noRoute
                  leftIcon={consultarManifestacaoIcon}
                />
              </a>
            </Stack>
          )}
        </Stack>
      </Container>
    </PageLayout>
  );
};

export const OnbudsmanCreate = () => {
  const [classifications, setClassifications] = useState<
    OnbudsmanClassification[]
  >([]);

  const [subjects, setSubjects] = useState<OnbudsmanSubject[]>([]);

  const [selectedSubject, setSelectedSubject] =
    useState<OnbudsmanSubject | null>(null);

  const navigate = useNavigate();

  const [files, setFiles] = useState<File[]>([]);
  const [invalidFiles, setInvalidFiles] = useState<boolean>(false);

  const { showToast } = useCustomToast();

  const schema = yup.object().shape({
    envolvidos: yup.string().required(CAMPO_OBRIGATORIO),
    descricao: yup.string().required(CAMPO_OBRIGATORIO),
    classificacao: yup.string().required(CAMPO_OBRIGATORIO),
    assunto: yup.string().required(CAMPO_OBRIGATORIO),
    subAssunto: yup.string(),
  });

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    getValues,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const {
    getOnbudsmanClassification,
    getSubjectsAndSubSubjects,
    registerManifest,
  } = useOnbudsmanService();

  const onFileUploadChange = (fileList: File[], isInvalid: boolean) => {
    setFiles(fileList);
    setInvalidFiles(isInvalid);
  };

  const queryClassification = useQuery<OnbudsmanClassification[], Error>(
    ["getOuvidoriaClassificacao"],
    async () => {
      return await getOnbudsmanClassification();
    },
    {
      refetchOnWindowFocus: false,
      onSuccess: (results: OnbudsmanClassification[]) =>
        setClassifications(results),
      onError: () =>
        showToast(
          "error",
          "Não foi possível listar as classificações de ouvidoria."
        ),
    }
  );

  const querySubject = useQuery<OnbudsmanSubject[], Error>(
    ["getOuvidoriaAssuntos"],
    async () => {
      return await getSubjectsAndSubSubjects();
    },
    {
      refetchOnWindowFocus: false,
      onSuccess: (results: OnbudsmanSubject[]) => setSubjects(results),
      onError: () =>
        showToast("error", "Não foi possível listar os assuntos de ouvidoria."),
    }
  );

  const mutation = useMutation(
    (data: Manifest) => {
      return registerManifest(data, files);
    },
    {
      onSuccess: () => {
        showToast("success", "Manifestação registrada!");
        navigate(ROUTES.ONBUDSMAN, { replace: true });
      },
      onError: (err: any) => {
        showToast(
          "error",
          err.response.data || "Não foi registrar a manifestação."
        );
      },
    }
  );

  const loadingStates = [
    queryClassification.isLoading,
    queryClassification.isFetching,
    querySubject.isLoading,
    querySubject.isFetching,
    mutation.isLoading,
  ];

  const isLoading = useCallback(
    () => loadingStates.some((x) => x === true),
    loadingStates
  );

  const watchSubject = watch("assunto");

  useEffect(() => {
    if (watchSubject && watchSubject !== "Todos") {
      const parsed = JSON.parse(watchSubject);
      if (parsed && parsed.iden) setSelectedSubject(parsed);
    } else {
      setSelectedSubject(null);
    }
  }, [watchSubject]);

  const onSubmit = handleSubmit((data: Manifest) => {
    if (invalidFiles) {
      showToast("error", "Os arquivos anexos não podem ter mais que 5mb.");
      return;
    }
    mutation.mutate(data);
  });

  return (
    <PageLayout>
      <Container maxW="container.md" pt={5} pb={20}>
        <Stack spacing={8}>
          <Breadcrumb
            items={[
              { route: "/", label: "Início" },
              { route: ROUTES.ONBUDSMAN, label: "Ouvidoria" },
              {
                route: ROUTES.ONBUDSMAN_CREATE,
                label: "Cadastrar manifestação",
              },
            ]}
          />
          {isLoading() ? (
            <Loading centered />
          ) : (
            <Stack spacing={6}>
              <Heading size={"md"} color="primary">
                Cadastro de Manifestação
              </Heading>

              <form onSubmit={onSubmit} id="onbudsman-form-register">
                <SimpleGrid columns={12} columnGap={4} rowGap={4}>
                  <GridItem colSpan={12}>
                    <FormControl isInvalid={!!errors?.envolvidos?.message}>
                      <HStack spacing={0} alignItems={"center"}>
                        <FormLabel fontWeight="400" color="gray.500">
                          Envolvidos
                        </FormLabel>
                        <Box style={{ marginBottom: "8px" }}>
                          <Tooltip
                            label={
                              "Cite o nome de todas as pessoas envolvidas na ocorrência do fato, identificando o seu vínculo (funcionário, testemunha, vítima, etc.)"
                            }
                            aria-label={
                              "Cite o nome de todas as pessoas envolvidas na ocorrência do fato, identificando o seu vínculo (funcionário, testemunha, vítima, etc.)"
                            }
                          >
                            <img src={helpIcon} />
                          </Tooltip>
                        </Box>
                      </HStack>
                      <Textarea
                        resize={"none"}
                        {...register("envolvidos")}
                      ></Textarea>
                      <FormErrorMessage>
                        {errors?.envolvidos?.message}
                      </FormErrorMessage>
                    </FormControl>
                  </GridItem>
                  <GridItem colSpan={12}>
                    <FormControl isInvalid={!!errors?.descricao?.message}>
                      <HStack spacing={0} alignItems={"center"}>
                        <FormLabel fontWeight="400" color="gray.500">
                          Descrição
                        </FormLabel>

                        <Box style={{ marginBottom: "8px" }}>
                          <Tooltip
                            label={"Descrição do ocorrido"}
                            aria-label={"Descrição do ocorrido"}
                          >
                            <img src={helpIcon} />
                          </Tooltip>
                        </Box>
                      </HStack>
                      <Textarea
                        resize={"none"}
                        {...register("descricao")}
                      ></Textarea>
                      <FormErrorMessage>
                        {errors?.descricao?.message}
                      </FormErrorMessage>
                    </FormControl>
                  </GridItem>
                  <GridItem colSpan={12}>
                    <FormControl>
                      <FormLabel fontWeight="400" color="gray.500">
                        Anexo
                      </FormLabel>
                      <Box mt={"20px"}>
                        <FileUploader
                          maxSize={1024 * 5000}
                          onChange={(fileList, invalid) =>
                            onFileUploadChange(fileList, invalid)
                          }
                        />
                      </Box>
                    </FormControl>
                  </GridItem>
                </SimpleGrid>

                <Box mt={"45px"} mb={10}>
                  <Heading size={"md"} color="primary">
                    Dados do cadastro
                  </Heading>
                </Box>
                <SimpleGrid columns={12} columnGap={4} rowGap={4}>
                  <GridItem colSpan={12}>
                    <FormControl isInvalid={!!errors?.classificacao?.message}>
                      <FormLabel fontWeight="400" color="gray.500">
                        Classificação
                      </FormLabel>
                      <Select {...register("classificacao")}>
                        <option
                          value=""
                          disabled={!!getValues("classificacao")}
                        >
                          Selecione
                        </option>
                        {classifications &&
                          classifications.map((x, i) => (
                            <option key={i} value={x.id}>
                              {capitalize(x.descricao)}
                            </option>
                          ))}
                      </Select>
                      <FormErrorMessage>
                        {errors?.classificacao?.message}
                      </FormErrorMessage>
                    </FormControl>
                  </GridItem>
                  <GridItem colSpan={12}>
                    <FormControl isInvalid={!!errors?.assunto?.message}>
                      <FormLabel fontWeight="400" color="gray.500">
                        Assunto
                      </FormLabel>
                      <Select {...register("assunto")}>
                        <option value="">Selecione</option>
                        {subjects &&
                          subjects.map((x, i) => (
                            <option key={i} value={JSON.stringify(x)}>
                              {capitalize(x.descricao)}
                            </option>
                          ))}
                      </Select>
                      <FormErrorMessage>
                        {errors?.assunto?.message}
                      </FormErrorMessage>
                    </FormControl>
                  </GridItem>
                  <GridItem colSpan={12}>
                    <FormControl>
                      <FormLabel fontWeight="400" color="gray.500">
                        Sub Assunto
                      </FormLabel>
                      <Select
                        {...register("subAssunto")}
                        disabled={
                          !selectedSubject ||
                          selectedSubject.listaSubAssuntos.length == 0
                        }
                      >
                        <option value={undefined}>Todos</option>
                        {selectedSubject &&
                          selectedSubject?.listaSubAssuntos.map((x, i) => (
                            <option key={i} value={x?.iden}>
                              {capitalize(x?.descricao)}
                            </option>
                          ))}
                      </Select>
                    </FormControl>
                  </GridItem>
                </SimpleGrid>
              </form>
              <Actions>
                <ActionButton type="submit" form="onbudsman-form-register">
                  Confirmar
                </ActionButton>
              </Actions>
            </Stack>
          )}
        </Stack>
      </Container>
    </PageLayout>
  );
};

export const OnbudsmanConsult = () => {
  const [manifest, setManifest] = useState<Manifest | null>(null);
  const [manifests, setManifests] = useState<Manifest[]>([]);
  const schema = yup.object().shape({
    codigoManifestacao: yup.string().required(CAMPO_OBRIGATORIO),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const { showToast } = useCustomToast();

  const { getManifests } = useOnbudsmanService();

  const navigate = useNavigate();

  const queryManifests = useQuery<Manifest[], Error>(
    ["getManifestacoesOuvidoria"],
    async () => {
      return await getManifests();
    },
    {
      refetchOnWindowFocus: false,
      onSuccess: (results: Manifest[]) => {
        setManifests(results);
      },
      onError: () =>
        showToast("error", "Não foi possível listar os assuntos de ouvidoria."),
    }
  );

  const filterManifest = ({ codigoManifestacao }: any) => {
    const manifest = manifests.find((x) => x.iden == codigoManifestacao);
    if (manifest) {
      navigate(
        `${ROUTES.ONBUDSMAN_CONSULT_DETAILS.replace(":id", manifest.iden)}`,
        { state: manifest }
      );
    } else showToast("error", "Manifestação não encontrada.");
  };

  const onSubmit = handleSubmit((data: any) => filterManifest(data));

  return (
    <PageLayout>
      <Container maxW="container.md" pt={5} pb={20}>
        <Stack spacing={8}>
          <Breadcrumb
            items={[
              { route: "/", label: "Início" },
              { route: ROUTES.ONBUDSMAN, label: "Ouvidoria" },
              { route: ROUTES.ONBUDSMAN, label: "Consultar manifestação" },
            ]}
          />

          <Stack spacing={5}>
            <PageTitle>Consultar manifestação</PageTitle>

            {queryManifests.isLoading || queryManifests.isFetching ? (
              <Loading centered />
            ) : (
              <>
                <form onSubmit={onSubmit} id="form-onbudsman-query">
                  <SimpleGrid columns={12} columnGap={4} rowGap={4}>
                    <GridItem colSpan={12}>
                      <FormControl
                        isInvalid={!!errors?.codigoManifestacao?.message}
                      >
                        <FormLabel fontWeight="400" color="gray.500">
                          Código da manifestação
                        </FormLabel>
                        <Input {...register("codigoManifestacao")} />
                        <FormErrorMessage>
                          {errors?.codigoManifestacao?.message}
                        </FormErrorMessage>
                      </FormControl>
                    </GridItem>
                  </SimpleGrid>
                </form>
                <Actions>
                  <ActionButton
                    variant={"outline"}
                    onClick={() => navigate(ROUTES.ONBUDSMAN)}
                  >
                    Voltar
                  </ActionButton>
                  <ActionButton type="submit" form="form-onbudsman-query">
                    Consultar
                  </ActionButton>
                </Actions>
              </>
            )}
          </Stack>
        </Stack>
      </Container>
    </PageLayout>
  );
};

export const OnbudsmanConsultDetails = () => {
  const [classification, setClassification] = useState<string>("");
  const [subject, setSubject] = useState<string>("");
  const { state } = useLocation();
  const navigate = useNavigate();
  const componentRef = useRef<any>();
  const { showToast } = useCustomToast();

  const { getOnbudsmanClassification, getSubjectsAndSubSubjects } =
    useOnbudsmanService();


  const queryClassification = useQuery<OnbudsmanClassification[], Error>(
    ["getOuvidoriaClassificacao"],
    async () => {
      return await getOnbudsmanClassification();
    },
    {
      refetchOnWindowFocus: false,
      onSuccess: (results: OnbudsmanClassification[]) =>
        setClassification(
          results.find((x) => x.id == state.classificacao)?.descricao || ""
        ),
      onError: () =>
        showToast(
          "error",
          "Não foi possível listar as classificações de ouvidoria."
        ),
    }
  );

  const querySubject = useQuery<OnbudsmanSubject[], Error>(
    ["getOuvidoriaAssuntos"],
    async () => {
      return await getSubjectsAndSubSubjects();
    },
    {
      refetchOnWindowFocus: false,
      onSuccess: (results: OnbudsmanSubject[]) =>
        setSubject(
          results.find((x) => x.iden == state.assunto)?.descricao || ""
        ),
      onError: () =>
        showToast("error", "Não foi possível listar os assuntos de ouvidoria."),
    }
  );

  return (
    <PageLayout>
      <Container maxW="container.md" pt={5} pb={20}>
        <Stack spacing={8}>
          <Breadcrumb
            items={[
              { route: "/", label: "Início" },
              { route: ROUTES.ONBUDSMAN, label: "Ouvidoria" },
              {
                route: ROUTES.ONBUDSMAN_CONSULT,
                label: "Consultar manifestação",
              },
              { route: ROUTES.ONBUDSMAN_CONSULT_DETAILS, label: "Detalhes" },
            ]}
          />

          {queryClassification.isLoading ||
          queryClassification.isFetching ||
          querySubject.isLoading ||
          querySubject.isFetching ? (
            <Loading centered />
          ) : (
            <>
              <Stack spacing={10} ref={componentRef}>
                <div className={styles.pdfHeader}>
                  <img src={logo} className={styles.pdfLogo} height={"80px"} />
                </div>

                <Heading color={"primary"} size="md">
                  Detalhes da manifestação
                </Heading>

                <section>
                  <div className={styles.sectionHeader}>
                    <Text fontWeight={500} color={"primary"}>
                      Dados do cadastro
                    </Text>
                  </div>
                  <Box p={5}>
                    <SimpleGrid columns={12} columnGap={2} rowGap={3}>
                      <GridItem colSpan={6}>
                        <HStack>
                          <Text color={"gray.500"}>Código:</Text>
                          <Text>{state?.iden}</Text>
                        </HStack>
                      </GridItem>
                      <GridItem colSpan={6}>
                        <HStack>
                          <Text color={"gray.500"}>Data:</Text>
                          <Text>-</Text>
                        </HStack>
                      </GridItem>
                      <GridItem colSpan={6}>
                        <HStack>
                          <Text color={"gray.500"}>Classificação:</Text>
                          <Text>{capitalize(classification)}</Text>
                        </HStack>
                      </GridItem>
                      <GridItem colSpan={6}>
                        <HStack>
                          <Text color={"gray.500"}>Hora:</Text>
                          <Text>-</Text>
                        </HStack>
                      </GridItem>
                      <GridItem colSpan={6}>
                        <HStack>
                          <Text color={"gray.500"}>Entrada:</Text>
                          <Text>Site</Text>
                        </HStack>
                      </GridItem>
                      <GridItem colSpan={6}>
                        <HStack>
                          <Text color={"gray.500"}>Identificação:</Text>
                          <Text>Sigilosa</Text>
                        </HStack>
                      </GridItem>
                      <GridItem colSpan={12}>
                        <HStack>
                          <Text color={"gray.500"}>Resposta:</Text>
                          <Text>E-mail</Text>
                        </HStack>
                      </GridItem>
                      <GridItem colSpan={12}>
                        <HStack>
                          <Text color={"gray.500"}>Tipo de Manifestante:</Text>
                          <Text>-</Text>
                        </HStack>
                      </GridItem>
                      <GridItem colSpan={12}>
                        <HStack>
                          <Text color={"gray.500"}>Assunto:</Text>
                          <Text>{capitalize(subject)}</Text>
                        </HStack>
                      </GridItem>
                    </SimpleGrid>
                  </Box>
                </section>

                <section>
                  <div className={styles.sectionHeader}>
                    <Text fontWeight={500} color={"primary"}>
                      Dados da ocorrência do fato
                    </Text>
                  </div>
                  <Box p={5}>
                    <SimpleGrid columns={12} columnGap={2} rowGap={3}>
                      <GridItem colSpan={12}>
                        <HStack>
                          <Text color={"gray.500"}>Unidade:</Text>
                          <Text>-</Text>
                        </HStack>
                      </GridItem>
                      <GridItem colSpan={12}>
                        <HStack>
                          <Text color={"gray.500"}>Data:</Text>
                          <Text>-</Text>
                        </HStack>
                      </GridItem>
                      <GridItem colSpan={12}>
                        <Stack>
                          <Text color={"gray.500"}>Descrição:</Text>
                          <Text>{capitalize(state?.descricao)}</Text>
                        </Stack>
                      </GridItem>
                    </SimpleGrid>
                  </Box>
                </section>

                <section>
                  <div className={styles.sectionHeader}>
                    <Text fontWeight={500} color={"primary"}>
                      Situação atual
                    </Text>
                  </div>
                  <Box p={5}>
                    <SimpleGrid columns={12} columnGap={2} rowGap={3}>
                      <GridItem colSpan={12}>
                        <HStack>
                          <Text color={"gray.500"}>Data de recebimento:</Text>
                          <Text>-</Text>
                        </HStack>
                      </GridItem>
                      <GridItem colSpan={6}>
                        <HStack>
                          <Text color={"gray.500"}>Situação:</Text>
                          <Text>-</Text>
                        </HStack>
                      </GridItem>
                      <GridItem colSpan={6}>
                        <HStack>
                          <Text color={"gray.500"}>Data:</Text>
                          <Text>-</Text>
                        </HStack>
                      </GridItem>
                      <GridItem colSpan={6}>
                        <HStack>
                          <Text color={"gray.500"}>Unidade:</Text>
                          <Text>-</Text>
                        </HStack>
                      </GridItem>
                      <GridItem colSpan={6}>
                        <HStack>
                          <Text color={"gray.500"}>Data:</Text>
                          <Text>-</Text>
                        </HStack>
                      </GridItem>
                    </SimpleGrid>
                  </Box>
                </section>
              </Stack>
              <Actions>
                <ActionButton
                  variant={"outline"}
                  onClick={() => navigate(ROUTES.ONBUDSMAN_CONSULT)}
                >
                  Voltar
                </ActionButton>
                <ReactToPrint
                  pageStyle={"padding: 40px"}
                  trigger={() => <ActionButton>Imprimir</ActionButton>}
                  content={() => componentRef.current}
                />
              </Actions>
            </>
          )}
        </Stack>
      </Container>
    </PageLayout>
  );
};
