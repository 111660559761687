export function intersect(arrays: Array<any>[], keyProp: string) {
  if (arrays.length === 0) return arrays;
  if (arrays.length === 1) return arrays[0];
  let intersection: any[] = [];
  arrays.forEach((arr, i) => {
    if (arrays[i + 1]) {
      let baseArray = i > 0 ? intersection : arr;

      const filtered = baseArray.filter(c => arrays[i + 1].findIndex(x => x[keyProp] === c[keyProp]) > -1);

      intersection = [...filtered];
    }
  });

  return intersection;
}
