export default function formatCPF(cpf){

    if(/^\d{3}\.\d{3}\.\d{3}\-\d{2}$/.test(cpf)){
        return cpf;
    }

    else{
        if(cpf.length === 11){
            const _cpf = cpf.slice(0,3) + '.' + cpf.slice(3,6) + '.' + cpf.slice(6,9) + '-' + cpf.slice(-2)
            return _cpf;
        }
        else{
            return cpf;
        }
    }

}