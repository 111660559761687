// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Noticias_noticiaLink__3BQi7:hover {\n  text-decoration: underline;\n  color: #0d4f7b;\n}\n\n.Noticias_imagens___5rBx {\n  object-fit: cover;\n  height: 225px;\n  max-width: 100%;\n}", "",{"version":3,"sources":["webpack://src/pages/Home/Noticias/Noticias.module.scss"],"names":[],"mappings":"AACE;EACG,0BAAA;EACA,cAAA;AAAL;;AAIA;EACE,iBAAA;EACA,aAAA;EACA,eAAA;AADF","sourcesContent":[".noticiaLink {\r\n  &:hover {\r\n     text-decoration: underline;\r\n     color: #0d4f7b;\r\n  }\r\n}\r\n\r\n.imagens {\r\n  object-fit: cover;\r\n  height: 225px;\r\n  max-width: 100%;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"noticiaLink": "Noticias_noticiaLink__3BQi7",
	"imagens": "Noticias_imagens___5rBx"
};
export default ___CSS_LOADER_EXPORT___;
