import {
  Container,
  Stack,
  Table,
  Tbody,
  Th,
  Thead,
  Tr,
  Text,
  Td,
  Icon,
  Tooltip,
  IconButton,
  useBreakpointValue,
} from "@chakra-ui/react";
import { useState } from "react";
import { FiDownload } from "react-icons/fi";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import Loading from "../../components/Loading/Loading";
import PageLayout from "../../components/PageLayout/PageLayout";
import { ROUTES } from "../../constants/routes";
import { useTransparencyService } from "../../services/transparency.service";
import styles from "./TransparencyPortal.module.scss";
import { TransparencyReport } from "../../models/transparency.model";
import { useMutation, useQuery } from "react-query";
import { sort } from "../../helpers/sort";
import useCustomToast from "../../hooks/useCustomToast";
import { PageTitle } from "../../components/PageTitle/PageTitle";
import { base64toBlob } from "../../helpers/base64toblob";
import saveAs from "file-saver";
import { OverlayBackdrop } from "../../components/OverlayBackdrop/OverlayBackdrop";
import { DownloadIcon } from "@chakra-ui/icons";
import PDFViewer from "pdf-viewer-reactjs";

export const TransparencyPortal = () => {
  const [items, setItems] = useState<TransparencyReport[]>([]);
  const [selectedItem, setSelectedItem] = useState<{
    base64: string;
    arquivo: TransparencyReport | null;
  }>({ base64: "", arquivo: null });
  const [modalVisible, setModalVisible] = useState<boolean>(false);

  const { showToast } = useCustomToast();

  const { getTransparencyReports, downloadTransparencyReport } =
    useTransparencyService();

  const { isLoading, isFetching } = useQuery<TransparencyReport[], Error>(
    ["listaPortalDaTransparencia"],
    async () => {
      return await getTransparencyReports();
    },
    {
      refetchOnWindowFocus: false,
      onSuccess: (results: TransparencyReport[]) => {
        setItems(sort(results, "iden", "desc"));
      },
      onError: () =>
        showToast(
          "error",
          "Não foi possível listar os itens do portal da transparência."
        ),
    }
  );

  const mutation = useMutation(
    (data: TransparencyReport) => {
      return downloadTransparencyReport(data.iden);
    },
    {
      onError: (err: any) => {
        showToast(
          "error",
          err.response.data || "Não foi baixar o arquivo solicitado."
        );
      },
    }
  );
  const handleDownload = (item: TransparencyReport) => {
    mutation.mutate(item, {
      onSuccess: (res: { arquivo: string }) => {
        try {
          if (window.ReactNativeWebView) {
            window.ReactNativeWebView.postMessage(res.arquivo);
          } else {
            const type =
              item.formatoArquivo === "PDF"
                ? "application/pdf"
                : "application/msword";
            const file =
              type === "application/pdf"
                ? res.arquivo.replace("data:application/pdf;base64,", "")
                : res.arquivo.replace(
                    "data:application/vnd.openxmlformats-officedocument.wordprocessingml.document;base64,",
                    ""
                  );

            const blob = base64toBlob(file, type);
            saveAs(
              blob,
              `${item.nomeArquivo}${
                type === "application/msword" ? ".docx" : ""
              }`
            );
          }
        } catch (error) {
          showToast("error", "Não foi possível baixar o arquivo.");
        }
      },
    });
  };

  const handleShowPDF = (item: TransparencyReport) => {
    if (item.formatoArquivo === "PDF") {
      mutation.mutate(item, {
        onSuccess: (res: { arquivo: string }) => {
          if (window.ReactNativeWebView) {
            window.ReactNativeWebView.postMessage(res.arquivo);
          } else {
            const type =
              item.formatoArquivo === "PDF"
                ? "application/pdf"
                : "application/msword";
            const file =
              type === "application/pdf"
                ? res.arquivo.replace("data:application/pdf;base64,", "")
                : res.arquivo.replace(
                    "data:application/vnd.openxmlformats-officedocument.wordprocessingml.document;base64,",
                    ""
                  );

            setSelectedItem({ base64: file, arquivo: item });
            setModalVisible(true);
          }
        },
      });
    } else {
      handleDownload(item);
    }
  };

  const isMobile = useBreakpointValue({ base: true, md: false });

  return (
    <PageLayout noPaddingX>
      <Container maxW="container.md" pt={5} pb={20}>
        <Stack spacing={8}>
          <Breadcrumb
            items={[
              { route: "/", label: "Início" },
              { route: ROUTES.TRANSPARENCY, label: "Portal da transparência" },
            ]}
          />

          <PageTitle>Portal da transparência</PageTitle>

          {isLoading || isFetching || mutation.isLoading ? (
            <Loading centered />
          ) : (
            <Table variant="simple" size="lg" className={styles.table}>
              <Thead>
                <Tr>
                  <Th></Th>
                  <Th></Th>
                </Tr>
              </Thead>
              <Tbody>
                {false ? (
                  <Text alignSelf={"center"}>
                    Não existem itens disponíves.
                  </Text>
                ) : (
                  <></>
                )}
                {items &&
                  items.map((item: any, i: number) => (
                    <Tr key={i}>
                      <Tooltip
                        hasArrow
                        label="Download do arquivo"
                        placement="top"
                      >
                        <Td
                          onClick={() => handleDownload(item)}
                          style={{ textAlign: "end" }}
                        >
                          <Icon as={FiDownload} color="primary" />
                        </Td>
                      </Tooltip>
                      <Tooltip
                        hasArrow
                        label="Visualizar arquivo"
                        placement="top"
                      >
                        <Td
                          onClick={() => handleShowPDF(item)}
                          color={"gray.500"}
                        >
                          {item.nomeArquivo}
                        </Td>
                      </Tooltip>
                    </Tr>
                  ))}
              </Tbody>
            </Table>
          )}
        </Stack>
      </Container>
      <OverlayBackdrop
        setModalVisible={setModalVisible}
        modalVisible={modalVisible}
        actions={
          <>
            <IconButton
              aria-label="Baixar arquivo"
              tooltip="Baixar arquivo"
              onClick={() => {
                try {
                  const blob = base64toBlob(
                    selectedItem?.base64 || "",
                    "application/pdf"
                  );
                  saveAs(blob, selectedItem.arquivo?.nomeArquivo);
                } catch (error) {
                  showToast("error", "Não foi possível baixar o arquivo.");
                }
              }}
            >
              <Icon fontSize={28} color={"#fff"} as={DownloadIcon}></Icon>
            </IconButton>
          </>
        }
      >
        <PDFViewer
          css={isMobile ? "pdfviewerMobile" : "pdfviewer"}
          canvasCss={isMobile ? "pdfviewerMobile" : "pdfviewer"}
          hideRotation
          hideNavbar
          document={{ base64: selectedItem.base64 }}
        />
      </OverlayBackdrop>
    </PageLayout>
  );
};
