import { Heading, useBreakpointValue } from "@chakra-ui/react";

export interface IPageTitleProps {
  children: any;
  size?: number
}

export function PageTitle({children, size }: IPageTitleProps) {
    const fontSize =  useBreakpointValue({base: 22, md: 24, lg: 26})
    return <Heading fontSize={size || fontSize} color="primary">{children}</Heading>;
}
