export const base64toBlobx = (data: string) => {
  // Cut the prefix `data:application/pdf;base64` from the raw base 64

   const base64WithoutPrefix = data.substr(
     "data:application/pdf;base64,".length
   );

   const bytes = atob(base64WithoutPrefix);
   let length = bytes.length;
   let out = new Uint8Array(length);

   while (length--) {
     out[length] = bytes.charCodeAt(length);
   }

   return new Blob([out], { type: "application/pdf" });


};

export function base64toBlob(base64Data: string, contentType: any) {
    contentType = contentType || '';
    var sliceSize = 1024;
    var byteCharacters = atob(base64Data);
    var bytesLength = byteCharacters.length;
    var slicesCount = Math.ceil(bytesLength / sliceSize);
    var byteArrays = new Array(slicesCount);

    for (var sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
        var begin = sliceIndex * sliceSize;
        var end = Math.min(begin + sliceSize, bytesLength);

        var bytes = new Array(end - begin);
        for (var offset = begin, i = 0; offset < end; ++i, ++offset) {
            bytes[i] = byteCharacters[offset].charCodeAt(0);
        }
        byteArrays[sliceIndex] = new Uint8Array(bytes);
    }
    return new Blob(byteArrays, { type: contentType });
}
