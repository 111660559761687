import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useDisclosure,
  Flex,
  Avatar,
  Heading,
  Stack,
  Link,
  HStack,
  Text,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
} from "@chakra-ui/react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { Icon } from "@chakra-ui/icons";
import styles from "./Sidebar.module.scss";
import { useEffect } from "react";
import { Menu, MENUS } from "../../constants/menus";
import SidebarItem from "../SidebarItem/SidebarItem";
import { FiPower } from "react-icons/fi";
import useAuth from "../../hooks/useAuth";
import Loading from "../Loading/Loading";
import useProfile from "../../hooks/useProfile";
interface SidebarProps {
  sidebarIsOpen: boolean;
  handleClose: () => void;
}

const Sidebar = ({ sidebarIsOpen, handleClose }: SidebarProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    if (sidebarIsOpen === true) {
      onOpen();
    }
  }, [sidebarIsOpen, onOpen]);

  const handleSidebarClose = () => {
    onClose();
    handleClose();
  };

  const auth = useAuth();
  const { user } = useProfile();

  const navigate = useNavigate();

  const logout = () => {
    auth.signOut(() => {
      onClose();
      handleClose();
      navigate("/login", { replace: true });
    });
  };

  const SidebarHeader = () => {
    return (
      <Flex className={`${styles.sidebarHeader}`}>
        {!user ? (
          <Loading centered />
        ) : (
          <>
            <Avatar size="lg" name={user?.NOME_PESSOA} marginRight={3} />
            <Stack spacing={0}>
              <Heading wordBreak={"break-word"} fontSize={"13px"} color="white">
                {user?.NOME_PESSOA}
              </Heading>
              <Text className={`${styles.sidebarHeaderLink}`}>
                <RouterLink to="/perfil" onClick={handleSidebarClose}>
                  <small>Visualizar perfil</small>
                </RouterLink>
              </Text>
            </Stack>
          </>
        )}
      </Flex>
    );
  };

  const SidebarFooter = () => {
    return (
      <Flex
        className={`${styles.sidebarFooter}`}
        alignItems="center"
        justifyContent="space-between"
        w="full"
      >
        <Link onClick={logout}>
          <HStack spacing={4}>
            <Icon as={FiPower} />
            <Text>Sair</Text>
          </HStack>
        </Link>
        <small>Versão {process.env.REACT_APP_VERSION}</small>
      </Flex>
    );
  };

  return (
    <Drawer isOpen={isOpen} placement="left" onClose={handleSidebarClose}>
      <DrawerOverlay />
      <DrawerContent className={`${styles.sidebarContent}`} bg="primary">
        <DrawerCloseButton color="white" />
        <DrawerHeader>
          <SidebarHeader />
        </DrawerHeader>

        <DrawerBody>
          <Stack>
            {MENUS
            .filter(menu => !menu.permission || menu.permission.some(p => user?.CD_APLICACAO.includes(p)))
            .map((menu: Menu, index) => {
              const content = menu.route ? (
                <RouterLink
                  to={menu.route}
                  key={index}
                  onClick={handleSidebarClose}
                >
                  <SidebarItem icon={menu.icon} text={menu.text} />
                </RouterLink>
              ) : (
                <>
                  <Accordion allowToggle>
                    <AccordionItem>
                      <h2>
                        <AccordionButton outline={'none'} p={0} boxShadow={'none'}>
                          <SidebarItem
                            key={index}
                            icon={menu.icon}
                            text={menu.text}
                          />
                        </AccordionButton>
                      </h2>
                      <AccordionPanel pb={4} pl={4}>
                        {menu.children?.map((subMenu: Menu) => {
                          return (
                            <RouterLink
                              to={subMenu.route || ''}
                              key={index}
                              onClick={handleSidebarClose}
                            >
                              <SidebarItem
                                isSubmenu
                                icon={subMenu.icon}
                                text={subMenu.text}
                              />
                            </RouterLink>
                          );
                        })}
                      </AccordionPanel>
                    </AccordionItem>
                  </Accordion>
                </>
              );
              return content;
            })}
          </Stack>
        </DrawerBody>

        <DrawerFooter>
          <SidebarFooter />
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};

export default Sidebar;
