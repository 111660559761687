import { ENDPOINTS } from "../constants/endpoints";
import useOldApi from "../hooks/useOldApi";

export function useContactService() {
  const oldApi = useOldApi();

  const getContacts = async () => {
    let response = await oldApi.get(ENDPOINTS.contacts);
    return response.data;
  };

  const services = {
    getContacts
  };

  return services;
}
