// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".FutureBillings_icons__1coNI {\n  font-size: 18px;\n  font-weight: bold;\n  color: #0d4f7b;\n}\n\n.FutureBillings_link__1s4uM:hover {\n  text-decoration: underline;\n}\n\n.FutureBillings_table__2ZsGv tbody tr:hover td {\n  background-color: #f7f7f7;\n}", "",{"version":3,"sources":["webpack://src/pages/Financial/FutureBillings/FutureBillings.module.scss","webpack://src/styles/variables.scss"],"names":[],"mappings":"AAEA;EACI,eAAA;EACA,iBAAA;EACA,cCJM;ADGV;;AAKI;EACI,0BAAA;AAFR;;AASM;EACE,yBAAA;AANR","sourcesContent":["@import \"../../../styles/variables.scss\";\r\n\r\n.icons{\r\n    font-size: 18px;\r\n    font-weight: bold;\r\n    color: $primary;\r\n}\r\n\r\n.link{\r\n    &:hover{\r\n        text-decoration: underline;\r\n    }\r\n}\r\n\r\n.table {\r\n  tbody tr {\r\n    &:hover {\r\n      td {\r\n        background-color: #f7f7f7;\r\n      }\r\n    }\r\n  }\r\n}\r\n\r\n","// $primary: #003E66;\r\n$primary: #0d4f7b;\r\n$danger: #F03D3E;\r\n$success: #44AC80;\r\n$white: #FFFFFF;\r\n\r\n$border-radius: 8px;\r\n$navbar-height: 50px;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"icons": "FutureBillings_icons__1coNI",
	"link": "FutureBillings_link__1s4uM",
	"table": "FutureBillings_table__2ZsGv"
};
export default ___CSS_LOADER_EXPORT___;
