// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Onbudsman_sectionHeader__cqIU9 {\n  background: rgba(79, 125, 161, 0.1);\n  height: 27px;\n  padding: 0.5rem;\n  display: flex;\n  align-items: center;\n}\n\n.Onbudsman_pdfHeader__2VNBt {\n  display: none;\n  text-align: center;\n}\n\n.Onbudsman_pdfLogo__2Nnvz {\n  margin: auto;\n  margin-bottom: 2rem;\n  text-align: center;\n  height: 80px;\n}\n\n@media print {\n  .Onbudsman_pdfHeader__2VNBt {\n    display: block;\n  }\n}", "",{"version":3,"sources":["webpack://src/pages/Onbudsman/Onbudsman.module.scss"],"names":[],"mappings":"AAAA;EACE,mCAAA;EACA,YAAA;EACA,eAAA;EACA,aAAA;EACA,mBAAA;AACF;;AAEA;EACE,aAAA;EACA,kBAAA;AACF;;AAEA;EACE,YAAA;EACA,mBAAA;EACA,kBAAA;EACA,YAAA;AACF;;AAEA;EACE;IACE,cAAA;EACF;AACF","sourcesContent":[".sectionHeader {\r\n  background: rgba(79, 125, 161, 0.1);\r\n  height: 27px;\r\n  padding: 0.5rem;\r\n  display: flex;\r\n  align-items: center;\r\n}\r\n\r\n.pdfHeader {\r\n  display: none;\r\n  text-align: center;\r\n}\r\n\r\n.pdfLogo {\r\n  margin: auto;\r\n  margin-bottom: 2rem;\r\n  text-align: center;\r\n  height: 80px;\r\n}\r\n\r\n@media print {\r\n  .pdfHeader {\r\n    display: block;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sectionHeader": "Onbudsman_sectionHeader__cqIU9",
	"pdfHeader": "Onbudsman_pdfHeader__2VNBt",
	"pdfLogo": "Onbudsman_pdfLogo__2Nnvz"
};
export default ___CSS_LOADER_EXPORT___;
