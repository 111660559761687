import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useQuery } from "react-query";

import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Container,
  Flex,
  Heading,
  HStack,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Thead,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";

import invitesIcon1 from "../../assets/icons/invites_1.svg";
import invitesIcon2 from "../../assets/icons/invites_2.svg";

import PageLayout from "../../components/PageLayout/PageLayout";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import LinkBox from "../../components/LinkBox/LinkBox";
import ActionButton from "../../components/ActionButton/ActionButton";
import Loading from "../../components/Loading/Loading";
import useCustomToast from "../../hooks/useCustomToast";

import { ROUTES } from "../../constants/routes";
import { useInvitesService } from "../../services/invites.service";
import { InviteBalance } from "../../models/invite.model";
import { userManager } from "../../services/authManager";


const Invites = () => {
  const [invitesBalance, setInvitesBalance] = useState<InviteBalance>({
    SD_CONVITE_VENDIDO: 0,
    SD_CONVITE: 0,
  });
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef: any = useRef();
  const { showToast } = useCustomToast();

  const { getInviteBalance } = useInvitesService();

  const { USER_ACESSO_SISTEMA } = userManager.get()

  const { isLoading, isRefetching } = useQuery<any, Error>(
    "saldoConvites",
    async () => {
      return await getInviteBalance(USER_ACESSO_SISTEMA);
    },
    {
      retry: 5,
      enabled: isOpen,
      refetchOnWindowFocus: false,
      onSuccess: (res: any) => {
        if (res && res.length > 0) {
          setInvitesBalance({
            SD_CONVITE_VENDIDO: res[0].SD_CONVITE_VENDIDO,
            SD_CONVITE: res[0].SD_CONVITE,
          })
        }
      },
      onError: () => {
        showToast("error", "Não foi possível recuperar o saldo de convites.");
        onClose();
      },
    }
  );

  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    if (location.state) {
      if (location.state.goToEmission) {
        navigate(ROUTES.INVITES_EMISSION);
        return;
      }
    }
  }, [location.state, navigate])

  const showInviteBalance = () => {
    onOpen();
  };
  return (
    <PageLayout>
      <Container maxW="container.md" pt={5} pb={20}>
        <Stack spacing={5}>
          <Breadcrumb
            items={[
              { route: "/", label: "Início" },
              { route: ROUTES.INVITES_SELECT, label: "Convites" },
              { route: ROUTES.INVITES_GENERAL, label: "Geral" },
              
            ]}
          />

          <LinkBox
            label="Emissão de convites"
            to={ROUTES.INVITES_EMISSION}
            leftIcon={invitesIcon1}
          />
          <LinkBox
            label="Convites emitidos"
            to={ROUTES.INVITES_EMITTED}
            leftIcon={invitesIcon2}
          />
          <LinkBox
            noRoute
            label="Consulta de saldo"
            leftIcon={invitesIcon2}
            onClick={showInviteBalance}
          />
        </Stack>
      </Container>
      <AlertDialog
        size="xs"
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        isCentered
      >
        <AlertDialogOverlay>
          <AlertDialogContent maxW={'90vw'}>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              <AlertDialogCloseButton />
            </AlertDialogHeader>

            <AlertDialogBody py={10}>
              {isLoading || isRefetching ? (
                <Flex w="full" alignItems={"center"} justifyContent="center">
                  <Loading />
                </Flex>
              ) : (
                <Stack
                  spacing={4}
                  justifyContent={"center"}
                  alignItems={"center"}
                  w="full"
                >
                  <Heading color="primary" fontSize={20}>
                    Saldo de convites
                  </Heading>
                  <Table>
                    <Thead></Thead>
                    <Tbody>
                      <Tr>
                        <Td>
                          <Text>Convites Gratuitos</Text>
                        </Td>
                        <Td>
                          <Text>{invitesBalance.SD_CONVITE}</Text>
                          {invitesBalance.SD_CONVITE === null && (
                            <Text>0</Text>
                          )}
                        </Td>
                      </Tr>
                      <Tr>
                        <Td>
                          <Text>Convites Vendidos</Text>
                        </Td>
                        <Td>
                          <Text>{invitesBalance.SD_CONVITE_VENDIDO}</Text>
                          {invitesBalance.SD_CONVITE_VENDIDO === null && (
                            <Text>0</Text>
                          )}
                        </Td>
                      </Tr>
                    </Tbody>
                  </Table>
                </Stack>
              )}
            </AlertDialogBody>

            <AlertDialogFooter>
              <HStack justifyContent={"center"} alignItems={"center"} w="full">
                <ActionButton
                  w="full"
                  onClick={() => {
                    onClose();
                  }}
                >
                  Fechar
                </ActionButton>
              </HStack>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </PageLayout>
  );
};

export default Invites;
