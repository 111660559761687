import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Container,
  Flex,
  Heading,
  HStack,
  Icon,
  Link,
  Stack,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";
import Breadcrumb from "../../../components/Breadcrumb/Breadcrumb";
import PageLayout from "../../../components/PageLayout/PageLayout";
import styles from "./Dependents.module.scss";
import { MdOutlineOpenInNew } from "react-icons/md";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { ROUTES } from "../../../constants/routes";
import { useRef, useState } from "react";
import Actions from "../../../components/Actions/Actions";
import ActionButton from "../../../components/ActionButton/ActionButton";
import { useDependentService } from "../../../services/dependent.service";
import { useMutation, useQuery } from "react-query";
import { Dependent } from "../../../models/dependent.model";
import Toast from "../../../components/Toast/Toast";
import Loading from "../../../components/Loading/Loading";

const Dependents = () => {
  const [dependents, setDependents] = useState<Dependent[]>([]);
  const [dependentIdToDelete, setDependentIdToDelete] = useState<any>();

  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef: any = useRef();

  const { getDependents, deleteDependent } = useDependentService();

  const toast = useToast();

  const showToast = (type: "error" | "success", data: any) => {
    toast({
      render: () => <Toast type={type} message={data} />,
      duration: 5000,
      isClosable: false,
    });
  };

  const query = useQuery<Dependent[], Error>(
    "listaDependentes",
    async () => {
      return await getDependents();
    },
    {
      refetchOnWindowFocus: true,
      onSuccess: (dependents: Dependent[]) => {
        setDependents(dependents);
      },
      onError: () =>
        showToast(
          "error",
          "Não foi possível recuperar os dados do perfil. Tente novamente."
        ),
    }
  );

  const deleteMutation = useMutation((id: string) => deleteDependent(id), {
    onSuccess: () => {
      showToast("success", "Dependente excluído.");
      query.refetch();
    },
    onError: () => showToast("error", "Não foi possível excluir o dependente."),
  });

  const handleDeleteDependent = () => {
    deleteMutation.mutate(dependentIdToDelete);
    setDependentIdToDelete(null);
  };

  return (
    <PageLayout>
      <Container maxW="container.md" pt={5} pb={20}>
        <Stack spacing={10} className={styles.profileForm}>
          <Breadcrumb
            items={[
              { route: "/", label: "Início" },
              { route: ROUTES.PROFILE, label: "Perfil" },
              { route: ROUTES.DEPENDENTS, label: "Dependentes" },
            ]}
          />

          {query.isLoading || query.isRefetching ? (
            <Flex w="full" justifyContent="center">
              <Loading />
            </Flex>
          ) : (
            <>
              <Stack spacing={2}>
                <Heading size="md" fontWeight="500" color="primary">
                  Meus dependentes
                </Heading>
              </Stack>

              {dependents.length > 0 ? (
                <table className={styles.table}>
                  <thead>
                    <tr>
                      <th>Nome</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {dependents.map((d, index) => (
                      <tr key={index}>
                        <td style={{ width: "90%" }}>
                          <Text color="gray.600">{d.NOME_PESSOA}</Text>
                        </td>

                        <td>
                          <HStack justifyContent={"center"}>
                            <RouterLink to={ROUTES.DEPENDENT_EDIT} state={d}>
                              <Icon
                                fontSize={20}
                                color="gray.500"
                                as={MdOutlineOpenInNew}
                              />
                            </RouterLink>
                          </HStack>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <Box
                  h={200}
                  w="full"
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"center"}
                >
                  <Text color={"gray.500"}>
                    Não há dependentes cadastrados.
                  </Text>
                </Box>
              )}
              <Actions>
                <RouterLink to={ROUTES.PROFILE}>
                  <ActionButton variant="outline">Voltar</ActionButton>
                </RouterLink>

                {/* {dependents.length >= 3 ? (
                  <></>
                ) : (
                  <RouterLink to={ROUTES.DEPENDENT_REGISTER}>
                    <ActionButton>Adicionar dependente</ActionButton>
                  </RouterLink>
                )} */}
                  {/* <RouterLink to={ROUTES.DEPENDENT_REGISTER}>
                    <ActionButton>Adicionar dependente</ActionButton>
                  </RouterLink> */}
              </Actions>
            </>
          )}
        </Stack>
        <AlertDialog
          size="xs"
          isOpen={isOpen}
          leastDestructiveRef={cancelRef}
          onClose={() => {
            setDependentIdToDelete(null);
            onClose();
          }}
          isCentered
        >
          <AlertDialogOverlay>
            <AlertDialogContent maxW={'90vw'}>
              <AlertDialogHeader fontSize="lg" fontWeight="bold">
                Cancelar dependente
                <AlertDialogCloseButton />
              </AlertDialogHeader>

              <AlertDialogBody py={10}>
                <Text textAlign="center">
                  Se realizar o pedido de cancelamento do seu dependente, você
                  não será ressarcido pelos dias restantes e ele perderá o
                  acesso total ao clube.
                </Text>
              </AlertDialogBody>

              <AlertDialogFooter>
                <ActionButton
                  w="full"
                  onClick={() => {
                    handleDeleteDependent();
                    onClose();
                  }}
                >
                  Cancelar agora
                </ActionButton>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
      </Container>
    </PageLayout>
  );
};

export default Dependents;
