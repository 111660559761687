import { ChevronLeftIcon } from "@chakra-ui/icons";
import {
  Box,
  Container,
  Flex,
  Stack,
  useBreakpointValue,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import PageLayout from "../../../../components/PageLayout/PageLayout";
import {FiShare2} from "react-icons/fi";
import { ROUTES } from "../../../../constants/routes";
import styles from "./DebtPaymentDetails.module.scss"
import Actions from "../../../../components/Actions/Actions";
import ActionButton from "../../../../components/ActionButton/ActionButton";

export type DebtPaymentDetailsProps = {};

export default function DebtPaymentDetails({}: DebtPaymentDetailsProps) {
  const paddingTop = useBreakpointValue({base: 8, md: 10, lg: 14});
  const mockContainerHeight = useBreakpointValue({base: '500px', md: '600px', lg: '750px'});
  return (
    <PageLayout noPaddingX>
    <Container maxW="container.md" pt={paddingTop} pb={20}>
      <Stack spacing={10}>
        <Flex w="full" justifyContent={'space-between'}>
          <a role="button"><ChevronLeftIcon className={styles.icons}/><Link className={styles.link} to={ROUTES.FINANCIAL_DEBT_PAYMENTS}>Voltar</Link></a>
          <ActionButton variant={"outline"}><FiShare2 className={styles.icons}/></ActionButton>
        </Flex>
        <Box  className={styles.mocKDeclaracao} h={mockContainerHeight}>
          Container da Declaração 
        </Box>
        <Actions justify="center">
          <ActionButton>Baixar arquivo</ActionButton>
        </Actions>
      </Stack>
    </Container>
  </PageLayout>
  );
}
