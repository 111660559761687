
import moment from "moment";
import { ENDPOINTS } from "../constants/endpoints";
import useAxios from "../hooks/useAxios";
import { FacilityReserve } from "../models/facility-reserve.model";
import { AvailableClassesMonthResponse, GymModalityClass, GymReservationDone, GymReservationState, GymUser } from "../models/reservations.model";
import { AcademiaReserva } from "../pages/Reservations/ReservationsHistory/ReservationsHistory";
import { userManager } from "./authManager";

export function useReservationService() {
  const axios = useAxios();

  const reserveFacility = async (reservation: FacilityReserve) => {
    const response = await axios.post<any>(ENDPOINTS.reserveFacility, reservation);
    return response.data;
  };

  const cancelReservation = async (id: string) => {
    const response = await axios.delete<FacilityReserve>(`${ENDPOINTS.reserveFacility}/${id}`);
    return response.data;
  };

  const cancelGymReservation = async (id: number) => {
    const usuario = userManager.get();
    if (!usuario) {
      throw new Error('Nenhum usuário logado!')
    }
    const cancelBody = {
      params: {
        seqAgendamento: id,
        usuarioCancelamento: usuario.USER_ACESSO_SISTEMA,
        // ip: ''
      }
    }
    const response = await axios.put(ENDPOINTS.cancelGymReservation, null, cancelBody);
    if (response.data[0].MSG !== 'OK') {
      throw new Error(response.data[0].MSG);
    }
    return response.data;
  };

  const getGymModalities = async () => {
    const response = await axios.get<{
      NU_SEQ_SERVICO: number;
      DE_SERVICO: string;
      CD_AGRUPAMENTO: number;
    }[]>(ENDPOINTS.getGymModalities);
    return response.data.map(jsonApi => (
      {
        iden: jsonApi.NU_SEQ_SERVICO,
        descricao: jsonApi.DE_SERVICO,
        idenModality: jsonApi.CD_AGRUPAMENTO,
      }
    ));
  };

  const getTeacherModalities = async (agrupamento: number) => {
    const response = await axios.get<{
      CD_FUNCIONARIO: number;
      NOME_FUNCIONARIO: string;
    }[]>(ENDPOINTS.getTeacherModalities, {
      params: {
        agrupamento
      }
    });
    return response.data.map(jsonApi => (
      {
        idenProfessor: jsonApi.CD_FUNCIONARIO,
        nome: jsonApi.NOME_FUNCIONARIO,
      }
    ));
  };

  const getGymModalitiesClasses = async (idModalidade: any, dataReferencia: string, funcionario: number, turno: string) => {

    const _data = moment(dataReferencia).format("MM/DD/YYYY");
    const response = await axios.get<{
      CD_FUNCIONARIO: number;
      NOME_FUNCIONARIO: string;
      QT_MIN_ATENDIMENTO: number;
      DT_INICIO: string;
      DT_FIM: string;
      NU_SEQ_AGENDA: number;
      NU_SEQ_EXCECAO: number;
      SD_VAGAS: number;
    }[]>(ENDPOINTS.getGymModalitiesClass, {
      params: {

        dataReferencia: _data, servico: idModalidade, funcionario, turno
      }
    });
    const listaTurmas: GymModalityClass[] = response.data.map(jsonApi => (
      {
        iden: jsonApi.NU_SEQ_AGENDA,
        disponibilidade: jsonApi.SD_VAGAS,
        dtaHorarioFim: jsonApi.DT_FIM,
        dtaHorarioInicio: jsonApi.DT_INICIO,
        professor: jsonApi.NOME_FUNCIONARIO,
        idenProfessor: jsonApi.CD_FUNCIONARIO
      }
    ));
    return listaTurmas;
  };

  const getGymModalitiesClassesMonth = async (idModalidade: any, mes: number, ano: number, funcionario: number, turno: string) => {
    const response = await axios.get<{
      DIA: number;
      SALDO: number;
      QT_VAGA: number;
    }[]>(ENDPOINTS.getGymModalitiesClassMonth, {
      params: {
        mes, ano, servico: idModalidade, funcionario, turno
      }
    });
    const datas: AvailableClassesMonthResponse[] = response.data.map(jsonApi => (
      {
        dia: jsonApi.DIA,
        date: new Date(ano, mes - 1, jsonApi.DIA, 23, 59),
        saldo: jsonApi.SALDO,
        qtdVaga: jsonApi.QT_VAGA
      }
    ));
    return datas;
  };

  const reserveGymModalityClass = async (reservation: GymReservationState) => {
    const usuario = userManager.get();
    if (!usuario) {
      throw new Error('Nenhum usuário logado!')
    }
    const reservationBody = {
      matricula: reservation.matricula,
      categoria: reservation.categoria,
      usuario: usuario.USER_ACESSO_SISTEMA,
      dependente: reservation.dependente,
      //dataInicio: moment(reservation.horaInicio).format('MM-DD-YYYYTHH:mm:ss'),
      dataInicio: moment(reservation.horaInicio).format('MM/DD/YYYY HH:mm:ss'),
      //dataFim: moment(reservation.horaFim).format('MM-DD-YYYYTHH:mm:ss'),
      dataFim: moment(reservation.horaFim).format('MM/DD/YYYY HH:mm:ss'),
      servico: reservation.idenModalidade,
      funcionario: reservation.idenProfessor,
      agenda: reservation.idenTurma,
      excecao: 0
    };
    const response = await axios.post(ENDPOINTS.confirmReservation, reservationBody, {
      validateStatus: (status) => status >= 200 && status < 500
    });
    if (response.status === 200) {
      if ('MENSAGEM' in response.data[0] && response.data[0].MENSAGEM !== 'OK') {
        throw new Error(response.data[0].MENSAGEM);
      }
      else if ('Mensagem' in response.data[0] && response.data[0].Mensagem !== 'OK') {
        throw new Error(response.data[0].Mensagem);
      }
    }
    else if (response.status === 404) {
      if ('MENSAGEM' in response.data && response.data.MENSAGEM !== 'OK') {
        throw new Error(response.data.MENSAGEM);
      }
      else if ('Mensagem' in response.data && response.data.Mensagem !== 'OK') {
        throw new Error(response.data.Mensagem);
      }
    }
    return response.data;
  };

  const getReservationsHistory = async (): Promise<AcademiaReserva[]> => {
    const usuario = userManager.get();
    if (!usuario) {
      throw new Error('Nenhum usuário logado!')
    }
    const response = await axios.get<AcademiaReserva[]>(ENDPOINTS.reservationsHistory, {
      params: {
        matricula: usuario.CD_MATRICULA,
        categoria: usuario.CD_CATEGORIA,
        //dependente: 0,
        //historico: ''
      }
    });
    return response.data.map(a => ({
      ...a,
      data: new Date(a.DT_INICIO),
      tipoReserva: 'MODALIDADE_ESPORTIVA'
    }));
  };

  const getReservationDetails = async (agendamento:number): Promise<GymReservationDone> => {
    const usuario = userManager.get();
    if (!usuario) {
      throw new Error('Nenhum usuário logado!')
    }
    const response = await axios.get<GymReservationDone[]>(ENDPOINTS.getReservationDetails, {
      params: {
        matricula: usuario.CD_MATRICULA,
        categoria: usuario.CD_CATEGORIA,
        agendamento
      }
    });
    return response.data[0];
  };

  const listGymUsers = async (): Promise<GymUser[]> => {
    const usuario = userManager.get();
    if (!usuario) {
      throw new Error('Nenhum usuário logado!')
    }
    const response = await axios.get<GymUser[]>(ENDPOINTS.listGymUsers, {
      params: {
        usuario: usuario.USER_ACESSO_SISTEMA,
      }
    });
    return response.data;
  };



  const services = {
    reserveFacility,
    cancelReservation,
    cancelGymReservation,
    getGymModalities,
    getGymModalitiesClasses,
    getGymModalitiesClassesMonth,
    reserveGymModalityClass,
    getReservationsHistory,
    getReservationDetails,
    getTeacherModalities,
    listGymUsers
  };

  return services;
}
