// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PageLayout_pageLayout__3Mu1C {\n  padding-top: calc(50px * 1.6);\n}", "",{"version":3,"sources":["webpack://src/components/PageLayout/PageLayout.module.scss"],"names":[],"mappings":"AAEA;EACI,6BAAA;AADJ","sourcesContent":["@import \"../../styles/variables.scss\";\r\n\r\n.pageLayout{\r\n    padding-top: calc(#{$navbar-height} * 1.6);\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pageLayout": "PageLayout_pageLayout__3Mu1C"
};
export default ___CSS_LOADER_EXPORT___;
