// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Profile_avatar__XVtQm {\n  align-self: center;\n}", "",{"version":3,"sources":["webpack://src/pages/Profile/Profile.module.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;AACJ","sourcesContent":[".avatar{\r\n    align-self: center;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"avatar": "Profile_avatar__XVtQm"
};
export default ___CSS_LOADER_EXPORT___;
