import PageLayout from "../../components/PageLayout/PageLayout";
import logo from "../../assets/img/logo/logo_iate_versao_12_azul.jpg";
import jornal from "../../assets/img/jornal-iate-nov.png";
import { Box, Container, Heading, Image, Stack } from "@chakra-ui/react";
import styles from "./Home.module.scss";
import Noticias from "./Noticias/Noticias";
import useProfile from "../../hooks/useProfile";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../constants/routes";

const Home = () => {
  const { refetch } = useProfile();
  const navigate = useNavigate();

  useEffect(() => {
    refetch();
  }, []);

  const openJornal = () => {
    navigate(ROUTES.JORNAL);
  }
  return (
    <PageLayout>
      {/* <Box
        className={styles.logoContainer}
        w="full"
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
      > */}
      {/* <Image
          className={styles.logo}
          src={logo}
          alt="Iate Clube de Brasília"
          height={["100px", "150px"]}
        /> */}
      {/* </Box> */}
      <Container maxW={"container.lg"} pt={12} pb={20}>
        <Stack spacing={8}>
          <Heading color="primary">
            <span style={{ fontWeight: 300 }}>Seu jornal </span>
            <span style={{ color: "#205bc0", fontWeight: 400 }}>chegou</span>
          </Heading>
          
          {/* <iframe src="http://jornal.iateclubedebrasilia.com.br"></iframe> */}

          <a
            style={{ maxWidth: "fit-content", marginTop: "0px" }}
            rel="noreferrer"
            role={"button"}
            onClick={openJornal}

          >
            <Image
              width="340px"
              height="225px"
              src={jornal}
              alt="Imagem do jornal"
              className={styles.imagens}
            />
          </a>
        </Stack>
        <Noticias />
      </Container>
    </PageLayout>
  );
};

export default Home;
