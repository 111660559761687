import {
  Box,
  Container,
  Flex,
  Stack,
  Text,
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  HStack,
  useDisclosure,
  Heading,
  useToast,
  useBreakpointValue,
  Icon,
} from "@chakra-ui/react";
import styles from "./BillingCopy.module.scss";
import moment from "moment";
import { useRef, useState } from "react";
import { useMutation, useQuery } from "react-query";
import ActionButton from "../../../components/ActionButton/ActionButton";
import Breadcrumb from "../../../components/Breadcrumb/Breadcrumb";
import GroupedButtons from "../../../components/GroupedButtons/GroupedButtons";
import Loading from "../../../components/Loading/Loading";
import PageLayout from "../../../components/PageLayout/PageLayout";
import Toast from "../../../components/Toast/Toast";
import { ROUTES } from "../../../constants/routes";
import { capitalize } from "../../../helpers/capitalize";
import { currency } from "../../../helpers/currency";
import { Slip } from "../../../models/financial.model";
import { useFinancialService } from "../../../services/financial.service";
import PDFViewer from 'pdf-viewer-reactjs'
import { saveAs } from 'file-saver';
import { base64toBlob } from "../../../helpers/base64toblob";
import useCustomToast from "../../../hooks/useCustomToast";
import { OverlayBackdrop } from "../../../components/OverlayBackdrop/OverlayBackdrop";
import { CloseIcon, CopyIcon, DownloadIcon } from "@chakra-ui/icons";
import IconButton from "../../../components/IconButton/IconButton";
import { HiClipboardCopy } from "react-icons/hi";


enum BillingsList {
  PENDENTES,
  GERADOS,
}

export interface IBillingCopyProps {}

export default function BillingCopy(props: IBillingCopyProps) {
  const [selectedOption, setSelectedOption] = useState<number>(0);
  const [selectedSlip, setSelectedSlip] = useState<Slip>(null!);
  const [slips, setSlips] = useState<Slip[]>([]);
  const [pdf, setPdf] = useState<any>(null!);
  const [modalVisible, setModalVisible] = useState<boolean>(false);

  const handleOptionChange = (opt: number) => {
    setSelectedOption(opt);
  };

  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isBarCodeOpen,
    onOpen: onBarCodeOpen,
    onClose: onBarCodeClose,
  } = useDisclosure();

  const cancelRef: any = useRef();

  const {showToast} = useCustomToast();

  const { getSlips, requestSlipCopy } = useFinancialService();

  const { isLoading } = useQuery<any[], Error>(
    "listaConvitesEmitidos",
    async () => {
      return await getSlips();
    },
    {
      refetchOnWindowFocus: false,
      onSuccess: (data: any[]) => {
        setSlips(data);
      },
      onError: () => showToast("error", "Erro ao listar boletos."),
    }
  );

  

  const requestSlipCopyMutation = useMutation(
    (id: number) => requestSlipCopy(id),
    {
      onSuccess: () => {
        showToast(
          "success",
          "A solicitação foi encaminhada à administração do clube."
        );
        setSelectedSlip(null!);
      },
      onError: () => {
        showToast(
          "error",
          "Não foi possível realizar a solicitação."
          );
        setSelectedSlip(null!);
      },
    }
  );

  const handleBillingCopy = () => {
    requestSlipCopyMutation.mutate(selectedSlip.iden);
  };

  const handleShowSlip = (slip: Slip) => {
    if(slip.status === 'PROCESSANDO'){
      showToast(
        "alert",
        "Boleto em processamento"
        );
      return;
    }
    if (slip.status === "DISPONIVEL" && slip.codBarras && slip.arquivo) {
      setSelectedSlip(slip);
      setPdf(slip.arquivo);
      setModalVisible(true);
    } else {
      showToast(
        "error",
        "Erro ao exibir boleto"
        );
    }
  };

  const PendingItemSmall = ({ bill, index }: any) => (
    <Box
      onClick={() => {
        setSelectedSlip(bill);
        setModalVisible(true);
      }}
      key={index}
      className={`${styles.item} ${styles.pendingBill}`}
      py={4}
    >
      <Stack spacing={2} justifyContent={"center"} alignItems={"center"}>
        <Text color={"danger"} fontWeight={600} fontSize={32}>
          {currency(bill.valor)}
        </Text>

        <Stack spacing={0}>
          <Text color="gray.400" fontSize={"12px"}>
            Referente a
          </Text>
          <Text color="gray.500" fontWeight={600} fontSize={"14px"}>
            {bill.mesRef}
          </Text>
        </Stack>
        <Stack spacing={0}>
          <Text color="gray.400" fontSize={"12px"}>
            Vencimento
          </Text>
          <Text color="gray.500" fontWeight={600} fontSize={"14px"}>
            {moment(bill.vencimento).format("DD/MM/YYYY")}
          </Text>
        </Stack>
      </Stack>
    </Box>
  );

  const PendingItemLarge = ({ bill, index }: any) => (
    <Box
      onClick={() => {
        setSelectedSlip(bill);
        setModalVisible(true);
      }}
      key={index}
      className={`${styles.item} ${styles.pendingBill}`}
    >
      <Flex justifyContent={"space-between"}>
        <Stack spacing={0}>
          <Text color="gray.400" fontSize={"12px"}>
            Referente a
          </Text>
          <Text color="gray.500" fontWeight={600} fontSize={"14px"}>
            {bill.mesRef}
          </Text>
        </Stack>

        <Text color={"danger"} fontWeight={600} fontSize={24}>
          {currency(bill.valor)}
        </Text>

        <Stack spacing={0}>
          <Text color="gray.400" fontSize={"12px"}>
            Vencimento
          </Text>
          <Text color="gray.500" fontWeight={600} fontSize={"14px"}>
            {moment(bill.vencimento).format("DD/MM/YYYY")}
          </Text>
        </Stack>
      </Flex>
    </Box>
  );

  const GeneratedItemSmall = ({ bill: bill, index }: any) => (
    <Box
      key={index}
      className={`${styles.item} ${styles.generatedBill}`}
      py={4}
      onClick={() => handleShowSlip(bill)}
    >
      <Stack spacing={2} justifyContent={"center"} alignItems={"center"}>
        <Text color={"black"} fontWeight={600} fontSize={32}>
          {currency(bill.valor)}
        </Text>

        <Stack spacing={0} justifyContent={"center"} alignItems={"center"}>
          <Text color="gray.400" fontSize={"12px"}>
            Vencimento
          </Text>
          <Text color="gray.500" fontWeight={600} fontSize={"14px"}>
            {moment(bill.vencimento).format("DD/MM/YYYY")}
          </Text>
        </Stack>
        <Stack spacing={0} justifyContent={"center"} alignItems={"center"}>
          <Text color="gray.400" fontSize={"12px"}>
            Status
          </Text>
          <Text
            color={bill.status === "DISPONIVEL" ? "green.500" : "blue.500"}
            fontWeight={600}
            fontSize={"14px"}
          >
            {capitalize(bill.status)}
          </Text>
        </Stack>
      </Stack>
    </Box>
  );

  const GeneratedItemLarge = ({ bill: bill, index }: any) => (
    <Box
      onClick={() => handleShowSlip(bill)}
      key={index}
      className={`${styles.item} ${styles.generatedBill}`}
    >
      <Flex justifyContent={"space-between"}>
        <Stack spacing={0}>
          <Text color="gray.400" fontSize={"12px"}>
            Vencimento
          </Text>
          <Text color="gray.500" fontWeight={600} fontSize={"14px"}>
            {moment(bill.vencimento).format("DD/MM/YYYY")}
          </Text>
        </Stack>

        <Stack spacing={0}>
          <Text color="gray.400" fontSize={"12px"}>
            Valor
          </Text>
          <Text color="black" fontWeight={600}>
            R$ {currency(bill.valor)}
          </Text>
        </Stack>

        <Stack w={"100px"} spacing={0}>
          <Text color="gray.400" fontSize={"12px"}>
            Status
          </Text>
          <Text
            color={bill.status === "DISPONIVEL" ? "green.500" : "blue.500"}
            fontWeight={600}
            fontSize={"14px"}
          >
            {capitalize(bill.status)}
          </Text>
        </Stack>
      </Flex>
    </Box>
  );

  const PendingItemResponsive = ({ bill, i }: any) => {
    const item: any = useBreakpointValue({
      base: <PendingItemSmall index={i} bill={bill} />,
      md: <PendingItemLarge index={i} bill={bill} />,
    }) || <></>;
    return item;
  };

  const GeneratedItemResponsive = ({ bill, i }: any) => {
    const item: any = useBreakpointValue({
      base: <GeneratedItemSmall index={i} bill={bill} />,
      md: <GeneratedItemLarge index={i} bill={bill} />,
    }) || <></>;
    return item;
  };

  const isMobile = useBreakpointValue({base: true, md: false})

  return (
    <PageLayout>
      <Container maxW="container.lg" pt={5} pb={20}>
        <Stack spacing={8}>
          <Breadcrumb
            items={[
              { route: "/", label: "Início" },
              { route: ROUTES.FINANCIAL, label: "Financeiro" },
              {
                route: ROUTES.FINANCIAL_BILLINGS_COPY,
                label: "2ª Via do Boleto",
              },
            ]}
          />
           
          {isLoading ? (
            <Flex justifyContent={"center"} w="full">
              <Loading />
            </Flex>
          ) : (
            <Stack spacing={10} w="full" alignItems={"center"}>
              <Box
                className={styles.box}
                padding={3}
                w={["100%", "100%", "fit-content"]}
                minW={["100%", "100%", "300px"]}
              >
                <Stack
                  spacing={1}
                  alignItems={"center"}
                  justifyContent={"center"}
                  w="full"
                >
                  <Text color="gray.400" fontWeight={400} fontSize={16}>
                    IATE CLUBE
                  </Text>
                  <Text color="gray.400" fontSize={13}>
                    Título
                  </Text>
                  <Text color="primary" fontSize="xl" fontWeight={600}>
                    125365859
                  </Text>
                </Stack>
              </Box>

              <Box w="full" display={"flex"} justifyContent={"center"}>
                <GroupedButtons
                  size="md"
                  options={["Boletos pendentes", "Boletos gerados"]}
                  active={selectedOption}
                  change={handleOptionChange}
                />
              </Box>

              {selectedOption === BillingsList.PENDENTES ? (
                <Stack
                  maxW={["full", "full", "90%"]}
                  minW={["full", "full", "450px"]}
                  spacing={5}
                >
                  {slips.filter((x) => x.status === "PENDENTE").length == 0 ? (
                    <Text pt={5} alignSelf={"center"}>
                      Não existem boletos pendentes.
                    </Text>
                  ) : (
                    <></>
                  )}

                  {slips.map((bill, index) => {
                    return (
                      bill.status === "PENDENTE" && (
                        <PendingItemResponsive
                          key={index}
                          bill={bill}
                          i={index}
                        />
                      )
                    );
                  })}
                </Stack>
              ) : (
                <Stack
                  maxW={["full", "full", "90%"]}
                  minW={["full", "full", "450px"]}
                  spacing={5}
                >
                  {slips.filter((x) => x.status !== "PENDENTE").length == 0 ? (
                    <Text pt={5} alignSelf={"center"}>
                      Não existem boletos gerados.
                    </Text>
                  ) : (
                    <></>
                  )}

                  {slips.map((bill, index) => {
                    return (
                      bill.status !== "PENDENTE" && (
                        <GeneratedItemResponsive key={index} bill={bill} />
                      )
                    );
                  })}
                </Stack>
              )}
            </Stack>
          )}

          <AlertDialog
            size="xs"
            isOpen={isOpen}
            leastDestructiveRef={cancelRef}
            onClose={onClose}
            isCentered
          >
            <AlertDialogOverlay>
              <AlertDialogContent maxW={'90vw'}>
                <AlertDialogHeader fontSize="lg" fontWeight="bold">
                  <AlertDialogCloseButton />
                </AlertDialogHeader>

                <AlertDialogBody py={10}>
                  <Stack
                    spacing={2}
                    justifyContent={"center"}
                    alignItems={"center"}
                    w="full"
                  >
                    <Heading color="primary" fontSize={20}>
                      Solicitação do boleto
                    </Heading>
                    <Heading color="primary" fontSize={30}>
                      {currency(selectedSlip?.valor)}
                    </Heading>
                    <Text color="gray.400" fontSize={"14px"}>
                      Vencimento:{" "}
                      {moment(selectedSlip?.vencimento).format("DD/MM/YYYY")}
                    </Text>
                  </Stack>
                </AlertDialogBody>

                <AlertDialogFooter>
                  <HStack
                    justifyContent={"center"}
                    alignItems={"center"}
                    w="full"
                  >
                    <ActionButton
                      w="full"
                      onClick={() => {
                        handleBillingCopy();
                        onClose();
                      }}
                    >
                      Confirmar
                    </ActionButton>
                  </HStack>
                </AlertDialogFooter>
              </AlertDialogContent>
            </AlertDialogOverlay>
          </AlertDialog>


          <OverlayBackdrop
        setModalVisible={setModalVisible}
        modalVisible={modalVisible}
        actions={
          <>
          <IconButton
           tooltip="Copiar código"
            onClick={() => {
              const cb = navigator.clipboard;
              let barCode = selectedSlip.codBarras;
              cb.writeText(barCode!)
                .then(() => {
                  showToast(
                    "success",
                    "Código copiado."
                    );
                })
                .catch(() =>
                  console.error("Erro ao copiar código de barras.")
                );
            }}
          >
            <Icon fontSize={28} color={"#fff"} as={CopyIcon}></Icon>
          </IconButton>
          <IconButton
          tooltip="Baixar boleto"
            onClick={() => {
              try {
                const blob = base64toBlob(pdf, "application/pdf");
              saveAs(blob, `Fatura ${selectedSlip.mesRef} - Iate Clube BSB.pdf`)
              } catch (error) {
                showToast(
                  "error",
                  'Não foi possível baixar o boleto'
                  );
              }
            }}
          >
            <Icon fontSize={28} color={"#fff"} as={DownloadIcon}></Icon>
          </IconButton>
 
         
        </
        >

        }
      >
        <PDFViewer
          css={isMobile ? "pdfviewerMobile" : "pdfviewer"}
          canvasCss={isMobile ? "pdfviewerMobile" : "pdfviewer"}
          hideRotation
          hideNavbar
          document={{base64: pdf}}
        /> 
      </OverlayBackdrop>

          {/* <AlertDialog
            size="lg"
            isOpen={isBarCodeOpen}
            leastDestructiveRef={cancelRef}
            onClose={onBarCodeClose}
            isCentered
          >
            <AlertDialogOverlay>
              <AlertDialogContent>
                <AlertDialogHeader fontSize="lg" fontWeight="bold">
                  <AlertDialogCloseButton />
                </AlertDialogHeader>

                <AlertDialogBody py={10}>
                <PDFViewer
                  css={isMobile ? "pdfviewerMobile" : 'pdfviewer'}
                  canvasCss={isMobile ? "pdfviewerMobile" : 'pdfviewer'}
                  hideNavbar
                  hideRotation
                  navbarOnTop={false}
                  document={{base64: pdf}}
                />
                </AlertDialogBody>

                <AlertDialogFooter>
                  <HStack
                    justifyContent={"center"}
                    alignItems={"center"}
                    w="full"
                  >
                    <ActionButton
                      w="full"
                      variant={"outline"}
                      onClick={() => {
                        onBarCodeClose();
                        setSelectedSlip(null!);
                      }}
                    >
                      Fechar
                    </ActionButton>
                    <ActionButton
                      w="full"
                      onClick={() => {
                        try {
                          const blob = base64toBlob(pdf, "application/pdf");
                        saveAs(blob, `Fatura ${selectedSlip.mesRef} - Iate Clube BSB.pdf`)
                        } catch (error) {
                          showToast(
                            "error",
                            'Não foi possível baixar o boleto'
                            );
                        }
                      }}
                    >
                      Baixar
                    </ActionButton>
                    <ActionButton
                      w="full"
                      onClick={() => {
                        const cb = navigator.clipboard;
                        let barCode = selectedSlip.codBarras;
                        cb.writeText(barCode!)
                          .then(() => {
                            showToast(
                              "success",
                              "Código copiado."
                              );
                          })
                          .catch(() =>
                            console.error("Erro ao copiar código de barras.")
                          );
                      }}
                    >
                      Cópiar código
                    </ActionButton>
                  </HStack>
                </AlertDialogFooter>
              </AlertDialogContent>
            </AlertDialogOverlay>
          </AlertDialog> */}
        </Stack>
      </Container>
    </PageLayout>
  );
}
