import {
  Box,
  Circle,
  Flex,
  Heading,
  HStack,
  Icon,
  Stack,
  Text,
  Image,
  Center,
} from "@chakra-ui/react";
import { FiDownload } from "react-icons/fi";
import styles from "./Ticket.module.scss";
import { Invite } from "../../models/invite.model";
import { uppercase } from "../../helpers/uppercase";
import { capitalize } from "../../helpers/capitalize";
import React, { useEffect, useState } from "react";
import logo from "../../assets/img/logo/logo_iate_versao_12_azul.jpg";
import moment from "moment";
import { useQuery } from "react-query";
import { useInvitesService } from "../../services/invites.service";
import { userManager } from "../../services/authManager";
import Loading from "../Loading/Loading";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import ActionButton from "../ActionButton/ActionButton";
import formatCPF from "../../helpers/formatCPF";
import { useBarcode } from "next-barcode";
import { useChurrasqueiraService } from "../../services/churrasqueira.service";

export type TicketComponentProps = {
  onClick?: () => void;
  children?: React.ReactNode;
};

export const TicketComponent = ({
  onClick,
  children,
  ...props
}: TicketComponentProps) => {
  return (
    <Box onClick={onClick} className={styles.ticket} {...props}>
      {children}
    </Box>
  );
};

interface InviteTicketProps {
  invite: Invite;
  isChurrasqueira?: boolean;
}

const Instructions = () => {
  return (
    <Box alignSelf={"center"} px={1} pb={4} mt={1} maxWidth={500}>
      <Heading mb={0} size="sm" color="primary">
        Instruções
      </Heading>
      <ul style={{ paddingLeft: "1rem", paddingRight: "1rem" }}>
        <li style={{ listStyle: "circle" }}>
          <Text fontSize={12} color={"#8d8d8d"}>
            Favor apresentar identidade na portaria.
          </Text>
        </li>
        <li style={{ listStyle: "circle" }}>
          <Text fontSize={12} color={"#8d8d8d"}>
            Favor manter consigo este convite enquanto permanecer no clube.
          </Text>
        </li>
        <li style={{ listStyle: "circle" }}>
          <Text fontSize={12} color={"#8d8d8d"}>
            Válido para uma única entrada.
          </Text>
        </li>
      </ul>
    </Box>
  );
};

export const InviteTicket = ({
  invite,
  isChurrasqueira,
}: InviteTicketProps) => {
  const { getInvites } = useInvitesService();

  const { USER_ACESSO_SISTEMA } = userManager.get();

  const [inviteData, setInviteData] = useState<Invite>();

  useEffect(() => {
    if (isChurrasqueira) {
      setInviteData(invite);
    }
  }, [invite, isChurrasqueira]);

  const { isLoading, isRefetching } = useQuery<any, Error>(
    "listaInfoConvite",
    async () => {
      return await getInvites(USER_ACESSO_SISTEMA);
    },
    {
      enabled: !isChurrasqueira,
      retry: 5,
      refetchOnWindowFocus: false,
      onSuccess: (res: any) => {
        if (res && res.length > 0) {
          const inviteData: Invite = res.find(
            (data: Invite) => data.NR_CONVITE == invite.NR_CONVITE
          );

          setInviteData(inviteData);
        }
      },
    }
  );

  const downloadPdf = () => {
    const input = document.getElementById("print-pdf-invite");
    html2canvas(input!).then((canvas) => {
      const imgData: any = canvas.toDataURL("image/jpeg");
      const pdf = new jsPDF({
        orientation: "portrait",
        unit: "px",
        compress: false,
      });
      const imgProps = pdf.getImageProperties(imgData);
      const pdfWidth = pdf.internal.pageSize.getWidth() * 0.4;
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
      pdf.addImage(imgData, "PNG", 110, 20, pdfWidth, pdfHeight, "SLOW");
      if (window.ReactNativeWebView) {
        window.ReactNativeWebView.postMessage(pdf.output("datauristring"));
      } else {
        pdf.save(
          `Convite Iate Clube de Brasília - ${
            inviteData?.NR_CONVITE || inviteData?.codigo
          }`
        );
      }
    });
  };

  const ConviteChurrasqueira = () => {
    const { inputRef: barcode1 } = useBarcode({
      value: `${inviteData?.NR_CONVITE || inviteData?.codigo}`,
      options: {
        background: "#fff",
        height: 40,
        fontSize: 10,
        textPosition: "top",
        fontOptions: "italic",
      },
    });

    const { inputRef: barcode2 } = useBarcode({
      value: `${inviteData?.NR_CONVITE || inviteData?.codigo}`,
      options: {
        background: "#fff",
        height: 40,
        fontSize: 10,
        textPosition: "top",
        fontOptions: "italic",
      },
    });

    return (
      <Stack spacing={2}>
        <Stack id="print-pdf-invite">
          {isLoading || isRefetching ? (
            <Flex w="full" alignItems={"center"} justifyContent="center">
              <Loading />
            </Flex>
          ) : (
            <>
              <Box
                w={"300px"}
                bg="white"
                borderRadius={8}
                pb={6}
                border={"2px solid lightgrey"}
                alignSelf="center"
                className={styles.printTicket}
              >
                <Stack p={6}>
                  <Stack p={6} pb={2}>
                    <Heading size="md" color="primary">
                      {uppercase(inviteData?.NO_CONVIDADO)}
                    </Heading>
                    <Stack spacing={1}>
                      {!inviteData?.CPF_CONVIDADO &&
                      !inviteData?.NR_DOC_ESTRANGEIRO ? (
                        <></>
                      ) : (
                        <>
                          {inviteData?.NR_DOC_ESTRANGEIRO &&
                          inviteData?.NR_DOC_ESTRANGEIRO !== "" ? (
                            <small>
                              Doc. Estrangeiro: {inviteData?.NR_DOC_ESTRANGEIRO}
                            </small>
                          ) : (
                            <small>
                              CPF: {formatCPF(inviteData?.CPF_CONVIDADO)}
                            </small>
                          )}
                        </>
                      )}
                      <td>
                        {inviteData?.DT_NASC_CONVIDADO ? (
                          <small>
                            Dt. Nascimento:{" "}
                            {moment(inviteData?.DT_NASC_CONVIDADO).format(
                              "DD/MM/YYYY"
                            )}
                          </small>
                        ) : (
                          <small>Dt. Nascimento: </small>
                        )}
                      </td>
                      <HStack>
                        <small>Sócio:</small>
                        <Text fontSize={12}>
                          {uppercase(inviteData?.NOME_SACADOR)}
                        </Text>
                      </HStack>

                      {isChurrasqueira ? (
                        <>
                          <HStack>
                            <small>Tipo:</small>
                            <small>Churrasqueira</small>
                          </HStack>
                        </>
                      ) : (
                        <>
                          <HStack>
                            <small>Tipo:</small>
                            <small>
                              {inviteData?.CD_CATEGORIA_CONVITE === "GR"
                                ? capitalize("GRATUITO")
                                : capitalize("VENDIDO")}
                            </small>
                          </HStack>
                        </>
                      )}
                    </Stack>
                  </Stack>
                  <Box
                    mt={5}
                    textAlign="center"
                    w="full"
                    alignItems={"center"}
                    justifyContent="center"
                    display={"flex"}
                    bgColor="rgb(79, 125, 161, 0.1)"
                  >
                    <Stack>
                      <Stack spacing={0}>
                        <Text fontSize={12} color="gray.500">
                          Válido até o dia:
                        </Text>
                        <Text color="primary" fontSize={28} fontWeight="500">
                          {moment(inviteData?.DT_MAX_UTILIZACAO).format(
                            "DD/MM/YYYY"
                          )}
                        </Text>
                      </Stack>
                      <Stack spacing={0}>
                        <Text fontSize={12} color="gray.500">
                          Código de acesso:
                        </Text>
                        <Text color="primary" fontSize={28} fontWeight="500">
                          {inviteData?.NR_CONVITE || inviteData?.codigo}
                        </Text>
                      </Stack>
                    </Stack>
                  </Box>
                  <Box className={styles.cutMark} w="full">
                    <Box
                      my={6}
                      w="full"
                      style={{ border: "2px dashed #C7C7C7" }}
                    ></Box>
                  </Box>
                  <Stack marginTop={2} px={6} spacing={0}>
                    <Stack px={6} spacing={1} alignItems={"center"}>
                      <ActionButton onClick={downloadPdf}>
                        Download
                      </ActionButton>
                    </Stack>
                  </Stack>
                </Stack>
              </Box>
              <Stack spacing={4}>
                <Box
                  bg="white"
                  borderRadius={8}
                  p={0}
                  pb={6}
                  border={"2px solid lightgrey"}
                  className={styles.noPrintTicket}
                >
                  <Stack>
                    <HStack position={"relative"} p={3} justifyContent="center">
                      <Image
                        position={"absolute"}
                        left="4%"
                        top="12%"
                        src={logo}
                        className={styles.logoConvite}
                        w={"60px"}
                        marginRight={"1rem"}
                      />
                      <Stack textAlign={"center"}>
                        <Heading fontSize={22} color={"black"}>
                          Convite Individual
                        </Heading>
                        <Text>CHURRASQUEIRA</Text>
                      </Stack>
                    </HStack>
                    <Box
                      height={"75px"}
                      p={3}
                      display="flex"
                      alignItems={"center"}
                      justifyContent="center"
                      bg="black"
                      color={"white"}
                    >
                      <h1 style={{ fontSize: 18, textAlign: "center" }}>
                        É indispensável a apresentação da <br /> carteira de
                        identidade
                      </h1>
                    </Box>

                    <Box px={3} w="full">
                      <table
                        style={{ width: "100%" }}
                        className={styles.uglyTable}
                      >
                        <tbody>
                          <tr>
                            <td colSpan={2}>
                              <small>
                                Convidado:{" "}
                                {uppercase(inviteData?.NOME_CONVIDADO)}
                              </small>
                            </td>
                          </tr>
                          <tr>
                            {!inviteData?.CPF_CONVIDADO &&
                            !inviteData?.NR_DOC_ESTRANGEIRO ? (
                              <td>
                                {" "}
                                <small>CPF: </small>
                              </td>
                            ) : (
                              <>
                                {inviteData?.NR_DOC_ESTRANGEIRO &&
                                inviteData?.NR_DOC_ESTRANGEIRO !== "" ? (
                                  <td>
                                    Doc. Estrangeiro:{" "}
                                    {inviteData?.NR_DOC_ESTRANGEIRO}
                                  </td>
                                ) : (
                                  <td>
                                    CPF: {formatCPF(inviteData?.CPF_CONVIDADO)}
                                  </td>
                                )}
                              </>
                            )}
                            <td>
                              {inviteData?.DT_NASC_CONVIDADO ? (
                                <small>
                                  Dt. Nascimento:{" "}
                                  {moment(inviteData?.DT_NASC_CONVIDADO).format(
                                    "DD/MM/YYYY"
                                  )}
                                </small>
                              ) : (
                                <small>Dt. Nascimento: </small>
                              )}
                            </td>
                          </tr>

                          <tr>
                            <td colSpan={1}>
                              {/* <small>Emissão: {invite.dtaEmissao}</small> */}
                              <small>Emissão: {moment(invite?.DT_RETIRADA).format("DD/MM/YYYY HH:mm")}</small>
                            </td>
                            <td colSpan={1}>
                              <small style={{ color: "red" }}>
                                Válido até o dia:{" "}
                                {moment(inviteData?.DT_MAX_UTILIZACAO).format(
                                  "DD/MM/YYYY"
                                )}
                              </small>
                            </td>
                          </tr>
                          <tr>
                            <td colSpan={2}>
                              <small>
                                <b>ESTACIONAMENTO: PÚBLICO</b>{" "}
                              </small>
                            </td>
                          </tr>
                          <tr>
                            <td colSpan={2}>
                              <small>
                                Sócio: <br />
                                <b>{uppercase(inviteData?.NOME_SACADOR)}</b>
                              </small>
                            </td>
                          </tr>
                          <tr>
                            <td colSpan={2}>
                              <Instructions />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <Stack>
                        <Center>
                          <svg ref={barcode1} />
                        </Center>
                        <Box borderBottom={"1px dashed #333"} w="full"></Box>
                        <small>Convidado:</small>
                        <Box borderBottom={"1px solid gray"} w="full"></Box>
                        <Center>
                          <svg ref={barcode2} />
                        </Center>
                      </Stack>
                    </Box>
                  </Stack>
                </Box>
              </Stack>
            </>
          )}
        </Stack>

        <Stack px={6} spacing={1} alignItems={"center"}>
          <ActionButton onClick={downloadPdf}>Download</ActionButton>
        </Stack>
      </Stack>
    );
  };

  const ConviteGeral = () => {
    return (
      <Stack px={6} spacing={2}>
        <Stack id="print-pdf-invite">
          {isLoading || isRefetching ? (
            <Flex w="full" alignItems={"center"} justifyContent="center">
              <Loading />
            </Flex>
          ) : (
            <>
              <div className={styles.pdfHeader}>
                <Image src={logo} className={styles.pdfLogo} height={"80px"} />
              </div>
              <Box
                w={"300px"}
                bg="white"
                borderRadius={8}
                pb={6}
                border={"2px solid lightgrey"}
                alignSelf="center"
                className={styles.printTicket}
              >
                <Stack p={6}>
                  <Stack p={6} pb={2}>
                    <Heading size="md" color="primary">
                      {uppercase(inviteData?.NO_CONVIDADO)}
                    </Heading>
                    <Stack spacing={1}>
                      {!inviteData?.CPF_CONVIDADO &&
                      !inviteData?.NR_DOC_ESTRANGEIRO ? (
                        <></>
                      ) : (
                        <>
                          {inviteData?.NR_DOC_ESTRANGEIRO &&
                          inviteData?.NR_DOC_ESTRANGEIRO !== "" ? (
                            <small>
                              Doc. Estrangeiro: {inviteData?.NR_DOC_ESTRANGEIRO}
                            </small>
                          ) : (
                            <small>
                              CPF: {formatCPF(inviteData?.CPF_CONVIDADO)}
                            </small>
                          )}
                        </>
                      )}
                      {inviteData?.DT_NASC_CONVIDADO ? (
                        <small>
                          Dt. Nascimento:{" "}
                          {moment(inviteData?.DT_NASC_CONVIDADO).format(
                            "DD/MM/YYYY"
                          )}
                        </small>
                      ) : (
                        <small> Dt. Nascimento: </small>
                      )}
                      <HStack>
                        <small>Sócio:</small>
                        <Text fontSize={12}>
                          {uppercase(inviteData?.NOME_SACADOR)}
                        </Text>
                      </HStack>

                      {isChurrasqueira ? (
                        <>
                          <HStack>
                            <small>Tipo:</small>
                            <small>Churrasqueira</small>
                          </HStack>
                        </>
                      ) : (
                        <>
                          <HStack>
                            <small>Tipo:</small>
                            <small>
                              {inviteData?.CD_CATEGORIA_CONVITE === "GR"
                                ? capitalize("GRATUITO")
                                : capitalize("VENDIDO")}
                            </small>
                          </HStack>
                        </>
                      )}
                    </Stack>
                  </Stack>
                  <Box
                    mt={5}
                    textAlign="center"
                    w="full"
                    alignItems={"center"}
                    justifyContent="center"
                    display={"flex"}
                    bgColor="rgb(79, 125, 161, 0.1)"
                  >
                    <Stack>
                      <Stack spacing={0}>
                        <Text fontSize={12} color="gray.500">
                          Válido até o dia:
                        </Text>
                        <Text color="primary" fontSize={28} fontWeight="500">
                          {moment(inviteData?.DT_MAX_UTILIZACAO).format(
                            "DD/MM/YYYY"
                          )}
                        </Text>
                      </Stack>
                      <Stack spacing={0}>
                        <Text fontSize={12} color="gray.500">
                          Código de acesso:
                        </Text>
                        <Text color="primary" fontSize={28} fontWeight="500">
                          {inviteData?.NR_CONVITE || inviteData?.codigo}
                        </Text>
                      </Stack>
                    </Stack>
                  </Box>
                  <Box className={styles.cutMark} w="full">
                    <Box
                      my={6}
                      w="full"
                      style={{ border: "2px dashed #C7C7C7" }}
                    ></Box>
                  </Box>
                  <Stack marginTop={2} px={6} spacing={0}>
                    <Stack px={6} spacing={1} alignItems={"center"}>
                      <ActionButton onClick={downloadPdf}>
                        Download
                      </ActionButton>
                    </Stack>
                  </Stack>
                </Stack>
              </Box>
              <Stack spacing={4}>
                <Box
                  bg="white"
                  borderRadius={8}
                  p={0}
                  pb={6}
                  border={"2px solid lightgrey"}
                  className={styles.noPrintTicket}
                >
                  <Stack>
                    <Stack p={6} pb={2}>
                      <Heading size="md" color="primary">
                        {uppercase(inviteData?.NO_CONVIDADO)}
                      </Heading>
                      <Stack spacing={1}>
                        {!inviteData?.CPF_CONVIDADO &&
                        !inviteData?.NR_DOC_ESTRANGEIRO ? (
                          <></>
                        ) : (
                          <>
                            {inviteData?.NR_DOC_ESTRANGEIRO &&
                            inviteData?.NR_DOC_ESTRANGEIRO !== "" ? (
                              <small>
                                Doc. Estrangeiro:{" "}
                                {inviteData?.NR_DOC_ESTRANGEIRO}
                              </small>
                            ) : (
                              <small>
                                CPF: {formatCPF(inviteData?.CPF_CONVIDADO)}
                              </small>
                            )}
                          </>
                        )}
                        {inviteData?.DT_NASC_CONVIDADO ? (
                          <small>
                            Dt. Nascimento:{" "}
                            {moment(inviteData?.DT_NASC_CONVIDADO).format(
                              "DD/MM/YYYY"
                            )}
                          </small>
                        ) : (
                          <small> Dt. Nascimento: </small>
                        )}
                        {/* <small>Emissão: {invite.dtaEmissao}</small> */}
                        <HStack>
                          <small>
                            Sócio: {uppercase(inviteData?.NOME_SACADOR)}
                          </small>
                        </HStack>
                        {isChurrasqueira ? (
                          <>
                            <HStack>
                              <small>Tipo:</small>
                              <small>Churrasqueira</small>
                            </HStack>
                          </>
                        ) : (
                          <>
                            <HStack>
                              <small>Tipo:</small>
                              <small>
                                {inviteData?.CD_CATEGORIA_CONVITE === "GR"
                                  ? capitalize("GRATUITO")
                                  : capitalize("VENDIDO")}
                              </small>
                            </HStack>
                          </>
                        )}
                      </Stack>
                    </Stack>
                    <Box
                      mt={3}
                      w="full"
                      py={3}
                      px={6}
                      bgColor="rgb(79, 125, 161, 0.1)"
                    >
                      <Flex justifyContent="space-between">
                        <Stack spacing={0}>
                          <Text fontSize={12} color="gray.500">
                            Válido até o dia:
                          </Text>
                          <Text color="primary" fontSize={20} fontWeight="500">
                            {moment(inviteData?.DT_MAX_UTILIZACAO).format(
                              "DD/MM/YYYY"
                            )}
                          </Text>
                        </Stack>
                        <Stack spacing={0}>
                          <Text fontSize={12} color="gray.500">
                            Código de acesso:
                          </Text>
                          <Text color="primary" fontSize={20} fontWeight="500">
                            {inviteData?.NR_CONVITE || inviteData?.codigo}
                          </Text>
                        </Stack>
                      </Flex>
                    </Box>
                    <Box className={styles.cutMark} w="full">
                      <Box
                        my={0}
                        w="full"
                        style={{ border: "2px dashed #C7C7C7" }}
                      ></Box>
                    </Box>
                    <Box px={4}>
                      <Instructions />
                    </Box>
                  </Stack>
                </Box>
              </Stack>
            </>
          )}
        </Stack>

        <Stack px={6} spacing={1} alignItems={"center"}>
          <ActionButton onClick={downloadPdf}>Download</ActionButton>
        </Stack>
      </Stack>
    );
  };

  const ConviteLayout = () =>
    isChurrasqueira ? <ConviteChurrasqueira /> : <ConviteGeral />;

  return <ConviteLayout />;
};

// <Stack p={6} pb={2}>
//                       <Heading size="md" color="primary">
//                         {uppercase(inviteData?.NO_CONVIDADO)}
//                       </Heading>
//                       <Stack spacing={1}>
//                         {!inviteData?.CPF_CONVIDADO &&
//                         !inviteData?.NR_DOC_ESTRANGEIRO ? (
//                           <></>
//                         ) : (
//                           <>
//                             {inviteData?.NR_DOC_ESTRANGEIRO &&
//                             inviteData?.NR_DOC_ESTRANGEIRO !== "" ? (
//                               <small>
//                                 Doc. Estrangeiro:{" "}
//                                 {inviteData?.NR_DOC_ESTRANGEIRO}
//                               </small>
//                             ) : (
//                               <small>
//                                 CPF: {formatCPF(inviteData?.CPF_CONVIDADO)}
//                               </small>
//                             )}
//                           </>
//                         )}
//                         <small>
//                           Dt. Nasc.:{" "}
//                           {moment(inviteData?.DT_NASC_CONVIDADO).format(
//                             "DD/MM/YYYY"
//                           )}{" "}
//                         </small>
//                         {/* <small>Emissão: {invite.dtaEmissao}</small> */}
//                         <HStack>
//                           <small>
//                             Sócio: {uppercase(inviteData?.NOME_SACADOR)}
//                           </small>
//                         </HStack>
//                         {isChurrasqueira ? (
//                           <>
//                             <HStack>
//                               <small>Tipo:</small>
//                               <small>Churrasqueira</small>
//                             </HStack>
//                           </>
//                         ) : (
//                           <>
//                             <HStack>
//                               <small>Tipo:</small>
//                               <small>
//                                 {inviteData?.CD_CATEGORIA_CONVITE === "GR"
//                                   ? capitalize("GRATUITO")
//                                   : capitalize("VENDIDO")}
//                               </small>
//                             </HStack>
//                           </>
//                         )}
//                       </Stack>
//                     </Stack>
//                     <Box
//                       mt={3}
//                       w="full"
//                       py={3}
//                       px={6}
//                       bgColor="rgb(79, 125, 161, 0.1)"
//                     >
//                       <Flex justifyContent="space-between">
//                         <Stack spacing={0}>
//                           <Text fontSize={12} color="gray.500">
//                             Válido para o dia:
//                           </Text>
//                           <Text color="primary" fontSize={20} fontWeight="500">
//                             {moment(inviteData?.DT_MAX_UTILIZACAO).format(
//                               "DD/MM/YYYY"
//                             )}
//                           </Text>
//                         </Stack>
//                         <Stack spacing={0}>
//                           <Text fontSize={12} color="gray.500">
//                             Código de acesso:
//                           </Text>
//                           <Text color="primary" fontSize={20} fontWeight="500">
//                             {inviteData?.NR_CONVITE || inviteData?.codigo}
//                           </Text>
//                         </Stack>
//                       </Flex>
//                     </Box>
//                     <Box className={styles.cutMark} w="full">
//                       <Box
//                         my={0}
//                         w="full"
//                         style={{ border: "2px dashed #C7C7C7" }}
//                       ></Box>
//                     </Box>
