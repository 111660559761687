import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useQuery } from "react-query";

import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogOverlay,
  Box,
  Container,
  Heading,
  HStack,
  Image,
  Stack,
  useDisclosure,
} from "@chakra-ui/react";

import Breadcrumb from "../../../components/Breadcrumb/Breadcrumb";
import PageLayout from "../../../components/PageLayout/PageLayout";
import PolicyAgreement from "../../../components/PolicyAgreement/PolicyAgreement";
import InviteEmissionForm from "../InviteEmissionForm/InviteEmissionForm";
import convite_emitido_img from "../../../assets/img/convite_emitido.svg";
import Actions from "../../../components/Actions/Actions";
import ActionButton from "../../../components/ActionButton/ActionButton";

import { ROUTES } from "../../../constants/routes";
import { InviteTicket } from "../../../components/Ticket/Ticket";

import { Invite, InviteBalance } from "../../../models/invite.model";
import { InviteTypes } from "../../../enums/inviteType";
import { useInvitesService } from "../../../services/invites.service";

import useCustomToast from "../../../hooks/useCustomToast";
import moment from "moment";
import { userManager } from "../../../services/authManager";
import { OverlayBackdrop } from "../../../components/OverlayBackdrop/OverlayBackdrop";

export const PolicyContent = () => (
  <Stack spacing={5} color={"#939393"}>
    <Heading size={"sm"}>COMO USAR SEU CONVITE</Heading>
    <p>
      Cada associado titular de título patrimonial ou seu usuário tem direito a retirar 04 convites individuais por bimestre-calendário, cada um permitindo a freqüência de uma só pessoa, por um só dia.
    </p>
    <Heading size={"sm"}>ISENTO DE CONVITES</Heading>
    <p>
      Os dependentes menores de 12 anos que acompanharem os convidados serão dispensados de convites.
    </p>
    <Heading size={"sm"}>CONVITES ACUMULATIVOS</Heading>
    <p>
      Os convites não são cumulativos, ou seja, os convites não utilizados durante um bimestre não poderão ser utilizados nos bimestres posteriores, perdendo o associado qualquer direito sobre eles.     </p>
    <Heading size={"sm"}>TRANSFERÊNCIA </Heading>
    <p>
      O convite é individual e intransferível, sendo necessária a apresentação de documento de identidade pelo convidado.
    </p>
  </Stack>
)


enum Steps {
  POLICY,
  FORM,
  CONFIRMATION,
}

const InviteEmission = () => {
  const navigate = useNavigate();

  const [currentStep, setCurrentStep] = useState<Steps>(Steps.POLICY);
  const [facilityType, setFacilityType] = useState<InviteTypes>(null!);
  const [emittedInvite, setEmittedInvite] = useState<Invite | null>(null);

  const {
    isOpen: isOpenInvite,
    onOpen: onOpenInvite,
    onClose: onCloseInvite,
  } = useDisclosure();

  const cancelRef: any = useRef();

  const { getInviteBalance, getInvites  } = useInvitesService();

  const [invitesBalance, setInvitesBalance] = useState<InviteBalance>({
    SD_CONVITE: 0,
    SD_CONVITE_VENDIDO: 0,
  });

  const [invite, setInvite] = useState<Invite>(null!)
  const [inviteDetails, setInviteDetails] = useState<Invite>(null!)
  const [modalVisible, setModalVisible] = useState<boolean>(false);


  const { showToast } = useCustomToast();

  const { USER_ACESSO_SISTEMA } = userManager.get()

  const queryConvite = useQuery<any, Error>(
    "verConviteEmitido",
    async () => {
      return await getInvites(USER_ACESSO_SISTEMA);
    },
    {
      retry: 5,
      refetchOnWindowFocus: false,
      enabled: !!invite,
      onSuccess: (res: any) => {
        if (res && res.length > 0) {
          let match = res.find((x: any) => `${x.NR_CONVITE}`.trim() == `${invite.NR_CONVITE}`.trim());
          if(match){
            setInviteDetails(match);
          }
          else{
            showToast("error", "Não foi possível visualizar o convite.");
          }
        }
      },
      onError: () => {
        showToast("error", "Não foi possível recuperar o saldo de convites.");
      },
    }
  );
  const query = useQuery<any, Error>(
    "saldoConvites",
    async () => {
      return await getInviteBalance(USER_ACESSO_SISTEMA);
    },
    {
      retry: 5,
      refetchOnWindowFocus: false,
      onSuccess: (res: any) => {
        if (res && res.length > 0) {
          setInvitesBalance({
            SD_CONVITE_VENDIDO: res[0].SD_CONVITE_VENDIDO,
            SD_CONVITE: res[0].SD_CONVITE,
          });
        }
      },
      onError: () => {
        showToast("error", "Não foi possível recuperar o saldo de convites.");
      },
    }
  );

  const resetFlow = () => {
    navigate(ROUTES.INVITES_GENERAL, { replace: true, state: { goToEmission: true } });
  };

  const handlePolicyResponse = (agreed: boolean) => {
    if (agreed) {
      setCurrentStep(Steps.FORM);
    } else {
      navigate(ROUTES.INVITES_GENERAL, { replace: true });
    }
  };

  const handleInviteSubmitted = (res: any) => {
    let invite: Invite = res[0];
    setCurrentStep(Steps.CONFIRMATION);
    setInvite(invite)
  };

  const goToEmittedInvites = () =>
    navigate(ROUTES.INVITES_EMITTED, { replace: true });

  const showInvite = () => {
    setModalVisible(true);
  };

  switch (currentStep) {
    case Steps.POLICY:
      return (
        <PageLayout>
          <Container maxW="container.md" pt={5} pb={20}>
            <Stack spacing={8}>
              <Breadcrumb
                items={[
                  { route: "/", label: "Início" },
                  { route: ROUTES.INVITES_SELECT, label: "Convites" },
                  { route: ROUTES.INVITES_GENERAL, label: "Geral" },
                  {
                    route: ROUTES.INVITES_EMISSION,
                    label: "Emissão de Convites",
                  },
                ]}
              />

              <PolicyAgreement
                title={"Termo de uso do seu convite"}
                agreed={handlePolicyResponse}
              >
                <PolicyContent />
              </PolicyAgreement>
            </Stack>
          </Container>
        </PageLayout>
      );

    case Steps.FORM:
      return (
        <PageLayout>
          <Container maxW="container.md" pt={5} pb={20}>
            <Stack spacing={8}>
              <Breadcrumb
                items={[
                  { route: "/", label: "Início" },
                  { route: ROUTES.INVITES_SELECT, label: "Convites" },
                  { route: ROUTES.INVITES_GENERAL, label: "Geral" },
                  {
                    route: ROUTES.INVITES_EMISSION,
                    label: "Emissão de Convites",
                  },
                ]}
              />

              <InviteEmissionForm
                facilityType={facilityType}
                invitesBalance={invitesBalance}
                inviteSubmitted={handleInviteSubmitted}
              />
            </Stack>
          </Container>
        </PageLayout>
      );

    case Steps.CONFIRMATION:
      return (
        <PageLayout>
          <Container maxW="container.md" pt={5} pb={20}>
            <Stack spacing={8}>
              <Breadcrumb
                items={[
                  { route: "/", label: "Início" },
                  { route: ROUTES.INVITES_SELECT, label: "Convites" },
                  { route: ROUTES.INVITES_GENERAL, label: "Geral" },
                  {
                    route: ROUTES.INVITES_EMISSION,
                    label: "Emissão de Convites",
                  },
                ]}
              />

              <Stack
                pt={10}
                spacing={10}
                alignItems={"center"}
                justifyContent={"center"}
              >
                <Image src={convite_emitido_img} alt="Convite emitido" />
                <Heading textAlign={"center"} color="primary">
                  Convite emitido com sucesso
                </Heading>
                {/* <Text textAlign={"center"}>
                  <b>Atenção:</b> você ainda possui um saldo <br /> para emissão
                  de
                  {facilityType == InviteTypes.GERAL
                    ? invitesBalance.geral
                    : facilityType == InviteTypes.CHURRASQUEIRA
                      ? invitesBalance.churrasqueira
                      : 0}{" "}
                  convites gratuitos.
                </Text> */}
                <Actions justify="center">
                  <ActionButton variant={"outline"} onClick={resetFlow}>
                    Novo convite
                  </ActionButton>
                  <ActionButton
                    variant={"outline"}
                    onClick={goToEmittedInvites}
                  >
                    Convites emitidos
                  </ActionButton>
                  <ActionButton onClick={showInvite}>Ver convite</ActionButton>
                </Actions>
              </Stack>
            </Stack>
          </Container>
          <OverlayBackdrop
            setModalVisible={setModalVisible}
            modalVisible={modalVisible}
          >
            <Stack>
              <Box borderRadius={10}>
                <InviteTicket invite={invite} />
              </Box>
            </Stack>
          </OverlayBackdrop>
        </PageLayout>
      );

    default:
      return <></>;
  }
};

export default InviteEmission;
