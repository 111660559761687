import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import Layout from "../components/Layout/Layout";
import { ROUTES } from "../constants/routes";
import useAuth from "../hooks/useAuth";
import { Contact } from "../pages/Contact/Contact";
import BillDetail from "../pages/Financial/BillDetail/BillDetail";
import BillingCopy from "../pages/Financial/BillingCopy/BillingCopy";
import BillingDetails from "../pages/Financial/BillingDetails/BillingDetails";
import DebtPaymentDetails from "../pages/Financial/DebtPayments/DebtPaymentDetails/DebtPaymentDetails";
import DebtPayments from "../pages/Financial/DebtPayments/DebtPayments";
import DeclarationOfDischargeDetails from "../pages/Financial/DeclarationOfDischarges/DeclarationOfDischargeDetails/DeclarationOfDischargeDetails";
import DeclarationOfDischarges from "../pages/Financial/DeclarationOfDischarges/DeclarationOfDischarges";
import Financial from "../pages/Financial/Financial";
import FutureBillings from "../pages/Financial/FutureBillings/FutureBillings";
import Home from "../pages/Home/Home";
import EmittedInvites from "../pages/Invites/EmittedInvites/EmittedInvites";
import InviteEmission from "../pages/Invites/InviteEmission/InviteEmission";
import Invites from "../pages/Invites/Invites";
import InvitesSelect from "../pages/Invites/InvitesSelect";
import Jornal from "../pages/Jornal/Jornal";
import Login from "../pages/Login/Login";
import { LostAndFound } from "../pages/LostAndFound/LostAndFound";
import NotFound from "../pages/NotFound/NotFound";
import {
  Onbudsman,
  OnbudsmanConsult,
  OnbudsmanConsultDetails,
  OnbudsmanCreate,
} from "../pages/Onbudsman/Onbudsman";
import ChangePassword from "../pages/Profile/ChangePassword/ChangePassword";
import DependentForm from "../pages/Profile/DependentForm/DependentForm";
import Dependents from "../pages/Profile/Dependents/Dependents";
import Profile from "../pages/Profile/Profile";
import ProfileForm from "../pages/Profile/ProfileForm/ProfileForm";
import Barbecue from "../pages/Reservations/Barbecue/Barbecue";
import {
  ChurrasqueiraConviteMenu,
  ChurrasqueiraConvites,
  ChurrasqueiraReservas,
  ConvitesEmitidosChurrasqueira,
} from "../pages/Reservations/Barbecue/Churrasqueira";
import {
  Gym,
  GymWizard,
  ScheduleModality,
} from "../pages/Reservations/Gym/Gym";
import Reservations from "../pages/Reservations/Reservations";
import ReservationsHistory from "../pages/Reservations/ReservationsHistory/ReservationsHistory";
import { TransparencyPortal } from "../pages/TransparencyPortal/TransparencyPortal";
import { authManager, userManager } from "../services/authManager";

const Router = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="*" element={<NotFound />} />
        <Route path={ROUTES.LOGIN} element={<Login />} />
        <Route path="/" element={<Layout />}>
          <Route
            path={ROUTES.HOME}
            element={
              <RequireAuth>
                <Home />
              </RequireAuth>
            }
          />
          <Route
            path={ROUTES.PROFILE}
            element={
              <RequireAuth>
                <Profile />
              </RequireAuth>
            }
          />
          <Route
            path={ROUTES.EDIT_PROFILE}
            element={
              <RequireAuth>
                <ProfileForm />
              </RequireAuth>
            }
          />
          <Route
            path={ROUTES.CHANGE_PASSWORD}
            element={
              <RequireAuth>
                <ChangePassword />
              </RequireAuth>
            }
          />
          <Route
            path={ROUTES.DEPENDENTS}
            element={
              <RequireAuth>
                <Dependents />
              </RequireAuth>
            }
          />
          <Route
            path={ROUTES.DEPENDENT_REGISTER}
            element={
              <RequireAuth>
                <DependentForm />
              </RequireAuth>
            }
          />
          <Route
            path={ROUTES.DEPENDENT_EDIT}
            element={
              <RequireAuth>
                <DependentForm editMode={true} />
              </RequireAuth>
            }
          />
          <Route
            path={ROUTES.RESERVATIONS}
            element={
              <RequireAuth>
                <RequirePermission permission={[110, 150]}>
                  <Reservations />
                </RequirePermission>
              </RequireAuth>
            }
          />

          <Route
            path={ROUTES.CHURRASQUEIRAS_CONVITES}
            element={
              <RequireAuth>
                <RequirePermission permission={[115, 116]}>
                  <ChurrasqueiraConviteMenu />
                </RequirePermission>
              </RequireAuth>
            }
          />
          <Route
            path={ROUTES.CHURRASQUEIRAS_CONVITES_EMITIDOS}
            element={
              <RequireAuth>
                <RequirePermission permission={[115, 116]}>
                  <ConvitesEmitidosChurrasqueira />
                </RequirePermission>
              </RequireAuth>
            }
          />

          <Route
            path={ROUTES.CHURRASQUEIRAS_CONVITES_EMITIR}
            element={
              <RequireAuth>
                <RequirePermission permission={[115, 116]}>
                  <ChurrasqueiraConvites />
                </RequirePermission>
              </RequireAuth>
            }
          />
          <Route
            path={ROUTES.RESERVATIONS_BARBECUE_LIST}
            element={
              <RequireAuth>
                <RequirePermission permission={[110]}>
                  <ChurrasqueiraReservas />
                </RequirePermission>
              </RequireAuth>
            }
          />
          <Route
            path={ROUTES.RESERVATIONS_BARBECUE}
            element={
              <RequireAuth>
                <RequirePermission permission={[110]}>
                  <Barbecue />
                </RequirePermission>
              </RequireAuth>
            }
          />
          <Route
            path={ROUTES.RESERVATIONS_BARBECUE_RESERVE}
            element={
              <RequireAuth>
                <RequirePermission permission={[110]}>
                  <Barbecue />
                </RequirePermission>
              </RequireAuth>
            }
          />
          <Route
            path={ROUTES.RESERVATIONS_GYM_MODALITIES}
            element={
              <RequireAuth>
                <RequirePermission permission={[150]}>
                  <Gym />
                </RequirePermission>
              </RequireAuth>
            }
          />
          <Route
            path={ROUTES.RESERVATIONS_GYM_SCHEDULE}
            element={
              <RequireAuth>
                <RequirePermission permission={[150]}>
                  <ScheduleModality />
                </RequirePermission>
              </RequireAuth>
            }
          />
          <Route
            path={ROUTES.RESERVATIONS_GYM_RESERVE}
            element={
              <RequireAuth>
                <RequirePermission permission={[150]}>
                  <GymWizard />
                </RequirePermission>
              </RequireAuth>
            }
          />
          <Route
            path={ROUTES.RESERVATIONS_HISTORY}
            element={
              <RequireAuth>
                <RequirePermission permission={[110, 150]}>
                  <ReservationsHistory />
                </RequirePermission>
              </RequireAuth>
            }
          />
          <Route
            path={ROUTES.RESERVATIONS_BARBECUE_INVITES}
            element={
              <RequireAuth>
                <RequirePermission permission={[110]}>
                  <Reservations />
                </RequirePermission>
              </RequireAuth>
            }
          />
          <Route
            path={ROUTES.FINANCIAL}
            element={
              <RequireAuth>
                <RequirePermission permission={[130, 190]}>
                  <Financial />
                </RequirePermission>
              </RequireAuth>
            }
          />
          <Route
            path={ROUTES.FINANCIAL_BILLINGS_COPY}
            element={
              <RequireAuth>
                <RequirePermission permission={[130]}>
                  <BillingCopy />
                </RequirePermission>
              </RequireAuth>
            }
          />
          <Route
            path={ROUTES.FINANCIAL_BILLINGS_DETAILS}
            element={
              <RequireAuth>
                <RequirePermission permission={[130]}>
                  <BillingDetails />
                </RequirePermission>
              </RequireAuth>
            }
          />
          <Route
            path={ROUTES.FINANCIAL_BILLINGS_DETAILS_ITEM}
            element={
              <RequireAuth>
                <RequirePermission permission={[130]}>
                  <BillDetail />
                </RequirePermission>
              </RequireAuth>
            }
          />
          <Route
            path={ROUTES.FINANCIAL_FUTURE_BILLINGS}
            element={
              <RequireAuth>
                <RequirePermission permission={[130]}>
                  <FutureBillings />
                </RequirePermission>
              </RequireAuth>
            }
          />
          <Route
            path={ROUTES.FINANCIAL_DEBT_PAYMENTS}
            element={
              <RequireAuth>
                <DebtPayments />
              </RequireAuth>
            }
          />
          <Route
            path={ROUTES.FINANCIAL_DEBT_PAYMENTS_DETAILS}
            element={
              <RequireAuth>
                <DebtPaymentDetails />
              </RequireAuth>
            }
          />
          <Route
            path={ROUTES.FINANCIAL_DECLARATION_OF_DISCHARGE}
            element={
              <RequireAuth>
                <RequirePermission permission={[190]}>
                  <DeclarationOfDischarges />
                </RequirePermission>
              </RequireAuth>
            }
          />
          <Route
            path={ROUTES.FINANCIAL_DECLARATION_OF_DISCHARGE_DETAILS}
            element={
              <RequireAuth>
                <RequirePermission permission={[190]}>
                  <DeclarationOfDischargeDetails />
                </RequirePermission>
              </RequireAuth>
            }
          />
          <Route
            path={ROUTES.INVITES_SELECT}
            element={
              <RequireAuth>
                <RequirePermission permission={[120]}>
                  <InvitesSelect />
                </RequirePermission>
              </RequireAuth>
            }
          />
          <Route
            path={ROUTES.INVITES_GENERAL}
            element={
              <RequireAuth>
                <RequirePermission permission={[120]}>
                  <Invites />
                </RequirePermission>
              </RequireAuth>
            }
          />
          <Route
            path={ROUTES.INVITES_EMISSION}
            element={
              <RequireAuth>
                <RequirePermission permission={[121]}>
                  <InviteEmission />
                </RequirePermission>
              </RequireAuth>
            }
          />
          <Route
            path={ROUTES.INVITES_EMITTED}
            element={
              <RequireAuth>
                <RequirePermission permission={[120]}>
                  <EmittedInvites />
                </RequirePermission>
              </RequireAuth>
            }
          />
          <Route
            path={ROUTES.LOST_AND_FOUND}
            element={
              <RequireAuth>
                <RequirePermission permission={[180]}>
                  <LostAndFound />
                </RequirePermission>
              </RequireAuth>
            }
          />
          <Route
            path={ROUTES.CONTACT}
            element={
              <RequireAuth>
                <Contact />
              </RequireAuth>
            }
          />
          <Route
            path={ROUTES.TRANSPARENCY}
            element={
              <RequireAuth>
                <TransparencyPortal />
              </RequireAuth>
            }
          />
          <Route
            path={ROUTES.ONBUDSMAN}
            element={
              <RequireAuth>
                <Onbudsman />
              </RequireAuth>
            }
          />
          <Route
            path={ROUTES.ONBUDSMAN_CREATE}
            element={
              <RequireAuth>
                <OnbudsmanCreate />
              </RequireAuth>
            }
          />
          <Route
            path={ROUTES.ONBUDSMAN_CONSULT}
            element={
              <RequireAuth>
                <OnbudsmanConsult />
              </RequireAuth>
            }
          />
          <Route
            path={ROUTES.ONBUDSMAN_CONSULT_DETAILS}
            element={
              <RequireAuth>
                <OnbudsmanConsultDetails />
              </RequireAuth>
            }
          />
          <Route
            path={ROUTES.JORNAL}
            element={
              <RequireAuth>
                <Jornal />
              </RequireAuth>
            }
          />
          {/* <Route
            path={ROUTES.NAUTICAL}
            element={
              <RequireAuth>
                <Nautical />
              </RequireAuth>
            }
          />
          <Route
            path={ROUTES.NAUTICAL_SHIP_REQUEST}
            element={
              <RequireAuth>
                <NauticalShipRequest />
              </RequireAuth>
            }
          />
          <Route
            path={ROUTES.NAUTICAL_SHIP_REQUEST_REGISTER}
            element={
              <RequireAuth>
                <NauticalShipRequestRegister />
              </RequireAuth>
            }
          />
          <Route
            path={ROUTES.NAUTICAL_SHIP_REQUEST_QUERY}
            element={
              <RequireAuth>
                <NauticalShipRequestQuery />
              </RequireAuth>
            }
          />
          <Route
            path={ROUTES.NAUTICAL_SHIP_REQUEST_QUERY_DETAILS}
            element={
              <RequireAuth>
                <NauticalShipRequestQueryDetails />
              </RequireAuth>
            }
          />
          <Route
            path={ROUTES.NAUTICAL_SHIP_AUTORIZATION}
            element={
              <RequireAuth>
                <NauticalShipAuthorization />
              </RequireAuth>
            }
          />
          <Route
            path={ROUTES.NAUTICAL_RESERVATIONS}
            element={
              <RequireAuth>
                <NauticalReservationsList />
              </RequireAuth>
            }
          />
          <Route
            path={ROUTES.NAUTICAL_RESERVATIONS_MODALITY}
            element={
              <RequireAuth>
                <NauticalReservation />
              </RequireAuth>
            }
          />
          <Route
            path={ROUTES.NAUTICAL_RESERVATIONS_MODALITY_BOX}
            element={
              <RequireAuth>
                <NauticalReservationBox />
              </RequireAuth>
            }
          />
          <Route
            path={ROUTES.NAUTICAL_RESERVATIONS_MODALITY_BOX_WAITING_LIST}
            element={
              <RequireAuth>
                <WaitingList />
              </RequireAuth>
            }
          />
          <Route
            path={ROUTES.NAUTICAL_RESERVATIONS_RESERVE}
            element={
              <RequireAuth>
                <NauticalReservationWizard />
              </RequireAuth>
            }
          />
          <Route
            path={ROUTES.NAUTICAL_RESERVATIONS_RESERVE_BOX}
            element={
              <RequireAuth>
                <NauticalReservationWizardBox />
              </RequireAuth>
            }
          />
          <Route
            path={ROUTES.NAUTICAL_INVITES}
            element={
              <RequireAuth>
                <EmittedInvites nautical />
              </RequireAuth>
            }
          /> */}
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default Router;

const RequireAuth = ({ children }: { children: JSX.Element }) => {
  let auth = useAuth();
  let location = useLocation();

  const { token } = authManager.get();

  if (!auth.token && !token) {
    return <Navigate to="/login" state={{ from: location }} />;
  }

  return children;
};

const RequirePermission = ({
  children,
  permission,
}: {
  children: JSX.Element;
  permission: number[];
}) => {
  const user = userManager.get();
  let location = useLocation();

  if (!permission.some((p) => user.CD_APLICACAO.includes(p))) {
    return <Navigate to="/" state={{ from: location }} />;
  }

  return children;
};
