import { ChevronRightIcon } from "@chakra-ui/icons";
import {
  Box,
  Container,
  Flex,
  HStack,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from "@chakra-ui/react";
import { ReactNode, useState } from "react";
import Breadcrumb from "../../../components/Breadcrumb/Breadcrumb";
import PageLayout from "../../../components/PageLayout/PageLayout";
import { PageTitle } from "../../../components/PageTitle/PageTitle";
import { ROUTES } from "../../../constants/routes";
import styles from "./ReservationsHistory.module.scss";
import watch from "../../../assets/icons/watch.svg";
import { useNavigate } from "react-router-dom";
import { useReservationService } from "../../../services/reservation.service";
import useCustomToast from "../../../hooks/useCustomToast";
import { useQuery } from "react-query";
import Loading from "../../../components/Loading/Loading";
import { capitalize } from "../../../helpers/capitalize";
import moment from "moment";
import { useChurrasqueiraService } from "../../../services/churrasqueira.service";
import { GymReservationState } from "../../../models/reservations.model";

type Reserva = {
  data?: Date;
  tipoReserva: "CHURRASQUEIRA" | "MODALIDADE_ESPORTIVA"//"NAUTICA"
}

export type ChurrasqueiraReserva = Reserva & {
  SEQ_RESERVA?: number;
  SEQ_DEPENDENCIA?: number;
  DT_INIC_UTILIZACAO?: Date;
  DT_FIM_UTILIZACAO?: Date;
  NOME_INTERESSADO?: string;
  TEL_CONTATO?: string;
  HH_INIC_UTIL?: string;
  HH_FIM_UTIL?: string;
  DT_CONFIRMACAO?: Date;
  CD_STATUS_RESERVA?: string;
  IC_PODE_CANCELAR?: string;
  DESCR_DEPENDENCIA?: string;
  DE_ABREVIACAO?: string;
  tipoReserva: "CHURRASQUEIRA";
};

export type AcademiaReserva = Reserva & {
  NOME_PESSOA: string;
  DE_SERVICO: string;
  DT_INICIO: string;
  CD_SITUACAO: string;
  COMPARECIMENTO: string;
  NU_SEQ_AGENDAMENTO: number;
  PODE_CANCELAR: string;
  tipoReserva: "MODALIDADE_ESPORTIVA";
};

export type ReservationPayload = {
  iden: number;
  data: string;
  horaInicio: string;
  horaFim: string;
  professor: string;
  tipoReserva: string;
  nomeSocio: string;
  descricaoModalidade: string;
  idenModalidade: number;
  equipamentoModNauticaDTO?: any;
};

export type ListaReservas = {
  reservasChurrasqueira: ChurrasqueiraReserva[];
  reservasAcademia: AcademiaReserva[];
};

const ReservationsHistory = () => {
  const [reservations, setReservations] = useState<ListaReservas>({
    reservasChurrasqueira: [],
    reservasAcademia: [],
  });

  const navigate = useNavigate();
  const { showToast } = useCustomToast();

  const { listarReservasChurrasqueira } = useChurrasqueiraService();
  const { getReservationsHistory, getReservationDetails } = useReservationService();

  const { isLoading, isFetching } = useQuery<ChurrasqueiraReserva[], Error>(
    "listaReservasRealizadasChurrasqueira",
    async () => {
      return await listarReservasChurrasqueira();
    },
    {
      refetchOnWindowFocus: false,
      onSuccess: (reservations: any[]) => {
        let sorted: any[] = [];

        if (reservations && reservations.length > 0) {
          sorted = [
            ...reservations.sort((a: any, b: any) => {
              if (a.DT_INIC_UTILIZACAO > b.DT_INIC_UTILIZACAO) return -1;
              if (a.DT_INIC_UTILIZACAO < b.DT_INIC_UTILIZACAO) return 1;
              return 0;
            }),
          ];
        }

        setReservations((old) => {
          let aux = { ...old };
          return {
            reservasAcademia: aux.reservasAcademia,
            reservasChurrasqueira: [...sorted],
          };
        });
      },
      onError: () =>
        showToast("error", "Não foi possível listar as reservas realizadas."),
    }
  );

  const queryReservasAcademia = useQuery<AcademiaReserva[], Error>(
    "listaReservasRealizadasAcademia",
    async () => {
      return await getReservationsHistory();
    },
    {
      refetchOnWindowFocus: false,
      onSuccess: (reservations: AcademiaReserva[]) => {
        setReservations((old) => {
          return { ...old, reservasAcademia: reservations };
        });
      },
      onError: () =>
        showToast("error", "Não foi possível listar as reservas realizadas."),
    }
  );

  const handleBarbecueReservationClick = (reserva: ChurrasqueiraReserva) => {
    const date = reserva.DT_INIC_UTILIZACAO;
    const id = reserva.SEQ_RESERVA;
    const isSameOrAfterToday = moment().isSameOrAfter(date);

    if (!isSameOrAfterToday) {
      let route: string = "";
      let state: any = null;
      route = ROUTES.RESERVATIONS_BARBECUE_RESERVE.replace(":id", `${id}`);
      state = { ...reserva, status: "REALIZADA", dataLocacao: date };
      navigate(route, {
        state,
      });
    }
  }

  const handleReservationClick = async (item: ChurrasqueiraReserva | AcademiaReserva) => {
    const date = item.tipoReserva === "MODALIDADE_ESPORTIVA" ? item.DT_INICIO : item.DT_INIC_UTILIZACAO;
    const id = item.tipoReserva === "MODALIDADE_ESPORTIVA" ? item.NU_SEQ_AGENDAMENTO : item.SEQ_RESERVA;
    const isSameOrAfterToday = moment().isSameOrAfter(date);

    if (!isSameOrAfterToday) {
      let route: string = "";
      let state: any = null;
      switch (item.tipoReserva) {
         case "CHURRASQUEIRA":
           route = ROUTES.RESERVATIONS_BARBECUE_RESERVE.replace(":id", `${id}`);
           state = { ...item, status: "REALIZADA", dataLocacao: date };
           break;
        case "MODALIDADE_ESPORTIVA":
          const reservationDetail = await getReservationDetails(item.NU_SEQ_AGENDAMENTO);
          const _item: GymReservationState = {
            data: item.data!,
            dataRegistro: moment(reservationDetail.DT_ATUAL).toDate(),
            horaInicio: moment(item.DT_INICIO).toDate(),
            horaFim: moment(item.DT_INICIO).toDate(),
            idenReserva: item.NU_SEQ_AGENDAMENTO,
            idenTurma: null,
            descricaoModalidade: item.DE_SERVICO,
            idenModalidade: 0,
            nomeSocio: item.NOME_PESSOA,
            professor: reservationDetail.NOME_FUNCIONARIO,
            idenProfessor: 0,
            DE_DETALHAMENTO: reservationDetail.DE_DETALHAMENTO,
            DE_INSTRUCAO: reservationDetail.DE_INSTRUCAO,
            ID_AUTENTICACAO: reservationDetail.ID_AUTENTICACAO,
            status: "REALIZADA",
            tipoReserva: "MODALIDADE_ESPORTIVA"
          };
          state = { ..._item };
          route = ROUTES.RESERVATIONS_GYM_RESERVE.replace(
            ":id",
            `${_item.idenReserva}`
          );
          break;
        default:
          break;
      }
      navigate(route, {
        state,
      });
    }
  };

  // const getDetails = (item: ReservationPayload): ReactNode => {
  //   let element: ReactNode = <></>;
  //   switch (item?.tipoReserva) {
  //     case "NAUTICA":
  //       element = (
  //         <Stack spacing={1}>
  //           <Text fontWeight={600} fontSize={[14, 16, 16]}>
  //             {capitalize(
  //               item?.equipamentoModNauticaDTO?.descricao || "Náutica"
  //             )}
  //           </Text>
  //           <Text fontWeight={500} color="#b1b1b1" fontSize={[12, 14, 14]}>
  //             Sócio: {capitalize(item.nomeSocio)}
  //           </Text>
  //           <HStack>
  //             <img src={watch} />
  //             {item.horaFim ? (
  //               <Text fontWeight={500} color="#b1b1b1" fontSize={[11, 12, 14]}>
  //                 {moment(item.horaInicio).format("HH:mm")} -{" "}
  //                 {moment(item.horaFim).format("HH:mm")}
  //               </Text>
  //             ) : (
  //               <Text fontWeight={500} color="#b1b1b1" fontSize={[11, 12, 14]}>
  //                 Dia inteiro
  //               </Text>
  //             )}
  //           </HStack>
  //         </Stack>
  //       );
  //       break;

  //     case "CHURRASQUEIRA":
  //       element = (
  //         <Stack spacing={1}>
  //           <Text fontWeight={600} fontSize={[14, 16, 16]}>
  //             {capitalize(item.tipoReserva)}
  //           </Text>
  //           <HStack>
  //             <img src={watch} />
  //             <Text fontWeight={500} color="#b1b1b1" fontSize={[11, 12, 14]}>
  //               {moment(item.horaInicio).format("HH:mm")} -{" "}
  //               {moment(item.horaFim).format("HH:mm")}
  //             </Text>
  //           </HStack>
  //         </Stack>
  //       );
  //       break;

  //     case "MODALIDADE_ESPORTIVA":
  //       element = (
  //         <Stack spacing={1}>
  //           <Text fontWeight={600} fontSize={[14, 16, 16]}>
  //             {capitalize(item.descricaoModalidade)}
  //           </Text>
  //           <Text fontWeight={500} color="#b1b1b1" fontSize={[12, 14, 14]}>
  //             Prof: {capitalize(item.professor)}
  //           </Text>
  //           <HStack>
  //             <img src={watch} />
  //             <Text fontWeight={500} color="#b1b1b1" fontSize={[11, 12, 14]}>
  //               {moment(item.horaInicio).format("HH:mm")} -{" "}
  //               {moment(item.horaFim).format("HH:mm")}
  //             </Text>
  //           </HStack>
  //         </Stack>
  //       );
  //       break;

  //     default:
  //       break;
  //   }
  //   return element;
  // };

  return (
    <PageLayout>
      <Container maxW="container.md" pt={5} pb={20}>
        <Stack spacing={8}>
          <Breadcrumb
            items={[
              { route: "/", label: "Início" },
              { route: ROUTES.RESERVATIONS, label: "Reservas" },
              {
                route: ROUTES.RESERVATIONS_HISTORY,
                label: "Reservas realizadas",
              },
            ]}
          />
          <PageTitle>Reservas Realizadas</PageTitle>
          <Tabs variant="solid-rounded">
            <TabList>
              <Tab>Academia</Tab>
              <Tab>Churrasqueira</Tab>
            </TabList>
            <TabPanels>
              <TabPanel>
                {queryReservasAcademia.isLoading || queryReservasAcademia.isFetching ? (
                  <Flex w="full" alignItems={"center"} justifyContent="center">
                    <Loading />
                  </Flex>
                ) : (
                  <Stack p={5} spacing={5}>
                    {reservations.reservasAcademia.map(
                      (item: AcademiaReserva, i: number) => (
                        <div
                          className={`${styles.item} ${moment(item.DT_INICIO).isBefore(moment())
                            ? styles.inactive
                            : (item.CD_SITUACAO === 'C' ? styles.cancelled : '')
                            }`}
                          key={i}
                          onClick={() => handleReservationClick(item)}
                        >
                          <Box
                            w={["55px", "55px", "80px"]}
                            className={styles.leftDiv}
                          >
                            <Text>
                              {capitalize(
                                moment(item.DT_INICIO).format("ddd")
                              )}
                            </Text>
                            <Text fontWeight={"bold"} fontSize={20}>
                              {moment(item.DT_INICIO).format("DD")}
                            </Text>
                            <Text>
                              {capitalize(
                                moment(item.DT_INICIO).format("MMM")
                              )}
                            </Text>
                          </Box>
                          <Flex
                            paddingLeft={["70px", "70px", "120px"]}
                            paddingRight="20px"
                            alignItems={"center"}
                            w="full"
                            height={"full"}
                            justifyContent="space-between"
                          >
                            <Stack spacing={1}>
                              <Text fontWeight={600} fontSize={[14, 16, 16]}>
                                {item.DE_SERVICO}
                              </Text>
                              <HStack>
                                <img src={watch} alt='Imagem de um relógio' />
                                <Text
                                  fontWeight={500}
                                  color="#707070"
                                  fontSize={[11, 12, 14]}
                                >
                                  {moment(item.DT_INICIO).format("HH:mm")}
                                  {/* -{" "}                                  {moment(item.DT_INICIO).format("HH:mm")} */}
                                </Text>
                              </HStack>
                              <Text fontWeight={600} fontSize={[12, 12, 14]}>
                                {item.NOME_PESSOA}
                              </Text>
                              {item.CD_SITUACAO === 'C' && (
                                <Text
                                  fontSize={[12, 13, 15]}
                                >
                                  CANCELADO
                                </Text>
                              )}
                            </Stack>

                            <ChevronRightIcon fontSize={20} />
                          </Flex>
                        </div>
                      )
                    )}
                    {reservations.reservasAcademia.length === 0 ? (
                      <Text py={"200px"} alignSelf="center">
                        Você não possui reservas de academia.
                      </Text>
                    ) : (
                      <></>
                    )}
                  </Stack>
                )}
              </TabPanel>
              <TabPanel>
                {isLoading || isFetching ? (
                  <Flex w="full" alignItems={"center"} justifyContent="center">
                    <Loading />
                  </Flex>
                ) : (
                  <Stack p={5} spacing={5}>
                    {reservations.reservasChurrasqueira.map(
                      (item: ChurrasqueiraReserva, i: number) => (
                        <div
                          className={`${styles.item} ${moment(item.DT_INIC_UTILIZACAO).isBefore(moment())
                            ? styles.inactive
                            : ""
                            }`}
                          key={i}
                          onClick={() => handleBarbecueReservationClick(item)}
                        >
                          <Box
                            w={["55px", "55px", "80px"]}
                            className={styles.leftDiv}
                          >
                            <Text>
                              {capitalize(
                                moment(item.DT_INIC_UTILIZACAO).format("ddd")
                              )}
                            </Text>
                            <Text fontWeight={"bold"} fontSize={20}>
                              {moment(item.DT_INIC_UTILIZACAO).format("DD")}
                            </Text>
                            <Text>
                              {capitalize(
                                moment(item.DT_INIC_UTILIZACAO).format("MMM")
                              )}
                            </Text>
                          </Box>
                          <Flex
                            paddingLeft={["70px", "70px", "120px"]}
                            paddingRight="20px"
                            alignItems={"center"}
                            w="full"
                            height={"full"}
                            justifyContent="space-between"
                          >
                            <Stack spacing={1}>
                              <Text fontWeight={600} fontSize={[14, 16, 16]}>
                                {item.DESCR_DEPENDENCIA}
                              </Text>
                              <HStack>
                                <img src={watch} alt="Imagem de um relógio" />
                                <Text
                                  fontWeight={500}
                                  color="#707070"
                                  fontSize={[11, 12, 14]}
                                >
                                  {moment(item.DT_INIC_UTILIZACAO).format("HH:mm")} -{" "}
                                  {moment(item.DT_FIM_UTILIZACAO).format("HH:mm")}
                                </Text>
                              </HStack>
                            </Stack>

                            <ChevronRightIcon fontSize={20} />
                          </Flex>
                        </div>
                      )
                    )}
                    {reservations.reservasChurrasqueira.length === 0 ? (
                      <Text py={"200px"} alignSelf="center">
                        Você não possui reservas de churrasqueira.
                      </Text>
                    ) : (
                      <></>
                    )}
                  </Stack>
                )}
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Stack>
      </Container>
    </PageLayout>
  );
};

export default ReservationsHistory;
