// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".DeclarationOfDischargeDetails_icons__2e0v4 {\n  font-size: 18px;\n  font-weight: bold;\n  color: #0d4f7b;\n}\n\n.DeclarationOfDischargeDetails_mocKDeclaracao__2eZvf {\n  width: 100%;\n  border: 1px solid rgb(207, 207, 207);\n  border-radius: 6px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  font-size: 1rem;\n  flex-direction: column;\n  padding: 2rem;\n  grid-gap: 2rem;\n  gap: 2rem;\n}\n\n.DeclarationOfDischargeDetails_link__3_cto:hover {\n  text-decoration: underline;\n}", "",{"version":3,"sources":["webpack://src/pages/Financial/DeclarationOfDischarges/DeclarationOfDischargeDetails/DeclarationOfDischargeDetails.module.scss","webpack://src/styles/variables.scss"],"names":[],"mappings":"AAEA;EACI,eAAA;EACA,iBAAA;EACA,cCJM;ADGV;;AAIA;EACI,WAAA;EACA,oCAAA;EACA,kBAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,eAAA;EACA,sBAAA;EACA,aAAA;EACH,cAAA;EAAA,SAAA;AADD;;AAKI;EACI,0BAAA;AAFR","sourcesContent":["@import \"../../../../styles/variables.scss\";\r\n\r\n.icons{\r\n    font-size: 18px;\r\n    font-weight: bold;\r\n    color: $primary;\r\n}\r\n\r\n.mocKDeclaracao{\r\n    width: 100%;\r\n    border: 1px solid rgb(207, 207, 207);\r\n    border-radius: 6px;\r\n    display: flex;\r\n    align-items: center;\r\n    justify-content: center;\r\n    font-size: 1rem;\r\n    flex-direction: column;\r\n    padding: 2rem;\r\n\tgap: 2rem;\r\n}\r\n\r\n.link{\r\n    &:hover{\r\n        text-decoration: underline;\r\n    }\r\n}","// $primary: #003E66;\r\n$primary: #0d4f7b;\r\n$danger: #F03D3E;\r\n$success: #44AC80;\r\n$white: #FFFFFF;\r\n\r\n$border-radius: 8px;\r\n$navbar-height: 50px;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"icons": "DeclarationOfDischargeDetails_icons__2e0v4",
	"mocKDeclaracao": "DeclarationOfDischargeDetails_mocKDeclaracao__2eZvf",
	"link": "DeclarationOfDischargeDetails_link__3_cto"
};
export default ___CSS_LOADER_EXPORT___;
