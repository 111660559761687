import { useMemo } from "react";
import { ENDPOINTS } from "../constants/endpoints";
import useAxios from "../hooks/useAxios";
import { ChangePasswordModel, TitularAlteracao } from "../models/profile.model";
import { userManager } from "./authManager";

export function useProfileService() {

  const axios = useAxios();

  const getProfile = async () => {

    const user = userManager.get();

    let response = await axios.get(ENDPOINTS.getProfile, {
      params: {
        matricula: user.CD_MATRICULA,
        categoria: user.CD_CATEGORIA,
        usuario: user.USER_ACESSO_SISTEMA,
      }
    });
    return response.data[0];
  };

  const updateProfile = async (profile: TitularAlteracao) => {
    const user = userManager.get();
    
    return await axios.put(ENDPOINTS.updateProfile, null, {
      params: {
        ...profile,
        matricula: user.CD_MATRICULA,
        categoria: user.CD_CATEGORIA,
        usuario: user.USER_ACESSO_SISTEMA,
      }
    });
  };

  const changePassword = async (body: ChangePasswordModel) => {
    return await axios.put(ENDPOINTS.changePassword, body);
  };

  const getIsSuspended = async () => {
    const user = userManager.get();
    return await axios.get(ENDPOINTS.isSuspended, {
      params: {usuario: user.USER_ACESSO_SISTEMA}
    });
  };

  const services = {
    getProfile,
    updateProfile,
    changePassword,
    getIsSuspended
  };

  return services;
}
