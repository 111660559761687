import { Box, Stack } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import ActionButton from "../../components/ActionButton/ActionButton";
import { PageTitle } from "../../components/PageTitle/PageTitle";

function NotFound() {
  return (
    <Box
      height={"100vh"}
      width={"100vw"}
      display={"flex"}
      justifyContent={"center"}
      alignItems={"center"}
    >
      <Stack
        spacing={8}
        width={"full"}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <PageTitle>Página não encontrada.</PageTitle>
        <Link to="/" >
          <ActionButton size={"sm"}>Início</ActionButton>
        </Link>
      </Stack>
    </Box>
  );
}

export default NotFound;
