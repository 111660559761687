import { Container, Stack } from "@chakra-ui/react";
import iconQuitacao from "../../assets/icons/check_hand.svg";
import iconFatura from "../../assets/icons/detalhamento_fatura.svg";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import LinkBox from "../../components/LinkBox/LinkBox";
import PageLayout from "../../components/PageLayout/PageLayout";
import { ROUTES } from "../../constants/routes";
import { userManager } from "../../services/authManager";

const Financial = () => {
  const user = userManager.get();
  return (
    <PageLayout>
      <Container maxW="container.md" pt={5} pb={20}>
      <Stack spacing={5}>
          <Breadcrumb
            items={[
              { route: "/", label: "Início" },
              { route: ROUTES.FINANCIAL, label: "Financeiro" },
            ]}
          />
          {/* <LinkBox
            label="2ª Via do Boleto"
            to={ROUTES.FINANCIAL_BILLINGS_COPY}
            leftIcon={iconBoleto}
          /> */}
          {user.CD_APLICACAO.includes(130) && 
            <LinkBox
              label="Faturas"
              to={ROUTES.FINANCIAL_BILLINGS_DETAILS}
              leftIcon={iconFatura}
            />
          }
          {/* <LinkBox
            label="Quitação de Débitos"
            to={ROUTES.FINANCIAL_DEBT_PAYMENTS}
            leftIcon={iconQuitacao}
          ></LinkBox> */}
          {user.CD_APLICACAO.includes(190) && 
            <LinkBox
              label="Declaração de Quitação"
              to={ROUTES.FINANCIAL_DECLARATION_OF_DISCHARGE}
              leftIcon={iconQuitacao}
            />
          }
        </Stack>
      </Container>
    </PageLayout>
  );
};

export default Financial;
