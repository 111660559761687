import { Container, Stack } from "@chakra-ui/react";
import LinkBox from "../../components/LinkBox/LinkBox";
import PageLayout from "../../components/PageLayout/PageLayout";
import { ROUTES } from "../../constants/routes";
import churrasqueiraIcon from "../../assets/icons/churrasqueira.svg"
import geralIcon from "../../assets/icons/invites_2.svg";
import { PageTitle } from "../../components/PageTitle/PageTitle";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";


export default function InvitesSelect() {
  return (
    <>
      <PageLayout>
        <Container maxW="container.md" pt={5} pb={20}>
          <Stack spacing={5}>
            <Breadcrumb
              items={[
                { route: "/", label: "Início" },
                { route: ROUTES.INVITES_SELECT, label: "Convites" },
              ]}
            />
            <PageTitle>Selecione o tipo de convite</PageTitle>
            <LinkBox
              label="Geral"
              to={ROUTES.INVITES_GENERAL}
              leftIcon={geralIcon}
            />
            <LinkBox
              label="Churrasqueira"
              to={ROUTES.CHURRASQUEIRAS_CONVITES}
              leftIcon={churrasqueiraIcon}
            />
          </Stack>
        </Container>
      </PageLayout>
    </>
  );
}
