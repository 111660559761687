// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Dependents_table__3x96z {\n  border-collapse: separate;\n  border-spacing: 0 1rem;\n}\n.Dependents_table__3x96z th {\n  font-weight: bold;\n  color: rgba(49, 49, 49, 0.85);\n  text-align: start;\n}\n.Dependents_table__3x96z tbody tr {\n  background-color: rgba(49, 49, 49, 0.05);\n  height: 50px;\n}\n.Dependents_table__3x96z tbody tr td {\n  vertical-align: middle;\n}\n.Dependents_table__3x96z tbody tr td:first-child {\n  padding-left: 1rem;\n  border-top-left-radius: 10px;\n  border-bottom-left-radius: 10px;\n}\n.Dependents_table__3x96z tbody tr td:last-child {\n  border-top-right-radius: 10px;\n  border-bottom-right-radius: 10px;\n}\n\n.Dependents_submitBtn__11VRj {\n  align-self: flex-end;\n}", "",{"version":3,"sources":["webpack://src/pages/Profile/Dependents/Dependents.module.scss"],"names":[],"mappings":"AAAA;EACE,yBAAA;EACA,sBAAA;AACF;AAAE;EACE,iBAAA;EACA,6BAAA;EACA,iBAAA;AAEJ;AACI;EACE,wCAAA;EACA,YAAA;AACN;AACM;EACE,sBAAA;AACR;AAEI;EACE,kBAAA;EACA,4BAAA;EACA,+BAAA;AAAN;AAEI;EACE,6BAAA;EACA,gCAAA;AAAN;;AAIA;EACE,oBAAA;AADF","sourcesContent":[".table {\r\n  border-collapse: separate;\r\n  border-spacing: 0 1rem;\r\n  th {\r\n    font-weight: bold;\r\n    color: rgba(49, 49, 49, 0.85);\r\n    text-align: start;\r\n  }\r\n  tbody {\r\n    tr {\r\n      background-color: rgba(49, 49, 49, 0.05);\r\n      height: 50px;\r\n\r\n      td {\r\n        vertical-align: middle;\r\n      }\r\n    }\r\n    tr td:first-child {\r\n      padding-left: 1rem;\r\n      border-top-left-radius: 10px;\r\n      border-bottom-left-radius: 10px;\r\n    }\r\n    tr td:last-child {\r\n      border-top-right-radius: 10px;\r\n      border-bottom-right-radius: 10px;\r\n    }\r\n  }\r\n}\r\n.submitBtn {\r\n  align-self: flex-end;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"table": "Dependents_table__3x96z",
	"submitBtn": "Dependents_submitBtn__11VRj"
};
export default ___CSS_LOADER_EXPORT___;
