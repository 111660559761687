import {
  AlertDialog,
  AlertDialogBody,
  Image,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogOverlay,
  Container,
  Heading,
  HStack,
  Stack,
  Text,
  useDisclosure,
  Flex,
  Box,
  SimpleGrid,
  GridItem,
  FormControl,
  FormLabel,
  Select,
  FormErrorMessage,
  Input,
  useBreakpointValue,
  useToast,
  Button,
  AlertDialogHeader,
  AlertDialogCloseButton,
  Checkbox,
} from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ActionButton from "../../../components/ActionButton/ActionButton";
import Actions from "../../../components/Actions/Actions";
import Breadcrumb from "../../../components/Breadcrumb/Breadcrumb";
import LinkBox from "../../../components/LinkBox/LinkBox";
import PageLayout from "../../../components/PageLayout/PageLayout";
import PolicyAgreement from "../../../components/PolicyAgreement/PolicyAgreement";
import { InviteTicket } from "../../../components/Ticket/Ticket";
import { ROUTES } from "../../../constants/routes";
import { PolicyContent } from "../../Invites/InviteEmission/InviteEmission";
import convite_emitido_img from "../../../assets/img/convite_emitido.svg";
import ImageBanner from "../../../components/ImageBanner/ImageBanner";
import defaultImage from "../../../assets/img/churrasqueira.jpg";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation, useQuery } from "react-query";
import moment from "moment";
import * as yup from "yup";
import { CAMPO_OBRIGATORIO } from "../../../common/feedbacks";
import { useChurrasqueiraService } from "../../../services/churrasqueira.service";
import { PageTitle } from "../../../components/PageTitle/PageTitle";
import Loading from "../../../components/Loading/Loading";
import { capitalize } from "../../../helpers/capitalize";
import { ChevronRightIcon } from "@chakra-ui/icons";
import styles from "./Churrasqueira.module.scss";
import watch from "../../../assets/icons/watch.svg";
import inviteIcon from "../../../assets/icons/invites_2.svg";
import inviteIcon1 from "../../../assets/icons/invites_1.svg";
import icon_churrasqueira from "../../../assets/icons/churrasqueira.svg";
import icon_calendario from "../../../assets/icons/calendario.svg";
import useCustomToast from "../../../hooks/useCustomToast";
import { Invite } from "../../../models/invite.model";
import { uppercase } from "../../../helpers/uppercase";
import IconButton from "../../../components/IconButton/IconButton";
import trashIcon from "../../../assets/icons/trash-icon.svg";
import { OverlayBackdrop } from "../../../components/OverlayBackdrop/OverlayBackdrop";
import MaskedInput from "react-input-mask";
import { testaCPF } from "../../../helpers/validCPF";
import { nomeRgx } from "../../../helpers/regex";
import { useProfileService } from "../../../services/profile.service";
import ReactInputDateMask from "react-input-mask"

export default function Churrasqueira() {
  return (
    <PageLayout>
      <Container maxW="container.md" pt={5} pb={20}>
        <Stack spacing={8}>
          <Breadcrumb items={[{ route: "/", label: "Início" }]} />
          <Stack spacing={5}>
            <LinkBox
              leftIcon={inviteIcon1}
              label="Reservar"
              to={ROUTES.RESERVATIONS_BARBECUE}
            />
            <LinkBox
              leftIcon={inviteIcon}
              label="Emitir convites"
              to={ROUTES.CHURRASQUEIRAS_CONVITES}
            />
            <LinkBox
              leftIcon={icon_calendario}
              label="Reservas realizadas"
              to={ROUTES.RESERVATIONS_BARBECUE_LIST}
            />
          </Stack>
        </Stack>
      </Container>
    </PageLayout>
  );
}

export function ChurrasqueiraConviteMenu() {
  return (
    <PageLayout>
      <Container maxW="container.md" pt={5} pb={20}>
        <Stack spacing={8}>
          <Breadcrumb
            items={[
              { route: "/", label: "Início" },
              { route: ROUTES.INVITES_SELECT, label: "Convites" },
              { route: ROUTES.CHURRASQUEIRAS_CONVITES, label: "Churrasqueira" },
            ]}
          />
          <Stack spacing={5}>
            <LinkBox
              leftIcon={inviteIcon}
              label="Emissão de convites"
              to={ROUTES.CHURRASQUEIRAS_CONVITES_EMITIR}
            />
            <LinkBox
              leftIcon={inviteIcon}
              label="Convites emitidos"
              to={ROUTES.CHURRASQUEIRAS_CONVITES_EMITIDOS}
            />
            {/* <LinkBox
              leftIcon={icon_calendario}
              label="Reservas realizadas"
              to={ROUTES.RESERVATIONS_BARBECUE_LIST}
            /> */}
          </Stack>
        </Stack>
      </Container>
    </PageLayout>
  );
}

type ListaDeReservasChurrasqueiraProps = {
  reservas: any[];
  handleReservaClick: (item: any) => void;
};
export const ListaDeReservasChurrasqueira = ({
  reservas,
  handleReservaClick,
}: ListaDeReservasChurrasqueiraProps) => {
  return (
    <Stack p={2} spacing={5}>
      {reservas.map((item: any, i: number) => (
        <div
          className={`${styles.item} ${
            moment(item.data).isBefore(moment()) ? styles.inactive : ""
          }`}
          key={i}
          onClick={() => handleReservaClick(item)}
        >
          <Box w={["55px", "55px", "80px"]} className={styles.leftDiv}>
            <Text>
              {capitalize(moment(item.DT_INIC_UTILIZACAO).format("ddd"))}
            </Text>
            <Text fontWeight={"bold"} fontSize={20}>
              {moment(item.DT_INIC_UTILIZACAO).format("DD")}
            </Text>
            <Text>
              {capitalize(moment(item.DT_INIC_UTILIZACAO).format("MMM"))}
            </Text>
          </Box>
          <Flex
            paddingLeft={["70px", "70px", "120px"]}
            paddingRight="20px"
            alignItems={"center"}
            w="full"
            height={"full"}
            justifyContent="space-between"
          >
            <Stack spacing={1}>
              <Text fontWeight={600} fontSize={[14, 16, 16]}>
                {item.DESCR_DEPENDENCIA}
              </Text>
              <HStack>
                <img src={watch} />
                <Text fontWeight={500} color="#b1b1b1" fontSize={[11, 12, 14]}>
                  {moment(item.DT_INIC_UTILIZACAO).format("HH:mm")} -{" "}
                  {moment(item.DT_FIM_UTILIZACAO).format("HH:mm")}
                </Text>
              </HStack>
            </Stack>
            <ChevronRightIcon fontSize={26} />
          </Flex>
        </div>
      ))}
      {reservas.length == 0 ? (
        <Text py={"200px"} alignSelf="center">
          Você não possui reservas realizadas.
        </Text>
      ) : (
        <></>
      )}
    </Stack>
  );
};

enum ConvitesEmitidosChurrasqueiraSteps {
  LISTA_RESERVAS,
  LISTA_CONVITES,
}

export const ConvitesEmitidosChurrasqueira = () => {
  const [invites, setInvites] = useState<Invite[]>([]);
  const [selectedInvite, setSelectedInvite] = useState<Invite>();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef: any = useRef();
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [currentStep, setCurrentStep] =
    useState<ConvitesEmitidosChurrasqueiraSteps>(
      ConvitesEmitidosChurrasqueiraSteps.LISTA_RESERVAS
    );

  const [reservas, setReservas] = useState<any[]>([]);
  const [reserva, setReserva] = useState<any>();

  const {
    listarConvitesDeReservaDeChurrasqueira,
    listarReservasChurrasqueira,
    cancelarConviteChurrasqueira,
  } = useChurrasqueiraService();

  const { isLoading: isLoadingReservas, isFetching: isFetchingReservas } =
    useQuery<any, Error>(
      "listarReservasChurrasqueira",
      async () => {
        return await listarReservasChurrasqueira();
      },
      {
        retry: 5,
        refetchOnWindowFocus: false,
        onSuccess: (res: any) => {
          if (res) {
            setReservas(res);
          }
        },
        onError: () => {
          showToast(
            "error",
            "Não foi possível listar as reservas de churrasqueira."
          );
        },
      }
    );

  const handleReservaClick = (reserva: any) => {
    setReserva(reserva);
    setCurrentStep(ConvitesEmitidosChurrasqueiraSteps.LISTA_CONVITES);
  };

  useEffect(() => {
    if (selectedInvite) setModalVisible(true);
  }, [selectedInvite]);

  const handleInviteDetails = (invite: Invite) => {
    let match = invites.find((x: any) => invite.NR_CONVITE == x.NR_CONVITE);

    if (match) {
      setSelectedInvite(match);
    } else {
      showToast("error", "Não foi visualizar o convite.");
    }
  };

  const deleteInviteMutation = useMutation(
    (numeroConvite: number) => cancelarConviteChurrasqueira(numeroConvite),
    {
      onSuccess: () => {
        showToast("success", "Convite excluído.");
        refetch();
      },
      onError: () =>
        showToast("error", "Não foi possível excluir o dependente."),
    }
  );

  const handleDelete = () => {
    deleteInviteMutation.mutate(selectedInvite?.NR_CONVITE!);
  };

  const { showToast } = useCustomToast();

  const {
    isLoading: isLoadingConvites,
    isFetching: isFetchingConvites,
    refetch,
  } = useQuery<any[], Error>(
    "listaConvitesEmitidosChurrasqueira",
    async () => {
      return await listarConvitesDeReservaDeChurrasqueira(reserva?.SEQ_RESERVA);
    },
    {
      enabled: !!reserva,
      refetchOnWindowFocus: false,
      onSuccess: (data: any[]) => {
        let _invites: any[] = [...data];
        setInvites(_invites);
      },
      onError: () => showToast("error", "Erro ao listar convites emitidos."),
      onSettled: () => setSelectedInvite(null!),
    }
  );

  switch (currentStep) {
    case ConvitesEmitidosChurrasqueiraSteps.LISTA_RESERVAS:
      return (
        <PageLayout>
          <Container maxW="container.md" pt={5} pb={20}>
            <Stack spacing={8}>
              <Breadcrumb
                items={[
                  { route: "/", label: "Início" },
                  { route: ROUTES.INVITES_SELECT, label: "Convites" },
                  {
                    route: ROUTES.CHURRASQUEIRAS_CONVITES,
                    label: "Churrasqueira",
                  },
                  {
                    route: ROUTES.CHURRASQUEIRAS_CONVITES_EMITIDOS,
                    label: "Convites emitidos",
                  },
                ]}
              />

              {isLoadingReservas || isFetchingReservas ? (
                <Flex justifyContent="center" alignItems="center" h="200">
                  <Loading />
                </Flex>
              ) : (
                <>
                  <Heading fontWeight={500} size={"md"} color="primary">
                    Clique em uma reserva para ver os convites emitidos.
                  </Heading>
                  <ListaDeReservasChurrasqueira
                    reservas={reservas}
                    handleReservaClick={handleReservaClick}
                  />
                </>
              )}
            </Stack>
          </Container>
        </PageLayout>
      );
    case ConvitesEmitidosChurrasqueiraSteps.LISTA_CONVITES:
      return (
        <PageLayout>
          <Container maxW="container.md" pt={5} pb={20}>
            <Stack spacing={8}>
              <Breadcrumb
                items={[
                  { route: "/", label: "Início" },
                  { route: ROUTES.INVITES_SELECT, label: "Convites" },
                  {
                    route: ROUTES.CHURRASQUEIRAS_CONVITES,
                    label: "Churrasqueira",
                  },
                  {
                    route: ROUTES.CHURRASQUEIRAS_CONVITES_EMITIDOS,
                    label: "Convites emitidos",
                  },
                ]}
              />
              {isFetchingConvites || isLoadingConvites ? (
                <Flex justifyContent="center" alignItems="center" h="200">
                  <Loading />
                </Flex>
              ) : (
                <Stack
                  justifyContent={"center"}
                  w="full"
                  display={"flex"}
                  alignItems={"center"}
                  spacing={6}
                >
                  <Box marginY={[4, 4, 10]}>
                    <PageTitle>Convites emitidos</PageTitle>
                  </Box>

                  {(!invites || invites.length === 0) && (
                    <Text alignSelf={"center"}>
                      Não existem convites emitidos.
                    </Text>
                  )}

                  {invites.length > 0 &&
                    invites.map((x, i) => (
                      <HStack spacing={6} key={i}>
                        <Box
                          onClick={() => handleInviteDetails(x)}
                          className={styles.ticket}
                          w={["280px", "300px", "500px"]}
                          maxW={"90%"}
                        >
                          <div className={styles.date}>
                            <Stack
                              spacing={0}
                              justifyContent={"center"}
                              alignItems={"center"}
                              textAlign="center"
                            >
                              <Text color={"primary"} fontSize={10}>
                                Válido para o dia:
                              </Text>
                              <Text color={"primary"} fontSize={10}>
                                {capitalize(
                                  moment(x.DT_MAX_UTILIZACAO).format(
                                    "DD/MM/YYYY"
                                  )
                                )}
                              </Text>
                            </Stack>
                          </div>
                          <Box
                            className={styles.info}
                            pl={["1rem", "1rem", "1.5rem"]}
                          >
                            <Stack spacing={0} marginRight={"auto"}>
                              <Text
                                color={"primary"}
                                fontWeight={"bold"}
                                fontSize={[15, 18, 20]}
                              >
                                {uppercase(x.NOME_CONVIDADO)}
                              </Text>

                              <Text color={"primary"} fontSize={[12, 12, 14]}>
                                Tipo: Churrasqueira
                              </Text>
                              <Text color={"primary"} fontSize={[12, 12, 14]}>
                                Código Convite: {x.NR_CONVITE}
                              </Text>
                            </Stack>
                          </Box>
                        </Box>
                        <IconButton
                          tooltip="Cancelar convite"
                          onClick={() => {
                            setSelectedInvite(x);
                            onOpen();
                          }}
                        >
                          <Image src={trashIcon} alt={"Excluir"} />
                        </IconButton>
                      </HStack>
                    ))}
                </Stack>
              )}
            </Stack>
          </Container>

          <AlertDialog
            size={"xs"}
            isOpen={isOpen}
            leastDestructiveRef={cancelRef}
            onClose={onClose}
            isCentered
          >
            <AlertDialogOverlay>
              <AlertDialogContent>
                <AlertDialogHeader fontSize="lg" fontWeight="bold">
                  <AlertDialogCloseButton />
                </AlertDialogHeader>
                <AlertDialogBody>
                  <Box>
                    <Text>Tem certeza que deseja excluir o convite?</Text>
                  </Box>
                </AlertDialogBody>
                <AlertDialogFooter>
                  <HStack justifyContent={"end"} alignItems={"center"}>
                    <ActionButton variant={"outline"} onClick={onClose}>
                      Não
                    </ActionButton>
                    <ActionButton
                      variant={"primary"}
                      onClick={() => {
                        onClose();
                        handleDelete();
                      }}
                    >
                      Sim
                    </ActionButton>
                  </HStack>
                </AlertDialogFooter>
              </AlertDialogContent>
            </AlertDialogOverlay>
          </AlertDialog>

          <OverlayBackdrop
            setModalVisible={setModalVisible}
            modalVisible={modalVisible}
          >
            <Stack>
              <Box borderRadius={10}>
                <InviteTicket invite={selectedInvite!} isChurrasqueira />
              </Box>
            </Stack>
          </OverlayBackdrop>
        </PageLayout>
      );
    default:
      return <></>;
  }
};

export const ChurrasqueiraReservas = () => {
  const [reservas, setReservas] = useState<any[]>([]);

  const { listarReservasChurrasqueira } = useChurrasqueiraService();

  const { showToast } = useCustomToast();

  const { isLoading, isFetching } = useQuery<any, Error>(
    "listarReservasChurrasqueira",
    async () => {
      return await listarReservasChurrasqueira();
    },
    {
      retry: 5,
      refetchOnWindowFocus: false,
      onSuccess: (res: any) => {
        if (res) {
          setReservas(res);
        }
      },
      onError: () => {
        showToast(
          "error",
          "Não foi possível listar as reservas de churrasqueira."
        );
      },
    }
  );

  const handleReservationClick = (item: any) => {};

  return (
    <PageLayout>
      <Container maxW="container.md" pt={5} pb={20}>
        <Stack spacing={8}>
          <Breadcrumb
            items={[
              { route: "/", label: "Início" },
              {
                route: ROUTES.RESERVATIONS_BARBECUE_LIST,
                label: "Reservas",
              },
            ]}
          />

          <PageTitle>Reservas Realizadas</PageTitle>
          {isLoading || isFetching ? (
            <Flex w="full" alignItems={"center"} justifyContent="center">
              <Loading />
            </Flex>
          ) : (
            <ListaDeReservasChurrasqueira
              reservas={reservas}
              handleReservaClick={handleReservationClick}
            />
          )}
        </Stack>
      </Container>
    </PageLayout>
  );
};

export const PolicyContentChurrasqueira = () => (
  <Stack spacing={5} color={"#939393"}>
    <Heading size={"sm"} alignSelf="center">
      IATE CLUBE DE BRASÍLIA
    </Heading>
    <p> CONSELHO DELIBERATIVO RESOLUÇÃO nº 001/2021</p>
    <p>
      O Conselho Deliberativo R E S O L V E, por unanimidade, proceder, em
      caráter temporário, as seguintes alterações no Capítulo III – Dos Convites
      do Regulamento de Acesso ao Clube - RAC: a) ficam suspensos os incisos II,
      “b”; e IV; b) fica mantida a redação do inciso I do art. 29; c) a alínea
      “a” do inciso II do art. 29 passa a vigorar com a seguinte redação: II,
      “a” - Churrasqueira, no limite de 50% (cinquenta por cento) da capacidade
      de cada uma, observadas as normas do Setor. Nota: caso o número
      correspondente a 50% da capacidade da churrasqueira seja uma fração, este
      deverá ser arredondado para o número inteiro anterior.
    </p>
    <Heading size={"sm"} alignSelf="center">
      QUANTIDADE DE CONVITES
    </Heading>
    <Stack>
      <p> Segunda a Sexta</p>
      <p>CH. Grande ou Gourmet: 40</p>
      <p>CH. Média: 20</p>
      <p>CH. Pequena: 10</p>
      <br />
      <p> Sábado, Domingo e Feriado "DIURNO"</p>
      <p>CH. Grande ou Gourmet: 20</p>
      <p>CH. Média: 10</p>
      <p>CH. Pequena: 5</p>
      <br />
      <p> Sábado, Domingo e Feriado "NOTURNO"</p>
      <p>CH. Grande ou Gourmet: 40</p>
      <p>CH. Média: 20</p>
      <p>CH. Pequena: 10</p>
      <br />
    </Stack>

    <Heading size={"sm"} alignSelf="center">
      REGULAMENTO DO SETOR DE CHURRASQUEIRAS
    </Heading>
    <p>
      Aprovado pelo Conselho Deliberativo nas reuniões extraordinárias de
      26/2/2013, 26/3/2013 e 23/4/2013.
    </p>
    <Heading size={"sm"} alignSelf="center">
      DISPOSIÇÕES PRELIMINARES
    </Heading>
    <p>
      Art. 1o - O presente regulamento tem por finalidade disciplinar a
      utilização das instalações do Setor de Churrasqueiras do Iate Clube de
      Brasília.
    </p>
    <p>Art. 2o - As Churrasqueiras são classificadas como:</p>
    <p>
      I. Pequenas, as de números P1, P2, P3, P4, P5, P6, P7, P8, P9, P10, P11,
      P12. <br />
      II. Médias, as de números M1, M2, M3, M4 <br />
      III. Gourmet grandes, as de números G1, G2, G3 E G4. <br />
    </p>
    <p>
      Parágrafo único. As reclassificações e as renumerações das churrasqueiras
      serão efetuadas pelo Conselho Diretor de acordo com as necessidades
      futuras.
    </p>
    <p>
      Art. 3o - Poderá fazer uso gratuito das instalações do Setor os sócios
      patrimoniais e usuário no pleno gozo de seus direitos estatutários e em
      dia com suas obrigações.
    </p>
    <Heading size={"sm"} alignSelf="center">
      DA RESERVA DE CHURRASQUEIRA
    </Heading>
    <p>
      <br />
      Art. 4o - A reserva de churrasqueira poderá ser feita: I. Pelos sócios
      patrimonial e usuário, pessoalmente na Secretaria Social ou pela internet,
      mediante prévio cadastramento de senha; e II. pelo dependente, mediante
      autorização por escrito do sócio titular dada na Secretaria.
      <br />
      Art. 5o - Não será admitida reserva de churrasqueira por fax, telefone ou
      e-mail.
      <br />
      Art. 6o - No ato da reserva de qualquer uma das churrasqueiras, quando
      efetuada pessoalmente na Secretaria, os sócios patrimoniais, usuários ou
      seus dependentes formalmente autorizados, deverão assinar termo de
      conhecimento do Regulamento da utilização das churrasqueiras, contendo
      data da reserva, número da churrasqueira, número do telefone, fax e
      endereço eletrônico para contato do interessado com o Iate. I. Quando a
      reserva for feita pela internet, o sócio deverá concordar com os termos do
      Regulamento; II. Em todas as churrasqueiras deverá existir cópia do
      Regulamento afixada em local visível.
      <br />
      Art. 7o - A reserva de uma churrasqueira será feita por um dia, para cada
      título patrimonial, com antecedência de, no máximo, 30 (trinta) dias, nas
      condições estabelecidas nos incisos deste artigo. I. Churrasqueira
      pequena: uma vez por quinzena para cada título patrimonial, vedada outra
      reserva durante esse período, exceto se, no prazo de 72 (setenta e duas)
      horas, contadas a partir do dia do pedido da reserva, houver
      disponibilidade constatada pela Secretaria Social; AI. Churrasqueira
      média: uma vez por mês para cada título patrimonial, vedada outra reserva
      durante esse período, exceto se, no prazo de 72 (setenta e duas) horas,
      contadas a partir do dia do pedido da reserva, houver disponibilidade
      constatada pela Secretaria Social; e BI. Churrasqueira grande ou gourmet:
      uma vez por quadrimestre-calendário para cada título patrimonial, aos
      sábados, domingos, feriados e dias festivos do clube, e uma vez por
      trimestre-calendário para cada título patrimonial, de segunda a sexta,
      vedada nova reserva durante esse período, exceto se no prazo de 72
      (setenta e duas) horas, contadas a partir do dia do pedido da reserva,
      houver disponibilidade constatada pela Secretaria Social.
      <br />
      Art. 8o - A churrasqueira reservada estará disponível a partir das 9
      (nove) horas do dia da reserva.
      <br />
      Art. 9o - No ato de entrega da churrasqueira, empregado do clube procederá
      à vistoria do local, ocasião em que produzirá lista dos móveis,
      equipamentos e instalações, e seus respectivos estados de conservação,
      documento este que será assinado pelo sócio responsável pela reserva,
      atestando a veracidade dos registros. Parágrafo único – Ao final do uso da
      churrasqueira, será feita outra vistoria, responsabilizando-se o
      signatário do documento por eventuais perdas e danos causados.
    </p>
    <Heading size={"sm"} alignSelf="center">
      DO CANCELAMENTO DA RESERVA
    </Heading>
    <p>
      Art. 10. O cancelamento da reserva poderá ser feito no prazo de até 72
      (setenta e duas) horas antes da data marcada para a utilização da
      churrasqueira, pela internet, pessoalmente, mediante solicitação por
      escrito entregue na Secretaria Social, por e-mail ou fax.
      <br />
      Parágrafo único. Será debitada no boleto do sócio que não proceder ao
      cancelamento da reserva no prazo estabelecido neste artigo multa
      equivalente a 30% (trinta por cento) do valor da contribuição fixa de
      administração mensal para as churrasqueiras pequenas, 50% cinquenta por
      cento) para as churrasqueiras médias e de 90% (noventa por cento) para as
      churrasqueiras grandes ou gourmet.
    </p>
    <Heading size={"sm"} alignSelf="center">
      DAS PROIBIÇÕES
    </Heading>
    <p>
      Art. 11. Durante a utilização da churrasqueira ficam proibidas:
      <br />
      I. ocupação ou uso da estrutura, área e equipamentos com objetivo diverso
      de sua finalidade;
      <br />
      II. Modificação da arquitetura ou retirada dos equipamentos;
      <br />
      BI. Uso de karaokê e a utilização de aparelhagem de som ou qualquer
      equipamento para música ao vivo acima do limite de 65 decibéis no período
      diurno e 55 decibéis no período noturno; e
      <br />
      IV. instalação de rede para descanso, equipamentos de diversão, tais como
      pula-pula, cama-elástica, tobogã, balanço, palco ou outros assemelhados.
    </p>
    <Heading size={"sm"} alignSelf="center">
      DO CONVIDADO DO SÓCIO
    </Heading>
    <p>
      <br />
      Art. 12. O convite para acesso ao Setor de Churrasqueiras é individual e
      específico para o espaço reservado, devendo ser registrado, na Portaria,
      por meio do sistema de informática do clube, o nome e o CPF do convidado,
      o nome e a identificação do título do sócio ou usuário convidante, bem
      como o número da churrasqueira a ser utilizada.
      <br />§ 1o - Satisfeitas as condições estabelecidas no art. 3o, o
      associado ou o usuário tem direito a 40 (quarenta) convites para as
      churrasqueiras grandes ou gourmet, 20 (vinte) para as churrasqueiras
      médias e 10 (dez) para as churrasqueiras pequenas, de segunda a
      sexta-feira, e, metade deste quantitativo, no período diurno, aos sábados,
      domingos, feriados e dias festivos do clube, permanecendo, no período
      noturno, os quantitativos anteriores.
      <br />§ 2o - Fica o associado ou usuário convidante responsável pela
      conduta de seu convidado, bem como, por qualquer dano por ele causado ao
      patrimônio do clube, ao de integrante do quadro social ou de terceiros,
      conforme disposto no art. 40, incisos X e XIII, do Estatuto.
      <br />§ 3o - Para o fim do cumprimento do disposto no caput deste artigo,
      o Setor de Apoio do Iate manterá controle sobre a efetiva presença e
      utilização da churrasqueira por parte do sócio ou usuário e seus
      convidados e, constatada a ociosidade permanente do espaço reservado,
      lavrará Termo de Vistoria relatando o fato, sujeitando-se o associado
      responsável às multas estabelecidas no parágrafo único do art. 10, além
      das penas estabelecidas no Estatuto do Iate.
      <br />
      Art. 13. O convidado ou o prestador de serviço terá acesso ao Setor de
      Churrasqueiras mediante identificação na Portaria, por meio de credencial
      que lhe será fornecida pela Secretaria Social, sendo-lhe permitido,
      excepcionalmente, o ingresso de seu veículo ao estacionamento interno do
      clube, tão somente para a descarga de material a ser utilizado na
      churrasqueira.
      <br />§ 1o - No horário pré-determinado pela Secretaria Social para a
      saída do veículo, constante na credencial, deverá o convidado ou o
      prestador de serviço fazê-lo, oportunidade em que devolverá na Portaria a
      credencial recebida.
      <br />§ 2o - O convidado ou o prestador de serviço que não retirar o
      veículo das dependências do clube, no horário pré-estabelecido, será
      instado a fazê-lo, respondendo o sócio convidante pela eventual
      desobediência e pela conduta de seu convidado.
      <br />
      Art. 14. Aos sábados, domingos, feriados e dias festivos do clube, o
      convidado para o Setor de Churrasqueiras ou o prestador de serviço,
      poderão ingressar no clube após as 12 (doze) horas, permitindo-se lhe, nos
      demais dias da semana, o acesso depois das 9 (nove) horas.
      <br />
      Art. 15. A utilização de churrasqueira após as 18 (dezoito) horas, em que
      haja a presença de mais de 8 (oito) convidados, implica a contratação de
      um apoio e de um servente, devendo as indenizações por estas contratações
      serem recolhidas, à Tesouraria do clube, pelo sócio responsável pela
      reserva, no ato da emissão dos convites e, caso não recolhida, serão
      debitadas no boleto do sócio responsável.
    </p>
    <Heading size={"sm"} alignSelf="center">
      DAS DISPOSIÇÕES GERAIS
    </Heading>
    <p>
      <br />
      Art. 16. O sócio responsável pela reserva de churrasqueira fica obrigado a
      encaminhar à Secretaria Social, quando for o caso, com antecedência mínima
      de 24 (vinte e quatro) horas, antes da data marcada para a utilização do
      espaço reservado, pela internet, pessoalmente, por e-mail ou fax, a
      relação dos prestadores de serviços de churrasqueiros e dos ajudantes
      contratados, devidamente identificados.
      <br />
      Art. 17. Os casos omissos serão decididos pelo Vice-Diretor do Setor ou
      Diretor do Dia, que se obrigam a levar o assunto à Comodoria.
      <br />
      Art. 18. Este regulamento entra em vigor na data de sua publicação no site
      do IATE.
      <br />
      Art. 19. Revogam-se as disposições em contrário.
    </p>
  </Stack>
);

enum Steps {
  POLICY,
  SELECT_RESERVATION,
  FORM,
  CONFIRMATION,
}

export const ChurrasqueiraConvites = () => {
  const [currentStep, setCurrentStep] = useState<Steps>(Steps.POLICY);
  const [reservaSelecionada, setReservaSelecionada] = useState<any | null>(
    null
  );
  const [saldoConvites, setSaldoConvites] = useState<number | null>(null);
  const [invite, setInvite] = useState<any>(null!);
  const [emittedInvite, setEmittedInvite] = useState<any | null>(null);

  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [selectedInvite, setSelectedInvite] = useState<Invite>(null!);

  const navigate = useNavigate();

  const {
    isOpen: isOpenInvite,
    onOpen: onOpenInvite,
    onClose: onCloseInvite,
  } = useDisclosure();

  const cancelRef: any = useRef();

  const handlePolicyResponse = (agreed: boolean) => {
    if (agreed) {
      setCurrentStep(Steps.SELECT_RESERVATION);
    } else {
      navigate("/", { replace: true });
    }
  };

  const handleInviteSubmitted = (
    res: any,
    dados: ConviteChurrasqueiraDadosConvidado
  ) => {
    // console.log(dados)
    let _invite: Invite = {
      NR_DOC_ESTRANGEIRO: dados.docEstrangeiro,
      codigo: res[0].NR_CONVITE,
      CPF_CONVIDADO: dados.cpfConvidado,
      // DT_NASC_CONVIDADO: dados.dataNascimento,
      DT_NASC_CONVIDADO: moment(dados.dataNascimento.split('/').reverse().join('/').toString().replace('/', '-'), "YYYY-MM-DD").format("MM/DD/YYYY"),
      DT_RETIRADA: dados.dataEmissao,
      NOME_SACADOR: reservaSelecionada.NOME_INTERESSADO,
      DT_MAX_UTILIZACAO: reservaSelecionada.DT_FIM_UTILIZACAO,
      NOME_CONVIDADO: dados.nomeConvidado,
    };
    setInvite(_invite);
    setCurrentStep(Steps.CONFIRMATION);
  };

  const [reservas, setReservas] = useState<any[]>([]);


  const {
    listarReservasChurrasqueira,
    recuperarSaldoConvitesReservaChurrasqueira,
  } = useChurrasqueiraService();

  const { showToast } = useCustomToast();

  const { isLoading, isFetching } = useQuery<any, Error>(
    "listarReservasChurrasqueira",
    async () => {
      return await listarReservasChurrasqueira();
    },
    {
      retry: 5,
      refetchOnWindowFocus: false,
      onSuccess: (res: any) => {
        if (res) {
          setReservas(res);
        }
      },
      onError: () => {
        showToast(
          "error",
          "Não foi possível listar as reservas de churrasqueira."
        );
      },
    }
  );

  const { isLoading: isLoadingSaldo, isFetching: isFetchingSaldo } = useQuery<
    any,
    Error
  >(
    "recuperarSaldoConvitesReservaChurrasqueira",
    async () => {
      return await recuperarSaldoConvitesReservaChurrasqueira(
        reservaSelecionada?.SEQ_RESERVA
      );
    },
    {
      enabled: !!reservaSelecionada,
      retry: 1,
      refetchOnWindowFocus: false,
      onSuccess: (res: any) => {
        if (res && res[0]) {
          setSaldoConvites(res[0].SD_DISPONIVEL);
          if (res[0].SD_DISPONIVEL > 0) {
            setCurrentStep(Steps.FORM);
          } else {
            showToast(
              "error",
              "Saldo de convites esgotado para a reserva selecionada."
            );
          }
        }
      },
      onError: () => {
        showToast(
          "error",
          "Não foi possível obter saldo de convites da reserva selecionada."
        );
      },
    }
  );

  const showLoading = () => {
    return isLoading || isFetching || isLoadingSaldo || isFetchingSaldo;
  };

  const handleReservationClick = (reserva: any) => {
    setReservaSelecionada(reserva);
  };

  const resetFlow = () => {
    setReservaSelecionada(null);
    setCurrentStep(Steps.SELECT_RESERVATION);
  };

  const goToEmittedInvites = () => {
    navigate(ROUTES.CHURRASQUEIRAS_CONVITES_EMITIDOS, { replace: true });
  };

  const showInvite = () => {
    setModalVisible(true);
  };

  switch (currentStep) {
    case Steps.POLICY:
      return (
        <PageLayout>
          <Container maxW="container.md" pt={5} pb={20}>
            <Stack spacing={8}>
              <Breadcrumb
                items={[
                  { route: "/", label: "Início" },
                  { route: ROUTES.INVITES_SELECT, label: "Convites" },
                  {
                    route: ROUTES.CHURRASQUEIRAS_CONVITES,
                    label: "Churrasqueira",
                  },
                  {
                    route: ROUTES.CHURRASQUEIRAS_CONVITES_EMITIR,
                    label: "Emissão de convites",
                  },
                ]}
              />

              <PolicyAgreement
                title={"Termo de uso do seu convite"}
                agreed={handlePolicyResponse}
              >
                <PolicyContentChurrasqueira />
              </PolicyAgreement>
            </Stack>
          </Container>
        </PageLayout>
      );
    case Steps.SELECT_RESERVATION:
      return (
        <PageLayout>
          <Container maxW="container.md" pt={5} pb={20}>
            <Stack spacing={8}>
              <Breadcrumb
                items={[
                  { route: "/", label: "Início" },
                  { route: ROUTES.INVITES_SELECT, label: "Convites" },
                  {
                    route: ROUTES.CHURRASQUEIRAS_CONVITES,
                    label: "Churrasqueira",
                  },
                  {
                    route: ROUTES.CHURRASQUEIRAS_CONVITES_EMITIR,
                    label: "Emissão de convites",
                  },
                ]}
              />
              {showLoading() ? (
                <Flex w="full" alignItems={"center"} justifyContent="center">
                  <Loading />
                </Flex>
              ) : (
                <Stack spacing={6} pt={5}>
                  <Heading fontWeight={500} size={"md"} color="primary">
                    Clique na reserva desejada para emitir convites.
                  </Heading>

                  <ListaDeReservasChurrasqueira
                    reservas={reservas}
                    handleReservaClick={handleReservationClick}
                  />
                </Stack>
              )}{" "}
            </Stack>
          </Container>
        </PageLayout>
      );

    case Steps.FORM:
      return (
        <PageLayout>
          <Container maxW="container.md" pt={5} pb={20}>
            <Stack spacing={8}>
              <Breadcrumb
                items={[
                  { route: "/", label: "Início" },
                  { route: ROUTES.INVITES_SELECT, label: "Convites" },
                  {
                    route: ROUTES.CHURRASQUEIRAS_CONVITES,
                    label: "Churrasqueira",
                  },
                  {
                    route: ROUTES.CHURRASQUEIRAS_CONVITES_EMITIR,
                    label: "Emissão de convites",
                  },
                ]}
              />

              <ChurrasqueiraConviteForm
                reserva={reservaSelecionada}
                inviteSubmitted={handleInviteSubmitted}
                invitesBalance={saldoConvites || 0}
              />
            </Stack>
          </Container>
        </PageLayout>
      );

    case Steps.CONFIRMATION:
      return (
        <PageLayout>
          <Container maxW="container.md" pt={5} pb={20}>
            <Stack spacing={8}>
              <Breadcrumb
                items={[
                  { route: "/", label: "Início" },
                  { route: ROUTES.INVITES_SELECT, label: "Convites" },
                  {
                    route: ROUTES.CHURRASQUEIRAS_CONVITES,
                    label: "Churrasqueira",
                  },
                  {
                    route: ROUTES.CHURRASQUEIRAS_CONVITES_EMITIR,
                    label: "Emissão de convites",
                  },
                ]}
              />

              <Stack
                pt={10}
                spacing={10}
                alignItems={"center"}
                justifyContent={"center"}
              >
                <Image src={convite_emitido_img} alt="Convite emitido" />
                <Heading textAlign={"center"} color="primary">
                  Convite emitido com sucesso
                </Heading>

                <Actions justify="center">
                  <ActionButton variant={"outline"} onClick={resetFlow}>
                    Novo convite
                  </ActionButton>
                  <ActionButton
                    variant={"outline"}
                    onClick={goToEmittedInvites}
                  >
                    Convites emitidos
                  </ActionButton>
                  <ActionButton onClick={showInvite}>Ver convite</ActionButton>
                </Actions>
              </Stack>
            </Stack>
          </Container>

          <OverlayBackdrop
            setModalVisible={setModalVisible}
            modalVisible={modalVisible}
          >
            <Stack>
              <Box borderRadius={10}>
                <InviteTicket invite={invite} isChurrasqueira />
              </Box>
            </Stack>
          </OverlayBackdrop>
        </PageLayout>
      );

    default:
      return <></>;
  }
};

export type ConviteChurrasqueiraDadosConvidado = {
  cpfConvidado: string;
  dataNascimento: string;
  dataEmissao: string;
  nomeConvidado: string;
  docEstrangeiro: string;
  isForeigner?: boolean;
};

interface ChurrasqueiraConviteFormProps {
  inviteSubmitted: (
    res: any,
    dados: ConviteChurrasqueiraDadosConvidado
  ) => void;
  invitesBalance: number;
  reserva: any;
  image?:
    | {
        base: any;
        md: any;
      }
    | any;
}

export const ChurrasqueiraConviteForm = ({
  inviteSubmitted,
  image = defaultImage,
  invitesBalance,
  reserva,
}: ChurrasqueiraConviteFormProps) => {
  const [invites, setInvites] = useState<any[]>([]);
  const [verifySuspension, setVerifySuspension] = useState<boolean>(false);
  const { getIsSuspended } = useProfileService();
  const { listarConvitesDeReservaDeChurrasqueira } = useChurrasqueiraService();
  const {
    isLoading: isLoadingConvites,
    isFetching: isFetchingConvites,
    refetch,
  } = useQuery<any[], Error>(
    "listaConvitesEmitidosChurrasqueira",
    async () => {
      return await listarConvitesDeReservaDeChurrasqueira(reserva?.SEQ_RESERVA);
    },
    {
      enabled: !!reserva,
      refetchOnWindowFocus: false,
      onSuccess: (data: any[]) => {
        let _invites: any[] = [...data];
        setInvites(_invites);
      },
      onError: () => showToast("error", "Erro ao listar convites emitidos."),
    }
  );

  const backgroundImg = useBreakpointValue({
    base: image.base ? image.base : image,
    md: image.md ? image.md : image,
  });

  let imageHeight = useBreakpointValue({
    base: "250px",
    md: "300px",
    lg: "350px",
  });

  const gridCols = 12;

  const { showToast } = useCustomToast();

  const schema = yup.object().shape({
    isForeigner: yup.boolean(),
    // dataNascimento: yup.string().typeError("Data inválida"),
    dataNascimento: yup.string().required(CAMPO_OBRIGATORIO).test("data-invalida", "Data Inválida", (value) => {
      if (value === '') return false;
      if (value?.replace("_", "").length !== 10) return false;
      const dateMask = value?.split('/');
      if (dateMask.length) {
        dateMask.reverse();
        // return moment(dateMask.reverse().join('/').toString().replace('/', '-')).isValid()
        return moment(dateMask.join('-')).isValid()
      }
      return false;
    }),
    cpfConvidado: yup.string().test("cpf-invalido", "CPF inválido", (cpf) => {
      if (cpf == "") return true;
      const formatted = (cpf && cpf.replace(/[^\w\s]/gi, "")) || "";
      return testaCPF(formatted);
    }),
    docEstrangeiro: yup.string(),
    nomeConvidado: yup
      .string()
      .required(CAMPO_OBRIGATORIO)
      .matches(nomeRgx, "Nome inválido"),
  });

  const { emitirConviteChurrasqueira } = useChurrasqueiraService();

  const mutation = useMutation(
    (data: any) => {
      // console.log('entrei em mutation...')
      if (data?.dataNascimento) {
        const dateMask = data.dataNascimento?.split('/');
        data.dataNascimento = moment(dateMask.reverse().join('/').toString().replace('/', '-'), "YYYY-MM-DD").format("MM/DD/YYYY");
        // console.log('data formatada ', data.dataNascimento)
        // data.dataNascimento = moment(data.dataNascimento,  "YYYY-MM-DD").format("MM/DD/YYYY");
      }
      if (data.cpfConvidado) {
        data.cpfConvidado = data.cpfConvidado.replace(/[^\w\s]/gi, "");
      }
      if (data.cpfConvidado == "") data.cpfConvidado = null;
      if (data.docEstrangeiro == "") data.docEstrangeiro = null;
      return emitirConviteChurrasqueira(reserva.SEQ_RESERVA, data);
    },
    {
      onSuccess: (res: any) => {
        if (res) {
          if (res[0].MSG !== "OK") {
            showToast("error", res[0].MSG);
            return;
          }

          showToast("success", "Convite emitido!");
          inviteSubmitted(res, getValues());
        }
      },
      onError: (err: any) => {
        showToast("error", "Não foi possível emitir o convite.");
      },
    }
  );

  const displayForeignerDocField = (): boolean => {
    return watch("isForeigner") || false;
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    trigger,
    control,
    watch,
  } = useForm<ConviteChurrasqueiraDadosConvidado>({
    mode: "onBlur",
    reValidateMode: "onChange",
    resolver: yupResolver(schema),
  });

  const {
    isOpen: isOpenEightInvitesAlert,
    onOpen: onOpenEightInvitesAlert,
    onClose: onCloseEightInvitesAlert,
  } = useDisclosure();
  const cancelRef = useRef<any>(null!);

  const emittedEightInvites = (): boolean => {
    const isNocturnal = reserva && reserva.HH_INIC_UTIL == "1800";
    return isNocturnal && invites && invites.length >= 8;
  };

  const { isLoading: isLoadingSuspensao, isFetching: isFetchingSuspensao } =
    useQuery<any, Error>(
      "verificaSuspensao",
      async () => {
        return await getIsSuspended();
      },
      {
        enabled: verifySuspension,
        retry: 5,
        refetchOnWindowFocus: false,
        onSuccess: (res: any) => {
          if (res && res.data[0].IC_DIREITO_SUSPENSO !== "N") {
            showToast(
              "error",
              "Direitos suspensos conforme inciso II do art. 57 do Estatuto do Clube."
            );
          } else {
            if (emittedEightInvites()) {
              onOpenEightInvitesAlert();
            } else {
              mutation.mutate(getValues());
            }
          }
          setVerifySuspension(false);
        },
      }
    );

  const onSubmit = handleSubmit((data: ConviteChurrasqueiraDadosConvidado) => {
    //validacoes solicitadas pelo cliente

    const documento = data.isForeigner
      ? data.docEstrangeiro
      : data.cpfConvidado;
    const documentoNome = data.isForeigner ? "Doc. Estrangeiro" : "CPF";

    if (!documento && !data.dataNascimento) {
      showToast(
        "error",
        `Preencha o ${documentoNome} ou a Data de Nascimento.`
      );
      return;
    }

    if (data.dataNascimento) {
      
      // console.log("data de nascimento post...", data)
      const dataFormated = data.dataNascimento.split('/').reverse()
      const dataMoment = moment(dataFormated.toString().replace('/','-'), "YYYY-MM-DD");

      const guestIsYoungerThan12yo = dataMoment.isAfter(
        moment().subtract(12, "y")
      );

      if (guestIsYoungerThan12yo) {
        showToast(
          "error",
          `Convidados com até 12 anos não precisam de convite.`
        );
        return;
      }

      const guestIsOlderThan18yo = dataMoment.isSameOrBefore(
        moment().subtract(18, "y")
      );

      if (guestIsOlderThan18yo && !documento) {
        showToast("error", `Necessário preencher o ${documentoNome}`);
        return;
      }
    }
    //verificar se está suspenso
    setVerifySuspension(true);
  });

  const confirmInviteAlert = () => {
    onCloseEightInvitesAlert();
    mutation.mutate(getValues());
  };

  return (
    <Flex direction="column" justifyContent="center" paddingTop={4}>
      <ImageBanner src={backgroundImg} height={imageHeight} alt="" />
      <Container maxW="container.md" paddingTop={"30px"} pb={20}>
        <Stack mb={9}>
          <Heading size="lg" color="primary" fontWeight="500">
            Preencha os campos abaixo
          </Heading>
          <small>
            Complete todos os dados com CPF OU Documeto de Pessoa Estrangeira{" "}
            <br />
            Para convidados com idade menor que 18 anos, é necessário informar
            CPF ou Doc. Estrangeiro.
          </small>
        </Stack>
        <HStack mb={9}>
          <Image src={inviteIcon} />
          <Heading size="sm" fontWeight="400" color="gray.500">
            {Number(invitesBalance) > 1
              ? `${invitesBalance} convites restantes`
              : `${invitesBalance} convite restante`}
          </Heading>
        </HStack>
        <Box pb={7}>
          <form onSubmit={onSubmit} id="invite-emission-form">
            <SimpleGrid columns={12} columnGap={4} rowGap={4}>
              <GridItem colSpan={12}>
                <FormControl isInvalid={!!errors?.nomeConvidado?.message}>
                  <FormLabel fontWeight="400" color="gray.500">
                    Nome do convidado
                  </FormLabel>
                  <Input {...register("nomeConvidado")} type="text" />
                  <FormErrorMessage>
                    {errors?.nomeConvidado?.message}
                  </FormErrorMessage>
                </FormControl>
              </GridItem>

              <GridItem colSpan={12}>
                <FormControl isInvalid={!!errors?.dataNascimento}>
                  <FormLabel fontWeight="400" color="gray.500">
                    Data de nascimento
                  </FormLabel>
                  <Controller
                      control={control}
                      name="dataNascimento"
                      defaultValue={""}
                      render={({ field }) => (
                        <ReactInputDateMask
                            mask="99/99/9999"
                            value={field.value}
                            onChange={field.onChange}
                          >
                            {(inputProps: any) => (
                              <Input {...inputProps} type="tel" />
                            )}
                          </ReactInputDateMask>
                        )}
                      />
                  <FormErrorMessage>
                    {errors?.dataNascimento?.type === 'data-invalida' &&
                      errors.dataNascimento.message}
                  </FormErrorMessage>
                </FormControl>
              </GridItem>

              <GridItem colSpan={12}>
                <FormControl>
                  <Stack spacing={5} direction="row">
                    <Checkbox
                      {...register("isForeigner")}
                      onBlur={() => trigger(["docEstrangeiro", "cpfConvidado"])}
                    >
                      Pessoa estrangeira
                    </Checkbox>
                  </Stack>
                </FormControl>
              </GridItem>

              {displayForeignerDocField() ? (
                <>
                  <GridItem colSpan={12}>
                    <FormControl
                      isInvalid={
                        errors?.cpfConvidado?.type == "required" ||
                        !!errors?.docEstrangeiro
                      }
                    >
                      <FormLabel fontWeight="400" color="gray.500">
                        Doc. Estrangeiro
                      </FormLabel>
                      <Input
                        {...register("docEstrangeiro")}
                        type="text"
                        onBlur={() =>
                          trigger(["docEstrangeiro", "cpfConvidado"])
                        }
                      />
                    </FormControl>
                  </GridItem>
                </>
              ) : (
                <>
                  <GridItem colSpan={12}>
                    <FormControl
                      isInvalid={
                        !!errors?.cpfConvidado || !!errors?.docEstrangeiro
                      }
                    >
                      <FormLabel fontWeight="400" color="gray.500">
                        CPF
                      </FormLabel>

                      <Controller
                        control={control}
                        name="cpfConvidado"
                        defaultValue={""}
                        render={({ field }) => (
                          <MaskedInput
                            mask="999.999.999-99"
                            value={field.value}
                            onChange={field.onChange}
                            onBlur={() =>
                              trigger(["docEstrangeiro", "cpfConvidado"])
                            }
                          >
                            {(inputProps: any) => (
                              <Input {...inputProps} type="text" />
                            )}
                          </MaskedInput>
                        )}
                      />
                      <FormErrorMessage>
                        {errors?.cpfConvidado?.type == "cpf-invalido" &&
                          errors.cpfConvidado.message}
                      </FormErrorMessage>
                    </FormControl>
                  </GridItem>
                </>
              )}

              <GridItem colSpan={gridCols}>
                <FormControl
                  isInvalid={
                    errors?.cpfConvidado?.type == "required" ||
                    !!errors?.docEstrangeiro
                  }
                >
                  <FormErrorMessage>
                    {displayForeignerDocField()
                      ? "Necessário preencher o Doc. Estrangeiro."
                      : "Necessário preencher o CPF."}
                  </FormErrorMessage>
                </FormControl>
              </GridItem>
            </SimpleGrid>
          </form>
        </Box>

        <Actions>
          <Link to={`/`}>
            <ActionButton variant="outline">Cancelar</ActionButton>
          </Link>

          <ActionButton
            type="submit"
            form="invite-emission-form"
            loadingText="Processando..."
            isLoading={
              mutation.isLoading || isLoadingSuspensao || isFetchingSuspensao
            }
          >
            Solicitar convite
          </ActionButton>
        </Actions>
      </Container>

      <AlertDialog
        size="sm"
        isOpen={isOpenEightInvitesAlert}
        leastDestructiveRef={cancelRef}
        onClose={onOpenEightInvitesAlert}
        isCentered
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              <AlertDialogCloseButton />
            </AlertDialogHeader>

            <AlertDialogBody py={10}>
              <Stack
                spacing={2}
                justifyContent={"center"}
                alignItems={"center"}
                w="full"
              >
                <Text textAlign="justify">
                  A utilização de churrasqueira após às 18 (dezoito) horas, em
                  que haja a presença de mais de 8 (oito) convidados, implica na
                  contratação de serviços previstos na norma de uso que serão
                  debitados no boleto do sócio responsável. Autoriza o
                  lançamento?
                </Text>
              </Stack>
            </AlertDialogBody>
            <AlertDialogFooter>
              <Actions>
                <ActionButton
                  marginRight={"1rem"}
                  minW={["full", "fit-content"]}
                  onClick={confirmInviteAlert}
                >
                  Sim
                </ActionButton>
                <ActionButton
                  minW={["full", "fit-content"]}
                  variant={"outline"}
                  onClick={onCloseEightInvitesAlert}
                >
                  Não
                </ActionButton>
              </Actions>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Flex>
  );
};
