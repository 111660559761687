export function currency(value, cypher = true) {
  if (typeof value !== "number") return;
  var parsed = "";
  if (cypher) {
    parsed = value.toLocaleString("pt-br", {
      style: "currency",
      currency: "BRL",
    });
  } else {
    parsed = value.toLocaleString("pt-br", { minimumFractionDigits: 2 });
  }
  return parsed;
}
