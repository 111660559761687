import { ENDPOINTS } from "../constants/endpoints";
import useAxios from "../hooks/useAxios";
import { LostAndFoundItem } from "../models/lostAndFound.model";

export function useLostAndFoundService() {
  const axios = useAxios();

  const searchAll = async () => {
    let response = await axios.post(ENDPOINTS.searchAll);
    return response.data;
  };
  const searchByLocation = async (id: any) => {
    let response = await axios.post(ENDPOINTS.searchByLocation(id));
    return response.data;
  };

  const searchByCategory = async (id: any) => {
    let response = await axios.post(ENDPOINTS.searchByCategory(id));
    return response.data;
  };

  const searchByTerms = async (terms: string) => {
    let response = await axios.post(ENDPOINTS.searchByTerms(terms));
    return response.data;
  };

  const getCategories = async () => {
    let response = await axios.get(ENDPOINTS.getCategories);
    return response.data;
  };

  const getLocations = async () => {
    let response = await axios.get(ENDPOINTS.getLocations);
    return response.data;
  };


  const services = {
    getCategories,
    getLocations,
    searchAll,
    searchByCategory,
    searchByLocation,
    searchByTerms
  };

  return services;
}
