// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".FileUploader_fileUploader__1xABm label {\n  background-color: #0d4f7b;\n  color: white;\n  padding: 0.5rem 1rem;\n  font-weight: 500;\n  border-radius: 8px;\n  cursor: pointer;\n}", "",{"version":3,"sources":["webpack://src/components/FileUploader/FileUploader.module.scss","webpack://src/styles/variables.scss"],"names":[],"mappings":"AAGI;EACI,yBCHE;EDIF,YAAA;EACA,oBAAA;EACA,gBAAA;EACA,kBAAA;EACA,eAAA;AAFR","sourcesContent":["@import \"../../styles/variables.scss\";\r\n\r\n.fileUploader{\r\n    label {\r\n        background-color: $primary;\r\n        color: white;\r\n        padding: 0.5rem 1rem;\r\n        font-weight: 500;\r\n        border-radius: 8px;\r\n        cursor: pointer;\r\n      }\r\n}","// $primary: #003E66;\r\n$primary: #0d4f7b;\r\n$danger: #F03D3E;\r\n$success: #44AC80;\r\n$white: #FFFFFF;\r\n\r\n$border-radius: 8px;\r\n$navbar-height: 50px;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"fileUploader": "FileUploader_fileUploader__1xABm"
};
export default ___CSS_LOADER_EXPORT___;
