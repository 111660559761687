import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import reportWebVitals from "./reportWebVitals";
import Router from "./config/Router";
import { ChakraProvider } from "@chakra-ui/react";
import theme from "./theme-config";
import { QueryClient, QueryClientProvider } from "react-query";
import AuthProvider from "./context/AuthContext";
import ProfileProvider from "./context/ProfileContext";
import 'react-responsive-modal/styles.css';

const queryClient = new QueryClient();

ReactDOM.render(
  <React.StrictMode>
    <AuthProvider>
      <QueryClientProvider client={queryClient}>
        <ChakraProvider theme={theme}>
          <ProfileProvider>
              <Router />
          </ProfileProvider>
        </ChakraProvider>
      </QueryClientProvider>
    </AuthProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
