import moment from "moment";
import { ENDPOINTS } from "../constants/endpoints";
import useAxios from "../hooks/useAxios";
import { userManager } from "./authManager";

export function useFinancialService() {
  const axios = useAxios();

  const getSlips = async () => {
    const response = await axios.get(ENDPOINTS.getSlips);
    return response.data;
  };

  const getSlipDetails = async (id: number) => {
    const response = await axios.get(ENDPOINTS.getSlipDetails, {
      params: {
        seqCarne: id
      }
    });
    return response.data;
  };

  const requestSlipCopy = async (id: number) => {
    const response = await axios.post(ENDPOINTS.requestSlipCopy, { iden: id });
    return response.data;
  }

  const getBillings = async () => {
    const usuario = userManager.get();
    if (!usuario) {
      throw new Error('Nenhum usuário logado!')
    }
    const response = await axios.get(ENDPOINTS.getBillings, {
      params: {
        usuario: usuario.USER_ACESSO_SISTEMA
      }
    });
    return response.data.map((jsonApi: {
      VENCIMENTO: string;
      VALOR: number;
      SEQ_CARNE: string;
      CD_SIT_CARNE: string;
    }) => {
      const data = moment(jsonApi.VENCIMENTO, 'DD/MM/YYYY')
      return {
        id: jsonApi.SEQ_CARNE,
        vencimento: jsonApi.VENCIMENTO,
        ano: data.isValid() ? data.format('YYYY') : '',
        mes: data.isValid() ? data.format('MM') : '',
        valor: jsonApi.VALOR,
        status: jsonApi.CD_SIT_CARNE === 'PG' ? 'QUITADA' : 'PENDENTE',
        isLancamentosFuturos: jsonApi.VENCIMENTO === 'Lançamentos Futuros'
      }
    });
  };

  const getBillingDetails = async (id: number) => {
    const response = await axios.request<{
      NOME_PESSOA: string;
      CD_MATRICULA: string;
      CD_CATEGORIA: string;
      DT_VENC_CARNE: string;
      VR_ORIGINAL: number;
      VR_CREDITO: number;
      VR_ENCARGOS: number;
      VR_TOTAL: number;
      CD_SIT_CARNE: string
    }[]>({
      url: ENDPOINTS.getBillingDetails,
      params: {
        seqParcela: id
      },
      method: "POST"
    });
    const jsonApi = response.data[0];
    return {
      titulo: `${jsonApi.CD_CATEGORIA.toString().padStart(2, '0')}/${jsonApi.CD_MATRICULA} - ${jsonApi.NOME_PESSOA}`,
      vencimento: moment(jsonApi.DT_VENC_CARNE).format('DD/MM/YYYY'),
      valorOriginal: jsonApi.VR_ORIGINAL,
      creditos: jsonApi.VR_CREDITO,
      encargos: jsonApi.VR_ENCARGOS,
      valorTotal: jsonApi.VR_TOTAL,
      status: jsonApi.CD_SIT_CARNE === 'PG' ? 'QUITADA' : 'PENDENTE',
    }
  };

  const getBillingDetailsInstallments = async (id: number) => {
    const response = await axios.get<{
      VALOR: number;
      DESCRICAO: string;
      NOME: string;
      DATA: string;
    }[]>(ENDPOINTS.getBillingDetailsInstallments, {
      params: {
        seqParcela: id
      }
    });
    return response.data.map(jsonApi => (
      {
        valor: jsonApi.VALOR,
        descricao: jsonApi.DESCRICAO,
        nome: jsonApi.NOME,
        data: moment(jsonApi.DATA).format('DD/MM/YYYY')
      }
    ));
  };

  const downloadBillingReport = async (id: number) => {
    try {
      const response = await axios.get(ENDPOINTS.downloadBillingReport, {
        params: {
          seqCarne: id
        },
        responseType: 'arraybuffer',
      });
      return response;
    } catch (error) {
      throw error;
    }
  };

  const getFutureBillingDetails = async () => {
    const usuario = userManager.get();
    if (!usuario) {
      throw new Error('Nenhum usuário logado!')
    }
    const response = await axios.get<{
      NOME: string;
      DESCRICAO: string;
      DATA: string;
      AA_COBRANCA: number;
      MM_COBRANCA: number;
      VR_TAXA: number;
    }[]>(ENDPOINTS.getFutureBillingsDetails, {
      params: {
        usuario: usuario.USER_ACESSO_SISTEMA
      }
    });
    return response.data.map(jsonApi => (
      {
        nome: jsonApi.NOME,
        descricao: jsonApi.DESCRICAO,
        data: jsonApi.DATA,
        anoCobranca: jsonApi.AA_COBRANCA,
        mesCobranca: jsonApi.MM_COBRANCA,
        valor: jsonApi.VR_TAXA,
      }
    ));
  };

  const getDebtPaymentTerms = async () => {
    const response = await axios.get(ENDPOINTS.getDebtPaymentTerms);
    return response.data;
  };

  const getDeclarationOfDischarge = async (year: string, ip: string) => {
    const usuario = userManager.get();
    if (!usuario) {
      throw new Error('Nenhum usuário logado!')
    }

    const response = await axios(ENDPOINTS.getDeclarationOfDischarge, {
      params: {
        aaReferencia: year,
        usuario: usuario.USER_ACESSO_SISTEMA,
        ip
      },
      method: "POST"
    });
    if (response.data[0].MSG === 'NAO FOI POSSIVEL EMITIR A DECLARACAO. FAVOR ENTRAR EM CONTATO COM A TESOURARIA DO CLUBE') {
      throw new Error(response.data[0].MSG);
    }
    return response.data[0];
  };


  const services = {
    getSlips,
    getSlipDetails,
    requestSlipCopy,
    getBillings,
    downloadBillingReport,
    getBillingDetails,
    getBillingDetailsInstallments,
    getFutureBillingDetails,
    getDebtPaymentTerms,
    getDeclarationOfDischarge
  };

  return services;
}
