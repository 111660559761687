import { ChevronLeftIcon } from "@chakra-ui/icons";
import {
  Container,
  Flex,
  Heading,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useBreakpointValue,
  VStack,
} from "@chakra-ui/react";
import { useState } from "react";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import Breadcrumb from "../../../components/Breadcrumb/Breadcrumb";
import Loading from "../../../components/Loading/Loading";
import PageLayout from "../../../components/PageLayout/PageLayout";
import { PageTitle } from "../../../components/PageTitle/PageTitle";
import { ROUTES } from "../../../constants/routes";
import { currency } from "../../../helpers/currency";
import useCustomToast from "../../../hooks/useCustomToast";
import { FutureBilling } from "../../../models/financial.model";
import { useFinancialService } from "../../../services/financial.service";
import styles from "./FutureBillings.module.scss";

export default function FutureBillings() {
  const [futureBillings, setFutureBillings] = useState<FutureBilling[]>();
  const { getFutureBillingDetails } = useFinancialService();
  const { showToast } = useCustomToast();

  const query = useQuery<FutureBilling[], Error>(
    "listaLancamentosFuturos",
    async () => {
      return await getFutureBillingDetails();
    },
    {
      refetchOnWindowFocus: false,
      onSuccess: (futureBillings: FutureBilling[]) => {
        setFutureBillings(futureBillings);
      },
      onError: () =>
        showToast("error", "Não foi possível listar os lançamentos futuros."),
    }
  );

  const tableSize = useBreakpointValue({ base: "sm", md: "md" });

  return (
    <PageLayout noPaddingX>
      <Container maxW="container.md" pt={5} pb={20}>
        <Stack spacing={5}>
          <Breadcrumb
            items={[
              { route: "/", label: "Início" },
              { route: ROUTES.FINANCIAL, label: "Financeiro" },
              {
                route: ROUTES.FINANCIAL_BILLINGS_DETAILS,
                label: "Fatura",
              },
              {
                route: `${ROUTES.FINANCIAL_FUTURE_BILLINGS}`,
                label: "Lançamentos Futuros",
              },
            ]}
          />
          <Link className={styles.link} to={ROUTES.FINANCIAL_BILLINGS_DETAILS}>
            <ChevronLeftIcon className={styles.icons} />
            Voltar
          </Link>

          <PageTitle>Lançamentos Futuros</PageTitle>

          {query.isLoading || query.isFetching ? (
            <Flex w="full" justifyContent="center">
              <Loading />
            </Flex>
          ) : (
            <>
              {futureBillings && futureBillings.length > 0 ? (
                <VStack overflowX="auto" alignItems="normal">
                  <Heading size="sm">Parcelas</Heading>
                  <Table
                    variant="striped"
                    size={tableSize}
                    className={styles.table}
                  >
                    <Thead>
                      <Tr>
                        <Th>Nome</Th>
                        <Th>Descrição</Th>
                        <Th>Data Cobrança</Th>
                        <Th isNumeric>Valor</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {futureBillings.map((x: FutureBilling, i) => (
                        <Tr key={i}>
                          <Td>{x.nome}</Td>
                          <Td>{x.descricao}</Td>
                          <Td>{`${x.mesCobranca}/${x.anoCobranca}`}</Td>
                          <Td isNumeric>{currency(x.valor)}</Td>
                        </Tr>
                      ))}
                    </Tbody>
                  </Table>
                </VStack>
              ) : (
                <Text color="red">Sem informações de lançamentos futuros</Text>
              )}
            </>
          )}
        </Stack>
      </Container>
    </PageLayout>
  );
}
