import { ENDPOINTS } from "../constants/endpoints";
import useAxios from "../hooks/useAxios";
import { Manifest } from "../models/onbudsman.model";

export function useOnbudsmanService() {
  const axios = useAxios();
  const axios_formData = useAxios("multipart/form-data");

  const getSubjectsAndSubSubjects = async () => {
    const response = await axios.get<any[]>(
      `${ENDPOINTS.getSubjectsAndSubSubjects}`
    );
    return response.data;
  };

  const getOnbudsmanClassification = async () => {
    const response = await axios.get<any[]>(
      `${ENDPOINTS.getOnbudsmanClassification}`
    );
    return response.data;
  };
  const getManifests = async () => {
    const response = await axios.get<Manifest[]>(`${ENDPOINTS.getManifests}`);
    return response.data;
  };

  const registerManifest = async (data: Manifest, files: File[]) => {
    const body = new FormData();
    body.append("envolvidos", data.envolvidos);
    body.append("descricao", data.descricao);
    body.append("classificacao", data.classificacao);
    body.append("assunto", JSON.parse(data.assunto).iden);
    data.subAssunto && body.append("subassunto", data.subAssunto);

    files.map((file) => {
      body.append("arquivos", file, file.name);
    });

    const response = await axios_formData.post<any>(
      `${ENDPOINTS.registerManifest}`,
      body
    );
    return response.data;
  };

  const services = {
    getSubjectsAndSubSubjects,
    getOnbudsmanClassification,
    registerManifest,
    getManifests,
  };

  return services;
}
