// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Home_logoContainer__24rF_ {\n  height: calc(100vh - 150px);\n}\n\n.Home_logo__WoKTO {\n  filter: opacity(0.7) grayscale(20%);\n}\n\n.Home_imagens__3M7a0 {\n  object-fit: cover;\n  height: 225px;\n  max-width: 100%;\n}", "",{"version":3,"sources":["webpack://src/pages/Home/Home.module.scss"],"names":[],"mappings":"AAEA;EACI,2BAAA;AADJ;;AAIA;EACI,mCAAA;AADJ;;AAIA;EACI,iBAAA;EACA,aAAA;EACA,eAAA;AADJ","sourcesContent":["@import \"../../styles/variables.scss\";\r\n\r\n.logoContainer {\r\n    height: calc(100vh - 150px);\r\n}\r\n\r\n.logo {\r\n    filter: opacity(0.7) grayscale(20%);\r\n}\r\n\r\n.imagens {\r\n    object-fit: cover;\r\n    height: 225px;\r\n    max-width: 100%;\r\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"logoContainer": "Home_logoContainer__24rF_",
	"logo": "Home_logo__WoKTO",
	"imagens": "Home_imagens__3M7a0"
};
export default ___CSS_LOADER_EXPORT___;
