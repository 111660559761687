import { Button, ButtonGroup } from "@chakra-ui/react";

export interface IGroupedButtonsProps {
  options: string[];
  active: number;
  size?: "sm" | "md" | "lg" | "xl"
  change: (newActive: number) => void;
}

export default function GroupedButtons({
  active = 0,
  change,
  options,
  size='sm',
  ...props
}: IGroupedButtonsProps) {
  return (
    <ButtonGroup size={size} isAttached variant="outline" {...props}>
      {options &&
        options.map((opt, index) => {
          return (
            <Button
              key={index}
              onClick={() => change(index)}
              variant={active === index ? "primary" : "outline"}
              mr="-px"
            >
              {opt}
            </Button>
          );
        })}
    </ButtonGroup>
  );
}
