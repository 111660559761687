// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".IconButton_button__1BPZn {\n  background: transparent;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  height: -moz-fit-content;\n  height: fit-content;\n  width: -moz-fit-content;\n  width: fit-content;\n  cursor: pointer;\n}", "",{"version":3,"sources":["webpack://src/components/IconButton/IconButton.module.scss"],"names":[],"mappings":"AAAA;EACI,uBAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,wBAAA;EAAA,mBAAA;EACA,uBAAA;EAAA,kBAAA;EACA,eAAA;AACJ","sourcesContent":[".button{\r\n    background: transparent;\r\n    display:flex;\r\n    justify-content: center;\r\n    align-items: center;\r\n    height: fit-content;\r\n    width: fit-content;\r\n    cursor: pointer;\r\n\r\n \r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": "IconButton_button__1BPZn"
};
export default ___CSS_LOADER_EXPORT___;
