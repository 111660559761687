import { ChevronLeftIcon } from "@chakra-ui/icons";
import {
  Box,
  Container,
  Flex,
  Stack,
  useBreakpointValue,
} from "@chakra-ui/react";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { useState } from "react";
import { FaPrint } from "react-icons/fa";
import { useQuery } from "react-query";
import { Link, useNavigate, useParams } from "react-router-dom";
import ActionButton from "../../../../components/ActionButton/ActionButton";
import Actions from "../../../../components/Actions/Actions";
import Breadcrumb from "../../../../components/Breadcrumb/Breadcrumb";
import Loading from "../../../../components/Loading/Loading";
import PageLayout from "../../../../components/PageLayout/PageLayout";
import { PageTitle } from "../../../../components/PageTitle/PageTitle";
import { ROUTES } from "../../../../constants/routes";
import useCustomToast from "../../../../hooks/useCustomToast";
import { DeclarationOfDischarge } from "../../../../models/financial.model";
import { useFinancialService } from "../../../../services/financial.service";
import styles from "./DeclarationOfDischargeDetails.module.scss";

export default function DeclarationOfDischargeDetails() {
  const [declaration, setDeclaration] = useState<DeclarationOfDischarge>();
  const { ano } = useParams();
  const mockContainerHeight = useBreakpointValue({
    base: "400px",
    md: "500px",
    lg: "600px",
  });
  const { getDeclarationOfDischarge } = useFinancialService();
  const { showToast } = useCustomToast();
  const navigate = useNavigate();

  const query = useQuery<DeclarationOfDischarge, Error>(
    "getDeclaracaoDeQuitacao",
    async () => {
      return await getDeclarationOfDischarge(ano!, "");
    },
    {
      refetchOnWindowFocus: false,
      retry: false,
      onSuccess: (declaration: DeclarationOfDischarge) => {
        setDeclaration(declaration);
      },
      onError: (error) => {
        showToast("error", error.message);
        navigate(-1);
      },
    }
  );

  const downloadPdf = () => {
    const input = document.getElementById("print-pdf-quitacao");

    html2canvas(input!).then((canvas) => {
      const imgData: any = canvas.toDataURL("image/png");
      const pdf = new jsPDF({
        orientation: "portrait",
      });
      const imgProps = pdf.getImageProperties(imgData);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
      pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight, "", "SLOW");
      if (window.ReactNativeWebView) {
        window.ReactNativeWebView.postMessage(pdf.output("datauristring"));
      } else {
        pdf.save(`Declaração de Quitação - ${ano}`);
      }
    });
  };

  return (
    <PageLayout noPaddingX>
      <Container maxW="container.md" pt={5} pb={20}>
        <Stack spacing={8}>
          <Breadcrumb
            items={[
              { route: "/", label: "Início" },
              { route: ROUTES.FINANCIAL, label: "Financeiro" },
              {
                route: ROUTES.FINANCIAL_DECLARATION_OF_DISCHARGE,
                label: "Declaração de Quitação",
              },
              {
                route: `${ROUTES.FINANCIAL_DECLARATION_OF_DISCHARGE}/${ano}`,
                label: ano!,
              },
            ]}
          />
          <Flex w="full" justifyContent={"space-between"}>
            <Link
              className={styles.link}
              to={ROUTES.FINANCIAL_DECLARATION_OF_DISCHARGE}
            >
              <ChevronLeftIcon className={styles.icons} />
              Voltar
            </Link>
            {/* <ActionButton variant={"outline"}><FiShare2 className={styles.icons}/></ActionButton> */}
          </Flex>
          {query.isLoading || query.isFetching ? (
            <Flex w="full" justifyContent="center">
              <Loading />
            </Flex>
          ) : (
            declaration && (
              <>
                <PageTitle>Declaração de Quitação - {ano}</PageTitle>
                <Box
                  id="print-pdf-quitacao"
                  className={styles.mocKDeclaracao}
                  h={mockContainerHeight}
                >
                  <span>{declaration.MSG}</span>
                  <span>Autenticação:</span>
                  <span>
                    {declaration.CD_AUTENTICACAO} -{" "}
                    {declaration.HASH_AUTENTICACAO}
                  </span>
                </Box>
                <Actions justify="center">
                  <ActionButton onClick={downloadPdf} leftIcon={<FaPrint />}>
                    Imprimir
                  </ActionButton>
                </Actions>
              </>
            )
          )}
        </Stack>
      </Container>
    </PageLayout>
  );
}
