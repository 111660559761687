import financeiro from "./../assets/icons/financeiro.svg";
import reservas from "./../assets/icons/reservas.svg";
import convites from "./../assets/icons/convites.svg";
import nautica from "./../assets/icons/nautica.svg";
import achados_e_perdidos from "./../assets/icons/achados_e_perdidos.svg";
import ouvidoria from "./../assets/icons/ouvidoria.svg";
import portal_da_transparencia from "./../assets/icons/portal_da_transparencia.svg";
import contato_e_redes_sociais from "./../assets/icons/contato_e_redes_sociais.svg";
import {ROUTES} from "./routes";

export interface Menu{
    icon: any,
    text: string,
    route?: string,
    children?: Menu[],
	permission?: number[]
}

export const MENUS: Menu[] = [
    {route: '/', icon: financeiro, text: 'Início'},
    {route: ROUTES.FINANCIAL, icon: financeiro, text: 'Financeiro', permission:[130,190]},
    {route: ROUTES.RESERVATIONS, icon: reservas, text: 'Reservas', permission:[110,150]},
    {route: ROUTES.INVITES_SELECT, icon: reservas, text: 'Convites', permission:[120]},
    // {icon: convites, text: 'Convites', children: [
    //     {route: ROUTES.INVITES, icon: reservas, text: 'Geral'},
    //     {route: ROUTES.CHURRASQUEIRAS_CONVITES, icon: reservas, text: 'Churrasqueira'},
    // ]},
    {route: ROUTES.LOST_AND_FOUND, icon: achados_e_perdidos, text: 'Achados e perdidos'},
    {route: ROUTES.ONBUDSMAN, icon: ouvidoria, text: 'Ouvidoria'},
    {route: ROUTES.TRANSPARENCY, icon: portal_da_transparencia, text: 'Portal da transparência'},
    // {route: ROUTES.NAUTICAL, icon: nautica, text: 'Náutica'},
    {route: ROUTES.CONTACT, icon: contato_e_redes_sociais, text: 'Contato e redes sociais'},
]