import { Box, useBreakpoint, useBreakpointValue } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import BackToTop from "../BackToTop/BackToTop";
import styles from "./PageLayout.module.scss";

interface PageLayoutProps {
  children: React.ReactNode;
  noPaddingX?: boolean;
}

const PageLayout = ({ noPaddingX = false, children }: PageLayoutProps) => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };
  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const showBackToTop = useBreakpointValue({base: false, lg: true});

  return (
    <Box paddingX={noPaddingX ? 0 : ["1rem"]} className={styles.pageLayout}>
      {children}
      {
       showBackToTop && scrollPosition > 300 ? <BackToTop/> : <></>
      }
    </Box>
  );
};

export default PageLayout;
