import { Container, Stack } from "@chakra-ui/react";
import icon_academia from "../../assets/icons/academia.svg";
import icon_calendario from "../../assets/icons/calendario.svg";
import icon_churrasqueira from "../../assets/icons/churrasqueira.svg";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import LinkBox from "../../components/LinkBox/LinkBox";
import PageLayout from "../../components/PageLayout/PageLayout";
import { ROUTES } from "../../constants/routes";
import { userManager } from "../../services/authManager";


const Reservations = () => {
  const user = userManager.get();
  return (
    <PageLayout>
      <Container maxW="container.md" pt={5} pb={20}>
        <Stack spacing={8}>
          <Breadcrumb
            items={[
              { route: "/", label: "Início" },
              { route: ROUTES.RESERVATIONS, label: "Reservas" },
            ]}
          />
         <Stack spacing={5}>
            {user.CD_APLICACAO.includes(110) && 
              <LinkBox
                leftIcon={icon_churrasqueira}
                label="Churrasqueira"
                to={ROUTES.RESERVATIONS_BARBECUE}
              />
            }
            {user.CD_APLICACAO.includes(150) && 
              <LinkBox
                leftIcon={icon_academia}
                label="Academia"
                to={ROUTES.RESERVATIONS_GYM_MODALITIES}
              />
            }
            <LinkBox
              leftIcon={icon_calendario}
              label="Reservas realizadas"
              to={ROUTES.RESERVATIONS_HISTORY}
            />
            {/* <LinkBox
              leftIcon={icon_convites_tickets}
              label="Convites da churrasqueira"
              to={ROUTES.RESERVATIONS_BARBECUE_INVITES}
            /> */}
          </Stack>
        </Stack>
      </Container>
    </PageLayout>
  );
};

export default Reservations;
