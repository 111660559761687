import { Avatar, Container, Stack, Text } from "@chakra-ui/react";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import LinkBox from "../../components/LinkBox/LinkBox";
import PageLayout from "../../components/PageLayout/PageLayout";
import styles from "./Profile.module.scss";
import { AiOutlineUser, AiOutlineUsergroupAdd } from "react-icons/ai";
import { RiLockPasswordLine } from "react-icons/ri";
import { Outlet, useLocation } from "react-router-dom";
import { ROUTES } from "../../constants/routes";
import useProfile from "../../hooks/useProfile";
import Loading from "../../components/Loading/Loading";
import { useEffect } from "react";

const Profile = () => {
  const { user, isLoading, refetch } = useProfile();
  const location = useLocation();

  useEffect(() => {
    refetch()
  }, [])
    
  return (
    <PageLayout>
      <Container maxW="container.md" pt={5} pb={20}>
        <Breadcrumb
          items={[
            { route: "/", label: "Início" },
            { route: "/perfil", label: "Perfil" },
          ]}
        />
        {isLoading ? (
          <Stack spacing={6} alignItems="center">
            <Loading />
            <Text>Carregando perfil...</Text>
          </Stack>
        ) : (
          <Stack spacing={8} pt={10}>
            <div className={styles.avatar}>
              <Avatar
                size="2xl"
                name={user?.NOME_PESSOA}
              />
            </div>

            {location.pathname === "/perfil" || location.pathname === "/perfil/" ? (
              <Stack spacing={5}>
                <LinkBox
                  to={ROUTES.EDIT_PROFILE}
                  leftIcon={AiOutlineUser}
                  label="Editar perfil"
                />
                <LinkBox
                  to={ROUTES.CHANGE_PASSWORD}
                  leftIcon={RiLockPasswordLine}
                  label="Alterar senha"
                />
                <LinkBox
                  to={ROUTES.DEPENDENTS}
                  leftIcon={AiOutlineUsergroupAdd}
                  label="Dependentes"
                />
              </Stack>
            ) : (
              <></>
            )}

            <Outlet />
          </Stack>
        )}
      </Container>
    </PageLayout>
  );
};

export default Profile;
