import { Flex, HStack, Stack } from "@chakra-ui/react";
import Modal from "react-responsive-modal";

export interface OverlayBackdropProps {
  setModalVisible: (state: boolean) => void;
  modalVisible: boolean;
  children?: React.ReactNode;
  actions?: React.ReactNode;
}
export const OverlayBackdrop = (props: OverlayBackdropProps) => {
  const { setModalVisible, modalVisible, children, actions } = props;

  return (
    <Modal
      open={modalVisible}
      onClose={() => setModalVisible(false)}
      center
      focusTrapped={false}
      classNames={{
        overlay: "customOverlay",
        modal: "customModal",
        closeIcon: "customModalClose",
      }}
      styles={{
        modalContainer: { overflow: "hidden" },
        closeButton: {
          top: "20px",
          right: "40px",
        },
      }}
    >
      <Stack spacing={10} paddingBottom={4} >
        <HStack
          justifyContent={"flex-start"}
          alignItems={"center"}
          spacing={8}
          w="full"
        >
          {actions}
        </HStack>
        <Flex w="full" justifyContent={"center"} alignItems="center">
          <Stack>{children}</Stack>
        </Flex>
      </Stack>
    </Modal>
  );
};
