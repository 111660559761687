import { Box, HStack, Text } from "@chakra-ui/layout";
import { useBreakpointValue } from "@chakra-ui/react";
import styles from "./SidebarItem.module.scss";

interface SidebarItemProps {
  icon: any;
  text: string;
  isSubmenu?: boolean
}

const SidebarItem = ({ icon, text, isSubmenu }: SidebarItemProps) => {
  const height = useBreakpointValue({base: '35px', md: '35px', lg: '38px', xl: '40px'});
  const fontSize = useBreakpointValue({base: '14px', md: '16px'});
  const fontWeight = isSubmenu ? '300' : 'bold';
  return (
    <Box className={`${styles.sidebarItem}`} style={{height, fontSize, fontWeight}}>
        <HStack spacing={5}>
          {/* <img alt="" src={icon} /> */}
          <Text>{text}</Text>
        </HStack>
    </Box>
  );
};

export default SidebarItem;
