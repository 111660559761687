import React, { useEffect } from "react";
import { ENDPOINTS } from "../constants/endpoints";
import useAxios from "../hooks/useAxios";
import { authManager, userManager } from "../services/authManager";

export type UserData = {
  NOME_PESSOA?: string;
  CD_MATRICULA?: any,
  CD_CATEGORIA?: any,
  USER_ACESSO_SISTEMA?: string,
  CD_APLICACAO: number[],
};

interface AuthContextType {
  token: string;
  user: UserData,
  signIn: (token: string, oldApiToken: string, user: any, callback: VoidFunction) => void;
  signOut: (callback: VoidFunction) => void;
}
export const AuthContext = React.createContext<AuthContextType>(null!);

const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const axios = useAxios();

  const [user, setUser] = React.useState<UserData>({
    NOME_PESSOA: "",
    USER_ACESSO_SISTEMA: "",
    CD_MATRICULA: "",
    CD_CATEGORIA: "",
	  CD_APLICACAO:[]
  });

  let [token, setToken] = React.useState<string>("");
  let [oldApiToken, setOldApiToken] = React.useState<string>("");


  // const pingServer = () => {
  //   if (window.location.pathname === '/login') return;

  //   axios
  //     .get(ENDPOINTS.ping)
  //     .catch((err) => {
  //       if (!err || !err.response) {
  //         window.location.href = "https://www.iateclubedebrasilia.com.br/";
  //         return;
  //       }
  //       else {
  //         if (err.response.status === 403) {
  //           setToken("");
  //           authManager.clear();
  //         }
  //       }
  //     });
  // }

  // useEffect(() => {
  //   pingServer();
  //   const interval = setInterval(() => {
  //     if (!authManager.get().token) return;
  //     pingServer();
  //   }, 60000 * 10);
  //   return () => clearInterval(interval);
  // });

  let signIn = (token: string, oldApiToken: string, user: UserData, callback: VoidFunction) => {
    let _user: UserData = {
      CD_CATEGORIA: user.CD_CATEGORIA,
      CD_MATRICULA: user.CD_MATRICULA,
      USER_ACESSO_SISTEMA: user.USER_ACESSO_SISTEMA,
      NOME_PESSOA: user.NOME_PESSOA,
	    CD_APLICACAO: user.CD_APLICACAO
    }
    setToken(token);
    setOldApiToken(oldApiToken)
    setUser(_user);

    userManager.set(user!);
    authManager.set(token!, oldApiToken)!;

    callback();
  };

  let signOut = (callback: VoidFunction) => {
    setToken("");
    authManager.clear();
    userManager.clear();
    callback();
  };

  let value = { token, user, signIn, signOut };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export default AuthProvider;


