export const ROUTES = {
    HOME: '/',

    LOGIN: '/login',

    PROFILE: '/perfil',
    EDIT_PROFILE: "/perfil/editar-perfil",
    CHANGE_PASSWORD: "/perfil/alterar-senha",

    DEPENDENTS: "/perfil/dependentes",
    DEPENDENT_REGISTER: "/perfil/dependentes/cadastrar",
    DEPENDENT_EDIT: "/perfil/dependentes/editar",

    RESERVATIONS: '/reservas',
    RESERVATIONS_INVITES: '/reservas/convites/:id',
    RESERVATIONS_BARBECUE: '/reservas/churrasqueira',
    RESERVATIONS_BARBECUE_LIST: '/reservas/churrasqueira/reservas-realizadas',
    RESERVATIONS_BARBECUE_RESERVE: '/reservas/churrasqueira/:id/reserva',
    RESERVATIONS_GYM_MODALITIES: '/reservas/academia',
    RESERVATIONS_GYM_SCHEDULE: '/reservas/academia/:id',
    RESERVATIONS_GYM_RESERVE: '/reservas/academia/:id/reserva',
    RESERVATIONS_HISTORY: '/reservas/reservas-realizadas',
    RESERVATIONS_BARBECUE_INVITES: '/reservas/convites-da-churrasqueira',

    FINANCIAL: '/financeiro',
    FINANCIAL_BILLINGS_COPY: '/financeiro/segunda-via-boleto',
    FINANCIAL_BILLINGS_DETAILS: '/financeiro/detalhamento-da-fatura',
    FINANCIAL_FUTURE_BILLINGS: '/financeiro/lancamentos-futuros',
    FINANCIAL_BILLINGS_DETAILS_ITEM: '/financeiro/detalhamento-da-fatura/:id',
    FINANCIAL_DEBT_PAYMENTS: '/financeiro/quitacao-de-debitos',
    FINANCIAL_DEBT_PAYMENTS_DETAILS: '/financeiro/quitacao-de-debitos/:id',
    FINANCIAL_DECLARATION_OF_DISCHARGE: '/financeiro/declaracao-de-quitacao',
    FINANCIAL_DECLARATION_OF_DISCHARGE_DETAILS: '/financeiro/declaracao-de-quitacao/:ano',

    INVITES_SELECT: '/convites',
    INVITES_GENERAL: '/convites/geral',
    INVITES_EMISSION: '/convites/geral/emissao-de-convites',
    INVITES_EMITTED: '/convites/geral/convites-emitidos',
    CHURRASQUEIRAS_CONVITES: '/convites/churrasqueira',
    CHURRASQUEIRAS_CONVITES_EMITIR: '/convites/churrasqueira/emissao-de-convites',
    CHURRASQUEIRAS_CONVITES_EMITIDOS: '/convites/churrasqueira/convites-emitidos',


    LOST_AND_FOUND: '/achados-e-perdidos',

    CONTACT: '/contato',

    TRANSPARENCY: '/portal-da-transparencia',

    ONBUDSMAN: '/ouvidoria',
    ONBUDSMAN_CREATE: '/ouvidoria/cadastrar-manifestacao',
    ONBUDSMAN_CONSULT: '/ouvidoria/consultar-manifestacao',
    ONBUDSMAN_CONSULT_DETAILS: '/ouvidoria/consultar-manifestacao/:id',

    NAUTICAL: '/nautica',
    NAUTICAL_SHIP_REQUEST: '/nautica/solicitacao-de-embarcacao',
    NAUTICAL_SHIP_REQUEST_REGISTER: '/nautica/solicitacao-de-embarcacao/cadastrar',
    NAUTICAL_SHIP_REQUEST_QUERY: '/nautica/solicitacao-de-embarcacao/consultar',
    NAUTICAL_SHIP_REQUEST_QUERY_DETAILS: '/nautica/solicitacao-de-embarcacao/consultar/:id',
    NAUTICAL_SHIP_AUTORIZATION: '/nautica/autorizacao-de-embarque',
    NAUTICAL_RESERVATIONS: '/nautica/reservas',
    NAUTICAL_RESERVATIONS_MODALITY: '/nautica/reservas/:id',
    NAUTICAL_RESERVATIONS_MODALITY_BOX: '/nautica/reservas/box',
    NAUTICAL_RESERVATIONS_MODALITY_BOX_WAITING_LIST: '/nautica/reservas/box/fila-de-espera',
    NAUTICAL_RESERVATIONS_RESERVE: '/nautica/reservas/:id/reservar',
    NAUTICAL_RESERVATIONS_RESERVE_BOX: '/nautica/reservas/box/reservar',
    NAUTICAL_INVITES: '/nautica/convites',

    JORNAL: '/jornal'
}