import { useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation, useQuery } from "react-query";

import moment from "moment";

import * as yup from "yup";

import {
  AlertDialog,
  Box,
  Container,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  GridItem,
  Heading,
  Input,
  Select,
  SimpleGrid,
  Stack,
  useBreakpointValue,
  useToast,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogOverlay,
  AlertDialogHeader,
  AlertDialogCloseButton,
  Text,
  useDisclosure,
  Checkbox,
} from "@chakra-ui/react";

import defaultImage from "../../../assets/img/Brasilia Helicoptero-377_tratada.jpg";

import ActionButton from "../../../components/ActionButton/ActionButton";
import Actions from "../../../components/Actions/Actions";
import ImageBanner from "../../../components/ImageBanner/ImageBanner";
import Loading from "../../../components/Loading/Loading";
import Toast from "../../../components/Toast/Toast";

import { CAMPO_OBRIGATORIO } from "../../../common/feedbacks";
import {
  Invite,
  InviteBalance,
  InviteSellTax,
} from "../../../models/invite.model";
import { InviteTypes } from "../../../enums/inviteType";
import { useInvitesService } from "../../../services/invites.service";
import { userManager } from "../../../services/authManager";
import MaskedInput from "react-input-mask";
import ReactInputDateMask from "react-input-mask"
import { testaCPF } from "../../../helpers/validCPF";
import { nomeRgx } from "../../../helpers/regex";
import { useProfileService } from "../../../services/profile.service";


type InviteForm = {
  isForeigner: boolean;
  nomeConvidado: string;
  nrDocEstrangeiro: string;
  cpfConvidado: string;
  categoriaConvite: "GV" | "GR" | "";
  dataNascimento: string;
};

interface InviteEmissionFormProps {
  inviteSubmitted: (res: Invite) => void;
  facilityType?: InviteTypes;
  invitesBalance: InviteBalance;
  image?:
    | {
        base: any;
        md: any;
      }
    | any;
}

const InviteEmissionForm = ({
  inviteSubmitted,
  image = defaultImage,
  facilityType,
  invitesBalance,
}: InviteEmissionFormProps) => {
  const backgroundImg = useBreakpointValue({
    base: image.base ? image.base : image,
    md: image.md ? image.md : image,
  });

  let imageHeight = useBreakpointValue({
    base: "250px",
    md: "300px",
    lg: "350px",
  });

  const [verifySuspension, setVerifySuspension] = useState<boolean>(false);
  const { getIsSuspended } = useProfileService();

  const gridCols = 12;

  const toast = useToast();

  const showToast = (type: "error" | "success", data: any) => {
    toast({
      render: () => <Toast type={type} message={data} />,
      duration: 5000,
      isClosable: false,
    });
  };

  const location = useLocation();

  const schema = yup.object().shape({
    isForeigner: yup.boolean(),
    categoriaConvite: yup.string().required(CAMPO_OBRIGATORIO),
    //dataNascimento: yup.string().typeError("Data inválida"),
    dataNascimento: yup.string().required(CAMPO_OBRIGATORIO).test("data-invalida", "Data Inválida", (value) => {
      if (value === '') return false;
      if (value?.replace("_", "").length !== 10) return false;
      const dateMask = value?.split('/');
      if (dateMask.length) {
        dateMask.reverse()
        // return moment(dateMask.reverse().join('/').toString().replace('/', '-')).isValid()
        return moment(dateMask.join('-')).isValid()
      }
      return false;
    }),
    cpfConvidado: yup.string().test("cpf-invalido", "CPF inválido", (cpf) => {
      if (cpf == "") return true;
      const formatted = (cpf && cpf.replace(/[^\w\s]/gi, "")) || "";
      return testaCPF(formatted);
    }),
    nrDocEstrangeiro: yup.string(),
    nomeConvidado: yup
      .string()
      .required(CAMPO_OBRIGATORIO)
      .matches(nomeRgx, "Nome inválido"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    watch,
    control,
    trigger,
  } = useForm<InviteForm>({
    mode: "onBlur",
    reValidateMode: "onChange",
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (facilityType) {
      setValue("categoriaConvite", facilityType);
    }
  }, [facilityType, setValue]);

  const tipoConviteValue = watch("categoriaConvite");

  const confirmSoldInviteAlert = () => {
    onCloseSoldInvitesAlert();
    mutation.mutate(getValues());
  };

  const onSubmit = handleSubmit((data: InviteForm) => {
    //validacoes solicitadas pelo cliente

    const documento = data.isForeigner
      ? data.nrDocEstrangeiro
      : data.cpfConvidado;
    const documentoNome = data.isForeigner ? "Doc. Estrangeiro" : "CPF";

    if (!documento && !data.dataNascimento) {
      showToast(
        "error",
        `Preencha o ${documentoNome} ou a Data de Nascimento.`
      );
      return;
    }

    if (data.dataNascimento) {
      // console.log("data de nascimento post...", data)
      const dataFormated = data.dataNascimento.split('/').reverse()
      const dataMoment = moment(dataFormated.toString().replace('/','-'), "YYYY-MM-DD");
      
      const guestIsYoungerThan12yo = dataMoment.isAfter(
        moment().subtract(12, "y")
      );
      
      if (guestIsYoungerThan12yo) {
        showToast(
          "error",
          `Convidados com até 12 anos não precisam de convite.`
        );
        return;
      }

      const guestIsOlderThan18yo = dataMoment.isSameOrBefore(
        moment().subtract(18, "y")
      );

      if (guestIsOlderThan18yo && !documento) {
        showToast(
          "error",
          `Necessário preencher o ${documentoNome}`
        );
        return;
      }
      
    }
    //checa se está suspenso
    setVerifySuspension(true);
  });

  useEffect(() => {
    if (tipoConviteValue === "" || !tipoConviteValue) return;
  }, [tipoConviteValue]);

  const { addInvite, getSellTax } = useInvitesService();

  const [sellTax, setSellTax] = useState<InviteSellTax>(null!);

  const query = useQuery<any, Error>(
    "taxa_convite_vendido",
    async () => {
      return await getSellTax();
    },
    {
      retry: 5,
      refetchOnWindowFocus: false,
      onSuccess: (res: any) => {
        if (res) {
          setSellTax(res);
        }
      },
      onError: () => {
        showToast("error", "Não foi possível recuperar o valor ta taxa.");
      },
    }
  );

  const { USER_ACESSO_SISTEMA } = userManager.get();

  const displayForeignerDocField = (): boolean => {
    return watch("isForeigner") || false;
  };

  const { isLoading: isLoadingSuspensao, isFetching: isFetchingSuspensao } =
    useQuery<any, Error>(
      "verificaSuspensao",
      async () => {
        return await getIsSuspended();
      },
      {
        enabled: verifySuspension,
        retry: 5,
        refetchOnWindowFocus: false,
        onSuccess: (res: any) => {
          if (res && res.data[0].IC_DIREITO_SUSPENSO !== "N") {
            showToast(
              "error",
              "Direitos suspensos conforme inciso II do art. 57 do Estatuto do Clube."
            );
          } else {
            if (tipoConviteValue === "GV") {
              onOpenSoldInviteAlert();
            } else {
              mutation.mutate(getValues());
            }
          }
          setVerifySuspension(false);
        },
      }
    );

  const mutation = useMutation(
    (data: any) => {
      if (data.cpfConvidado) {
        data.cpfConvidado = data.cpfConvidado.replace(/[^\w\s]/gi, "");
      }
      if (data.dataNascimento) {
        const dataFormated = data.dataNascimento.split('/').reverse()
        const dataMoment = moment(dataFormated.toString().replace('/','-'), "YYYY-MM-DD");
        data.dataNascimento = dataMoment.format("MM/DD/YYYY")
      }
      if (data.cpfConvidado == "") data.cpfConvidado = null;
      if (data.docEstrangeiro == "") data.docEstrangeiro = null;
      const payload: any = {
        ...data,
        usuario: USER_ACESSO_SISTEMA,
      };
      return addInvite(payload);
    },
    {
      onSuccess: (res: any) => {
        if (!res.find((invite: Invite) => invite.NR_CONVITE)) {
          const errorMessage = res && res[0] && res[0].MSG;
          showToast("error", errorMessage || "Não foi possível emitir o convite.");
          return;
        }

        showToast("success", "Convite emitido!");
        inviteSubmitted(res);
      },
      onError: (err: any) => {
        showToast(
          "error",
          err.response.data || "Não foi possível emitir o convite."
        );
      },
    }
  );

  const {
    isOpen: isOpenSoldInviteAlert,
    onOpen: onOpenSoldInviteAlert,
    onClose: onCloseSoldInvitesAlert,
  } = useDisclosure();
  const cancelRef = useRef<any>(null!);

  return (
    <Flex direction="column" justifyContent="center" paddingTop={4}>
      <ImageBanner src={backgroundImg} height={imageHeight} alt="" />
      <Container maxW="container.md" paddingTop={"30px"} pb={20}>
        <Stack mb={9}>
          <Heading size="lg" color="primary" fontWeight="500">
            Preencha os campos abaixo
          </Heading>
          <small>
            Complete todos os dados com CPF ou Documento de Pessoa Estrangeira
          </small>
        </Stack>
        <Box pb={7}>
          <form onSubmit={onSubmit} id="invite-emission-form">
            <SimpleGrid columns={12} columnGap={4} rowGap={4}>
              <GridItem colSpan={12}>
                <FormControl isInvalid={!!errors?.categoriaConvite?.message}>
                  <FormLabel fontWeight="400" color="gray.500">
                    Tipo de Convite
                  </FormLabel>
                  <Select {...register("categoriaConvite")}>
                    <option value={""} disabled={!!tipoConviteValue}>
                      Selecione
                    </option>
                    <option
                      disabled={invitesBalance.SD_CONVITE === 0}
                      value={"GR"}
                    >
                      Geral Gratuito
                    </option>
                    <option
                      disabled={invitesBalance.SD_CONVITE_VENDIDO === 0}
                      value={"GV"}
                    >
                      Geral Vendido
                    </option>
                  </Select>
                  <FormErrorMessage>
                    {errors?.categoriaConvite?.message}
                  </FormErrorMessage>
                </FormControl>
              </GridItem>

              <GridItem colSpan={12}>
                <FormControl isInvalid={!!errors?.nomeConvidado?.message}>
                  <FormLabel fontWeight="400" color="gray.500">
                    Nome do convidado
                  </FormLabel>
                  <Input {...register("nomeConvidado")} type="text" />
                  <FormErrorMessage>
                    {errors?.nomeConvidado?.message}
                  </FormErrorMessage>
                </FormControl>
              </GridItem>

              <GridItem colSpan={12}>
                <FormControl isInvalid={!!errors?.dataNascimento}>
                  <FormLabel fontWeight="400" color="gray.500">
                    Data de nascimento
                  </FormLabel>
                  <Controller
                      control={control}
                      name="dataNascimento"
                      defaultValue={""}
                      render={({ field }) => (
                        <ReactInputDateMask
                            mask="99/99/9999"
                            value={field.value}
                            onChange={field.onChange}
                          >
                            {(inputProps: any) => (
                              <Input {...inputProps} type="tel" />
                            )}
                          </ReactInputDateMask>
                        )}
                      />
                  
                  <FormErrorMessage>
                    {errors?.dataNascimento?.type === 'data-invalida' &&
                      errors.dataNascimento.message}
                    
                  </FormErrorMessage>
                </FormControl>
              </GridItem>
              <GridItem colSpan={12}>
                <FormControl>
                  <Stack spacing={5} direction="row">
                    <Checkbox
                      {...register("isForeigner")}
                      onBlur={() =>
                        trigger(["nrDocEstrangeiro", "cpfConvidado"])
                      }
                    >
                      Pessoa estrangeira
                    </Checkbox>
                  </Stack>
                </FormControl>
              </GridItem>

              {displayForeignerDocField() ? (
                <>
                  <GridItem colSpan={12}>
                    <FormControl
                      isInvalid={
                        errors?.cpfConvidado?.type == "required" ||
                        !!errors?.nrDocEstrangeiro
                      }
                    >
                      <FormLabel fontWeight="400" color="gray.500">
                        Doc. Estrangeiro
                      </FormLabel>
                      <Input
                        {...register("nrDocEstrangeiro")}
                        type="text"
                        onBlur={() =>
                          trigger(["nrDocEstrangeiro", "cpfConvidado"])
                        }
                      />
                    </FormControl>
                  </GridItem>
                </>
              ) : (
                <>
                  <GridItem colSpan={12}>
                    <FormControl
                      isInvalid={
                        !!errors?.cpfConvidado || !!errors?.nrDocEstrangeiro
                      }
                    >
                      <FormLabel fontWeight="400" color="gray.500">
                        CPF
                      </FormLabel>

                      <Controller
                        control={control}
                        name="cpfConvidado"
                        defaultValue={""}
                        render={({ field }) => (
                          <MaskedInput
                            mask="999.999.999-99"
                            value={field.value}
                            onChange={field.onChange}
                          >
                            {(inputProps: any) => (
                              <Input {...inputProps} type="text" />
                            )}
                          </MaskedInput>
                        )}
                      />
                      <FormErrorMessage>
                        {errors?.cpfConvidado?.type == "cpf-invalido" &&
                          errors.cpfConvidado.message}
                      </FormErrorMessage>
                    </FormControl>
                  </GridItem>
                </>
              )}

              <GridItem colSpan={gridCols}>
                <FormControl
                  isInvalid={
                    errors?.cpfConvidado?.type == "required" ||
                    !!errors?.nrDocEstrangeiro
                  }
                >
                  <FormErrorMessage>
                    {displayForeignerDocField()
                      ? "Necessário preencher o Doc. Estrangeiro."
                      : "Necessário preencher o CPF."}
                  </FormErrorMessage>
                </FormControl>
              </GridItem>
            </SimpleGrid>
          </form>
        </Box>

        <Actions>
          <Link to={`/${location.pathname.split("/")[1]}`}>
            <ActionButton variant="outline">Cancelar</ActionButton>
          </Link>

          <ActionButton
            type="submit"
            form="invite-emission-form"
            loadingText="Processando..."
            isLoading={
              mutation.isLoading || isLoadingSuspensao || isFetchingSuspensao
            }
            // disabled={Object.keys(errors).length > 0}
          >
            Solicitar convite
          </ActionButton>
        </Actions>
      </Container>

      <AlertDialog
        size="sm"
        isOpen={isOpenSoldInviteAlert}
        leastDestructiveRef={cancelRef}
        onClose={onOpenSoldInviteAlert}
        isCentered
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Convite Vendido
              <AlertDialogCloseButton />
            </AlertDialogHeader>

            <AlertDialogBody py={10}>
              <Stack
                spacing={2}
                justifyContent={"center"}
                alignItems={"center"}
                w="full"
              >
                <Text textAlign="justify">
                  A emissão de CONVITE VENDIDO tem o custo conforme previsto na
                  tabela de contribuições e preços vigente e será debitada no
                  boleto do sócio responsável. Autoriza o lançamento?
                </Text>
              </Stack>
            </AlertDialogBody>
            <AlertDialogFooter>
              <Actions>
                <ActionButton
                  marginRight={"1rem"}
                  minW={["full", "fit-content"]}
                  onClick={confirmSoldInviteAlert}
                >
                  Sim
                </ActionButton>
                <ActionButton
                  minW={["full", "fit-content"]}
                  variant={"outline"}
                  onClick={onCloseSoldInvitesAlert}
                >
                  Não
                </ActionButton>
              </Actions>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Flex>
  );
};

export default InviteEmissionForm;
