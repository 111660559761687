import { ChevronLeftIcon } from "@chakra-ui/icons";
import { Container, Flex, Heading, Stack, Table, Tbody, Td, Text, Th, Thead, Tr, useBreakpointValue, VStack } from "@chakra-ui/react";
import { useState } from "react";
import { useQuery } from "react-query";
import { Link, useParams } from "react-router-dom";
import Breadcrumb from "../../../components/Breadcrumb/Breadcrumb";
import Loading from "../../../components/Loading/Loading";
import PageLayout from "../../../components/PageLayout/PageLayout";
import { PageTitle } from "../../../components/PageTitle/PageTitle";
import { ROUTES } from "../../../constants/routes";
import { currency } from "../../../helpers/currency";
import useCustomToast from "../../../hooks/useCustomToast";
import { BillingDetails, BillingDetailsInstallment } from "../../../models/financial.model";
import { useFinancialService } from "../../../services/financial.service";
import styles from "./BillDetail.module.scss";

export default function BillDetail() {
  const [billingDetails, setBillingDetails] = useState<BillingDetails>();
  const [listaParcelas, setListaParcelas] = useState<BillingDetailsInstallment[]>();
  const { getBillingDetails, getBillingDetailsInstallments } = useFinancialService();
  const { showToast } = useCustomToast();
  const { id } = useParams();

  const query = useQuery<BillingDetails, Error>(
    "listaDetalhesDeFatura",
    async () => {
      return await getBillingDetails(Number.parseInt(id!));
    },
    {
      refetchOnWindowFocus: false,
      onSuccess: (billingDetails: BillingDetails) => {
        setBillingDetails(billingDetails);
      },
      onError: () => showToast("error", "Não foi possível listar o detalhamento da fatura."),
    }
  );

  const queryParcelas = useQuery<BillingDetailsInstallment[], Error>(
    "listaParcelasDeFatura",
    async () => {
      return await getBillingDetailsInstallments(Number.parseInt(id!));
    },
    {
      refetchOnWindowFocus: false,
      onSuccess: (billingDetailsInstallment: BillingDetailsInstallment[]) => {
        setListaParcelas(billingDetailsInstallment);
      },
      onError: () => showToast("error", "Não foi possível listar as parcelas da fatura."),
    }
  );

  const tableSize = useBreakpointValue({ base: "sm", md: "md" });

  return (
    <PageLayout noPaddingX>
      <Container maxW="container.md" pt={5} pb={20}>
        <Stack spacing={5}>
          <Breadcrumb
            items={[
              { route: "/", label: "Início" },
              { route: ROUTES.FINANCIAL, label: "Financeiro" },
              {
                route: ROUTES.FINANCIAL_BILLINGS_DETAILS,
                label: "Fatura",
              },
              {
                route: `${ROUTES.FINANCIAL_BILLINGS_DETAILS}/${id}`,
                label: "Detalhamento da Fatura",
              },
            ]}
          />
          <Link className={styles.link} to={ROUTES.FINANCIAL_BILLINGS_DETAILS}>
            <ChevronLeftIcon className={styles.icons} />
            Voltar
          </Link>

          <PageTitle>Detalhamento da fatura</PageTitle>

          {query.isLoading || query.isFetching ? (
            <Flex w="full" justifyContent="center">
              <Loading />
            </Flex>
          ) : (
            <Flex direction={"row"} alignItems={"flex-start"} justifyContent={"flex-start"}>
              <Stack w="full">
                {billingDetails ? (
                  <>
                    <Text>
                      <b>Título:</b> {billingDetails.titulo}
                    </Text>
                    <Text>
                      <b>Data de vencimento:</b> {billingDetails.vencimento}
                    </Text>
                    <Text>
                      <b>Situação:</b> {billingDetails.status}
                    </Text>
                    <Text>
                      <b>Valor:</b> {currency(billingDetails.valorOriginal)}
                    </Text>
                    <Text>
                      <b>Créditos:</b> {currency(billingDetails.creditos)}
                    </Text>
                    <Text>
                      <b>Encargos:</b> {currency(billingDetails.encargos)}
                    </Text>
                    <Text>
                      <b>Valor Total:</b> {currency(billingDetails.valorTotal)}
                    </Text>
                  </>
                ) : (
                  <Text color="red">Sem informações detalhadas</Text>
                )}
              </Stack>
            </Flex>
          )}

          {queryParcelas.isLoading || queryParcelas.isFetching ? (
            <Flex w="full" justifyContent="center" />
          ) : (
            <>
              {listaParcelas && listaParcelas.length > 0 ? (
                <VStack overflowX="auto" alignItems="normal">
                  <Heading size="sm">Parcelas</Heading>
                  <Table variant="striped" size={tableSize} className={styles.table}>
                    <Thead>
                      <Tr>
                        <Th>Nome</Th>
                        <Th>Descrição</Th>
                        <Th>Data</Th>
                        <Th isNumeric>Valor</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {listaParcelas.map((x: BillingDetailsInstallment, i) => (
                        <Tr key={i}>
                          <Td>{x.nome}</Td>
                          <Td>{x.descricao}</Td>
                          <Td>{x.data}</Td>
                          <Td isNumeric>{currency(x.valor)}</Td>
                        </Tr>
                      ))}
                    </Tbody>
                  </Table>
                </VStack>
              ) : (
                <Text color="red">Sem informações das parcelas</Text>
              )}
            </>
          )}
        </Stack>
      </Container>
    </PageLayout>
  );
}
