// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Toast_toast__21c9f {\n  background: #212429;\n  box-shadow: 0px 6px 12px rgba(106, 115, 129, 0.16), 0px 3px 8px rgba(87, 102, 117, 0.06);\n  border-radius: 4px;\n  color: #FFFFFF;\n  font-weight: 400;\n  position: relative;\n}\n\n.Toast_message__2y57w {\n  color: #FFFFFF;\n}\n\n.Toast_error__2p3Ea {\n  color: #F03D3E !important;\n}\n\n.Toast_success__1RiCm {\n  color: #44AC80 !important;\n}\n\n.Toast_alert__3zoCA {\n  color: #4E9ECB !important;\n}", "",{"version":3,"sources":["webpack://src/components/Toast/Toast.module.scss","webpack://src/styles/variables.scss"],"names":[],"mappings":"AAEA;EACE,mBAAA;EACA,wFAAA;EAEA,kBAAA;EACA,cCHM;EDIN,gBAAA;EACA,kBAAA;AAFF;;AAKA;EACI,cCTI;ADOR;;AAKA;EACI,yBAAA;AAFJ;;AAIA;EACI,yBAAA;AADJ;;AAGA;EACI,yBAAA;AAAJ","sourcesContent":["@import \"../../styles/variables.scss\";\r\n\r\n.toast {\r\n  background: #212429;\r\n  box-shadow: 0px 6px 12px rgba(106, 115, 129, 0.16),\r\n    0px 3px 8px rgba(87, 102, 117, 0.06);\r\n  border-radius: 4px;\r\n  color: $white;\r\n  font-weight: 400;\r\n  position: relative;\r\n}\r\n\r\n.message{\r\n    color: $white;\r\n}\r\n\r\n.error{\r\n    color: $danger !important;\r\n}\r\n.success{\r\n    color: $success !important;\r\n}\r\n.alert{\r\n    color: #4E9ECB !important;\r\n}","// $primary: #003E66;\r\n$primary: #0d4f7b;\r\n$danger: #F03D3E;\r\n$success: #44AC80;\r\n$white: #FFFFFF;\r\n\r\n$border-radius: 8px;\r\n$navbar-height: 50px;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"toast": "Toast_toast__21c9f",
	"message": "Toast_message__2y57w",
	"error": "Toast_error__2p3Ea",
	"success": "Toast_success__1RiCm",
	"alert": "Toast_alert__3zoCA"
};
export default ___CSS_LOADER_EXPORT___;
