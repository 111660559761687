import { ENDPOINTS } from "../constants/endpoints";
import useAxios from "../hooks/useAxios";
import { Invite } from "../models/invite.model";


export function useInvitesService() {
  const axios = useAxios();

  const getInvite = async (id: string) => {
    let response = await axios.get(ENDPOINTS.getInvite(id));
    return response.data;
  };
  const deleteInvite = async (id: number, user: string) => {
    let response = await axios.get(ENDPOINTS.deleteInvite, {
      params: {
        usuario: user,
        numeroConvite: id
      }
    });

    return response.data;
  };

  const getInvites = async (user?: string) => {
    let response = await axios.get(ENDPOINTS.getInvites, {
      params: {
        usuario: user
      }
    });
    return response.data;
  };

  const getInviteBalance = async (user?: string) => {
    let response = await axios.get(ENDPOINTS.getInviteBalance, {
      params: {
        usuario: user
      }
    });

    return response.data;
  };

  const getSellTax = async () => {
    let response = await axios.get(ENDPOINTS.getSellTax);

    return response.data[0];
  }

  const addInvite = async (invite: Invite) => {
    let response = await axios.post(ENDPOINTS.addInvite, null, {
      params: {
        usuario: invite.usuario,
        nomeConvidado: invite.nomeConvidado,
        cpfConvidado: invite.cpfConvidado,
        dataNascimento: invite.dataNascimento,
        nrDocEstrangeiro: invite.nrDocEstrangeiro,
        categoriaConvite: invite.categoriaConvite,
        ip: invite.ip,
      }
    });
    return response.data;
  };

  const services = {
    addInvite,
    getInvite,
    getInvites,
    deleteInvite,
    getInviteBalance,
    getSellTax
  };

  return services;
}
