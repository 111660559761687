import { FaArrowUp } from "react-icons/fa";
type Props = {};

export default function BackToTop({}: Props) {
  return (
    <button
      onClick={toTop}
      style={{
        position: "fixed",
        bottom: "40px",
        right: "40px",
        background: '#0d4f7b',
        borderRadius: '40px',
        padding: '1rem',
        color: 'white',
        zIndex: 99999
      }}
    >
      <FaArrowUp />
    </button>
  );
}
function toTop() {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
}
