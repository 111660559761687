// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ChangePassword_form__2SY3x {\n  padding-bottom: 1rem;\n  width: 100%;\n}", "",{"version":3,"sources":["webpack://src/pages/Profile/ChangePassword/ChangePassword.module.scss"],"names":[],"mappings":"AAAA;EACI,oBAAA;EACA,WAAA;AACJ","sourcesContent":[".form{\r\n    padding-bottom: 1rem;\r\n    width: 100%;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form": "ChangePassword_form__2SY3x"
};
export default ___CSS_LOADER_EXPORT___;
