// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".LinkBox_linkBox__B3Dij {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  padding: 1rem 2rem;\n  font-weight: 500;\n  color: #0d4f7b;\n  background: #ffffff;\n  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.04), 0px 2px 4px rgba(0, 0, 0, 0.16);\n  border-radius: 8px;\n  cursor: pointer;\n}\n.LinkBox_linkBox__B3Dij:hover {\n  background-color: rgba(241, 241, 241, 0.2117647059);\n  text-decoration: underline;\n}", "",{"version":3,"sources":["webpack://src/components/LinkBox/LinkBox.module.scss","webpack://src/styles/variables.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,kBAAA;EACA,gBAAA;EACA,cCPQ;EDQR,mBAAA;EACA,4EAAA;EACA,kBAAA;EACA,eAAA;AADF;AAGE;EACE,mDAAA;EACA,0BAAA;AADJ","sourcesContent":["@import \"../../styles/variables.scss\";\r\n\r\n.linkBox {\r\n  display: flex;\r\n  justify-content: space-between;\r\n  align-items: center;\r\n  padding: 1rem 2rem;\r\n  font-weight: 500;\r\n  color: $primary;\r\n  background: #ffffff;\r\n  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.04), 0px 2px 4px rgba(0, 0, 0, 0.16);\r\n  border-radius: 8px;\r\n  cursor: pointer;\r\n\r\n  &:hover {\r\n    background-color: #f1f1f136;\r\n    text-decoration: underline;\r\n  }\r\n}\r\n","// $primary: #003E66;\r\n$primary: #0d4f7b;\r\n$danger: #F03D3E;\r\n$success: #44AC80;\r\n$white: #FFFFFF;\r\n\r\n$border-radius: 8px;\r\n$navbar-height: 50px;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"linkBox": "LinkBox_linkBox__B3Dij"
};
export default ___CSS_LOADER_EXPORT___;
