// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".BillingDetails_table__3wz2l tbody tr td {\n  cursor: pointer;\n}\n.BillingDetails_table__3wz2l tbody tr:hover td {\n  background-color: #f7f7f7;\n}\n\n.BillingDetails_warningText__1wLMh {\n  padding: 15px;\n  color: #FF0000;\n}\n\n.BillingDetails_upperCase__bG-x6 {\n  color: #FF0000;\n  text-transform: uppercase;\n}", "",{"version":3,"sources":["webpack://src/pages/Financial/BillingDetails/BillingDetails.module.scss"],"names":[],"mappings":"AAGI;EACE,eAAA;AAFN;AAKM;EACE,yBAAA;AAHR;;AAQA;EACE,aAAA;EACA,cAAA;AALF;;AAQA;EACE,cAAA;EACA,yBAAA;AALF","sourcesContent":["\r\n.table {\r\n  tbody tr {\r\n    td {\r\n      cursor: pointer;\r\n    }\r\n    &:hover {\r\n      td {\r\n        background-color: #f7f7f7;\r\n      }\r\n    }\r\n  }\r\n}\r\n.warningText {\r\n  padding: 15px;\r\n  color: #FF0000;\r\n}\r\n\r\n.upperCase {\r\n  color: #FF0000;\r\n  text-transform: uppercase;\r\n}\r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"table": "BillingDetails_table__3wz2l",
	"warningText": "BillingDetails_warningText__1wLMh",
	"upperCase": "BillingDetails_upperCase__bG-x6"
};
export default ___CSS_LOADER_EXPORT___;
