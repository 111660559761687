import { Box, HStack, Icon, Stack, Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { FaRegTimesCircle} from "react-icons/fa";
import IconButton from "../IconButton/IconButton";
import styles from "./FileUploader.module.scss";
import { v4 as uuidv4 } from 'uuid';

type FileUploaderProps = {
    onChange: (fileList: File[], invalid: boolean) => void,
    disabled?: boolean,
    maxSize?: number //em bytes 
}

export const FileUploader = ({onChange, disabled, maxSize}: FileUploaderProps) => {
  const [files, setFiles] = useState<any[]>(null!);

  const handleChange = (e: any) => {
    setFiles(Array.from(e.target.files).map((x: any) => {
       return {file: x, id: uuidv4()}
    }));
  };

  const removeFile = (file: any) => {
    setFiles([...files.filter(f => f.id !== file.id)]);
  }

  useEffect(() => {
    const fileList = files?.map((x:any) => x.file);
    let invalid = false;
    if(maxSize && fileList){
      fileList.forEach(x => {
        if(x.size > maxSize){
          invalid = true;
        }
      })
    }
    onChange(fileList, invalid);
  }, [files])

  return (
    <Box className={styles.fileUploader}>
      <input
        id="actual-btn"
        disabled={disabled}
        type="file"
        multiple
        hidden
        onChange={handleChange}
      />
      <label htmlFor="actual-btn">Escolher arquivo</label>
      <Stack ml={5} mt={5} spacing={2}>
        {files && files?.map((x: any, i: number) => (
          <HStack spacing={3} key={i}>
              <Text  color={(maxSize && x?.file.size > maxSize) ? 'danger' : 'primary'}>{x?.file?.name}</Text>
              <IconButton onClick={() => removeFile(x)}>
                   <Icon marginTop={'4px'} color={"gray.500"} as={FaRegTimesCircle} />
              </IconButton>
          </HStack>
        ))}
      </Stack>
    </Box>
  );
};
