import React, { createContext, useEffect, useState } from "react";
import { useQuery } from "react-query";
import useAuth from "../hooks/useAuth";
import { Titular } from "../models/profile.model";
import { userManager } from "../services/authManager";
import { useProfileService } from "../services/profile.service";
import { UserData } from "./AuthContext";

interface ProfileContextType {
  profile: Titular,
  refetch: () => void,
  isLoading: boolean,
  user: UserData
}

export const ProfileContext = createContext<ProfileContextType>(null!);

const ProfileProvider = ({ children }: { children: React.ReactNode }) => {
  const [profile, setProfile] = useState<Titular>(null!);

  const { getProfile } = useProfileService();

  const {refetch, isLoading} = useQuery<Titular, Error>(
    "perfil",
    async () => {
      return await getProfile();
    },
    {
      
      retry: 10,
      refetchOnWindowFocus: true,
      onSuccess: (profile: Titular) => {
        setProfile(profile);
      },
      onError: () =>
        console.error("Não foi possível recuperar os dados do perfil.")
    }
  );

  const value = { profile, user: userManager.get(), refetch, isLoading };

  return (
    <ProfileContext.Provider value={value}>{children}</ProfileContext.Provider>
  );
};

export default ProfileProvider;
