import { ChevronRightIcon } from "@chakra-ui/icons";
import {
  Container,
  Flex,
  Icon,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import Breadcrumb from "../../../components/Breadcrumb/Breadcrumb";
import PageLayout from "../../../components/PageLayout/PageLayout";
import { ROUTES } from "../../../constants/routes";
import styles from "./DebtPayments.module.scss";
import { useQuery } from "react-query";
import { DebtPaymentTerm } from "../../../models/financial.model";
import { useFinancialService } from "../../../services/financial.service";
import useCustomToast from "../../../hooks/useCustomToast";
import Loading from "../../../components/Loading/Loading";
import { useState } from "react";
import useDownloadFile from "../../../hooks/useDownloadFile";
import { FiDownload } from "react-icons/fi";
import { PageTitle } from "../../../components/PageTitle/PageTitle";

export type DebtPaymentsProps = {};

export default function DebtPayments({}: DebtPaymentsProps) {
  const [terms, setTerms] = useState<DebtPaymentTerm[]>([]);
  const { getDebtPaymentTerms } = useFinancialService();
  const { showToast } = useCustomToast();
  const { download } = useDownloadFile();

  const handleDebtPayments = async (item: DebtPaymentTerm) => {
    download(
      item.urlDownload,
      () => {
        showToast("error", "Não foi possível fazer o download.");
      },
      "application/pdf",
      `Termo de Quitação ${item.ano}`
    );
  };

  const query = useQuery<DebtPaymentTerm[], Error>(
    "listaDetalhesDeFatura",
    async () => {
      return await getDebtPaymentTerms();
    },
    {
      refetchOnWindowFocus: false,
      onSuccess: (terms: DebtPaymentTerm[]) => {
        setTerms(terms);
      },
      onError: () =>
        showToast("error", "Não foi possível listar os termos de quitação."),
    }
  );

  return (
    <PageLayout noPaddingX>
      <Container maxW="container.md" pt={5} pb={20}>
        <Stack spacing={8}>
          <Breadcrumb
            items={[
              { route: "/", label: "Início" },
              { route: ROUTES.FINANCIAL, label: "Financeiro" },
              {
                route: ROUTES.FINANCIAL_DEBT_PAYMENTS,
                label: "Quitação de Débitos",
              },
            ]}
          />
           <PageTitle>Quitação de débitos</PageTitle>
          {query.isLoading || query.isFetching?  (
            <Flex w="full" justifyContent="center">
              <Loading />
            </Flex>
          ) : (
            <Table variant="simple" size="lg" className={styles.table}>
              <Thead>
                <Tr>
                  <Th>Mês de referência</Th>
                  <Th></Th>
                </Tr>
              </Thead>
              <Tbody>
                {!terms || terms.length === 0 ? <Text alignSelf={"center"}>Não existem termos de quitação disponíves.</Text> : <></>}
                {terms && terms.map((term: DebtPaymentTerm, i) => (
                  <Tr key={i} onClick={() => handleDebtPayments(term)}>
                    <Td color={"gray.500"}>{term.ano}</Td>
                    <Td style={{ textAlign: "end" }}>
                     <Icon as={FiDownload} color="primary"/>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          )}
        </Stack>
      </Container>
    </PageLayout>
  );
}
