// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Navbar_navbar__10UeP {\n  padding: 2.25rem;\n  height: 60px;\n  width: 100vw;\n  display: flex;\n  align-items: center;\n  position: fixed;\n  z-index: 999;\n}", "",{"version":3,"sources":["webpack://src/components/Navbar/Navbar.module.scss"],"names":[],"mappings":"AAAA;EACI,gBAAA;EACA,YAAA;EACA,YAAA;EACA,aAAA;EACA,mBAAA;EACA,eAAA;EACA,YAAA;AACJ","sourcesContent":[".navbar{\r\n    padding: 2.25rem;\r\n    height: 60px;\r\n    width: 100vw;\r\n    display: flex;\r\n    align-items: center;\r\n    position: fixed;\r\n    z-index: 999;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"navbar": "Navbar_navbar__10UeP"
};
export default ___CSS_LOADER_EXPORT___;
