import {
  Box,
  Checkbox,
  Container,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  Select,
  Stack,
  useToast,
} from "@chakra-ui/react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ActionButton from "../../../components/ActionButton/ActionButton";
import Actions from "../../../components/Actions/Actions";
import Breadcrumb from "../../../components/Breadcrumb/Breadcrumb";
import PageLayout from "../../../components/PageLayout/PageLayout";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useMutation } from "react-query";
import { CAMPO_OBRIGATORIO } from "../../../common/feedbacks";
import Toast from "../../../components/Toast/Toast";
import { useEffect, useRef, useState } from "react";
import { Dependent, DependentAlteracao } from "../../../models/dependent.model";
import { useDependentService } from "../../../services/dependent.service";

interface DependentFormProps {
  editMode?: boolean;
  id?: string;
}
//editMode é passado no src\config\Router.tsx
const DependentForm = ({ editMode = false }: DependentFormProps) => {
  const { state: dependent } = useLocation();

  const navigate = useNavigate();

  const schema = yup.object().shape({
    NOME_PESSOA: yup.string().required(CAMPO_OBRIGATORIO),
    TELEFONE_R: yup.string().nullable(),
    TELEFONE_C: yup.string().nullable(),
    TELEFONE_L: yup.string().nullable(),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<Dependent>({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (dependent) {
      setValue("NOME_PESSOA", dependent.NOME_PESSOA);
      setValue("TELEFONE_R", dependent.TELEFONE_R);
      setValue("TELEFONE_C", dependent.TELEFONE_C);
      setValue("TELEFONE_L", dependent.TELEFONE_L);

    } else {
      if (editMode) {
        navigate("/perfil/dependentes");
      }
    }
  }, [dependent, setValue, editMode, navigate]);

  const toast = useToast();

  const showToast = (type: "error" | "success", data: any) => {
    toast({
      render: () => <Toast type={type} message={data} />,
      duration: 5000,
      isClosable: false,
    });
  };

  const transformPayload = (data: Dependent) : DependentAlteracao => {
    let payload: DependentAlteracao = {
      dependente: dependent.SEQ_DEPENDENTE,
      telefone_c: data.TELEFONE_C,
      telefone_l: data.TELEFONE_L,
      telefone_r: data.TELEFONE_R,
    }
    return payload;
  }

  const { saveDependent } = useDependentService();

  const mutation = useMutation(
    (data: Dependent) => {
      return saveDependent(transformPayload(data));
    },
    {
      onSuccess: () => {
        showToast("success", editMode ? "Dependente atualizado." :  "Dependente adicionado.");
        navigate("/perfil/dependentes");
      },
      onError: () =>
        showToast(
          "error",
          `Não foi possível ${
            editMode ? "atualizar" : "cadastrar"
          } o dependente.`
        ),
    }
  );

  const onSubmit = handleSubmit((data: Dependent) => mutation.mutate(data));

  const [checked, setChecked] = useState<boolean>(false);

  const handleCheckboxChange = (event: any) => {
    setChecked(event.target.checked);
  };

  return (
    <PageLayout>
      <Container maxW="container.md" pt={5} pb={20}>
        <Stack spacing={10} w={["100%", "100%", "80%"]}>
          <Breadcrumb
            items={[
              { route: "/", label: "Início" },
              { route: "/perfil", label: "Perfil" },
              { route: "/perfil/dependentes", label: "Dependentes" },
              {
                route: "/perfil/editar-dependente",
                label: "Editar Dependente",
              },
            ]}
          />

          <Box alignSelf={"center"} w="full">
            <form
              id="dependent-form"
              onSubmit={onSubmit}
              style={{ paddingBottom: "1rem", width: "100%" }}
            >
              <Stack spacing={4}>
                <Heading size="md" fontWeight="500" color="primary">
                  {editMode
                    ? "Alterar dados do dependente"
                    : "Incluir novo familiar"}
                </Heading>
                <FormControl isReadOnly isInvalid={!!errors?.NOME_PESSOA?.message}>
                  <FormLabel fontWeight="400" color="gray.500">
                    Nome do dependente*
                  </FormLabel>
                  <Input readOnly type="text" placeholder="" {...register("NOME_PESSOA")} />
                  <FormErrorMessage>{errors?.NOME_PESSOA?.message}</FormErrorMessage>
                </FormControl>
                <FormControl isInvalid={!!errors?.TELEFONE_R?.message}>
                  <FormLabel fontWeight="400" color="gray.500">
                    Telefone Residencial
                  </FormLabel>
                  <Input type="text" placeholder="" {...register("TELEFONE_R")} />
                  <FormErrorMessage>
                    {errors?.TELEFONE_R?.message}
                  </FormErrorMessage>
                </FormControl>
                <FormControl isInvalid={!!errors?.TELEFONE_C?.message}>
                  <FormLabel fontWeight="400" color="gray.500">
                    Telefone Comercial
                  </FormLabel>
                  <Input type="text" placeholder="" {...register("TELEFONE_C")} />
                  <FormErrorMessage>
                    {errors?.TELEFONE_C?.message}
                  </FormErrorMessage>
                </FormControl>
                <FormControl isInvalid={!!errors?.TELEFONE_L?.message}>
                  <FormLabel fontWeight="400" color="gray.500">
                    Telefone Celular
                  </FormLabel>
                  <Input type="text" placeholder="" {...register("TELEFONE_L")} />
                  <FormErrorMessage>
                    {errors?.TELEFONE_L?.message}
                  </FormErrorMessage>
                </FormControl>
              </Stack>
            </form>
            {editMode ? (
              <></>
            ) : (
              <Checkbox onChange={(e) => handleCheckboxChange(e)}>
                <small>
                  Declaro que as informações preenchidas acima são verdadeiras e
                  estou ciente de que o Iate Clube podera verificar a veracidade
                  das informações.
                </small>
              </Checkbox>
            )}
          </Box>

          <Actions>
            <Link to="/perfil/dependentes">
              <ActionButton variant="outline">Cancelar</ActionButton>
            </Link>
            <ActionButton
              type="submit"
              form="dependent-form"
              loadingText="Processando..."
              isLoading={mutation.isLoading}
              disabled={!editMode && !checked}
            >
              {editMode ? " Salvar alterações" : "Incluir dependente"}
            </ActionButton>
          </Actions>
        </Stack>
      </Container>
    </PageLayout>
  );
};

export default DependentForm;
