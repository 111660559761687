import { Box, Circle, HStack, Image, Stack } from "@chakra-ui/react";
import { StepDTO } from "react-form-stepper/dist/components/Step/StepTypes";
import ActionButton from "../ActionButton/ActionButton";
import Actions from "../Actions/Actions";
import { PageTitle } from "../PageTitle/PageTitle";
import CustomStepper from "../Stepper/Stepper";
import userIcon from "../../assets/icons/user.svg";
import watchIcon from "../../assets/icons/watch.svg";
import calendarIcon from "../../assets/icons/calendar.svg";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../constants/routes";
import { ReactNode, useState } from "react";
import { ChevronLeftIcon } from "@chakra-ui/icons";
import ReCAPTCHA from "react-google-recaptcha";
import { useRecaptchaService } from "../../services/recaptcha.service";
import { useMutation } from "react-query";
import useCustomToast from "../../hooks/useCustomToast";

export type ReservationWizardProps = {
  hasRecaptcha?: boolean;
  image: any;
  title: string;
  reservationDetails: {
    title: string;
    date: string;
    time?: string | string[];
    person: string;
  };
  activeStep: number;
  advise?: string;
  handleConfirm: () => void;
  handleCancel: () => void;
  isLoading: boolean;
  aditionalDetails?: ReactNode[];
  extraButtons?: ReactNode[];
  disclaimer?: ReactNode;
};

export default function ReservationWizard({
  image,
  title,
  activeStep,
  reservationDetails,
  handleConfirm,
  handleCancel,
  advise,
  isLoading,
  aditionalDetails,
  extraButtons,
  disclaimer,
  hasRecaptcha
}: ReservationWizardProps) {
  const steps: StepDTO[] = [
    {
      label: "Reserva",
    },
    {
      label: "Concluído",
    },
  ];

  const navigate = useNavigate();

  const handleFinish = () => {
    navigate(ROUTES.RESERVATIONS_HISTORY, { replace: true });
  };

  const {showToast} = useCustomToast();

  const [recaptchaSolved, setRecaptchaSolved] = useState<boolean>(false);

  
  const recaptchaVerify = useMutation(
    (token: string) => {
      return validateRecaptcha(token);
    },
    {
      onSuccess: (res) => {
        if (res) {
          if (res.success) setRecaptchaSolved(true);
        }
      },
      onError: () => {
        setRecaptchaSolved(false);
        showToast("error", "Erro de comunicação ao validar recaptcha.");
      },
    }
  );

  const { validateRecaptcha } = useRecaptchaService();

  const onChangeRecaptcha = (value: any) => {
    if (value) recaptchaVerify.mutate(value);
  };

  const wizardBtn =
    activeStep === 0 ? (
      <HStack>
        <ActionButton
          variant={"outline"}
          leftIcon={<ChevronLeftIcon />}
          onClick={() => navigate(-1)}
        >
          Voltar
        </ActionButton>
        <ActionButton
          onClick={handleConfirm}
          isLoading={isLoading}
          loadingText={"Processando..."}
          disabled={hasRecaptcha && !recaptchaSolved}
        >
          Confirmar reserva
        </ActionButton>
      </HStack>
    ) : (
      <Box marginTop={"2rem"}>
        <Actions justify="center">
          <ActionButton
            variant={"danger"}
            onClick={handleCancel}
            isLoading={isLoading}
            loadingText={"Processando..."}
          >
            Cancelar reserva
          </ActionButton>
          <ActionButton variant={"primary"} onClick={handleFinish}>
            Finalizar
          </ActionButton>
          {extraButtons &&
            extraButtons.map((x: React.ReactNode, i) => (
              <div key={i}>{x}</div>
            ))}
        </Actions>
      </Box>
    );

  return (
    <Stack spacing={4} justifyContent={"center"} alignItems={"center"}>
      <Stack spacing={3}>
        <Image alt={title} src={image} maxH={"140px"} minH={"100px"} />
        <PageTitle>
          {activeStep === 0 ? title : "Pronto, reserva feita!"}
        </PageTitle>
        <CustomStepper steps={steps} activeStep={activeStep}></CustomStepper>
      </Stack>
      <Stack
        pb={4}
        spacing={4}
        w="full"
        justifyContent={"center"}
        alignItems="center"
      >
        <PageTitle size={20}>{reservationDetails.title}</PageTitle>
        <Stack>
          <HStack spacing={2}>
            <Image src={calendarIcon} alt="Data" />
            <small>{reservationDetails.date}</small>
          </HStack>
          {reservationDetails && reservationDetails.time ? (
            <HStack spacing={2}>
              <Image src={watchIcon} alt="Período" />
              {reservationDetails.time.length > 0 &&
                typeof reservationDetails.time !== "string" ? (
                <HStack>
                  <small>{reservationDetails.time[0]}</small>
                  <Circle size={"4px"} bg={"gray.400"} />
                  <small>{reservationDetails.time[1]}</small>
                </HStack>
              ) : (
                <small>{reservationDetails.time}</small>
              )}
            </HStack>
          ) : (
            <></>
          )}

          <HStack spacing={2}>
            <Image src={userIcon} alt="Responsável" />
            <small>{reservationDetails.person}</small>
          </HStack>
          {aditionalDetails &&
            aditionalDetails.map((x: React.ReactNode, i) => (
              <div key={i}>{x}</div>
            ))}
        </Stack>
        {disclaimer && <>{disclaimer}</>}
        {advise && activeStep > 0 && (
          <div>
            <b>Como se preparar?</b>
            <p style={{whiteSpace:"pre-wrap"}}>{advise}</p>
          </div>
        )}
      </Stack>
      {hasRecaptcha && 
       <ReCAPTCHA
       sitekey="6LfMRh4iAAAAAADRt0P3MCB30H0Sjg2L22Gr1uAa"
       onChange={onChangeRecaptcha}
       onErrored={() => showToast("error", "Recaptcha inválido.")}
     /> }
      <Actions justify="center">{wizardBtn}</Actions>
    </Stack>
  );
}
