import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay, Box, Container,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  HStack, Select,
  Stack,
  Text,
  useBreakpointValue,
  useDisclosure
} from "@chakra-ui/react";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import moment from "moment";
import { useEffect, useMemo, useRef, useState } from "react";
import { View, Views } from "react-big-calendar";
import { useMutation, useQuery } from "react-query";
import {
  Navigate,
  useLocation,
  useNavigate,
  useParams
} from "react-router-dom";
import gymReservation from "../../../assets/img/gymReservation.svg";
import ActionButton from "../../../components/ActionButton/ActionButton";
import Breadcrumb from "../../../components/Breadcrumb/Breadcrumb";
import CustomBigCalendar from "../../../components/Calendar/BigCalendar";
import LinkBox from "../../../components/LinkBox/LinkBox";
import Loading from "../../../components/Loading/Loading";
import { OverlayBackdrop } from "../../../components/OverlayBackdrop/OverlayBackdrop";
import PageLayout from "../../../components/PageLayout/PageLayout";
import { PageTitle } from "../../../components/PageTitle/PageTitle";
import ReservationWizard from "../../../components/ReservationWizard/ReservationWizard";
import { ROUTES } from "../../../constants/routes";
import { capitalize } from "../../../helpers/capitalize";
import useCustomToast from "../../../hooks/useCustomToast";
import {
  AvailableClassesMonthResponse,
  GymModality,
  GymModalityClass,
  GymReservationState,
  GymUser
} from "../../../models/reservations.model";
import { useReservationService } from "../../../services/reservation.service";

export interface IGymProps { }

export const Gym = (props: IGymProps) => {
  const [modalities, setModalities] = useState<GymModality[]>([]);
  const { showToast } = useCustomToast();
  const navigate = useNavigate();
  const { getGymModalities } = useReservationService();

  const handleItemSelected = (modalidade: GymModality) => {
    window.scrollTo(0, 0);
    navigate(ROUTES.RESERVATIONS_GYM_SCHEDULE.replace(':id', `${modalidade.iden}`), {
      state: { modality: modalidade },
    });
  };

  const queryModalities = useQuery<any[], Error>(
    "listaModalidadesAcademia",
    async () => {
      return await getGymModalities();
    },
    {
      refetchOnWindowFocus: false,
      onSuccess: (res: GymModality[]) => {
        setModalities(res);
      },
      onError: () =>
        showToast(
          "error",
          "Não foi possível listar as modalidades disponíveis para reserva."
        ),
    }
  );

  const gridLayout = useBreakpointValue({ base: '1fr', md: 'repeat(2, 1fr)' });

  return (
    <PageLayout>
      <Container maxW="container.md" pt={5} pb={20}>
        <Stack spacing={8}>
          <Breadcrumb
            items={[
              { route: "/", label: "Início" },
              { route: ROUTES.RESERVATIONS, label: "Reservas" },
              { route: ROUTES.RESERVATIONS_GYM_MODALITIES, label: "Academia" },
            ]}
          />
          <Stack spacing={14}>
            <PageTitle>Escolha da modalidade</PageTitle>
            <Stack spacing={5}>
              {queryModalities.isLoading || queryModalities.isFetching ? (
                <Flex w="full" justifyContent={"center"}>
                  <Loading />
                </Flex>
              ) : (
                <Grid templateColumns={gridLayout} gap={3}>
                  {modalities &&
                    modalities.map((x: GymModality, i) => (
                      <GridItem key={i} onClick={() => handleItemSelected(x)}>
                        <LinkBox
                          key={x.iden}
                          //   leftIcon={x.icone}
                          label={capitalize(x.descricao)}
                          noRoute
                        />
                      </GridItem>
                    ))}
                  {
                    modalities && modalities.length === 0 ?
                      <Text py={'200px'} alignSelf="center">Sem modalidades disponíveis.</Text> : <></>
                  }
                </Grid>
              )}
            </Stack>
          </Stack>
        </Stack>
      </Container>
    </PageLayout>
  );
};


type Teacher = {
  idenProfessor: number;
  nome: string;
};

type ScheduleModalityProps = {};

type AvailableClassesResponse = {
  dia: string;
  listaTurmas: GymModalityClass[];
  listaProfessores: Teacher[];
};

let fullTeacherList: Teacher[] = [];

export const ScheduleModality = ({ }: ScheduleModalityProps) => {
  const { id: modalityId } = useParams();
  const { state: prevState } = useLocation();

  const [month, setMonth] = useState(Number(moment().format("MM")));
  const [year, setYear] = useState(Number(moment().format("YYYY")));
  const [availableClasses, setAvailableClasses] = useState<AvailableClassesResponse>();
  const [availableClassesMonth, setAvailableClassesMonth] = useState<AvailableClassesMonthResponse[]>();
  const [resultsBkp, setResultsBkp] = useState<GymModalityClass[]>([]);
  const [results, setResults] = useState<GymModalityClass[]>([]);
  const [date, setDate] = useState<Date>(moment().toDate());
  const [view, setView] = useState<View>(Views.MONTH);
  const [teacherList, setTeacherList] = useState<Teacher[]>([]);
  const [selectedTeacher, setSelectedTeacher] = useState(0);
  const [selectedPeriod, setSelectedPeriod] = useState('');

  const navigate = useNavigate();
  const { showToast } = useCustomToast();
  const { getGymModalitiesClasses, getGymModalitiesClassesMonth, getTeacherModalities } = useReservationService();

  const queryGymClasses = useQuery<GymModalityClass[], Error>(
    ["listaModalidadesAcademiaTurmas", modalityId, moment(date).format('YYYYMMDD')],
    async () => {
      return await getGymModalitiesClasses(modalityId, moment(date).format('YYYYMMDD'), 0, '');
    },
    {
      retry: 0,
      refetchOnWindowFocus: false,
      onSuccess: (res: GymModalityClass[]) => {
        // console.log('queryGymClasses', res)
        if (res) {
          const listaProfessores: Teacher[] =
            Array.from(new Set(res.map(s => s.idenProfessor!)))
              .map((idenProfessor) => ({
                idenProfessor,
                nome: res.find(s => s.idenProfessor === idenProfessor)?.professor || ''
              }))
          const availableClass: AvailableClassesResponse = {
            dia: moment(date).format('DD/MM/YYYY'),
            listaTurmas: res,
            listaProfessores
          }
          setAvailableClasses(availableClass);
        }
      },
      onError: () => showToast("error", "Erro ao trazer lista de turmas."),
    }
  );

  const queryGymMonth = useQuery<AvailableClassesMonthResponse[], Error>(
    ["listaModalidadesAcademiaMes", month, year, selectedTeacher, selectedPeriod],
    async () => {
      return await getGymModalitiesClassesMonth(modalityId, month, year, selectedTeacher, selectedPeriod);
    },
    {
      retry: 0,
      enabled: false,
      refetchOnWindowFocus: false,
      onSuccess: (res: AvailableClassesMonthResponse[]) => {
        if (res) {
          setAvailableClassesMonth(res);
        }
      },
      onError: () => showToast("error", "Erro ao trazer o calendário mensal."),
    }
  );

  useQuery<Teacher[], Error>(
    ["listaProfessores", prevState.modality.idenModality],
    async () => {
      return await getTeacherModalities(prevState.modality.idenModality);
    },
    {
      retry: 0,
      refetchOnWindowFocus: false,
      onSuccess: (res: Teacher[]) => {
        if (res) {
          fullTeacherList = res;
          setTeacherList(fullTeacherList);
        }
      },
      onError: () => {
        fullTeacherList = [];
        setTeacherList(fullTeacherList);
        showToast("error", "Erro ao trazer lista de professores.");
      }
    }
  );

  useEffect(() => {
    // console.log('useEffect:availableClasses', availableClasses)
    // filterClassesByDate(moment(date).format("DD/MM/YYYY"));
    setTeachersListByDate();
    const resultsFiltered = onFilterTeacher(onFilterPeriod(availableClasses?.listaTurmas || []))
    setResults(resultsFiltered);
    setResultsBkp(resultsFiltered)
    // console.log('useEffect:availableClasses(resultsFiltered)', resultsFiltered)
  }, [availableClasses]);

  useEffect(() => {
    // console.log('useEffect:availableClassesMonth', availableClassesMonth)
    if (availableClassesMonth) {
      const newResults = availableClassesMonth.filter(a => a.qtdVaga > 0 && moment().isSameOrBefore(a.date, 'day')).map(a => ({
        disponibilidade: a.saldo,
        dtaHorarioInicio: a.date.toISOString(),
        dtaHorarioFim: a.date.toISOString()
      }));
      setResultsBkp(newResults);
      setResults(newResults);
    }
  }, [availableClassesMonth]);

  useEffect(() => {
    // console.log('useEffect:month, view')
    if (view === Views.MONTH || view === Views.WEEK) {
      queryGymMonth.refetch();
      setTeacherList(fullTeacherList);
    }
    else if (view === Views.DAY || view === Views.AGENDA) {
      queryGymClasses.refetch();
    }
  }, [month, view]);

  useEffect(() => {
    if(view === Views.DAY || view === Views.AGENDA) {
      if (selectedTeacher === 0 && selectedPeriod === '') {
        setResults(resultsBkp);
        return;
      }
      const res = onFilterTeacher(onFilterPeriod(resultsBkp));
      setResults(res);
    }
    else {
      queryGymMonth.refetch();
    }
  }, [selectedTeacher, selectedPeriod])

  const onFilterTeacher = (arr: GymModalityClass[]): GymModalityClass[] => {
    if (selectedTeacher === 0) return arr;

    const filtered = arr.filter(
      (x: GymModalityClass) => x.idenProfessor === selectedTeacher
    );
    return filtered;
  };

  const onFilterPeriod = (originalClasses: GymModalityClass[]): GymModalityClass[] => {
    let value = selectedPeriod;
    let arr: GymModalityClass[] = [...originalClasses];

    let filterFunction = (x: any) => { };
    if (value === "N") {
      filterFunction = (x) =>
        moment(x.dtaHorarioInicio).hours() >= 18
    } else if (value === "T") {
      filterFunction = (x) =>
        moment(x.dtaHorarioInicio).hours() >= 12 && moment(x.dtaHorarioInicio).hours() < 18
    } else if (value === "M") {
      filterFunction = (x) =>
        moment(x.dtaHorarioInicio).hours() < 12
    } else {
      filterFunction = (x) => x;
    }

    arr = [...arr.filter(filterFunction)];
    return arr;
  };

  const setTeachersListByDate = () => {
    let teachers: Teacher[] = availableClasses?.listaProfessores || fullTeacherList;
      // availableClasses?.find((x) => x.dia === moment(date).format("DD/MM/YYYY"))
      //   ?.listaProfessores || fullTeacherList;

    if (!teachers) return;

    // console.log("setTeachersListByDate", teachers, fullTeacherList)
    teachers.sort((a, b) => a.nome.localeCompare(b.nome));
    setTeacherList(teachers);
  };

  // const filterClassesByDate = (date: string, response = availableClasses) => {
  //   console.log('filterClassesByDate:', date)
  //   const res: any = response?.filter((x: any) => x.dia === date);
  //   if (res && res.length > 0) {
  //     if (res.length > 1) {
  //       let turmas: any[] = [];
  //       res.forEach((x: any) => {
  //         x.listaTurmas.forEach((t: any) => {
  //           turmas.push(t);
  //         });
  //       });
  //       setResults(turmas);
  //       setResultsBkp(turmas);
  //     } else {
  //       setResults(res[0].listaTurmas);
  //       setResultsBkp(res[0].listaTurmas);
  //     }
  //   } else {
  //     setResults([]);
  //     setResultsBkp([]);
  //   }
  // };

  const handleMonthChange = (e: any) => {
    if (!moment(e).isSame(date) && moment().isSameOrBefore(e, 'day')) {
      setMonth(Number(moment(e).format("MM")));
      setYear(Number(moment(e).format("YYYY")));
      setDate(e);
    }
  };

  const handleDrillDown = (data: Date) => {
    if (moment().isSameOrBefore(data, 'day')) {
      setDate(data);
      setView(Views.DAY)
    }
  }

  const handleClassSelected = (turma: GymModalityClass, date: string) => {
    if (!turma.iden || !turma.professor || !turma.idenProfessor) {
      return;
    }
    if (turma.disponibilidade <= 0) {
      return showToast("error", "Turma lotada!")
    }
    if (moment().isAfter(turma.dtaHorarioInicio)) {
      return showToast("error", "Não é possível agendar com data passada!")
    }
    window.scrollTo(0, 0);

    const _state: GymReservationState = {
      descricaoModalidade: prevState.modality.descricao,
      data: moment(date, 'DD/MM/YYYY').toDate(),
      horaFim: moment(turma.dtaHorarioFim).toDate(),
      horaInicio: moment(turma.dtaHorarioInicio).toDate(),
      idenTurma: turma.iden!,
      idenModalidade: prevState.modality.iden,
      idenReserva: null,
      nomeSocio: '',
      professor: turma.professor,
      idenProfessor: turma.idenProfessor,
      status: "NOVA_RESERVA",
      tipoReserva: "MODALIDADE_ESPORTIVA"
    };
    navigate(ROUTES.RESERVATIONS_GYM_RESERVE.replace(":id", modalityId!), {
      state: _state
    });
  };

  return (
    <PageLayout>
      <Container maxW="container.md" pt={5} pb={20}>
        <Stack spacing={8}>
          <Breadcrumb
            items={[
              { route: "/", label: "Início" },
              { route: ROUTES.RESERVATIONS, label: "Reservas" },
              { route: ROUTES.RESERVATIONS_GYM_MODALITIES, label: "Academia" },
              { route: ROUTES.RESERVATIONS_GYM_SCHEDULE.replace(':id', `${prevState.modality.iden}`), label: prevState.modality.descricao },
            ]}
          />
          <Stack spacing={2}>
            <PageTitle size={useBreakpointValue({ base: 16, md: 20, lg: 22 })}>AGENDA DE SERVIÇOS DA ACADEMIA</PageTitle>
            <PageTitle>Marcar Aula: {prevState.modality.descricao}</PageTitle>
          </Stack>
          {/* <> */}
          {/* {(!queryGymClasses.isError && queryGymClasses.isLoading) ||
              queryGymClasses.isFetching ? (
              <Flex w="full" justifyContent={"center"}>
                <Loading />
              </Flex>
            ) : ( */}
          <form>
            <Stack>
              <HStack spacing={6}>
                <FormControl w={["full", "full", "50%"]}>
                  <FormLabel>Turno</FormLabel>
                  <Select onChange={(opcao) => setSelectedPeriod(opcao.target.value)}>
                    <option value="">Todos</option>
                    <option value='M'>Matutino</option>
                    <option value='T'>Vespertino</option>
                    <option value='N'>Noturno</option>
                  </Select>
                </FormControl>

                <FormControl w={["full", "full", "50%"]}>
                  <FormLabel>Profissional</FormLabel>
                  <Select onChange={(opcao) => setSelectedTeacher(opcao.target.value !== '' ? parseInt(opcao.target.value) : 0)}>
                    <option value={0}>Todos</option>
                    {teacherList.map((x) => (
                      <option key={x.idenProfessor} value={x.idenProfessor}>
                        {x.nome}
                      </option>
                    ))}
                  </Select>
                </FormControl>
              </HStack>

              <CustomBigCalendar
                onNavigate={handleMonthChange}
                date={date}
                popup
                view={view}
                views={{month: true, day: true}}
                onView={setView}
                events={results.map(c => ({
                  title: `${c.disponibilidade === 0 ? 'LOTADO' : (c.disponibilidade + ' vaga' + (c.disponibilidade > 1 ? 's' : ''))}${c.professor ? `: ${c.professor}` : ''}`,
                  start: new Date(c.dtaHorarioInicio),
                  end: new Date(c.dtaHorarioFim),
                  allDay: c.dtaHorarioInicio === c.dtaHorarioFim,
                  resource: c
                }))}
                scrollToTime={results.map(c => new Date(c.dtaHorarioInicio)).sort((a, b) => a.getTime() - b.getTime()).shift()}
                onSelectEvent={(e) => {
                  if (!e.resource.iden) {
                    return handleDrillDown(e.start!);
                  }
                  handleClassSelected(e.resource, moment(e.start).format("DD/MM/YYYY"))
                }}
                onDrillDown={handleDrillDown}
                eventPropGetter={
                  (event) => ({
                    ...((event.resource.disponibilidade === 0) && {
                      style: {
                        backgroundColor: 'darkred',
                      },
                    }),
                    ...((moment().isAfter(event.resource.dtaHorarioInicio)) && {
                      style: {
                        backgroundColor: '#e6e6e6',
                        color: '#363636',
                      },
                    }),
                  })}
                dayPropGetter={
                  (data) => ({
                    ...((moment().isAfter(data)) && {
                      className: 'rbc-off-range-b rbc-off-range-bg'
                    }),
                  })}
              />

              {results.length === 0 ? <Text pt={5}>Não há turmas disponíveis.</Text> : <></>}
            </Stack>
          </form>
        </Stack>
      </Container>
    </PageLayout>
  );
};

enum Steps {
  CONFIRMACAO,
  FINALIZACAO,
}

export const GymWizard = () => {
  const location = useLocation();

  const state: GymReservationState = useMemo(() => location.state, [location.state]);

  const navigate = useNavigate();
  const { reserveGymModalityClass, cancelGymReservation, listGymUsers } = useReservationService();
  const { showToast } = useCustomToast();

  const [showBookingVoucher, setShowBookingVoucher] = useState(false);
  const [currentStep, setCurrentStep] = useState<Steps>(Steps.CONFIRMACAO);

  const [reservationId, setReservationId] = useState<number>(null!);

  const [gymUsers, setGymUsers] = useState<GymUser[]>([]);
  const [selectedUser, setSelectedUser] = useState(0);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const cancelRef: any = useRef<any>();

  useQuery<GymUser[], Error>(
    "listaPessoaAcademia", listGymUsers,
    {
      retry: 0,
      refetchOnWindowFocus: false,
      onSuccess: (users: GymUser[]) => {
        setGymUsers(users);
      },
      onError: () =>
        showToast(
          "error",
          "Não foi possível listar as pessoas disponíveis para reservar."
        ),
    }
  );

  const mutation = useMutation((gymState: GymReservationState) => reserveGymModalityClass(gymState), {
    onSuccess: (res: any) => {
      window.scrollTo(0, 0);
      setCurrentStep(Steps.FINALIZACAO);
      showToast("success", `Reserva feita com sucesso!`);
      // if (res && res.iden) setRerservationId(res.iden);
      if (res) {
        setReservationId(res[0].NU_SEQ_AGENDAMENTO);
        state.DE_INSTRUCAO = res[0].DE_INSTRUCAO;
        state.DE_DETALHAMENTO = res[0].DE_DETALHAMENTO;
      }
    },
    onError: (err: any) => {
      if (err && err.message) showToast("error", err.message);
      else showToast("error", "Não foi possível realizar a reserva.");
    },
  });

  const cancelReservationMutation = useMutation(
    () => cancelGymReservation(reservationId),
    {
      onSuccess: (res: any) => {
        window.scrollTo(0, 0);
        showToast("success", `Reserva cancelada!`);
        navigate(ROUTES.RESERVATIONS, { replace: true });
      },
      onError: (err: any) => {
        if (err && err.message) showToast("error", err.message);
        else showToast("error", "Não foi possível cancelar a reserva.");
      },
    }
  );

  useEffect(() => {
    if (!state) return;

    //quando usuário vier da tela 'Reservas realizadas'
    //ou tentar se matricular numa turma em que ele já tenha matrícula
    if (state.status === "REALIZADA") {
      setCurrentStep(Steps.FINALIZACAO);
      setReservationId(state.idenReserva!);
      const userSelected = gymUsers.findIndex(u => u.NOME_PESSOA === state.nomeSocio);
      setSelectedUser(userSelected);
      return;
    }
  }, [state, gymUsers]);

  if (!state) {
    return <Navigate to={ROUTES.RESERVATIONS} replace />;
  }

  const handleConfirmReservation = () => {
    const userSelected = gymUsers[selectedUser];
    if(userSelected) {
      state.matricula = userSelected.CD_MATRICULA;
      state.categoria = userSelected.CD_CATEGORIA;
      state.dependente = userSelected.SEQ_DEPENDENTE;
    }
    mutation.mutate(state);
  };

  const handleCancelReservation = () => {
    if (state) {
      const now = moment();
      const reservationDate = moment(state.data);
      if (now.diff(reservationDate, 's') / 3600 > 4) { //4 horas de antecedência de agora
        showToast('error', 'Reservas de academia só podem ser canceladas com pelo menos 4 horas de antecedência');
        return;
      }
    }

    onOpen();
  };

  const onCancel = () => {
    window.scrollTo(0, 0);
    cancelReservationMutation.mutate();
  }

  if(showBookingVoucher) {
    const downloadPdf = () => {
      const input = document.getElementById("print-pdf-invite");
      html2canvas(input!).then((canvas) => {
        const imgData: any = canvas.toDataURL();
        const pdf = new jsPDF({
          orientation: "portrait",
          unit: "px",
          compress: true,
        });
        const imgProps = pdf.getImageProperties(imgData);
        pdf.addImage(imgData, "PNG", 110, 20, imgProps.width*0.65, imgProps.height*0.65, "FAST");
        if (window.ReactNativeWebView) {
          window.ReactNativeWebView.postMessage(pdf.output("datauristring"));
          pdf.addImage(imgData, "PNG", 110, 20, imgProps.width*0.5, imgProps.height*0.4, "FAST");
        } else {
          pdf.save(`Comprovante Academia`);
        }
      });
    };
    return (
      <OverlayBackdrop
        setModalVisible={setShowBookingVoucher}
        modalVisible={showBookingVoucher}
        actions={
        <ActionButton onClick={downloadPdf}>
        Download
      </ActionButton>}
      >
        <Stack>
          <Box borderRadius={10}>
            <Stack px={6} spacing={2}>
                <Box id="print-pdf-invite"
                  maxWidth={['90%', '500px']}
                  bg="white"
                  borderRadius={8}
                  pb={[1, 6]}
                  border={"2px solid lightgrey"}
                  alignSelf="center"
                >
                  <Stack p={2}>
                    <Stack p={2} spacing={3} pb={0}>
                      <Heading size="sm" color="primary">
                        COMPROVANTE DE AGENDAMENTO DE SERVIÇO DA ACADEMIA
                      </Heading>
                      <Stack spacing={0}>
                        <small><b>Sócio:</b> {state.nomeSocio}</small>
                        <small><b>Serviço:</b> {state.descricaoModalidade} </small>
                        <small><b>Profissional:</b> {state.professor} </small>
                        <small><b>Data do Serviço:</b> {state.data.toLocaleString()} </small>
                        <small><b>Autenticação:</b> {state.idenReserva?.toString()} - {state.ID_AUTENTICACAO}</small>
                        <small><b>Data do Registro:</b> {state.dataRegistro?.toLocaleString()} </small>
                        <small style={{whiteSpace:"pre-wrap"}}><b>Detalhamento:</b> {state.DE_DETALHAMENTO} </small>
                        <small style={{whiteSpace:"pre-wrap"}}><b>Instruções:</b> {state.DE_INSTRUCAO} </small>
                      </Stack>
                    </Stack>
                  </Stack>
                </Box>
            </Stack>
          </Box>
        </Stack>
      </OverlayBackdrop>
    );
  }

  return (
    <PageLayout>
      <Container maxW="container.md" pt={5} pb={20}>
        <Stack spacing={8}>
          <Breadcrumb
            items={[
              { route: "/", label: "Início" },
              { route: ROUTES.RESERVATIONS, label: "Reservas" },
              { route: ROUTES.RESERVATIONS_GYM_MODALITIES, label: "Academia" },
            ]}
          />
          <ReservationWizard
            hasRecaptcha={true}
            image={gymReservation}
            title="Pronto para treinar?"
            reservationDetails={{
              date: moment(state.data).format("DD/MM/YYYY"),
              person: state.professor,
              time: [
                moment(state.horaInicio).format("HH:mm"),
                moment(state.horaFim).format("HH:mm"),
              ],
              title: state.descricaoModalidade,
            }}
            activeStep={currentStep}
            handleCancel={handleCancelReservation}
            handleConfirm={handleConfirmReservation}
            isLoading={cancelReservationMutation.isLoading || mutation.isLoading}
            aditionalDetails={[
              <Select
                disabled={state.status === "REALIZADA"}
                value={selectedUser}
                onChange={(opcao) =>
                  setSelectedUser(
                    opcao.target.value !== "" ? parseInt(opcao.target.value) : 0
                  )
                }
              >
                {gymUsers.map((x, index) => (
                  <option key={index} value={index}>
                    {x.NOME_PESSOA}
                  </option>
                ))}
              </Select>
            ]}
            advise={state.DE_INSTRUCAO}
            extraButtons={[
            <ActionButton variant="outline" onClick={() => setShowBookingVoucher(true)}>
            Comprovante
          </ActionButton>]}
          />
          <AlertDialog
            size="xs"
            isOpen={isOpen}
            leastDestructiveRef={cancelRef}
            onClose={onClose}
            isCentered
          >
            <AlertDialogOverlay>
              <AlertDialogContent>
                <AlertDialogHeader fontSize="lg" fontWeight="bold">
                  Cancelar reserva
                  <AlertDialogCloseButton />
                </AlertDialogHeader>

                <AlertDialogBody py={10}>
                  <Text textAlign="center">
                    Deseja realmente cancelar a reserva?
                  </Text>
                </AlertDialogBody>

                <AlertDialogFooter>
                  <HStack>
                    <ActionButton
                      w="full"
                      variant="outline"
                      onClick={() => {
                        onClose();
                      }}
                    >
                      Não
                    </ActionButton>
                    <ActionButton
                      w="full"
                      onClick={() => {
                        onCancel();
                        onClose();
                      }}
                    >
                      Sim
                    </ActionButton>
                  </HStack>
                </AlertDialogFooter>
              </AlertDialogContent>
            </AlertDialogOverlay>
          </AlertDialog>
        </Stack>
      </Container>
    </PageLayout>
  );
};
