import { useMemo } from "react";
import { ENDPOINTS } from "../constants/endpoints";
import useAxios from "../hooks/useAxios";
import { Dependent, DependentAlteracao } from "../models/dependent.model";
import { userManager } from "./authManager";

export function useDependentService() {
  const axios = useAxios();

  const getDependents = async () => {
    const user = userManager.get();

    let response = await axios.get(ENDPOINTS.getDependents, {
      params: {
        matricula: user.CD_MATRICULA,
        categoria: user.CD_CATEGORIA,
        usuario: user.USER_ACESSO_SISTEMA,
      },
    });
    return response.data;
  };

  const saveDependent = async (dependent: DependentAlteracao) => {

    const user = userManager.get();
    
    let payload = {
      ...dependent,
      matricula: user.CD_MATRICULA,
      categoria: user.CD_CATEGORIA,
      usuario: user.USER_ACESSO_SISTEMA,
    };

    return await axios.put(ENDPOINTS.saveDependent, null, {
      params: {
        ...payload,
      },
    });
  };

  const deleteDependent = async (id: string) => {
    let response = await axios.delete(ENDPOINTS.deleteDependent(id));
    return response.data;
  };

  const services = {
    getDependents,
    saveDependent,
    deleteDependent,
  };

  return services;
}
