import {
  GridItem,
  Heading,
  Image,
  SimpleGrid,
  Stack
} from "@chakra-ui/react";
import { useState } from "react";
import { useQuery } from "react-query";
import Loading from "../../../components/Loading/Loading";
import useCustomToast from "../../../hooks/useCustomToast";
import { Noticia } from "../../../models/news.model";
import { useNewsService } from "../../../services/news.service";
import styles from "./Noticias.module.scss";

export default function Noticias() {
  const [noticias, setNoticias] = useState<Noticia[]>([]);

  const { obterNoticias } = useNewsService();

  const { showToast } = useCustomToast();

  const { isLoading, isFetching } = useQuery<Noticia[], Error>(
    "obterNoticias",
    async () => await obterNoticias(),
    {
      retry: 5,
      refetchOnWindowFocus: false,
      onSuccess: (res: any) => {
        if (res) {
          setNoticias(res);
        }
      },
      onError: () => {
        showToast(
          "error",
          "Não foi possível obter as noticias"
        );
      },
    }
  );

  return (
    <Stack spacing={8}>
      <Heading color="primary">
        <span style={{ fontWeight: 300 }}>Iate</span>
        <span style={{ color: "#205bc0", fontWeight: 400 }}>News</span>
      </Heading>
      {/* <Divider /> */}
      { isLoading || isFetching
      ? <Loading/>
      :
      <SimpleGrid columns={[1, 1, 3]} rowGap={8} columnGap={8}>
        {noticias.map((noticia: Noticia, index: number) => (
          <GridItem key={index}>
            <Stack spacing={4} textAlign="center" justifyContent="center" alignItems="center">
              <a
                style={{ maxWidth: "fit-content" }}
                target="_blank"
                href={noticia.link}
              >
                <Image width="340px" height="225px" src={noticia.urlThumbnail} alt={noticia.titulo} className={styles.imagens}/>
              </a>
              <Heading fontSize={['18px']} fontWeight={500} color="primary">
                <a className={styles.noticiaLink} target="_blank" href={noticia.link}>
                  {noticia.titulo}
                </a>
              </Heading>
            </Stack>
          </GridItem>
        ))}
      </SimpleGrid>
      }
    </Stack>
  );
}
