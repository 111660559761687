import { ChevronLeftIcon } from "@chakra-ui/icons";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Container,
  Flex,
  Grid,
  GridItem,
  Heading,
  HStack,
  Icon,
  Image,
  Radio,
  RadioGroup,
  Stack,
  Text,
  useBreakpointValue,
  useDisclosure
} from "@chakra-ui/react";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import moment from "moment";
import { useEffect, useMemo, useRef, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { useForm } from "react-hook-form";
import { FaPrint } from "react-icons/fa";
import { FiAlertTriangle } from "react-icons/fi";
import { useMutation, useQuery } from "react-query";
import { useLocation, useNavigate } from "react-router-dom";
import calendarIcon from "../../../assets/icons/calendar.svg";
import userIcon from "../../../assets/icons/user.svg";
import watchIcon from "../../../assets/icons/watch.svg";
import barbecueImg from "../../../assets/img/barbecue_image.svg";
import mapaChurrasqueiras from "../../../assets/img/mapaChurrasqueiras.jpg";
import ActionButton from "../../../components/ActionButton/ActionButton";
import Actions from "../../../components/Actions/Actions";
import Breadcrumb from "../../../components/Breadcrumb/Breadcrumb";
import CustomCalendar from "../../../components/Calendar/Calendar";
import Loading from "../../../components/Loading/Loading";
import { OverlayBackdrop } from "../../../components/OverlayBackdrop/OverlayBackdrop";
import PageLayout from "../../../components/PageLayout/PageLayout";
import { PageTitle } from "../../../components/PageTitle/PageTitle";
import Paginator from "../../../components/Paginator/Paginator";
import PolicyAgreement from "../../../components/PolicyAgreement/PolicyAgreement";
import CustomStepper from "../../../components/Stepper/Stepper";
import { ROUTES } from "../../../constants/routes";
import useCustomToast from "../../../hooks/useCustomToast";
import useProfile from "../../../hooks/useProfile";
import {
  Churrasqueira,
  ChurrasqueiraReserva
} from "../../../models/churrasqueira.model";
import { policiyText } from "../../../policy/policy";
import { useChurrasqueiraService } from "../../../services/churrasqueira.service";
import { useProfileService } from "../../../services/profile.service";
import { useRecaptchaService } from "../../../services/recaptcha.service";
import styles from "./Barbecue.module.scss";

enum BarbecuePageViews {
  POLICIY,
  SELECT_BARBECUE,
  RESERVE_BARBECUE,
}

interface SelectBarbecueProps {
  onItemSelect: (item: Churrasqueira, date: string) => void;
}
const SelectBarbecue = ({ onItemSelect }: SelectBarbecueProps) => {
  const [date, setDate] = useState({
    rawDate: moment().add(1, "d").toDate(),
    formattedDate: moment().add(1, "d").format("YYYY-MM-DD"),
  });
  const [results, setResults] = useState<Churrasqueira[]>([]);
  const [resultsPage, setResultsPage] = useState<Churrasqueira[]>([]);

  const pageSize = useMemo(() => 5, []);

  const [currentPage, setCurrentPage] = useState<number>(0);

  const churrasqueiraService = useChurrasqueiraService();

  const handleDateChange = (date: Date) => {
    const formatted = moment(date).format("YYYY-MM-DD");
    setDate({ rawDate: date, formattedDate: formatted });
  };

  const isUnavailable = (item: Churrasqueira) => {
    if (!item.DESCRICAO) return true;
    return item.DESCRICAO?.search("LIVRE") < 0;
  };

  const { isLoading, refetch } = useQuery<Churrasqueira[], Error>(
    "listaChurrasqueiras",
    async () => {
      return await churrasqueiraService.listarChurrasqueirasPorData(
        date.formattedDate
      );
    },
    {
      refetchOnWindowFocus: false,
      onSuccess: (data: Churrasqueira[]) => {
        setResults(data);
        setResultsPage(data.slice(0, pageSize));
      },
      onError: (err) => console.error(err),
    }
  );

  const handlePageClick = (event: any) => {
    const page = event.selected;
    const array = [...results];
    setCurrentPage(page);
    setResultsPage(array.slice(page * pageSize, pageSize * (page + 1)));
  };

  useEffect(() => {
    refetch();
    setCurrentPage(0);
  }, [date, refetch]);

  const cols = useBreakpointValue({ base: [4, 4], lg: [3, 1] }) || [3, 1];

  return (
    <Box>
      {isLoading ? (
        <Flex justifyContent={"center"} paddingTop={"100px"}>
          <Loading />
        </Flex>
      ) : (
        <Grid templateColumns="repeat(4, 1fr)" gap={6}>
          <GridItem w="100%" colSpan={cols[0]}>
            <Stack alignSelf="center">
              <Box width="fit-content" alignSelf="center">
                <CustomCalendar
                  minDate={moment().add(1, "d").toDate()}
                  onChange={(item) => handleDateChange(item)}
                  date={date.rawDate}
                />
              </Box>
              <Box w={["100%", "70%"]} alignSelf="center">
                <Stack spacing={4} pt={5}>
                  {resultsPage.map((item: Churrasqueira, index) => {
                    let content = !item.DESCRICAO ? (
                      <Box key={index} className={styles.reservationListItem}>
                        <Stack>
                          <Text fontWeight="bold" color="gray" fontSize={18}>
                            CHURRASQUEIRA - {item.DE_ABREVIACAO}: INDISPONÍVEL
                          </Text>
                        </Stack>
                        <Button
                          minW="fit-content"
                          size="md"
                          onClick={() => onItemSelect(item, date.formattedDate)}
                          disabled={isUnavailable(item)}
                        >
                          Reservar
                        </Button>
                      </Box>
                    ) : (
                      <Box key={index} className={styles.reservationListItem}>
                        <Stack>
                          <Text fontWeight="bold" color="gray" fontSize={18}>
                            {item.DESCRICAO}
                          </Text>
                        </Stack>
                        <Button
                          minW="fit-content"
                          size="md"
                          onClick={() => onItemSelect(item, date.formattedDate)}
                          disabled={isUnavailable(item)}
                        >
                          Reservar
                        </Button>
                      </Box>
                    );

                    return content;
                  })}
                  {results && results.length == 0 ? (
                    <Text py={"200px"} alignSelf="center" textAlign={"center"}>
                      Não há churrasqueiras disponíveis <br /> para reserva na
                      data selecionada.
                    </Text>
                  ) : (
                    <></>
                  )}
                  <Box py={"1rem"}>
                    <Paginator
                      pageCount={Math.ceil(results.length / pageSize)}
                      forcePage={currentPage}
                      onPageChange={handlePageClick}
                    />
                  </Box>
                </Stack>
              </Box>
            </Stack>
          </GridItem>
          <GridItem w="100%" colSpan={cols[1]}>
            <Flex
              display={"flex"}
              alignItems="center"
              justifyContent={"center"}
            >
              <Stack alignSelf={"center"} maxW={"320px"}>
                <Stack
                  textAlign={"center"}
                  alignSelf={"center"}
                  py="10px"
                  spacing={2}
                >
                  <Heading size="md" color="primary">
                    Mapa das churrasqueiras
                  </Heading>
                  <Text color="gray.500" style={{ fontSize: "12px" }}>
                    Veja no mapa o posicionamento da churrasqueira de sua <br />{" "}
                    preferência e bom evento.
                  </Text>
                  <Image src={mapaChurrasqueiras} alt="Mapa" />
                </Stack>

                <Box
                  alignSelf={"center"}
                  p={4}
                  bg={"gray.100"}
                  borderRadius={10}
                >
                  <Stack>
                    <Stack textAlign={"center"} spacing={2}>
                      <Icon
                        alignSelf={"center"}
                        as={FiAlertTriangle}
                        color="primary"
                      />
                      <Heading size="md" color="primary">
                        Cancelamento de reserva
                      </Heading>
                    </Stack>
                    <Text
                      textAlign={"start"}
                      color="gray.500"
                      style={{ fontSize: "12px" }}
                    >
                      O cancelamento deverá ser feita até 72 horas antes da{" "}
                      <br />
                      data marcada para utilização da churrasqueira.
                    </Text>
                    <Text
                      textAlign={"start"}
                      color="gray.500"
                      style={{ fontSize: "12px" }}
                    >
                      O não cancelamento implicará a multa de 30% do valor{" "}
                      <br />
                      da contribuição mensal de administração, que será <br />
                      debitada à conta mensal do sócio.
                    </Text>
                  </Stack>
                </Box>
              </Stack>
            </Flex>
          </GridItem>
        </Grid>
      )}
    </Box>
  );
};

interface ConfirmReservationProps {
  selectedBarbecue: ChurrasqueiraReserva;
  emitInvites: () => void;
  reservationDone?: any;
}
const ConfirmReservation = ({
  selectedBarbecue,
  emitInvites,
  reservationDone,
}: ConfirmReservationProps) => {
  const [showBookingVoucher, setShowBookingVoucher] = useState(false);
  const [reservationConfirmed, setReservationConfirmed] =
    useState<ChurrasqueiraReserva | null>(reservationDone);
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [recaptchaSolved, setRecaptchaSolved] = useState<boolean>(false);
  const [verifySuspension, setVerifySuspension] = useState<boolean>(false);
  const [reservationId, setReservationId] = useState<string>("");
  const [reservationCancelled, setReservationCancelled] = useState<
    (ChurrasqueiraReserva & { CD_ANTENTICACAO: number; IC_TIPO: number }) | null
  >(null);
  const navigate = useNavigate();
  const { showToast } = useCustomToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef: any = useRef();
  const { user } = useProfile();
  const churrasqueiraService = useChurrasqueiraService();
  const { getIsSuspended } = useProfileService();

  useEffect(() => {
    if (reservationDone) {
      setReservationId(reservationDone.SEQ_RESERVA);
      setCurrentStep(1);
    }
  }, [reservationDone]);

  const nextStep = () => {
    setCurrentStep((old) => {
      return old + 1;
    });
    window.scrollTo(0, 0);
  };
  const backToList = () => {
    navigate("/reservas");
  };

  const backToBegin = () => {
    if (!!reservationDone) {
      navigate(ROUTES.RESERVATIONS_HISTORY, { replace: true });
      return;
    }
    navigate("/reservas", { replace: true });
    localStorage.setItem("reservationPolicyRead", "false");
  };

  const mutation = useMutation(
    (data: ChurrasqueiraReserva) => {
      let payload = {
        ...data,
      };
      payload.dataReserva = moment(payload.dataReserva, "YYYY-MM-DD").format('MM/DD/YYYY');
      return churrasqueiraService.reservarChurrasqueira(payload);
    },
    {
      onSuccess: (res: any) => {
        if (res && res[0]) {
          if (res[0].MSG !== "OK") {
            showToast("error", res[0].MSG);
            return;
          }
        }
        const id: string = res[0]?.SEQ_RESERVA;
        setReservationId(`${id}`);
        setReservationConfirmed({
          ...selectedBarbecue,
          ...res[0],
          DT_INIC_UTILIZACAO: moment(selectedBarbecue.dataReserva)
            .set("hour", selectedBarbecue.periodo === "D" ? 9 : 18)
            .toDate(),
          DT_FIM_UTILIZACAO: moment(selectedBarbecue.dataReserva)
            .set("hour", selectedBarbecue.periodo === "D" ? 18 : 22)
            .toDate(),
          DT_CONFIRMACAO: new Date(),
          NOME_INTERESSADO: user.NOME_PESSOA,
        });
        nextStep();
      },
      onError: (err: any) => {
        showToast("error", "Não foi possível completar a reserva.");
      },
    }
  );
  //cancelar aqui
  const cancelReservationMutation = useMutation(
    (id: any) => {
      return churrasqueiraService.cancelarReservaChurrasqueira(id);
    },
    {
      onSuccess: (cancelattionData) => {
        showToast("success", "Reserva cancelada com sucesso.");
        setReservationCancelled({
          ...(reservationDone ? reservationDone : selectedBarbecue),
          ...cancelattionData[0],
        });
        setShowBookingVoucher(true);
      },
      onError: () => {
        showToast(
          "error",
          "Não foi possível realizar o cancelamento da reserva."
        );
      },
    }
  );

  const { isLoading: isLoadingSuspensao, isFetching: isFetchingSuspensao } =
    useQuery<any, Error>(
      "verificaSuspensao",
      async () => {
        return await getIsSuspended();
      },
      {
        enabled: verifySuspension,
        retry: 5,
        refetchOnWindowFocus: false,
        onSuccess: (res: any) => {
          if (res && res.data[0].IC_DIREITO_SUSPENSO !== "N") {
            showToast(
              "error",
              "Direitos suspensos conforme inciso II do art. 57 do Estatuto do Clube."
            );
          } else {
            mutation.mutate(selectedBarbecue);
          }
          setVerifySuspension(false);
        },
      }
    );

  const handleConfirmReservation = () => {
    //verifica se usuario esta suspenso
    setVerifySuspension(true);
  };

  const openCancelDialog = () => {
    onOpen();
  };

  const handleCancelReservation = () => {
    if (reservationId === "") {
      showToast(
        "error",
        "Não foi possível realizar o cancelamento da rescanerva."
      );
    } else {
      cancelReservationMutation.mutate(reservationId);
    }
  };

  const getDate = () => {
    if (reservationDone) {
      return moment(reservationDone.DT_INIC_UTILIZACAO).format("DD/MM/YYYY");
    } else if (selectedBarbecue) {
      return moment(selectedBarbecue.dataReserva).format("DD/MM/YYYY");
    } else {
      return "Data Inválida";
    }
  };

  const getPeriodo = () => {
    let periodo = "";
    if (selectedBarbecue) {
      periodo = selectedBarbecue.periodo === "D" ? "diurno" : "noturno";
    }
    if (reservationDone) {
      periodo =
        moment(reservationDone.DT_INIC_UTILIZACAO).hours() < 18
          ? "diurno"
          : "noturno";
    }
    return `Período ${periodo}`;
  };

  const getHeading = () => {
    switch (currentStep) {
      case 0:
        return "Pronto para se divertir?";
      case 1:
        return "Pronto, reserva feita!";

      default:
        return "";
    }
  };

  type FormData = {
    selectedPeriod: "D" | "N";
  };

  const { register, handleSubmit } = useForm<FormData>({});

  const onSubmit = handleSubmit((data: FormData) => {
    selectedBarbecue.periodo = data.selectedPeriod;
    handleConfirmReservation();
  });

  const getActionButtons = () => {
    switch (currentStep) {
      case 0:
        return (
          <Actions justify="center">
            <ActionButton
              variant="outline"
              onClick={backToList}
              disabled={mutation.isLoading}
            >
              Voltar
            </ActionButton>
            <ActionButton
              type="submit"
              form="reserve-barbecue-period-select"
              disabled={
                mutation.isLoading || isLoadingSuspensao || !recaptchaSolved
              }
              isLoading={mutation.isLoading || isLoadingSuspensao}
              loadingText="Processando"
            >
              Confirmar reserva
            </ActionButton>
          </Actions>
        );
      case 1:
        return (
          <Actions justify="center">
            <ActionButton variant="outline" onClick={backToBegin}>
              Voltar
            </ActionButton>
            <ActionButton
              variant="danger"
              loadingText="Cancelando..."
              isLoading={cancelReservationMutation.isLoading}
              onClick={openCancelDialog}
            >
              Cancelar reserva
            </ActionButton>
            <ActionButton onClick={emitInvites}>
              Emissão de convites
            </ActionButton>
            <ActionButton
              variant="outline"
              onClick={() => setShowBookingVoucher(true)}
            >
              Comprovante
            </ActionButton>
          </Actions>
        );

      default:
        break;
    }
  };

  const recaptchaVerify = useMutation(
    (token: string) => {
      return validateRecaptcha(token);
    },
    {
      onSuccess: (res) => {
        if (res) {
          if (res.success) setRecaptchaSolved(true);
        }
      },
      onError: () => {
        setRecaptchaSolved(false);
        showToast("error", "Erro de comunicação ao validar recaptcha.");
      },
    }
  );

  const { validateRecaptcha } = useRecaptchaService();

  const onChangeRecaptcha = (value: any) => {
    if (value) recaptchaVerify.mutate(value);
  };

  if (showBookingVoucher && reservationConfirmed && !reservationCancelled) {
    const downloadPdf = () => {
      const input = document.getElementById("print-pdf-comprovante");

      html2canvas(input!).then((canvas) => {
        const imgData: any = canvas.toDataURL("image/png");
        const pdf = new jsPDF({
          orientation: "portrait",
          unit: "px",
          compress: false,
        });
        const imgProps = pdf.getImageProperties(imgData);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
        if (window.ReactNativeWebView) {
          pdf.addImage(imgData, "PNG", 110, 20, pdfWidth * 0.35, pdfHeight, "SLOW");
          window.ReactNativeWebView.postMessage(pdf.output("datauristring"));
        } else {
          pdf.addImage(imgData, "PNG", 60, 20, pdfWidth * 0.8, pdfHeight, "SLOW");
          pdf.save(
            `Comprovante de Reserva${
              reservationConfirmed.AUTENTICACAO
                ? ` - ${reservationConfirmed.AUTENTICACAO}`
                : ""
            }`
          );
        }
      });
    };
    return (
      <Stack spacing={3} pt={14}>
        <Box alignSelf={'center'} id="print-pdf-comprovante" className={styles.boxVoucher} maxWidth={'450px'}>
          <PageTitle size={18}>Comprovante de Reserva de Dependência</PageTitle>
          <Flex
            direction={"row"}
            alignItems={"flex-start"}
            justifyContent={"flex-start"}
          >
            <Stack w="full">
              <Text>
                <b>Dependência:</b> {reservationConfirmed.DESCR_DEPENDENCIA}
              </Text>
              <Text>
                <b>Data de Utilização:</b>{" "}
                {moment(reservationConfirmed.DT_INIC_UTILIZACAO).format(
                  "DD/MM/YYYY"
                )}
              </Text>
              <Text>
                <b>Previsão de horário:</b>{" "}
                {`${moment(reservationConfirmed.DT_INIC_UTILIZACAO).format(
                  "HH:mm"
                )}hs às ${moment(reservationConfirmed.DT_FIM_UTILIZACAO).format(
                  "HH:mm"
                )}hs`}
              </Text>
              <Text>
                <b>Sócio:</b> {reservationConfirmed.NOME_INTERESSADO}
              </Text>
              <Text>
                <b>Data de confirmação:</b>{" "}
                {moment(reservationConfirmed.DT_CONFIRMACAO).format(
                  "DD/MM/YYYY HH:mm:ss"
                )}
              </Text>
              <Text>
                <b>Autenticação:</b> {reservationConfirmed.AUTENTICACAO}
              </Text>
              <Text>
                <b>Telefone p/ Contato</b> {reservationConfirmed.TEL_CONTATO}
              </Text>
              <Text>
                <b>E-mail</b> {reservationConfirmed.DE_EMAIL}
              </Text>
              <Text>
                <b>Fax</b> {reservationConfirmed.FAX}
              </Text>
            </Stack>
          </Flex>
        </Box>
        <Actions justify="center">
          <ActionButton
            variant="outline"
            onClick={() => setShowBookingVoucher(false)}
            leftIcon={<ChevronLeftIcon />}
          >
            Voltar
          </ActionButton>
          <ActionButton onClick={downloadPdf} leftIcon={<FaPrint />}>
            Imprimir
          </ActionButton>
        </Actions>
      </Stack>
    );
  }

  if (reservationCancelled) {
    const downloadPdf = () => {
      const input = document.getElementById("print-pdf-cancelamento");

      html2canvas(input!).then((canvas) => {
        const imgData: any = canvas.toDataURL("image/png");
        const pdf = new jsPDF({
          orientation: "portrait",
          unit: "px",
          compress: false,
        });
        const imgProps = pdf.getImageProperties(imgData);
        const pdfWidth = pdf.internal.pageSize.getWidth() ;
        const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
        if (window.ReactNativeWebView) {
          pdf.addImage(imgData, "PNG", 110, 20, pdfWidth * 0.35, pdfHeight, "SLOW");
          window.ReactNativeWebView.postMessage(pdf.output("datauristring"));
        } else {
          pdf.addImage(imgData, "PNG", 50, 20, pdfWidth * 0.8, pdfHeight, "SLOW");
          pdf.save(
            `Comprovante de Cancelamento - ${reservationCancelled.CD_ANTENTICACAO}`
          );
        }
      });
    };
    return (
      <Stack spacing={3} pt={14}>
        <Box
        alignSelf={'center'}
          id="print-pdf-cancelamento"
          className={styles.boxVoucher}
          maxWidth={"450px"}
        >
          <PageTitle size={18}>
            Comprovante de Cancelamento de Reserva de Dependência
          </PageTitle>
          <Flex
            direction={"row"}
            alignItems={"flex-start"}
            justifyContent={"flex-start"}
          >
            <Stack w="full">
              <Text>
                <b>Dependência:</b> {reservationCancelled.DESCR_DEPENDENCIA}
              </Text>
              <Text>
                <b>Data de Utilização:</b>{" "}
                {moment(reservationCancelled.DT_INIC_UTILIZACAO).format(
                  "DD/MM/YYYY"
                )}
              </Text>
              <Text>
                <b>Previsão de horário:</b>{" "}
                {`${moment(reservationCancelled.DT_INIC_UTILIZACAO).format(
                  "HH:mm"
                )} às ${moment(reservationCancelled.DT_FIM_UTILIZACAO).format(
                  "HH:mm"
                )}`}
              </Text>
              <Text>
                <b>Sócio:</b> {reservationCancelled.NOME_INTERESSADO}
              </Text>
              <Text>
                <b>Data de confirmação:</b>{" "}
                {moment(reservationCancelled.DT_CONFIRMACAO).format(
                  "DD/MM/YYYY"
                )}
              </Text>
              <Text>
                <b>Autenticação do cancelamento:</b>{" "}
                {reservationCancelled.CD_ANTENTICACAO}
              </Text>
              <Text>
                <b>Assinatura eletrônica do sócio:</b>{" "}
                {reservationCancelled.NOME_INTERESSADO}
              </Text>
            </Stack>
          </Flex>
        </Box>
        <Actions justify="center">
          <ActionButton
            variant="outline"
            onClick={() => navigate("/reservas", { replace: true })}
            leftIcon={<ChevronLeftIcon />}
          >
            Voltar
          </ActionButton>
          <ActionButton onClick={downloadPdf} leftIcon={<FaPrint />}>
            Imprimir
          </ActionButton>
        </Actions>
      </Stack>
    );
  }

  return (
    <Stack spacing={3} pt={14}>
      <Stack spacing={10} pt={14}>
        <Stack alignSelf="center" spacing={4}>
          <Image
            height={["150px", "175px", "190px"]}
            alt="Churrasqueira"
            src={barbecueImg}
          />
          <Heading textAlign="center" alignItems="center" color="primary">
            {getHeading()}
          </Heading>
        </Stack>
        <CustomStepper
          activeStep={currentStep}
          steps={[{ label: "Reserva" }, { label: "Confirmação" }]}
        />
        <Flex justifyContent="center" width={["100%"]}>
          <Stack pt={4} color="gray.500">
            <Heading mb={2} size="md" color="primary">
              {/* {selectedBarbecue?.facility?.descricao} */}
            </Heading>
            <HStack>
              <Image src={calendarIcon} alt="Data" />
              <small>{getDate()}</small>
            </HStack>
            {currentStep === 1 && (
              <HStack>
                <Image src={watchIcon} alt="Período" />
                <small>{getPeriodo()}</small>
              </HStack>
            )}
            <HStack>
              <Image src={userIcon} alt="Titular" />
              <small>{user.NOME_PESSOA}</small>
            </HStack>
            {currentStep === 0 && (
              <Stack pt={6} spacing={6}>
                <Heading mb={2} size="sm" color="primary">
                  Escolha o período
                </Heading>
                <form onSubmit={onSubmit} id="reserve-barbecue-period-select">
                  <RadioGroup defaultValue="D">
                    <Stack spacing={1}>
                      <Radio {...register("selectedPeriod")} value="D">
                        Diurno
                      </Radio>
                      <Radio {...register("selectedPeriod")} value="N">
                        Noturno
                      </Radio>
                    </Stack>
                  </RadioGroup>
                </form>

                <ReCAPTCHA
                  sitekey="6LfMRh4iAAAAAADRt0P3MCB30H0Sjg2L22Gr1uAa"
                  onChange={onChangeRecaptcha}
                  onErrored={() => showToast("error", "Recaptcha inválido.")}
                /> 
              </Stack>
            )}
          </Stack>
        </Flex>
        {getActionButtons()}
      </Stack>
      <AlertDialog
        size="xs"
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        isCentered
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Cancelar reserva
              <AlertDialogCloseButton />
            </AlertDialogHeader>

            <AlertDialogBody py={10}>
              <Text textAlign="center">
                Deseja realmente cancelar a reserva?
              </Text>
            </AlertDialogBody>

            <AlertDialogFooter>
              <HStack>
                <ActionButton
                  w="full"
                  variant="outline"
                  onClick={() => {
                    onClose();
                  }}
                >
                  Não
                </ActionButton>
                <ActionButton
                  w="full"
                  onClick={() => {
                    handleCancelReservation();
                    onClose();
                  }}
                >
                  Sim
                </ActionButton>
              </HStack>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Stack>
  );
};

const Barbecue = () => {
  const { state } = useLocation();
  const navigate = useNavigate();

  const [currentView, setCurrentView] = useState<BarbecuePageViews>(
    BarbecuePageViews.POLICIY
  );

  const [selectedBarbecue, setSelectedBarbecue] =
    useState<ChurrasqueiraReserva>();

  useEffect(() => {
    if (!state) return;
    if (state.status === "REALIZADA") {
      setCurrentView(BarbecuePageViews.RESERVE_BARBECUE);
    }
  }, [state]);

  const nextStep = () => {
    setCurrentView((old) => {
      return old + 1;
    });
    window.scrollTo(0, 0);
  };

  const handlePolicyAction = (agreed: boolean) => {
    if (agreed) {
      nextStep();
    } else {
      navigate("/reservas", { replace: true });
    }
  };

  const handleBarbecueReservationSelected = (
    item: Churrasqueira,
    dataReserva: string
  ) => {
    setSelectedBarbecue({
      seqDependencia: item.SEQ_DEPENDENCIA,
      periodo: "D",
      dataReserva,
    });
    nextStep();
  };

  const handlEmitInvites = () => {
    navigate(`${ROUTES.CHURRASQUEIRAS_CONVITES}`, { replace: true });
  };

  const getView = (): JSX.Element => {
    switch (currentView) {
      case BarbecuePageViews.POLICIY:
        return (
          <PolicyAgreement
            title="Regulamento de uso da reserva"
            text={policiyText}
            agreed={handlePolicyAction}
          />
        );

      case BarbecuePageViews.SELECT_BARBECUE:
        return (
          <SelectBarbecue onItemSelect={handleBarbecueReservationSelected} />
        );

      case BarbecuePageViews.RESERVE_BARBECUE:
        return (
          <ConfirmReservation
            selectedBarbecue={selectedBarbecue!}
            emitInvites={handlEmitInvites}
            reservationDone={
              state && state.status === "REALIZADA" ? state : null
            }
          />
        );

      default:
        return <></>;
    }
  };

  return (
    <PageLayout>
      <Container maxW="container.lg" pt={5} pb={20}>
        <Breadcrumb
          items={[
            { route: "/", label: "Início" },
            { route: ROUTES.RESERVATIONS, label: "Reservas" },
            { route: ROUTES.RESERVATIONS_BARBECUE, label: "Churrasqueira" },
          ]}
        />
        {getView()}
      </Container>
    </PageLayout>
  );
};

export default Barbecue;
