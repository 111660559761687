// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Jornal_stack__3TLPq {\n  margin-top: 2rem;\n}\n.Jornal_stack__3TLPq iframe {\n  height: 100vh;\n}", "",{"version":3,"sources":["webpack://src/pages/Jornal/Jornal.module.scss"],"names":[],"mappings":"AACA;EACI,gBAAA;AAAJ;AAEI;EACI,aAAA;AAAR","sourcesContent":["\r\n.stack{\r\n    margin-top: 2rem;\r\n\r\n    iframe {\r\n        height: 100vh;\r\n     }\r\n     \r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"stack": "Jornal_stack__3TLPq"
};
export default ___CSS_LOADER_EXPORT___;
