// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Barbecue_calendarWrapper__kt3P8 {\n  align-self: center;\n  width: -moz-fit-content;\n  width: fit-content;\n}\n\n.Barbecue_reservationListItem__rpkyc {\n  border-radius: 8px;\n  background: rgba(49, 49, 49, 0.05);\n  padding: 1rem;\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n}\n\n.Barbecue_boxVoucher__5irxM {\n  width: 100%;\n  border: 2px solid rgb(207, 207, 207);\n  background-color: white;\n  border-radius: 6px;\n  display: flex;\n  font-size: 1rem;\n  flex-direction: column;\n  padding: 2rem;\n  grid-gap: 1rem;\n  gap: 1rem;\n}", "",{"version":3,"sources":["webpack://src/pages/Reservations/Barbecue/Barbecue.module.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,uBAAA;EAAA,kBAAA;AACJ;;AAEA;EACI,kBAAA;EACA,kCAAA;EACA,aAAA;EACA,aAAA;EACA,mBAAA;EACA,8BAAA;AACJ;;AAEA;EACI,WAAA;EACA,oCAAA;EACA,uBAAA;EACA,kBAAA;EACA,aAAA;EACA,eAAA;EACA,sBAAA;EACA,aAAA;EACH,cAAA;EAAA,SAAA;AACD","sourcesContent":[".calendarWrapper{\r\n    align-self: center;\r\n    width: fit-content;\r\n}\r\n\r\n.reservationListItem{\r\n    border-radius: 8px;\r\n    background: rgba(49, 49, 49, 0.05);\r\n    padding: 1rem;\r\n    display: flex;\r\n    align-items: center;\r\n    justify-content: space-between;\r\n}\r\n\r\n.boxVoucher {\r\n    width: 100%;\r\n    border: 2px solid rgb(207, 207, 207);\r\n    background-color: white;\r\n    border-radius: 6px;\r\n    display: flex;\r\n    font-size: 1rem;\r\n    flex-direction: column;\r\n    padding: 2rem;\r\n\tgap: 1rem;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"calendarWrapper": "Barbecue_calendarWrapper__kt3P8",
	"reservationListItem": "Barbecue_reservationListItem__rpkyc",
	"boxVoucher": "Barbecue_boxVoucher__5irxM"
};
export default ___CSS_LOADER_EXPORT___;
