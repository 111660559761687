// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Loading_blockUi__1xVEB {\n  background: rgba(255, 255, 255, 0.589);\n  height: 100%;\n  -webkit-backdrop-filter: blur(2px);\n          backdrop-filter: blur(2px);\n  position: absolute;\n  top: 0;\n  left: 0;\n  z-index: 100;\n}", "",{"version":3,"sources":["webpack://src/components/Loading/Loading.module.scss"],"names":[],"mappings":"AAAA;EACI,sCAAA;EACA,YAAA;EACA,kCAAA;UAAA,0BAAA;EACA,kBAAA;EACA,MAAA;EACA,OAAA;EACA,YAAA;AACJ","sourcesContent":[".blockUi{\r\n    background: rgba(255, 255, 255, 0.589);\r\n    height: 100%;\r\n    backdrop-filter: blur(2px);\r\n    position: absolute;\r\n    top: 0;\r\n    left: 0;\r\n    z-index: 100;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"blockUi": "Loading_blockUi__1xVEB"
};
export default ___CSS_LOADER_EXPORT___;
