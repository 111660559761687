// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ProfileForm_profileForm__3ZnMo {\n  display: flex;\n  flex-direction: column;\n}\n\n.ProfileForm_avatar__37qsd {\n  align-self: center;\n}", "",{"version":3,"sources":["webpack://src/pages/Profile/ProfileForm/ProfileForm.module.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,sBAAA;AACJ;;AAGA;EACI,kBAAA;AAAJ","sourcesContent":[".profileForm{\r\n    display: flex;\r\n    flex-direction: column;\r\n}\r\n\r\n\r\n.avatar{\r\n    align-self: center;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"profileForm": "ProfileForm_profileForm__3ZnMo",
	"avatar": "ProfileForm_avatar__37qsd"
};
export default ___CSS_LOADER_EXPORT___;
