// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TransparencyPortal_table__1dLdp td {\n  cursor: pointer;\n}\n.TransparencyPortal_table__1dLdp td:hover {\n  background-color: #f3f3f3;\n}\n\n.TransparencyPortal_chevron__2Wouh {\n  font-size: 26px;\n}", "",{"version":3,"sources":["webpack://src/pages/TransparencyPortal/TransparencyPortal.module.scss"],"names":[],"mappings":"AAEE;EACE,eAAA;AADJ;AAEI;EACI,yBAAA;AAAR;;AAOE;EACE,eAAA;AAJJ","sourcesContent":["\r\n.table {\r\n  td{\r\n    cursor: pointer;\r\n    &:hover {\r\n        background-color: #f3f3f3;\r\n    }\r\n  }\r\n \r\n  }\r\n  \r\n  \r\n  .chevron{\r\n    font-size: 26px;\r\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"table": "TransparencyPortal_table__1dLdp",
	"chevron": "TransparencyPortal_chevron__2Wouh"
};
export default ___CSS_LOADER_EXPORT___;
