import Axios, { AxiosRequestConfig } from 'axios';
import { ENDPOINTS } from '../constants/endpoints';
import { authManager, userManager } from '../services/authManager';

function createAxiosInstance(type: string) {
  const axios = Axios.create({
    baseURL: ENDPOINTS.apiBaseUrl,
    headers: {
      'Content-Type': type,
      Accept: '*/*',
      'Access-Control-Expose-Headers': 'Authorization',
      'Access-Control-Allow-Origin': '*',
    },
    timeout: 90000000,
  });

  axios.interceptors.request.use((config: AxiosRequestConfig) => {
    const token = authManager.get().token;
    if (token) {
      config.headers!.Authorization = `Bearer ${token}`;
    }
    return config;
  });

  axios.interceptors.response.use(response => {
    return response;
  },
    (err) => {
      if (err?.response?.status === 403 || err?.response?.status === 401) {
        authManager.clear();
        userManager.clear();
        if(window.location.pathname !== '/login'){
          window.location.pathname = '/login'
        }
      }

      return Promise.reject(err);
    });

  return axios;
}

export default function useAxios(type: string = 'application/json') {
  return createAxiosInstance(type);
}

export function getAxios(type: string = 'application/json') {
  return createAxiosInstance(type);
}
