// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Contact_socialMedia__24aU5 {\n  height: 37px;\n  width: 37px;\n  border-radius: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  background: #ffffff;\n  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.04), 0px 4px 8px rgba(0, 0, 0, 0.16);\n}", "",{"version":3,"sources":["webpack://src/pages/Contact/Contact.module.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,WAAA;EACA,mBAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,mBAAA;EACA,4EAAA;AACF","sourcesContent":[".socialMedia {\r\n  height: 37px;\r\n  width: 37px;\r\n  border-radius: 100%;\r\n  display: flex;\r\n  align-items: center;\r\n  justify-content: center;\r\n  background: #ffffff;\r\n  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.04), 0px 4px 8px rgba(0, 0, 0, 0.16);\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"socialMedia": "Contact_socialMedia__24aU5"
};
export default ___CSS_LOADER_EXPORT___;
