export const policiyText: JSX.Element = (
    <div>
      <p>
        REGULAMENTO DO SETOR DE CHURRASQUEIRAS Aprovado pelo Conselho Deliberativo
        nas reuni&otilde;es extraordin&aacute;rias de 26/2/2013, 26/3/2013 e
        23/4/2013.
      </p>
  
      <p> DISPOSI&Ccedil;&Otilde;ES PRELIMINARES</p>
  
      <p>
        Art. 1o - O presente regulamento tem por finalidade disciplinar a
        utiliza&ccedil;&atilde;o das instala&ccedil;&otilde;es do Setor de
        Churrasqueiras do Iate Clube de Bras&iacute;lia. Art. 2o - As
        Churrasqueiras s&atilde;o classificadas como: I. Pequenas, as de
        n&uacute;meros P1, P2, P3, P4, P5, P6, P7, P8, P9, P10, P11, P12. II.
        M&eacute;dias, as de n&uacute;meros M1, M2, M3, M4 III. Gourmet grandes,
        as de n&uacute;meros G1, G2, G3 E G4. Par&aacute;grafo &uacute;nico. As
        reclassifica&ccedil;&otilde;es e as renumera&ccedil;&otilde;es das
        churrasqueiras ser&atilde;o efetuadas pelo Conselho Diretor de acordo com
        as necessidades futuras. Art. 3o - Poder&aacute; fazer uso gratuito das
        instala&ccedil;&otilde;es do Setor os s&oacute;cios patrimoniais e
        usu&aacute;rio no pleno gozo de seus direitos estatut&aacute;rios e em dia
        com suas obriga&ccedil;&otilde;es.
      </p>
  
      <p> DA RESERVA DE CHURRASQUEIRA</p>
  
      <p>
        Art. 4o - A reserva de churrasqueira poder&aacute; ser feita: I. Pelos
        s&oacute;cios patrimonial e usu&aacute;rio, pessoalmente na Secretaria
        Social ou pela internet, mediante pr&eacute;vio cadastramento de senha; e
        II. pelo dependente, mediante autoriza&ccedil;&atilde;o por escrito do
        s&oacute;cio titular dada na Secretaria. Art. 5o - N&atilde;o ser&aacute;
        admitida reserva de churrasqueira por fax, telefone ou e-mail. Art. 6o -
        No ato da reserva de qualquer uma das churrasqueiras, quando efetuada
        pessoalmente na Secretaria, os s&oacute;cios patrimoniais, usu&aacute;rios
        ou seus dependentes formalmente autorizados, dever&atilde;o assinar termo
        de conhecimento do Regulamento da utiliza&ccedil;&atilde;o das
        churrasqueiras, contendo data da reserva, n&uacute;mero da churrasqueira,
        n&uacute;mero do telefone, fax e endere&ccedil;o eletr&ocirc;nico para
        contato do interessado com o Iate. I. Quando a reserva for feita pela
        internet, o s&oacute;cio dever&aacute; concordar com os termos do
        Regulamento; II. Em todas as churrasqueiras dever&aacute; existir
        c&oacute;pia do Regulamento afixada em local vis&iacute;vel. Art. 7o - A
        reserva de uma churrasqueira ser&aacute; feita por um dia, para cada
        t&iacute;tulo patrimonial, com anteced&ecirc;ncia de, no m&aacute;ximo, 30
        (trinta) dias, nas condi&ccedil;&otilde;es estabelecidas nos incisos deste
        artigo. I. Churrasqueira pequena: uma vez por quinzena para cada
        t&iacute;tulo patrimonial, vedada outra reserva durante esse
        per&iacute;odo, exceto se, no prazo de 72 (setenta e duas) horas, contadas
        a partir do dia do pedido da reserva, houver disponibilidade constatada
        pela Secretaria Social; AI. Churrasqueira m&eacute;dia: uma vez por
        m&ecirc;s para cada t&iacute;tulo patrimonial, vedada outra reserva
        durante esse per&iacute;odo, exceto se, no prazo de 72 (setenta e duas)
        horas, contadas a partir do dia do pedido da reserva, houver
        disponibilidade constatada pela Secretaria Social; e BI. Churrasqueira
        grande ou gourmet: uma vez por quadrimestre-calend&aacute;rio para cada
        t&iacute;tulo patrimonial, aos s&aacute;bados, domingos, feriados e dias
        festivos do clube, e uma vez por trimestre-calend&aacute;rio para cada
        t&iacute;tulo patrimonial, de segunda a sexta, vedada nova reserva durante
        esse per&iacute;odo, exceto se no prazo de 72 (setenta e duas) horas,
        contadas a partir do dia do pedido da reserva, houver disponibilidade
        constatada pela Secretaria Social. Art. 8o - A churrasqueira reservada
        estar&aacute; dispon&iacute;vel a partir das 9 (nove) horas do dia da
        reserva.
      </p>
  
      <p>
        Art. 9o - No ato de entrega da churrasqueira, empregado do clube
        proceder&aacute; &agrave; vistoria do local, ocasi&atilde;o em que
        produzir&aacute; lista dos m&oacute;veis, equipamentos e
        instala&ccedil;&otilde;es, e seus respectivos estados de
        conserva&ccedil;&atilde;o, documento este que ser&aacute; assinado pelo
        s&oacute;cio respons&aacute;vel pela reserva, atestando a veracidade dos
        registros. Par&aacute;grafo &uacute;nico &ndash; Ao final do uso da
        churrasqueira, ser&aacute; feita outra vistoria, responsabilizando-se o
        signat&aacute;rio do documento por eventuais perdas e danos causados.
      </p>
  
      <p> DO CANCELAMENTO DA RESERVA</p>
  
      <p>
        Art. 10. O cancelamento da reserva poder&aacute; ser feito no prazo de
        at&eacute; 72 (setenta e duas) horas antes da data marcada para a
        utiliza&ccedil;&atilde;o da churrasqueira, pela internet, pessoalmente,
        mediante solicita&ccedil;&atilde;o por escrito entregue na Secretaria
        Social, por e-mail ou fax. Par&aacute;grafo &uacute;nico. Ser&aacute;
        debitada no boleto do s&oacute;cio que n&atilde;o proceder ao cancelamento
        da reserva no prazo estabelecido neste artigo multa equivalente a 30%
        (trinta por cento) do valor da contribui&ccedil;&atilde;o fixa de
        administra&ccedil;&atilde;o mensal para as churrasqueiras pequenas, 50%
        (cinquenta por cento) para as churrasqueiras m&eacute;dias e de 90%
        (noventa por cento) para as churrasqueiras grandes ou gourmet.
      </p>
  
      <p>
        DAS PROIBI&Ccedil;&Otilde;ES Art. 11. Durante a utiliza&ccedil;&atilde;o
        da churrasqueira ficam proibidas: I. ocupa&ccedil;&atilde;o ou uso da
        estrutura, &aacute;rea e equipamentos com objetivo diverso de sua
        finalidade; II. Modifica&ccedil;&atilde;o da arquitetura ou retirada dos
        equipamentos; BI. Uso de karaok&ecirc; e a utiliza&ccedil;&atilde;o de
        aparelhagem de som ou qualquer equipamento para m&uacute;sica ao vivo
        acima do limite de 65 decib&eacute;is no per&iacute;odo diurno e 55
        decib&eacute;is no per&iacute;odo noturno; e IV. instala&ccedil;&atilde;o
        de rede para descanso, equipamentos de divers&atilde;o, tais como
        pula-pula, cama-el&aacute;stica, tobog&atilde;, balan&ccedil;o, palco ou
        outros assemelhados.
      </p>
  
      <p> DO CONVIDADO DO S&Oacute;CIO</p>
  
      <p>
        {" "}
        Art. 12. O convite para acesso ao Setor de Churrasqueiras &eacute;
        individual e espec&iacute;fico para o espa&ccedil;o reservado, devendo ser
        registrado, na Portaria, por meio do sistema de inform&aacute;tica do
        clube, o nome e o CPF do convidado, o nome e a identifica&ccedil;&atilde;o
        do t&iacute;tulo do s&oacute;cio ou usu&aacute;rio convidante, bem como o
        n&uacute;mero da churrasqueira a ser utilizada. &sect; 1o - Satisfeitas as
        condi&ccedil;&otilde;es estabelecidas no art. 3o, o associado ou o
        usu&aacute;rio tem direito a 40 (quarenta) convites para as churrasqueiras
        grandes ou gourmet, 20 (vinte) para as churrasqueiras m&eacute;dias e 10
        (dez) para as churrasqueiras pequenas, de segunda a sexta-feira, e, metade
        deste quantitativo, no per&iacute;odo diurno, aos s&aacute;bados,
        domingos, feriados e dias festivos do clube, permanecendo, no
        per&iacute;odo noturno, os quantitativos anteriores. &sect; 2o - Fica o
        associado ou usu&aacute;rio convidante respons&aacute;vel pela conduta de
        seu convidado, bem como, por qualquer dano por ele causado ao
        patrim&ocirc;nio do clube, ao de integrante do quadro social ou de
        terceiros, conforme disposto no art. 40, incisos X e XIII, do Estatuto.
        &sect; 3o - Para o fim do cumprimento do disposto no caput deste artigo, o
        Setor de Apoio do Iate manter&aacute; controle sobre a efetiva
        presen&ccedil;a e utiliza&ccedil;&atilde;o da churrasqueira por parte do
        s&oacute;cio ou usu&aacute;rio e seus convidados e, constatada a
        ociosidade permanente do espa&ccedil;o reservado, lavrar&aacute; Termo de
        Vistoria relatando o fato, sujeitando-se o associado respons&aacute;vel
        &agrave;s multas estabelecidas no par&aacute;grafo &uacute;nico do art.
        10, al&eacute;m das penas estabelecidas no Estatuto do Iate. Art. 13. O
        convidado ou o prestador de servi&ccedil;o ter&aacute; acesso ao Setor de
        Churrasqueiras mediante identifica&ccedil;&atilde;o na Portaria, por meio
        de credencial que lhe ser&aacute; fornecida pela Secretaria Social,
        sendo-lhe permitido, excepcionalmente, o ingresso de seu ve&iacute;culo ao
        estacionamento interno do clube, t&atilde;o somente para a descarga de
        material a ser utilizado na churrasqueira. &sect; 1o - No hor&aacute;rio
        pr&eacute;-determinado pela Secretaria Social para a sa&iacute;da do
        ve&iacute;culo, constante na credencial, dever&aacute; o convidado ou o
        prestador de servi&ccedil;o faz&ecirc;-lo, oportunidade em que
        devolver&aacute; na Portaria a credencial recebida.
      </p>
  
      <p>
        {" "}
        &sect; 2o - O convidado ou o prestador de servi&ccedil;o que n&atilde;o
        retirar o ve&iacute;culo das depend&ecirc;ncias do clube, no
        hor&aacute;rio pr&eacute;- estabelecido, ser&aacute; instado a
        faz&ecirc;-lo, respondendo o s&oacute;cio convidante pela eventual
        desobedi&ecirc;ncia e pela conduta de seu
      </p>
  
      <p>
        {" "}
        convidado. Art. 14. Aos s&aacute;bados, domingos, feriados e dias festivos
        do clube, o convidado para o Setor de Churrasqueiras ou o prestador de
        servi&ccedil;o, poder&atilde;o ingressar no clube ap&oacute;s as 12 (doze)
        horas, permitindo-se lhe, nos demais dias da semana, o acesso depois das 9
        (nove) horas. Art. 15. A utiliza&ccedil;&atilde;o de churrasqueira
        ap&oacute;s as 18 (dezoito) horas, em que haja a presen&ccedil;a de mais
        de 8 (oito) convidados, implica a contrata&ccedil;&atilde;o de um apoio e
        de um servente, devendo as indeniza&ccedil;&otilde;es por estas
        contrata&ccedil;&otilde;es serem recolhidas, &agrave; Tesouraria do clube,
        pelo s&oacute;cio respons&aacute;vel pela reserva, no ato da
        emiss&atilde;o dos convites e, caso n&atilde;o recolhida, ser&atilde;o
        debitadas no boleto do s&oacute;cio respons&aacute;vel.
      </p>
  
      <p> DAS DISPOSI&Ccedil;&Otilde;ES GERAIS</p>
  
      <p>
        {" "}
        Art. 16. O s&oacute;cio respons&aacute;vel pela reserva de churrasqueira
        fica obrigado a encaminhar &agrave; Secretaria Social, quando for o caso,
        com anteced&ecirc;ncia m&iacute;nima de 24 (vinte e quatro) horas, antes
        da data marcada para a utiliza&ccedil;&atilde;o do espa&ccedil;o
        reservado, pela internet, pessoalmente, por e-mail ou fax, a
        rela&ccedil;&atilde;o dos prestadores de servi&ccedil;os de churrasqueiros
        e dos ajudantes contratados, devidamente identificados.
      </p>
  
      <p>
        {" "}
        Art. 17. Os casos omissos ser&atilde;o decididos pelo Vice-Diretor do
        Setor ou Diretor do Dia, que se obrigam a levar o assunto &agrave;
        Comodoria. Art. 18. Este regulamento entra em vigor na data de sua
        publica&ccedil;&atilde;o no site do IATE. Art. 19. Revogam-se as
        disposi&ccedil;&otilde;es em contr&aacute;rio.{" "}
      </p>
    </div>
  );