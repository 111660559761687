import { ENDPOINTS } from "../constants/endpoints";
import useOldApi from "../hooks/useOldApi";
import { Noticia } from "../models/news.model";
import Axios from 'axios';

export function useNewsService() {
  const URL_IMAGEM_FALLBACK = 'https://www.iateclubedebrasilia.com.br/wp-content/uploads/2022/08/triangulo.jpg';
  const axios = useOldApi();

  const obterNoticias = async () => {
    try {
      const noticias = await getNoticiasDireto();
      return noticias;
    } catch (error) {
      const response = await axios.get<Noticia[]>(`${ENDPOINTS.obterNoticias}`);
      return response.data;
    }
  };

  const getNoticiasDireto = async () => {
    const response = await Axios.get<{
      id: number;
      modified: string;
      slug: string;
      status: string;
      link: string;
      title: { rendered: string };
      content: { rendered: string };
      _links: { "wp:featuredmedia": { href: string }[] }
    }[]>(ENDPOINTS.obterNoticiasDireto, {
      params: {
        per_page: 6
      },
      // timeout: 5000
    });
    if (response.status === 200) {
      const urlImagensPromises = response.data.map(n => buscarUrlThumbnail(n._links["wp:featuredmedia"][0].href));
      const urlImagens: string[] = await Promise.all(urlImagensPromises);
      const noticias = response.data.map((n, index) => ({
        id: n.id,
        dtaModificacao: n.modified,
        slug: n.slug,
        status: n.status,
        link: n.link,
        titulo: n.title.rendered,
        conteudo: n.content.rendered,
        textoCompleto: '',
        urlThumbnail: urlImagens[index]
      })) as Array<Noticia>;
      return noticias;
    }
    throw new Error('Erro tentando obter as notícias diretamente')
  };

  const buscarUrlThumbnail = async (url: string): Promise<string> => {
    try {
      const response = await Axios.get<{
        media_details: {
          sizes: {
            large: { source_url: string },
            medium: { source_url: string }
            thumbnail: { source_url: string }
          }
        }
      }>(url,);
      if (response.status === 200) {
        const sizes = response.data.media_details.sizes;
        return sizes.large?.source_url || sizes.medium?.source_url || sizes.thumbnail?.source_url;
      }
      return '';
    } catch (error) {
      return URL_IMAGEM_FALLBACK
    }
  }

  const services = {
    obterNoticias,
  };

  return services;
}
