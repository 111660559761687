// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Login_container__7TefY {\n  height: 100vh;\n  width: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  flex-direction: column;\n}\n\n.Login_waves__1Jezj {\n  position: absolute;\n  top: 0;\n  left: 0;\n  background-position: top;\n  background-size: contain;\n  background-repeat: no-repeat;\n  height: 50vh;\n  width: 100%;\n}\n\n.Login_backgroundImage__3zeNH {\n  justify-content: center;\n  background-position: top;\n  background-repeat: no-repeat;\n  background-size: contain;\n  width: 100%;\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  position: absolute;\n  top: 300px;\n}", "",{"version":3,"sources":["webpack://src/pages/Login/Login.module.scss"],"names":[],"mappings":"AAEA;EACI,aAAA;EACA,WAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,sBAAA;AADJ;;AAIA;EACI,kBAAA;EACA,MAAA;EACA,OAAA;EACA,wBAAA;EACA,wBAAA;EACA,4BAAA;EACA,YAAA;EACA,WAAA;AADJ;;AAIC;EACI,uBAAA;EACA,wBAAA;EACA,4BAAA;EACA,wBAAA;EACA,WAAA;EACA,YAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,kBAAA;EACA,UAAA;AADL","sourcesContent":["@import '../../styles/variables.scss';\r\n\r\n.container{\r\n    height: 100vh;\r\n    width: 100%;\r\n    display: flex;\r\n    align-items: center;\r\n    justify-content: center;\r\n    flex-direction: column;\r\n}\r\n\r\n.waves{\r\n    position: absolute;\r\n    top: 0;\r\n    left: 0;\r\n    background-position: top;\r\n    background-size: contain;\r\n    background-repeat: no-repeat;\r\n    height: 50vh;\r\n    width: 100%;\r\n}\r\n\r\n .backgroundImage{\r\n     justify-content: center;\r\n     background-position: top;\r\n     background-repeat: no-repeat;\r\n     background-size: contain;\r\n     width: 100%;\r\n     height: 100%;\r\n     display: flex;\r\n     flex-direction: column;\r\n     align-items: center;\r\n     position: absolute;\r\n     top: 300px;\r\n }\r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "Login_container__7TefY",
	"waves": "Login_waves__1Jezj",
	"backgroundImage": "Login_backgroundImage__3zeNH"
};
export default ___CSS_LOADER_EXPORT___;
